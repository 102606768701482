import Check from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCourse } from '../../utils/contexts/CourseContext';
import { useLocalStorage } from '../../utils/hooks/useLocalStorage';
import './ClassSelectorMenuItem.scss';

const ClassSelectorMenuItem = ({ type }) => {
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { removeItem } = useLocalStorage();

  const { availableCourses, selectedCourse, setSelectedCourse } = useCourse();

  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (availableCourses && !selectedCourse?.guid) {
      setSelectedCourse(availableCourses[0]);
    }
  }, [selectedCourse, availableCourses]);

  // for topbar type selector
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const openMenu = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (course, index) => {
    setSelectedCourse(course);
    setSelectedIndex(index);
    setAnchorEl(null);
    setSearchParams({});
    removeItem('/content/units');
    removeItem('/content/standards');
    removeItem('/students/units');
    removeItem('/students/standards');
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const listItemRef = useRef(null);

  const numberOfStudents =
    selectedCourse?.users?.filter((user) => user.role_guid === 'R01')?.length ||
    0;

  return (
    <div className='class-selector__container'>
      {type === 'topbar' ? (
        numberOfStudents > 0 && (
          <>
            <List component='nav'>
              <ListItemButton
                id='class-selector'
                onClick={handleClickListItem}
                className='class-selector__menu-button'
                disableRipple
                ref={listItemRef}
              >
                <ListItemText
                  primary={selectedCourse?.school_group_name}
                  secondary={`${numberOfStudents} ${t('students')}`}
                />
                <ListItemIcon>
                  <KeyboardArrowDownIcon />
                </ListItemIcon>
              </ListItemButton>
            </List>
            <Menu
              id='class-selector-menu'
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
              className='class-selector__menu'
            >
              {availableCourses
                ?.sort((a, b) =>
                  a.school_group_name.localeCompare(b.school_group_name)
                )
                .map((course, index) => (
                  <MenuItem
                    className='class-selector__menu-item'
                    key={course?.guid}
                    selected={course?.guid === selectedCourse?.guid}
                    onClick={() => handleMenuItemClick(course, index)}
                    style={{
                      minWidth: listItemRef.current
                        ? listItemRef.current.offsetWidth - 20
                        : 0
                    }}
                  >
                    {course.school_group_name}
                    {course?.guid === selectedCourse?.guid && (
                      <Check fontSize='small' />
                    )}
                  </MenuItem>
                ))}
            </Menu>
          </>
        )
      ) : (
        <>
          <Chip
            label={selectedCourse?.school_group_name}
            variant='contained'
            className='class-selector__chip'
            onClick={handleClickOpen}
          />
          <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
            <DialogTitle>{t('select_class_title')}</DialogTitle>
            <DialogContent>
              <div className='available-classes__container'>
                {availableCourses?.map((course) => (
                  <div
                    onClick={() => {
                      setSearchParams({});
                      removeItem('/content/units');
                      removeItem('/content/standards');
                      removeItem('/students/units');
                      removeItem('/students/standards');

                      setSelectedCourse(course);
                      handleClose();
                    }}
                    key={course?.guid}
                    className='class-selector__course'
                  >
                    <div className='number-of-students'>
                      {course?.users.length} {t('students')}
                    </div>
                    <div>{course.name}</div>
                    <div className='school-group-name'>
                      {course?.school_group_name}
                    </div>
                  </div>
                ))}
              </div>
            </DialogContent>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default ClassSelectorMenuItem;
