import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SearchWithFilterButton from '../../../atoms/SearchWithFilterButton/SearchWithFilterButton';
import EDDashboardTabs from '../../../components/EDDashboardTabs/EDDashboardTabs';
import Table from '../../../components/table/Table';
import PageWithHeader from '../../../layouts/pageWithHeader/PageWithHeader';
import ConfirmDeleteDialog from '../../tenantDashboard/schools/Edit/tabs/dialogs/ConfirmDeleteDialog';
import KnowledgeComponentsFilter from './components/KnowledgeComponentsFilter';
import NewKnowledgeComponentDialog from './components/NewKnowledgeComponentDialog';
import useKnowledgeComponentsManager from './useKnowledgeComponentsManager';

const KnowledgeComponentsManager = () => {
  const {
    isUserProgramEditor,
    data,
    columns,
    total,
    filters,
    search,
    numberOfFilters,
    isLoading,
    currentPage,
    totalPages,
    onChangeFilter,
    onChangeSearch,
    onChangePage,
    onCreateKnowledgeComponent,
    onDeleteKnowledgeComponent
  } = useKnowledgeComponentsManager();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [
    isNewKnowledgeComponentDialogOpen,
    setIsNewKnowledgeComponentDialogOpen
  ] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [kcToDelete, setKcToDelete] = useState(null);

  const handleClickOpen = () => {
    setIsNewKnowledgeComponentDialogOpen(true);
  };

  const handleClose = () => {
    setIsNewKnowledgeComponentDialogOpen(false);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <PageWithHeader
      left={
        <EDDashboardTabs
          tabs={[
            {
              name: t('learning_outcomes_tab_title'),
              to: '/learning-outcomes',
              key: 'learning-outcomes'
            },
            {
              name: t('knowledge_components_tab_title'),
              to: '/knowledge-components',
              key: 'knowledge-components'
            },
            {
              name: t('departments_tab_title'),
              to: '/departments',
              key: 'departments'
            }
          ]}
          currentTab={'knowledge-components'}
        />
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px'
        }}
      >
        <SearchWithFilterButton
          onClick={toggleDrawer}
          value={search}
          onChange={onChangeSearch}
          numberOfFilters={numberOfFilters}
          placeholder={t('search_placeholder')}
        />
        {!isUserProgramEditor && (
          <Button
            disableElevation
            color='primary'
            variant='contained'
            onClick={handleClickOpen}
            sx={{
              padding: '0.75rem 1.5rem',
              textTransform: 'none'
            }}
          >
            {t('Create')}
          </Button>
        )}
      </div>
      <div>
        <div
          style={{
            marginBottom: '19px',
            color: '#8c99ad',
            fontSize: '12px',
            fontWeight: '600'
          }}
        >
          <span
            style={{
              color: '#414b5c'
            }}
          >
            {Intl.NumberFormat().format(total)}
          </span>{' '}
          {t('knowledge_components_have_been_found')}
        </div>
      </div>

      <Table
        name='kc'
        data={data}
        columns={columns}
        isLoading={isLoading || !total}
        onRowClick={
          isUserProgramEditor
            ? null
            : (e) => {
                navigate(`/knowledge-components/${e.guid}`);
              }
        }
        showPagination
        showTopPagination
        paginationSize='large'
        totalPages={totalPages}
        currentPage={parseInt(currentPage) || 1}
        onChangePage={onChangePage}
        isCustomizable
        tableColumnsVisibility={{
          name: true,
          description: true,
          knowledgeComponents: true,
          department: false
        }}
        isFirstColumnSticky
        isLastColumnSticky
        useApiSorting={totalPages > 1}
        onChangeSort={onChangeFilter}
        options={
          !isUserProgramEditor && [
            {
              icon: <BorderColorOutlinedIcon />,
              label: t('edit'),
              onClick: (e) => {
                navigate(`/knowledge-components/${e.guid}`);
              }
            },
            {
              icon: <DeleteOutlineIcon />,
              label: t('delete'),
              onClick: (kc) => {
                setKcToDelete(kc);
                setIsConfirmDeleteDialogOpen(true);
              }
            }
          ]
        }
      />

      <NewKnowledgeComponentDialog
        isOpen={isNewKnowledgeComponentDialogOpen}
        onClose={handleClose}
        onSubmit={(newKC) => {
          handleClose();
          onCreateKnowledgeComponent(newKC);
        }}
      />

      <KnowledgeComponentsFilter
        isDrawerOpen={isDrawerOpen}
        currentFilters={filters}
        onSearch={(value) => {
          onChangeFilter(value);
          setIsDrawerOpen(false);
        }}
        onCloseDrawer={() => setIsDrawerOpen(false)}
      />

      <ConfirmDeleteDialog
        isOpen={isConfirmDeleteDialogOpen}
        onClose={() => {
          setKcToDelete(null);
          setIsConfirmDeleteDialogOpen(false);
        }}
        onConfirm={() => {
          setIsConfirmDeleteDialogOpen(false);
          onDeleteKnowledgeComponent(kcToDelete);
          setKcToDelete(null);
        }}
        itemToBeDeletedName={kcToDelete?.name}
        itemToBeDeletedDescription={''}
      />
    </PageWithHeader>
  );
};

export default KnowledgeComponentsManager;
