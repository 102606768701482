import { Skeleton, Typography, styled } from '@mui/material';
import React from 'react';
import EDInput from './EDInput';

const Label = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: 1.58,
  color: theme.palette.grey[500],
  marginBottom: theme.spacing(0.5)
}));

const EDInputWithLabel = ({ label, required, isLoading, ...props }) => {
  return (
    <div>
      {isLoading ? (
        <Skeleton variant='text' />
      ) : (
        <Label>
          {label}
          {required && ` (*)`}
        </Label>
      )}
      {isLoading ? (
        <Skeleton variant='rectangular' />
      ) : (
        <EDInput
          fullWidth
          {...props}
          inputProps={{
            ...props.inputProps,
            autoComplete: props.disableAutocomplete ? 'one-time-code' : 'on'
          }}
        />
      )}
    </div>
  );
};

export default EDInputWithLabel;
