import React, { useState } from 'react';
import TableDashboard from '../../../../../view/tableDashboard/TableDashboard';
import { createColumnHelper } from '@tanstack/react-table';
import EDCard from '../../../../../atoms/EDCard/EDCard';
import { Grid, IconButton, Typography } from '@mui/material';
import CreateNewUserDialog from '../../../users/List/Dialogs/CreateNewUserDialog';
import ImportUsersDialog from '../../../users/List/Dialogs/ImportUsersDialog/ImportUsersDialog';
import AddUsersToGroupDialog from './dialogs/AddUsersToGroupDialog';
import CellTag from '../../../../../components/table/cell/cellTypes/CellTag';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DeleteForever } from '@mui/icons-material';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog';

const SchoolUsers = ({
  users,
  onClickOnUser,
  onCreateNewUser,
  newUserDialogOpen,
  setNewUserDialogOpen,
  newUserSavedState,
  setNewUserSavedState,
  totalUsersInSchool,
  groups,
  schoolGuid,
  onDeleteUser,
  getUsersInSchool,
  getSchoolGroups,
  newImportUsersDialogOpen,
  setImportUsersDialogOpen
}) => {
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name'
    }),
    columnHelper.accessor('lastname', {
      id: 'lastname',
      header: 'Lastname'
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: 'Email'
    }),
    columnHelper.accessor('role_name', {
      id: 'role_name',
      header: 'Role'
    }),
    columnHelper.accessor('group', {
      id: 'group',
      header: 'Group',
      cell: (info) => {
        const auxGroups = info.getValue();
        return (
          <CellTag
            tags={auxGroups.map((item) => ({
              label: item.name,
              description: `${item.education_year_name} ${item.education_level_name}`
            }))}
          />
        );
      }
    })
  ];

  const roles = [
    { guid: 'R01', role: 'Student' },
    { guid: 'R02', role: 'Teacher' },
    { guid: 'R03', role: 'School Admin' },
    { guid: 'R04', role: 'Editorial Admin' },
    { guid: 'R05', role: 'Help desk' }
  ];

  // const [isCreateNewUserOpen, setIsCreateNewUserOpen] = useState(false);

  const [userToDelete, setUserToDelete] = useState(null);
  const [confirmDeleteUserDialogOpen, setConfirmDeleteUserDialogOpen] =
    useState(false);

  const onCloseNewUserDialog = () => {
    setNewUserDialogOpen(false);
  };

  const onCloseImportDialog = async () => {
    await getUsersInSchool();
    await getSchoolGroups();
    setImportUsersDialogOpen(false);
  };

  const submit = async (params) => {
    const isCreatedOk = await onCreateNewUser(params);

    if (isCreatedOk) {
      setNewUserSavedState(null);
      onClose();
    }
  };

  const [isAddUsersToGroupDialogOpen, setIsAddUsersDialogOpen] =
    useState(false);

  const onCloseAddUsersToGroupDialog = () => {
    setIsAddUsersDialogOpen(false);
  };

  const onRemoveUserFromGroup = (user) => {
    setConfirmDeleteUserDialogOpen(true);
    setUserToDelete(user);
  };

  const handleCloseConfirmDeleteUserDialog = () => {
    setConfirmDeleteUserDialogOpen(false);
    setUserToDelete(null);
  };

  return (
    <Grid item xs={12}>
      <EDCard elevation={0}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2'>Users</Typography>
          </Grid>

          <Grid item xs={12}>
            <TableDashboard
              filters={[]}
              onSearch={() => {}}
              onFilter={() => {}}
              tableData={users}
              onRowClick={onClickOnUser}
              tableColumns={columns}
              totalEntries={totalUsersInSchool}
              onClickOnCreate={() => setNewUserDialogOpen(true)}
              extraActions={[
                {
                  label: 'Add existing users to group',
                  onClick: () => setIsAddUsersDialogOpen(true),
                  variant: 'outlined'
                },
                {
                  label: 'Import',
                  onClick: () => setImportUsersDialogOpen(true)
                }
              ]}
              singleEntryLabel={'user'}
              pluralEntryLabel={'users'}
              isTableDataLoading={false}
              searchInputPlaceholder={'Search users'}
              options={[
                {
                  label: 'Remove user',
                  onClick: onRemoveUserFromGroup,
                  icon: <DeleteForever />
                }
              ]}
            />
          </Grid>
        </Grid>
      </EDCard>

      {newImportUsersDialogOpen && (
        <ImportUsersDialog
          roles={roles}
          isOpen={newImportUsersDialogOpen}
          onClose={onCloseImportDialog}
          schoolGuid={schoolGuid}
        />
      )}
      <CreateNewUserDialog
        roles={roles}
        onSubmit={submit}
        isOpen={newUserDialogOpen}
        onClose={onCloseNewUserDialog}
        newUserSavedState={newUserSavedState}
        setNewUserSavedState={setNewUserSavedState}
        groups={groups}
      />
      {isAddUsersToGroupDialogOpen && (
        <AddUsersToGroupDialog
          isOpen={isAddUsersToGroupDialogOpen}
          onClose={onCloseAddUsersToGroupDialog}
          groups={groups}
          schoolGuid={schoolGuid}
          currentUsers={users}
          getUsersInSchool={getUsersInSchool}
          getSchoolGroups={getSchoolGroups}
        />
      )}
      {confirmDeleteUserDialogOpen && (
        <ConfirmDeleteDialog
          isOpen={confirmDeleteUserDialogOpen}
          onClose={handleCloseConfirmDeleteUserDialog}
          itemToBeDeletedName={'user'}
          itemToBeDeletedDescription={
            "By deleting this user, you are removing the user from all the groups inside this school, and removing the user's access to the school."
          }
          onConfirm={() => {
            onDeleteUser(userToDelete);
            handleCloseConfirmDeleteUserDialog();
          }}
        />
      )}
    </Grid>
  );
};

export default SchoolUsers;
