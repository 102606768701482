import React, { useState } from 'react';
import FullPageLayout from '../../../layouts/dashboardFullPage/FullPageLayout';
import BackButton from '../../../atoms/BackButton/BackButton';
import { Container, Grid, Typography } from '@mui/material';
import EDTabs from '../../../components/EDTabs/EDTabs';
import EDButton from '../../../atoms/EDButton/EDButton';
import Toast from '../../../atoms/Toast/Toast';
import { useTranslation } from 'react-i18next';
import useSingleKnowledgeComponent from './useSingleKnowledgeComponent';
import KnowledgeComponentMetadata from './components/KnowledgeComponentMetadata';
import KnowledgeComponentDependencies from './components/KnowledgeComponentDependencies';
import KnowledgeComponentLOs from './components/KnowledgeComponentLOs';

const SingleKnowledgeComponent = () => {
  const {
    tabs,
    isLoading,
    knowledgeComponent,
    onChange,
    isUpdating,
    onSaveKnowledgeComponentMetadata,
    showToast,
    toastMessage,
    activities,
    setShowToast,
    setToastMessage,
    dependenciesTableColumns,
    learningOutcomesTableColumns,
    dependencies,
    learningOutcomes,
    educationYears,
    onAddDependencies,
    onRemoveDependencies
  } = useSingleKnowledgeComponent();
  const { t } = useTranslation();
  // add tabs here
  const [currentTab, setCurrentTab] = useState('metadata');

  const [tempKC, setTempKC] = useState(knowledgeComponent);

  const onChangeKC = (value) => {
    setTempKC(value);
  };

  return (
    <FullPageLayout
      left={<BackButton onClick={() => history.back()} />}
      title={
        <Typography variant='h3'>
          Edit Knowledge Component "{knowledgeComponent?.name}"
        </Typography>
      }
      footerRight={
        currentTab === 'metadata' && (
          <EDButton
            disableElevation
            color='primary'
            variant='contained'
            sx={{
              padding: '0.75rem 1.5rem',
              textTransform: 'none'
            }}
            onClick={() => onSaveKnowledgeComponentMetadata(tempKC)}
            disabled={isUpdating}
          >
            {isUpdating ? '...' : t('Save')}
          </EDButton>
        )
      }
    >
      <Container maxWidth='xl'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EDTabs
              tabs={tabs}
              currentTab={currentTab}
              onClick={(selectedTab) => {
                setCurrentTab(selectedTab.key);
              }}
            />
          </Grid>
          {currentTab === 'metadata' && (
            <KnowledgeComponentMetadata
              isLoading={isLoading}
              knowledgeComponent={knowledgeComponent}
              onChange={onChangeKC}
            />
          )}
          {currentTab === 'dependencies' && (
            <KnowledgeComponentDependencies
              columns={dependenciesTableColumns}
              educationYears={educationYears}
              onAddDependencies={onAddDependencies}
              onRemoveDependencies={onRemoveDependencies}
              data={dependencies}
              isLoading={false}
            />
          )}
          {currentTab === 'los' && (
            <KnowledgeComponentLOs
              columns={learningOutcomesTableColumns}
              data={learningOutcomes}
              isLoading={false}
            />
          )}
        </Grid>
        {showToast && (
          <Toast
            handleShowToast={setShowToast}
            type={toastMessage.type}
            text={toastMessage.text}
          />
        )}
      </Container>
    </FullPageLayout>
  );
};

export default SingleKnowledgeComponent;
