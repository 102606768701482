import { CardContent, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import EDCard from '../../../../atoms/EDCard/EDCard';

const ProgramLoading = () => {
  return (
    <Grid item xs={12}>
      <EDCard elevation={0}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        </CardContent>
      </EDCard>
    </Grid>
  );
};

export default ProgramLoading;
