import { createColumnHelper } from '@tanstack/react-table';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { ReactComponent as HasDataAppIcon } from '../../../assets/icons/has_app.svg';
import CellSeedStatusChip from '../../../components/table/cell/cellTypes/CellSeedStatusChip';
import CellTag from '../../../components/table/cell/cellTypes/CellTag';
import CellWithOneLineOfTextLarge from '../../../components/table/cell/cellTypes/CellWithOneLineOfTextLarge';
import { fetchWithToken } from '../../../utils/helpers/fetcher';

import { useTranslation } from 'react-i18next';
import { lastUpdate } from '../../../utils/helpers/lastUpdate';
import useEditorDashboardPermissions from '../../../utils/hooks/useEditorDashboardPermissions';

const useActivitiesManager = () => {
  const PAGE_SIZE = 25;
  const columnHelper = createColumnHelper();
  const { t } = useTranslation();

  const { isUserReviewer, isUserProgramEditor } =
    useEditorDashboardPermissions();

  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState({});
  const [numberOfFilters, setNumberOfFilters] = useState(0);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [currentTab, setCurrentTab] = useState('activities');
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(total / PAGE_SIZE);

  const [isNewActivityDialogOpen, setIsNewActivityDialogOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ type: '', text: '' });

  const [tabs, setTabs] = useState(
    isUserReviewer
      ? [
          {
            key: 'activities',
            name: t('activities_tab_title'),
            to: '/activities'
          }
        ]
      : [
          {
            key: 'activities',
            name: t('activities_tab_title'),
            to: '/activities'
          },
          {
            key: 'programs',
            name: t('programs_tab_title'),
            to: '/programs'
          }
        ]
  );

  const handleOpenActivityDialog = () => {
    setIsNewActivityDialogOpen(true);
  };

  const handleCloseActivityDialog = () => {
    setIsNewActivityDialogOpen(false);
  };

  // Move to translation file???
  const translatedPhaseName = {
    evocar: t('Evoke'),
    identificar: t('Identify'),
    aplicar: t('Apply')
  };

  const columns = [
    columnHelper.accessor('name', {
      header: t('activity_name'),
      id: 'name',
      enableSorting: true,
      sortingFn: 'cellWithOneLineOfTextLarge',
      enableHiding: false,
      cell: (info) => (
        <CellWithOneLineOfTextLarge text={info.row.original.name} />
      )
    }),
    columnHelper.accessor('hasDataApp', {
      header: t('activity_App'),
      id: 'hasDataApp',
      enableSorting: false,
      sortingFn: 'basic',
      cell: (info) =>
        info.renderValue() ? (
          <span
            style={{
              // textAlign: 'center',
              display: 'inline-block',
              width: '100%',
              paddingLeft: '21px'
            }}
          >
            <HasDataAppIcon />
          </span>
        ) : (
          ''
        )
    }),
    columnHelper.accessor('learningOutcomes', {
      header: t('activity_LO'),
      id: 'learningOutcomes',
      enableSorting: false,
      sortingFn: 'cellTag',
      canSort: false,
      cell: (props) => <CellTag tags={props.row.original.learningOutcomes} />
    }),
    columnHelper.accessor('seedTemplate', {
      header: 'Template',
      id: 'seedTemplate',
      enableSorting: false,
      sortingFn: 'alphanumeric',
      cell: (props) => (
        <CellWithOneLineOfTextLarge text={props.row.original.seedTemplate} />
      )
    }),
    columnHelper.accessor('responsible', {
      header: t('activity_responsible'),
      id: 'responsible',
      enableSorting: false,
      sortingFn: 'alphanumeric',
      cell: (info) => info.renderValue()
    }),
    columnHelper.accessor('lastUpdate', {
      header: t('activity_last_update'),
      id: 'updatedAt',
      enableSorting: true,
      sortingFn: 'datetime',
      cell: (info) => info.renderValue()
    }),
    columnHelper.accessor('status', {
      header: t('activity_status'),
      id: 'status',
      enableSorting: false,
      sortingFn: 'alphanumeric',
      enableHiding: false,
      cell: (info) => <CellSeedStatusChip status={info.row.original.status} />
    })
  ];

  const tableColumnsVisibility = {
    seedTemplate: false
  };

  const onChangeFilter = (newFilters) => {
    let tempFilters = { ...newFilters };
    // remove page from filters to avoid pagination issues when changing filters
    delete tempFilters.page;
    // filters are added if they do not exist and replaced if they do
    setFilters({ ...tempFilters, search: search });
    // save filters on url
    saveFiltersInURL({ ...tempFilters, search: search });
    // update number of filters
    Object.keys(
      Object.keys(tempFilters).filter(
        (item) => !['search', 'orderBy', 'orderType'].includes(item)
      )
    ).length - 1;

    getData();
  };

  const saveFiltersInURL = (newFilters) => {
    // check if all filters are empty
    const allFiltersEmpty = Object.values(newFilters).every(
      (filter) => filter === '' || filter === null || filter === undefined
    );

    // if all filters are empty, remove filters from url
    if (allFiltersEmpty) {
      const searchParams = new URLSearchParams(window.location.search);

      const URLfilters = Object.fromEntries(searchParams);
      Object.keys(URLfilters).forEach((key) => {
        // delete except search
        if (!['search', 'orderBy', 'orderType'].includes(key)) {
          searchParams.delete(key);
        }
      });

      const newUrl = `${window.location.pathname}${
        searchParams.toString() !== ''
          ? `?${searchParams.toString()}`
          : searchParams.toString()
      }`;

      history.pushState(null, '', newUrl);
      return;
    }

    const searchParams = new URLSearchParams();
    const tempFilters = { ...newFilters };
    delete tempFilters.offset;

    Object.keys(tempFilters).forEach((key) => {
      if (
        tempFilters[key] !== '' &&
        tempFilters[key] !== null &&
        tempFilters[key] !== undefined
      ) {
        searchParams.append(
          key,
          typeof tempFilters[key] === 'object'
            ? JSON.stringify(tempFilters[key])
            : tempFilters[key]
        );
      }
    });
    // searchParams.set('filters', JSON.stringify(newFilters));
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    history.pushState(null, '', newUrl);
  };

  const getFiltersFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    // get all search params
    let URLfilters = {};
    try {
      URLfilters = Object.fromEntries(searchParams);
      Object.keys(URLfilters).forEach((key) => {
        // check if value is a stringified object
        if (URLfilters[key].startsWith('{')) {
          URLfilters[key] = JSON.parse(URLfilters[key]);
        }

        // check if value is a stringified array
        if (URLfilters[key].startsWith('[')) {
          URLfilters[key] = JSON.parse(URLfilters[key]);
        }
      });
    } catch (e) {
      console.log('error parsing filters', e);
    }

    setFilters(URLfilters);
    setSearch(URLfilters.search);
    setOffset(((URLfilters.page || 1) - 1) * PAGE_SIZE);
    setCurrentPage(URLfilters.page || 1);

    // update number of filters
    setNumberOfFilters(
      Object.keys(URLfilters).filter(
        (item) =>
          !['search', 'page', 'orderBy', 'orderType'].includes(item) &&
          URLfilters[item] !== '' &&
          URLfilters[item] !== null &&
          URLfilters[item] !== undefined
      ).length
    );

    return { ...URLfilters, offset: ((URLfilters.page || 1) - 1) * PAGE_SIZE };
  };

  const onChangeSearch = (searchValue) => {
    setSearch(searchValue);
    debounceSearch(searchValue);
  };

  const onChangePage = (e, page) => {
    setCurrentPage(page);
    setOffset((page - 1) * PAGE_SIZE);

    saveFiltersInURL({ ...filters, page: page });
  };

  const getData = async () => {
    // to avoid multiple calls
    if (isLoading) return;
    setIsLoading(true);

    const filtersFromURL = getFiltersFromURL();

    const queryParameters = [
      `offset=${
        filtersFromURL.offset !== undefined && filtersFromURL.offset !== null
          ? filtersFromURL.offset
          : offset
      }`,
      `pageSize=${PAGE_SIZE}`,
      filtersFromURL.status &&
        filtersFromURL.status.map((s) => `status[]=${s}`).join('&'),
      filtersFromURL.phase &&
        filtersFromURL.phase.map((s) => `phase[]=${s}`).join('&'),
      filtersFromURL.learningObjective &&
        filtersFromURL.learningObjective
          .map((s) => `nodeGuid[]=${s.id}`)
          .join('&'),
      filtersFromURL.responsible &&
        filtersFromURL.responsible &&
        `responsible[]=${filtersFromURL.responsible}`,
      filtersFromURL.updatedBy && `updatedBy=${filtersFromURL.updatedBy}`,
      filtersFromURL.createdBy && `createdBy=${filtersFromURL.createdBy}`,
      filtersFromURL.search && `search=${filtersFromURL.search}`,
      filtersFromURL.jsonStimulus &&
        `jsonStimulus=${filtersFromURL.jsonStimulus}`,
      filtersFromURL.language && `langCode=${filtersFromURL.language}`,
      filtersFromURL.educationYearGuid &&
        `educationYearGuid=${filtersFromURL.educationYearGuid}`,
      filtersFromURL.updatedAt && `updatedAt=${filtersFromURL.updatedAt}`,
      filtersFromURL.seedTemplate &&
        `seedTemplate=${filtersFromURL.seedTemplate}`,
      filtersFromURL.hasDataApp && `hasDataApp=${filtersFromURL.hasDataApp}`,
      filtersFromURL.departments &&
        `departmentGuid[]=${filtersFromURL.departments}`,
      `orderBy=${filtersFromURL.orderBy || 'updatedAt'}`,
      `orderType=${filtersFromURL.orderType || 'DESC'}`,
      `includeLang=0`,
      // `includeData=0`,
      `includeInternalEducationYear=0`,
      `includeEducationYear=0`
    ];

    const queryParams = queryParameters.filter(Boolean).join('&');
    const url = `/seeds?${queryParams}`;

    const response = await fetchWithToken({
      method: 'GET',
      path: url
    });

    setTotal(response.data.count);

    setData(
      response.data.loSeeds.map((seed) => ({
        id: seed.guid || t('no_data'),
        activityId: seed.data ? parseJSON(seed.data).id : t('no_data'),
        name: seed.name || '',
        learningOutcomes: seed.loNodeSeeds
          ? seed.loNodeSeeds.map((node) => ({
              label:
                node.node?.name?.slice('', 30) +
                  (node.node?.name?.length > 30 ? '...' : '') || t('no_data'),
              description: node.node?.description || t('no_data')
            }))
          : {
              label: t('no_data'),
              description: t('no_data')
            },
        learningOutcomesDescription: seed.loNodeSeeds[0]
          ? seed.loNodeSeeds[0].node?.description
          : t('no_data'),
        phase: seed.loNodeSeeds[0]
          ? translatedPhaseName[seed.loNodeSeeds[0].phase]
          : null,
        responsible: seed.responsible
          ? seed.responsible?.name + ' ' + seed.responsible?.lastname
          : null,
        seedTemplate: seed.template || t('no_data'),
        lastUpdate: lastUpdate(
          seed.updatedAt,
          (seed.updatedBy || seed.createdBy).name || t('no_data'),
          t
        ),
        status: seed.status || 'to_do',
        // educationYear: seed.educationYear
        //   ? seed.educationYear?.year + 'º Primaria'
        //   : null,
        // internalEducationYear: seed.internalEducationYear
        //   ? seed.internalEducationYear?.year + 'º Primaria'
        //   : null,
        // lang: seed.lang ? seed.lang.code : null,
        // stimulus: seed.data ? getStimulus(parseJSON(seed.data)) : null,
        lemonadeJSON: parseJSON(seed.data),
        hasDataApp: seed.haveDataApp
      }))
    );

    setIsLoading(false);
  };

  const parseJSON = (json) => {
    try {
      return JSON.parse(json);
    } catch (e) {
      return {};
    }
  };

  const debounceSearch = useCallback(
    debounce((searchValue) => {
      let filtersFromURL = getFiltersFromURL();
      delete filtersFromURL.page;
      saveFiltersInURL({ ...filtersFromURL, search: searchValue });
      getData();
      return;
    }, 500),
    []
  );

  const onCreateActivity = async (newActivityData) => {
    const response = await fetchWithToken({
      path: '/seeds',
      method: 'POST',
      data: newActivityData
    });
    if (response.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_activity_saved_success')
      });
      setIsNewActivityDialogOpen(false);
      // Reload the table with the new data

      getData();
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_activity_saved_error')
      });
    }
    setShowToast(true);
  };

  const onDeleteActivity = async (activityGuid) => {
    const response = await fetchWithToken({
      path: `/seeds/${activityGuid}`,
      method: 'DELETE'
    });

    if (response.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_activity_deleted_success')
      });
      getData();
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_activity_deleted_error')
      });
    }

    setShowToast(true);
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  return {
    isUserReviewer,
    isUserProgramEditor,
    tabs,
    data,
    total,
    search,
    columns,
    tableColumnsVisibility,
    filters,
    isLoading,
    currentTab,
    showToast,
    toastMessage,
    setFilters,
    onChangeSearch,
    onChangeFilter,
    numberOfFilters,
    currentPage,
    totalPages,
    isNewActivityDialogOpen,
    onChangePage,
    setShowToast,
    setToastMessage,
    onDeleteActivity,
    onCreateActivity,
    handleOpenActivityDialog,
    handleCloseActivityDialog
  };
};

export default useActivitiesManager;
