import { Drawer, IconButton } from '@mui/material';
import React from 'react';
import './FilterDrawer.scss';
import { Close } from '@mui/icons-material';
import EDButton from '../EDButton/EDButton';
import { useTranslation } from 'react-i18next';

const FilterDrawer = ({
  title,
  children,
  isDrawerOpen,
  onCloseDrawer,
  onReset,
  onSearch,
  isSearchDisabled
}) => {
  const { t } = useTranslation();

  return (
    <Drawer anchor={'right'} open={isDrawerOpen} onClose={onCloseDrawer}>
      <div className='filter'>
        <div className='filter-header'>
          <div className='filter-header__title'>{title}</div>
          <div className='filter-header__close'>
            <IconButton onClick={onCloseDrawer}>
              <Close />
            </IconButton>
          </div>
        </div>
        <div className='filter-content'>{children}</div>
        <div className='filter-footer'>
          <div className='filter-footer__container'>
            <div className='filter-footer__reset'>
              <EDButton
                variant='text'
                disabled={isSearchDisabled}
                onClick={onReset}
              >
                {t('filter_reset')}
              </EDButton>
            </div>
            <div className='filter-footer__search'>
              <EDButton
                variant='contained'
                disableElevation
                disabled={isSearchDisabled}
                onClick={onSearch}
              >
                {t('filter_search')}
              </EDButton>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default FilterDrawer;
