import React, { useContext, useEffect, useState } from 'react';
import GetQuestionForm from './components/GetQuestionForm';
import UpdateQuestionJSONForm from './components/UpdateQuestionJSONForm';
import './UpdateActivity.scss';
import FullPageLayout from '../../../layouts/dashboardFullPage/FullPageLayout';
import { Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { fetchWithToken } from '../../../utils/helpers/fetcher';

// This page is only for admin users with role R04
// The page will look for questions by ID and display them in a JSON editor
// The user will be able to edit the question JSON and save them
const UpdateActivity = () => {
  const [isLoadingQuestion, setIsLoadingQuestion] = useState(false);
  const [loadingQuestionError, setLoadingQuestionError] = useState(null);
  const [isLoadingNewJSON, setIsLoadingNewJSON] = useState(false);
  const [questionSeeds, setQuestionSeeds] = useState([]);
  const [questionGuid, setQuestionGuid] = useState('');

  const onGetQuestion = async (guid) => {
    setLoadingQuestionError(false);
    setIsLoadingQuestion(true);
    setQuestionGuid(guid);
    const response = await fetchWithToken({
      path: `/seeds?offset=0&pageSize=25&jsonID=${guid}`
    });
    if (response.status === 'success') {
      try {
        setQuestionSeeds(
          response.data.loSeed.map((seed) => ({
            ...seed,
            data: JSON.parse(seed.data)
          }))
        );
        setLoadingQuestionError(null);
        setIsLoadingQuestion(false);
        if (response.data.loSeed.length === 0) {
          setLoadingQuestionError('Question not found');
        }
      } catch (e) {
        setIsLoadingQuestion(false);
        setLoadingQuestionError('Question not found or Invalid JSON');
      }
    } else {
      setIsLoadingQuestion(false);
      setLoadingQuestionError(response);
    }
  };

  const onSubmitNewJSON = async (newJSON, questionSeedGuid) => {
    setIsLoadingNewJSON(true);
    const response = await fetchWithToken({
      path: `/seeds/${questionSeedGuid}`,
      method: 'PATCH',
      data: JSON.stringify({
        data: JSON.stringify(newJSON)
      })
    });
    if (response.status === 'success') {
      try {
        await onGetQuestion(questionGuid);
        // setQuestionSeeds(newJSON)
        alert('Success');
      } catch (e) {
        console.log(e);
      }
    }
    setIsLoadingNewJSON(false);
  };

  return (
    <FullPageLayout
      title={<Typography variant='h2'>Update Activity</Typography>}
    >
      <Container className='update-activity'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card elevation={0}>
              <CardContent>
                <span className='text size-16 weight-700'>Get Question</span>
                <GetQuestionForm
                  onSubmit={onGetQuestion}
                  isLoading={isLoadingQuestion}
                  error={loadingQuestionError}
                />
              </CardContent>
            </Card>
          </Grid>

          {Boolean(questionSeeds.length) && (
            <Grid item xs={12}>
              <Card elevation={0}>
                <CardContent>
                  {questionSeeds.map((question) => (
                    <div key={question.guid}>
                      <span className='text size-16 weight-700'>
                        Question {question.guid} details
                      </span>
                      <UpdateQuestionJSONForm
                        isLoading={isLoadingNewJSON}
                        json={question.data}
                        guid={question.guid}
                        onSubmit={onSubmitNewJSON}
                      />
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </FullPageLayout>
  );
};

export default UpdateActivity;
