import { Search, Tune } from '@mui/icons-material';
import {
  Badge,
  Button,
  FormGroup,
  InputAdornment,
  TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './SearchWithFilterButton.scss';

const SearchWithFilterButton = ({
  onClick = () => {},
  onChange = () => {},
  value = '',
  placeholder = '',
  numberOfFilters = 0
}) => {
  const { t } = useTranslation();

  return (
    <form autoComplete='off'>
      <FormGroup row>
        <TextField
          variant='outlined'
          placeholder={placeholder}
          className='search-with-filter-button__search-input'
          onChange={({ target: { value } }) => onChange(value)}
          value={value}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Search sx={{ color: '#8c99ad' }} />
              </InputAdornment>
            )
          }}
        />
        <Badge badgeContent={numberOfFilters} color='primary'>
          <Button
            variant='contained'
            disableElevation
            color='inherit'
            className='search-with-filter-button__filter-button'
            startIcon={<Tune sx={{ color: '#8c99ad' }} />}
            onClick={onClick}
          >
            {t('Filter')}
          </Button>
        </Badge>
      </FormGroup>
    </form>
  );
};

SearchWithFilterButton.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  numberOfFilters: PropTypes.number,
  value: PropTypes.string,
  placeholder: PropTypes.string
};

export default SearchWithFilterButton;
