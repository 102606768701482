import { Outlet } from 'react-router-dom';
import TDLinkTabs from '../../../components/linkTabs/TDLinkTabs';
import PageTitleWithTooltip from '../../../components/pageTitleWithTooltip/PageTitleWithTooltip';
import PageWithHeader from '../../../layouts/pageWithHeader/PageWithHeader';

import { useTranslation } from 'react-i18next';
import TDDepartmentsFilter from '../../../components/filters/TDDepartmentsFilter';
import TDUnitsFilter from '../../../components/filters/TDUnitsFilter';
import useStudentsPage from './useStudentsPage';

const StudentsPage = () => {
  const { tabs, currentTab, onChangeTab } = useStudentsPage();

  const { t } = useTranslation();

  return (
    <PageWithHeader
      left={
        <PageTitleWithTooltip
          title={t('students_analysis')}
          tooltip={t('students_analysis_info')}
        />
      }
      center={<TDLinkTabs links={tabs} onChange={onChangeTab} />}
      right={
        currentTab === tabs[0]?.name ? (
          <TDUnitsFilter />
        ) : (
          <TDDepartmentsFilter />
        )
      }
    >
      <Outlet />
    </PageWithHeader>
  );
};

export default StudentsPage;
