import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  Select,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import PropTypes from 'prop-types';

import React, { useEffect, useState } from 'react';
import './FilterItem.scss';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '& .Mui-expanded': {
    backgroundColor: '#f0f3f8',
    borderRadius: '0.5rem',
    borderBottom: 0
  },
  border: 0,
  borderBottom: 0,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  },
  '&:after': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  backgroundColor: 'white',
  flexDirection: 'row-reverse',
  color: '#8c99ad',
  fontSize: '1rem',
  lineHeight: 1.64,
  fontWeight: 500,
  '& .Mui-expanded': {
    color: '#414b5c'
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)',
    color: '#8c99ad'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
    color: '#414b5c'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '0.625rem'
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '1rem',
  paddingTop: '0.5rem'
}));

// types: checkbox, radio, dropdown
// options: [{label: 'label', value: 'value'}]
// onChange: (option) => void
// multiple: boolean
const FilterItem = ({
  type,
  title,
  value,
  options,
  multiple,
  onChange,
  customFilter,
  defaultSelection
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultSelection);

  useEffect(() => {
    setSelectedValue(defaultSelection);
    if (typeof defaultSelection === 'string' && defaultSelection !== '') {
      setIsOpen(true);
    } else {
      defaultSelection.length > 0 ? setIsOpen(true) : setIsOpen(false);
    }
  }, [defaultSelection]);

  useEffect(() => {
    onChange(selectedValue);
  }, [selectedValue]);

  return (
    <Accordion onChange={() => setIsOpen(!isOpen)} expanded={isOpen}>
      <AccordionSummary>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {type === 'checkbox' && (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                label={option.label}
                value={option.value}
                control={<Checkbox />}
                checked={selectedValue.includes(option.value)}
                onChange={() => {
                  if (multiple) {
                    if (selectedValue.includes(option.value)) {
                      setSelectedValue(
                        selectedValue.filter((value) => value !== option.value)
                      );
                    } else {
                      setSelectedValue([...selectedValue, option.value]);
                    }
                  } else {
                    if (selectedValue.includes(option.value)) {
                      setSelectedValue([]);
                    } else {
                      setSelectedValue([option.value]);
                    }
                  }
                }}
              />
            ))}
          </FormGroup>
        )}
        {type === 'radio' && (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                control={<Radio />}
                label={option.label}
                value={option.value}
                checked={selectedValue.includes(option.value)}
                onChange={() => onChange(option.value)}
              />
            ))}
          </FormGroup>
        )}
        {type === 'dropdown' && (
          <Select
            placeholder={title}
            fullWidth
            onChange={(e) => setSelectedValue(e.target.value)}
            multiple={multiple}
            value={selectedValue}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
        {type === 'custom' && (
          <div>
            {/* {React.cloneElement(customFilter, {
              onChange: (value) => setSelectedValue(value)
            })} */}
            {customFilter}
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

FilterItem.prototype = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['checkbox', 'radio', 'dropdown']).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  defaultSelection: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number)
  ]),
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool
};

FilterItem.defaultProps = {
  multiple: false,
  defaultSelection: [],
  onChange: () => {}
};

export default FilterItem;
