import './MultilanguageTabs.scss';

const MultilanguageTabs = ({
  currentLanguage,
  onChangeLanguage,
  languagesData,
  languagesField
}) => {
  const emptyLanguageES =
    languagesData?.find((lang) => lang.lang_code === 'es')?.[languagesField] ===
    '';

  const emptyLanguageEN =
    languagesData?.find((lang) => lang.lang_code === 'en')?.[languagesField] ===
    '';

  const emptyLanguagePT =
    languagesData?.find((lang) => lang.lang_code === 'pt')?.[languagesField] ===
    '';

  return (
    <div className='multilanguage-tabs'>
      <div
        onClick={() => onChangeLanguage('es')}
        className={`multilanguage-tabs__item ${
          currentLanguage === 'es' ? 'multilanguage-tabs__item--active' : ''
        }
        ${emptyLanguageES ? 'multilanguage-tabs__item--empty' : ''}`}
      >
        ES
      </div>
      <div
        onClick={() => onChangeLanguage('en')}
        className={`multilanguage-tabs__item ${
          currentLanguage === 'en' ? 'multilanguage-tabs__item--active' : ''
        }
        ${emptyLanguageEN ? 'multilanguage-tabs__item--empty' : ''}`}
      >
        EN
      </div>
      <div
        onClick={() => onChangeLanguage('pt')}
        className={`multilanguage-tabs__item ${
          currentLanguage === 'pt' ? 'multilanguage-tabs__item--active' : ''
        }
        ${emptyLanguagePT ? 'multilanguage-tabs__item--empty' : ''}`}
      >
        PT
      </div>
    </div>
  );
};

export default MultilanguageTabs;
