import { FormGroup, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { Search, Tune } from '@mui/icons-material';
import PropTypes from 'prop-types';

import './SearchInput.scss';

const SearchInput = ({ onChange, value, placeholder }) => {
  return (
    <div className='search-input'>
      <form autoComplete='off'>
        <FormGroup row>
          <TextField
            variant='outlined'
            placeholder={placeholder}
            className='search-input__search-input'
            onChange={({ target: { value } }) => onChange(value)}
            value={value}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search sx={{ color: '#8c99ad' }} />
                </InputAdornment>
              )
            }}
          />
        </FormGroup>
      </form>
    </div>
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string
};

SearchInput.defaultProps = {
  onChange: () => {},
  value: '',
  placeholder: ''
};

export default SearchInput;
