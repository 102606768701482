import { Skeleton } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDInput from '../../atoms/EDInput/EDInput';
import { fetchWithToken } from '../../utils/helpers/fetcher';

const EDStandardsFilter = ({
  showLabel,
  onChange,
  defaultValue,
  multiple,
  required,
  type,
  label,
  disabled,
  isLoading: isInitialLoad
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(-1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState();

  const getData = async (searchValue) => {
    setIsLoading(true);
    let response = await fetchWithToken({
      method: 'GET',
      path: `/standards?pageSize=300&offset=${offset + 1}${
        type ? `&parentGuid[]=${type === 'CC' ? 'CC' : 'BNCC'}` : ''
      }${searchValue ? `&name=${searchValue}` : ''}`
    });

    // setData([]);
    setData(
      response.data.standards
        .map((standards) => ({
          label: standards.guid,
          id: standards.guid,
          parentGuid: standards.parentGuid.guid,
          parentName: standards.parentGuid.name
        }))
        .flat(1)
    );
    setTotal(response.data.count);
    setOffset(response.data.offset);
    setIsLoading(false);
  };

  const debouncedFetchOptions = useCallback(
    debounce((value) => {
      getData(value);
    }, 500),
    []
  );

  // const handleInputChange = (event, value) => {
  //   setOffset(-1);
  //   if (data.length > 0) {
  //     const optionValue = data?.find((elem) => elem.label === value);
  //     const optionId = optionValue.id;
  //     setInputValue(optionId);
  //   }
  //   debouncedFetchOptions(value);
  // };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    onChange(inputValue);
  }, [inputValue]);

  return (
    <>
      {isInitialLoad ? (
        <>
          <Skeleton variant='text' />
          <Skeleton variant='rectangle' />
        </>
      ) : (
        <EDAutocompleteWithLabel
          label={label || t(`standard_label_${type}`)}
          placeholder={t('select_placeholder')}
          required={required}
          disabled={disabled}
          showLabel={showLabel}
          multiple={multiple}
          loading={isLoading}
          loadingText={t('loading_text')}
          noOptionsText={t('no_options_text')}
          // value={inputValue}
          defaultValue={defaultValue}
          onChange={(event, value) => {
            !multiple ? onChange(value ? value.id : null) : onChange(value);
          }}
          options={data}
          ListboxProps={{
            onScroll: (event) => {
              if (
                event.target.scrollTop + event.target.clientHeight ===
                event.target.scrollHeight
              ) {
                if ((offset + 1) * 100 < total) {
                  getData(inputValue);
                }
              }
            }
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.label + option.id}>
                {option.label}
              </li>
            );
          }}
          // onInputChange={(e, value) => handleInputChange(e, value)}
          renderInput={(params) => (
            <EDInput {...params} placeholder={t('select_placeholder')} />
          )}
        />
      )}
    </>
  );
};

export default EDStandardsFilter;
