import { Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import EDCard from '../../../../atoms/EDCard/EDCard';
import TableDashboard from '../../../../view/tableDashboard/TableDashboard';
import { useTranslation } from 'react-i18next';
import EDButton from '../../../../atoms/EDButton/EDButton';
import AddKCsDialog from './AddKCsDialog';

const KnowledgeComponentDependencies = ({
  columns,
  data,
  isLoading,
  educationYears,
  onAddDependencies,
  onRemoveDependencies
}) => {
  const { t } = useTranslation();

  const [isManagingDependencies, setIsManagingDependencies] = useState(false);

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} justifyContent='center'>
        <Grid item xs={12}>
          <EDCard>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Typography variant='h2'>
                    {t('dependencies_tab_title')}
                  </Typography>
                  <EDButton
                    disableElevation
                    variant='contained'
                    onClick={() => {
                      setIsManagingDependencies(true);
                    }}
                  >
                    Manage
                  </EDButton>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <TableDashboard
                  tableColumns={columns}
                  tableData={data}
                  disableFiltersAndSearch
                  totalEntries={data.length}
                  singleEntryLabel={t('dependencies_tab_title')}
                  pluralEntryLabel={t('dependencies_tab_title')}
                  isTableDataLoading={isLoading}
                  onRowClick={(row) => {
                    window.open(
                      window.location.origin +
                        `/knowledge-components/${row.guid}`,
                      '_blank'
                    );
                  }}
                />
              </Grid>
            </Grid>
          </EDCard>

          <AddKCsDialog
            isOpen={isManagingDependencies}
            onClose={() => setIsManagingDependencies(false)}
            existingKCs={data}
            educationYears={educationYears}
            onAddKCs={onAddDependencies}
            onRemoveKCs={onRemoveDependencies}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KnowledgeComponentDependencies;
