import React, { useState } from 'react';
import Table from '../../components/table/Table';
import EDCircularLoading from '../../atoms/EDCircularLoading/EDCircularLoading';

import useDemoUsers from './useDemoUsers';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PageWithHeader from '../../layouts/pageWithHeader/PageWithHeader';
import CreateUserDialog from './components/CreateUserDialog';
import Toast from '../../atoms/Toast/Toast';

const DemoUsers = () => {
  const {
    data,
    total,
    error,
    columns,
    isLoading,
    showToast,
    onCreateUser,
    setShowToast,
    isCreatingNewUser,
    isCreateDialogOpen,
    setIsCreateDialogOpen
  } = useDemoUsers();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setIsCreateDialogOpen(true);
  };

  const handleClose = () => {
    setIsCreateDialogOpen(false);
  };

  return (
    <PageWithHeader
      left={
        <span
          style={{
            fontSize: '1.5rem',
            fontWeight: '600',
            lineHeight: '1.75'
          }}
        >
          {t('users_title')}
        </span>
      }
      right={
        <Button
          disableElevation
          color='primary'
          variant='contained'
          onClick={handleClickOpen}
          sx={{
            padding: '0.75rem 1.5rem',
            textTransform: 'none'
          }}
        >
          {t('Create')}
        </Button>
      }
    >
{/*
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginBottom: '16px'
        }}
      >
        <Button
          disableElevation
          color='primary'
          variant='contained'
          onClick={handleClickOpen}
          sx={{
            padding: '0.75rem 1.5rem',
            textTransform: 'none'
          }}
        >
          {t('Create')}
        </Button>
      </div> */}

      <div>
        <div
          style={{
            marginBottom: '19px',
            color: '#8c99ad',
            fontSize: '12px',
            fontWeight: 'bold'
          }}
        >
          <span
            style={{
              color: '#414b5c'
            }}
          >
            {Intl.NumberFormat().format(total)}
          </span>{' '}
          {t('users')}
        </div>
      </div>

      {isLoading && <EDCircularLoading />}
      {total > 0 && (
        <>
          <Table data={data} columns={columns} isLoading={isLoading} />
        </>
      )}

      {console.log('isCreatingNewUser', isCreatingNewUser)}

      {isCreateDialogOpen && (
        <CreateUserDialog
          isOpen={isCreateDialogOpen}
          onClose={handleClose}
          isLoading={isCreatingNewUser}
          onCreate={onCreateUser}
          error={error}
        />
      )}

      {console.log(error)}

      {showToast && (
        <Toast type='error' text={error} handleShowToast={setShowToast} />
      )}
    </PageWithHeader>
  );
};

export default DemoUsers;
