import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDInput from '../../atoms/EDInput/EDInput';
import { fetchWithToken } from '../../utils/helpers/fetcher';

const EDLemonadeTemplateProgramFilter = ({
  defaultValue,
  showLabel,
  multiple,
  required,
  disabled,
  onChange
}) => {
  const { t } = useTranslation();
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [offset, setOffset] = useState(-1);
  const [total, setTotal] = useState(0);
  const [inputValue, setInputValue] = useState('');

  const getData = async () => {
    setIsLoading(true);

    let templatesResponse = await fetchWithToken({
      method: 'GET',
      path: `/seeds/templates`
    });

    // console.log('templatesResponse', templatesResponse.data);

    setTemplates(
      templatesResponse.data?.map((template) => ({
        value: template.seedTemplate,
        label: template.seedTemplate,
        id: template.seedTemplate
      }))
    );

    // console.log(
    //   'template response',
    //   templatesResponse.data?.map((template) => ({
    //     value: template.seedTemplate,
    //     label: template.seedTemplate
    //   }))
    // );

    setTotal(templatesResponse.data.count);
    // setOffset(templatesResponse.data.offset);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (inputValue && inputValue.length > 0) {
      onChange(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (templates && templates.length > 0) {
      const selectedTemplates = templates.find(
        (level) => level.id === defaultValue || level.id === inputValue
      );
      setInputValue(selectedTemplates || null);
    }
  }, [defaultValue, templates]);

  return (
    <EDAutocompleteWithLabel
      label='JSON Template'
      showLabel={showLabel}
      multiple={multiple}
      required={required}
      loading={isLoading}
      disabled={disabled}
      loadingText={t('loading_text')}
      noOptionsText={t('no_options_text')}
      value={inputValue}
      // defaultValue={defaultValue}
      options={templates}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        );
      }}
      getOptionLabel={(option) => option.label || ''}
      onChange={(event, value) => {
        onChange(value ? value.id : null);
        // onChange(value ? [value] : []);
      }}
      renderInput={(params) => (
        <EDInput {...params} placeholder={t('select_placeholder')} />
      )}
    />
  );
};

export default EDLemonadeTemplateProgramFilter;
