import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import './LanguageSelector.scss';

const LanguageSelector = ({ languageValue, handleChange }) => {
  return (
    <div className='language-selector'>
      <ToggleButtonGroup
        color='primary'
        value={languageValue}
        exclusive
        onChange={handleChange}
        aria-label='Language'
        size='small'
      >
        <ToggleButton value={'es'}>ES</ToggleButton>
        <ToggleButton value={'en'}>EN</ToggleButton>
        <ToggleButton value={'pt'}>PT</ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default LanguageSelector;
