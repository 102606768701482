import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSchools from './useSchools';
import PageWithHeader from '../../../../layouts/pageWithHeader/PageWithHeader';
import TableDashboard from '../../../../view/tableDashboard/TableDashboard';
import EDDashboardTabs from '../../../../components/EDDashboardTabs/EDDashboardTabs';
import CreateSchoolDialog from './Dialogs/CreateSchoolDialog';

const Schools = () => {
  const {
    total,
    offset,
    columns,
    schools,
    pageSize,
    isLoading,
    totalPages,
    currentPage,
    isCreateNewSchoolOpen,
    onSearch,
    onChangePage,
    onClickOnSchool,
    onCreateNewSchool,
    onOpenCreateSchoolDialog,
    onCloseCreateSchoolDialog
  } = useSchools();

  return (
    <PageWithHeader
      left={
        <EDDashboardTabs
          tabs={[
            { name: 'Users', to: '/users', key: 'users' },
            // { name: 'Groups', to: '/groups', key: 'groups' },
            { name: 'Schools', to: '/schools', key: 'schools' }
          ]}
          currentTab={'schools'}
        />
      }
    >
      <TableDashboard
        disableFilters
        filters={[
          {
            id: 'status',
            key: 'status',
            title: 'Status',
            type: 'radio',
            options: [
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' }
            ]
          }
        ]}
        onSearch={onSearch}
        onFilter={() => {}}
        tableData={schools}
        onRowClick={onClickOnSchool}
        tableColumns={columns}
        totalEntries={total}
        onClickOnCreate={onOpenCreateSchoolDialog}
        singleEntryLabel='school'
        pluralEntryLabel='schools'
        searchInputPlaceholder='Search schools'
        numberOfFilters={0}
        isTableDataLoading={isLoading}
        showPagination={true}
        totalPages={totalPages}
        currentPage={currentPage}
        onChangePage={onChangePage}
      />
      <CreateSchoolDialog
        onSubmit={onCreateNewSchool}
        isOpen={isCreateNewSchoolOpen}
        onClose={onCloseCreateSchoolDialog}
      />
    </PageWithHeader>
  );
};

export default Schools;
