import React, { useEffect, useState } from 'react';
import EDDialog from '../../../../atoms/EDDialog/EDDialog';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import EDInputWithLabel from '../../../../atoms/EDInput/EDInputWithLabel';
import EDButton from '../../../../atoms/EDButton/EDButton';
import { useTranslation } from 'react-i18next';

const CreateDepartmentDialog = ({ isOpen, onClose, onSubmit }) => {
  const [departmentName, setDepartmentName] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);

  const handleDepartmentNameChange = (event) => {
    setDepartmentName(event.target.value);
  };

  useEffect(() => {
    setCanSubmit(departmentName.length > 2);
  }, [departmentName]);

  const { t } = useTranslation();

  return (
    <EDDialog maxWidth='sm' fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>{t('create_department_dialog_title')}</DialogTitle>
      <DialogContent>
        <EDInputWithLabel
          label={t('department_name_label')}
          onChange={handleDepartmentNameChange}
        />
      </DialogContent>
      <DialogActions>
        <EDButton variant='text' onClick={onClose}>
          {t('cancel')}
        </EDButton>
        <EDButton
          variant='contained'
          onClick={() => onSubmit({ name: departmentName })}
          disableElevation
          disabled={!canSubmit}
        >
          {t('create_department_button')}
        </EDButton>
      </DialogActions>
    </EDDialog>
  );
};

export default CreateDepartmentDialog;
