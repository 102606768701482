import { Button, ButtonGroup } from '@mui/material';
import React from 'react';
import EDButtonRoundedTab from '../../atoms/EDButton/EDButtonRoundedTab';
import EDButtonGroup from '../../atoms/EDButtonGroup/EDButtonGroup';

const PillTabs = ({ tabs, currentTab, onChangeTab }) => {
  return (
    <EDButtonGroup>
      {tabs.map((tab) => (
        <EDButtonRoundedTab
          disableElevation
          key={tab.name}
          variant={tab.key === currentTab ? 'contained' : 'text'}
          onClick={() => onChangeTab(tab.key)}
        >
          {tab.name}
        </EDButtonRoundedTab>
      ))}
    </EDButtonGroup>
  );
};

export default PillTabs;
