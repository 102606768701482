import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { fetchWithToken } from '../../../../utils/helpers/fetcher';

const useEditUser = () => {
  const { userGuid } = useParams();
  const { t } = useTranslation();

  const [user, setUser] = useState(null);
  const [currentUserSchool, setCurrentUserSchool] = useState(null);
  const [isFirsTimeGetGroups, setIsFirsTimeGetGroups] = useState(true);
  const [schools, setSchools] = useState([]);
  const [groups, setGroups] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [currentTab, setCurrentTab] = useState('details');
  const [fieldsToUpdate, setFieldsToUpdate] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ type: '', text: '' });
  const [isLoginAsUserLoading, setIsLoginAsUserLoading] = useState(false);

  const tenantToStudentMap = {
    'localhost:3002': 'https://blueberry.tangerine-dev1.oneclicklabs.es/',
    'backoffice.school.blueberry-qa.oneclicklabs.es':
      'https://school.blueberry-qa.oneclicklabs.es/',
    'tenants-backoffice.tangerine-dev.oneclicklabs.es':
      'https://blueberry.tangerine-dev1.oneclicklabs.es/',
    'backoffice.school.blueberrymath.ai': 'https://school.blueberrymath.ai/',
    'backoffice.escola.blueberrymath.ai': 'https://escola.blueberrymath.ai/',
    'backoffice.escuela.blueberrymath.ai': 'https://escuela.blueberrymath.ai/',
    'backoffice.school.blueberrymath.com': 'https://school.blueberrymath.com/',
    'backoffice.escola.blueberrymath.com': 'https://escola.blueberrymath.com/',
    'backoffice.escuela.blueberrymath.com': 'https://escuela.blueberrymath.com/'
  };

  const tenantToTeacherMap = {
    'localhost:3002':
      'https://dashboard-blueberry.tangerine-dev1.oneclicklabs.es/',
    'backoffice.school.blueberry-qa.oneclicklabs.es':
      'https://dashboard.school.blueberry-qa.oneclicklabs.es/',
    'tenants-backoffice.tangerine-dev.oneclicklabs.es':
      'https://dashboard-blueberry.tangerine-dev1.oneclicklabs.es/',
    'backoffice.school.blueberrymath.ai':
      'https://dashboard.school.blueberrymath.ai/',
    'backoffice.escola.blueberrymath.ai':
      'https://dashboard.escola.blueberrymath.ai/',
    'backoffice.escuela.blueberrymath.ai':
      'https://dashboard.escuela.blueberrymath.ai/',
    'backoffice.school.blueberrymath.com':
      'https://dashboard.school.blueberrymath.com/',
    'backoffice.escola.blueberrymath.com':
      'https://dashboard.escola.blueberrymath.com/',
    'backoffice.escuela.blueberrymath.com':
      'https://dashboard.escuela.blueberrymath.com/'
  };

  const getData = async () => {
    setIsLoading(true);

    let response = await fetchWithToken({
      path: `/users/${userGuid}?include[]=groups`
    });

    const auxUserSchool =
      response.data.schools && response.data.schools.length > 0
        ? {
            label: response.data.schools[0]?.name,
            value: response.data.schools[0]?.guid
          }
        : null;

    setUser({
      ...response.data,
      role: { label: response.data.role_name, value: response.data.role_guid },
      userSchool: auxUserSchool,
      isDemoUser: response.data.is_demo,
      userGroups:
        (response.data.groups &&
          response.data.groups.map((group) => ({
            label: group.name,
            value: group.guid
          }))) ||
        []
    });

    setCurrentUserSchool(auxUserSchool);

    let schoolsResponse = await fetchWithToken({
      path: `/schools?pageSize=100&offset=0`
    });

    setSchools(
      schoolsResponse.data.schools.map((school) => ({
        label: school.name,
        value: school.guid
      }))
    );

    let licensesResponse = await fetchWithToken({
      path: `/users/${userGuid}/license-personals`
    });

    setLicenses(licensesResponse.data);

    console.log('licensesResponse', licensesResponse.data);

    setIsLoading(false);
  };

  const onChangeTab = (tab) => {
    setCurrentTab(tab.key);
  };

  const onCreateNewContract = () => {
    console.log('create new contract');
  };

  const onCreateNewGroup = () => {
    console.log('create new contract');
  };

  const onUpdateUser = (data) => {
    setUser({ ...user, ...data });
    let newFieldsToUpdate = {
      name: user?.name,
      ...fieldsToUpdate,
      ...data
    };
    // delete newFieldsToUpdate.userSchool;
    // delete newFieldsToUpdate.userGroups;
    setFieldsToUpdate(newFieldsToUpdate);
  };

  const onSaveUser = async () => {
    setIsSaving(true);

    try {
      let response = null;
      if (Object.keys(fieldsToUpdate).length > 0) {
        delete fieldsToUpdate.userSchool;
        delete fieldsToUpdate.userGroups;
        response = await fetchWithToken({
          path: `/users/${userGuid}`,
          method: 'PUT',
          data: fieldsToUpdate
        });

        if (response.status !== 'success') {
          setToastMessage({
            type: 'error',
            text: 'An error has occurred.'
          });
          setIsSaving(false);
          setShowToast(true);
          return;
        }
      }

      if (user.userSchool && user.userSchool.value !== user.schools[0]?.guid) {
        response = await fetchWithToken({
          path: `/users/${userGuid}/schools`,
          method: 'POST',
          data: {
            guid: [user.userSchool.value]
          }
        });

        if (response.status !== 'success') {
          setToastMessage({
            type: 'error',
            text: 'An error has occurred.'
          });
          setIsSaving(false);
          setShowToast(true);
          return;
        }
      }

      if (user.userGroups.length > 0) {
        await Promise.all(
          user.userGroups.map(async (group) => {
            response = await fetchWithToken({
              path: `/front/school-admin/${currentUserSchool.value}/groups/${group.value}/users`,
              method: 'POST',
              data: {
                guid: [userGuid]
              }
            });

            if (response.status !== 'success') {
              setToastMessage({
                type: 'error',
                text: 'An error has occurred.'
              });
              setIsSaving(false);
              setShowToast(true);
              return;
            }
          })
        );
      }

      setToastMessage({
        type: 'success',
        text: t('user_updated_successfully')
      });

      setIsSaving(false);
      setShowToast(true);
    } catch (error) {
      console.error('Error saving user:', error);
      // Handle the error, show an error message, etc.
      setToastMessage({
        type: 'error',
        text: 'An error occurred while saving the user.'
      });
      setIsSaving(false);
      setShowToast(true);
    }
  };

  const onLoginAsUser = async () => {
    setIsLoginAsUserLoading(true);

    let response = await fetchWithToken({
      method: 'POST',
      path: `/users/${userGuid}/login`,
      data: {}
    });

    if (response.status === 'success') {
      let userDomain;
      const loginEndpoint = 'login-qa';

      const isStudent = user.role_guid === 'R01';
      const isTeacher = user.role_guid === 'R02';

      if (isTeacher) {
        userDomain = tenantToTeacherMap[window.location.host];
      } else if (isStudent) {
        userDomain = tenantToStudentMap[window.location.host];
      }

      if (userDomain === undefined || userDomain === null) {
        setToastMessage({
          type: 'error',
          text: t('An error occurred while trying to login as the user.')
        });
        setShowToast(true);
        return;
      }

      window.open(
        `${userDomain}${loginEndpoint}?token=${response.data.token}`,
        '_blank'
      );

      setIsLoginAsUserLoading(false);
    }
  };

  useEffect(() => {
    if (user && currentUserSchool) {
      const hasToGetGroups =
        user?.userSchool?.value !== currentUserSchool?.value ||
        isFirsTimeGetGroups;

      if (user && user.userSchool && hasToGetGroups && schools.length > 0) {
        getGroups(user.userSchool.value);
        setIsFirsTimeGetGroups(false);
      }
    }
  }, [user, schools]);

  useEffect(() => {
    if (fieldsToUpdate.userSchool) {
      getGroups(fieldsToUpdate.userSchool.value);
    }
  }, [fieldsToUpdate]);

  useEffect(() => {
    getData();
  }, []);

  const getGroups = async (schoolGuid) => {
    let groupsResponse = await fetchWithToken({
      path: `/front/school-admin/${schoolGuid}/groups`
    });

    const auxSchoolGroups = groupsResponse.data.schoolGroups.map((group) => ({
      label: group.name,
      value: group.guid
    }));

    // Si tenía grupos asignados quitarlos si no son del colegio actualmente seleccionado
    let auxUserGroups = [];
    if (user?.userGroups?.length && user.userGroups.length > 0) {
      auxUserGroups = user.userGroups.filter((userItem) =>
        auxSchoolGroups.some(
          (schoolItem) => schoolItem.value === userItem.value
        )
      );
    }

    setCurrentUserSchool(user.userSchool);
    setUser({ ...user, userGroups: auxUserGroups });
    setGroups(auxSchoolGroups);
  };

  return {
    user,
    groups,
    schools,
    licenses,
    isSaving,
    isLoading,
    showToast,
    currentTab,
    toastMessage,
    isLoginAsUserLoading,
    onSaveUser,
    onChangeTab,
    setShowToast,
    onUpdateUser,
    onLoginAsUser,
    onCreateNewGroup,
    onCreateNewContract
  };
};

export default useEditUser;
