import { useEffect, useState } from 'react';
import { getNodesGraph } from '../../../utils/crud/nodes';
import { formatNumericDateWithTime } from '../../../utils/helpers/dateFormatters';
import { tryJsonParse } from '../../../utils/helpers/tryJsonParse';

const useNodesVisualizer = ({
  selectedEducationYear,
  selectedLanguage,
  selectedDepartment
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState(null);

  const initialize = async () => {
    setIsLoading(true);
    const nodesData = await getNodesGraph({
      internalEducationYearGuid: selectedEducationYear,
      language: selectedLanguage?.value?.toUpperCase()
    });
    const nodesHierarchy = tryJsonParse(nodesData[0]?.data);
    setJsonData(nodesHierarchy);
    setLastUpdateTimestamp(
      formatNumericDateWithTime(new Date(nodesData[0]?.updatedAt))
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (!selectedEducationYear) return;
    initialize();
  }, [selectedEducationYear, selectedLanguage, selectedDepartment]);

  return { jsonData, lastUpdateTimestamp, isLoading };
};

export default useNodesVisualizer;
