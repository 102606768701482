import { Chip } from '@mui/material';
import BBTooltip from '../../../../atoms/tooltip/BBTooltip';

const CellTag = ({ tags, customClass }) => {
  return (
    <div className='cell__container cell--tag'>
      {tags.map((tag) => (
        <BBTooltip text={tag.description} key={tag.label}>
          <Chip
            label={tag.label}
            sx={{
              backgroundColor: '#f7f9fc',
              color: '#8c99ad',
              borderRadius: '4px',
              margin: '0 2px 0 2px',
              cursor: 'pointer'
            }}
            className={customClass || ''}
          />
        </BBTooltip>
      ))}
    </div>
  );
};

export default CellTag;
