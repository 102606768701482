import React, { useEffect, useState } from 'react';
import './EDSeedStatusSelect.scss';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as ToDoIcon } from '../../assets/icons/status_todo.svg';
import { ReactComponent as NeedsRevisionIcon } from '../../assets/icons/status_needs_revision.svg';
import { ReactComponent as NeedsCorrectionIcon } from '../../assets/icons/status_needs_correction.svg';
import { ReactComponent as ValidatedIcon } from '../../assets/icons/status_validated.svg';
import { ReactComponent as JsonPendingIcon } from '../../assets/icons/status_json_pending.svg';
import { ReactComponent as PendingImageIcon } from '../../assets/icons/status_pending_image.svg';
import { ReactComponent as JsonRevisionIcon } from '../../assets/icons/status_json_revision.svg';
import { ReactComponent as CompletedIcon } from '../../assets/icons/status_completed.svg';
import { ReactComponent as CustomArrowImage } from '../../assets/icons/arrow_down.svg';

import { fetchWithToken } from '../../utils/helpers/fetcher';
import { useTranslation } from 'react-i18next';

const EDSeedStatusSelect = ({ activity, setShowToast, setToastMessage }) => {
  const { t } = useTranslation();

  const statusInfo = [
    { key: 'to_do', icon: <ToDoIcon /> },
    { key: 'needs_revision', icon: <NeedsRevisionIcon /> },
    { key: 'needs_correction', icon: <NeedsCorrectionIcon /> },
    { key: 'validated', icon: <ValidatedIcon /> },
    { key: 'json_to_do', icon: <JsonPendingIcon /> },
    { key: 'pending_image', icon: <PendingImageIcon /> },
    { key: 'json_revision', icon: <JsonRevisionIcon /> },
    { key: 'done', icon: <CompletedIcon /> }
  ];

  const [status, setStatus] = useState(activity?.status || '');

  const handleChange = (event) => {
    setStatus(event.target.value);
    onSaveStatus(event.target.value);
  };

  const onSaveStatus = async (newStatus) => {
    const questionSeedGuid = activity.guid;
    const response = await fetchWithToken({
      path: `/seeds/${questionSeedGuid}`,
      method: 'PATCH',
      data: { status: newStatus }
    });
    if (response.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_activity_saved_success')
      });
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_activity_saved_error')
      });
    }
    setShowToast(true);
  };

  useEffect(() => {
    setStatus(activity?.status || '');
  }, [activity]);

  return (
    <div className='seed-status-select'>
      <Select
        id='seed-status-select__select'
        className='seed-status-select__select'
        // defaultValue={activity?.status}
        value={status}
        onChange={handleChange}
        variant='outlined'
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          getContentAnchorEl: null,
          sx: { marginTop: '12px' }
        }}
        IconComponent={CustomArrow}
      >
        {statusInfo.map((statusItem) => (
          <MenuItem
            key={statusItem.key}
            value={statusItem.key}
            className='seed-status-select__item'
          >
            <span className='seed-status-select__item-icon'>
              {statusItem.icon}
            </span>
            <span className='seed-status-select__item-name'>
              {t(statusItem.key)}
            </span>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default EDSeedStatusSelect;

// Custom arrow component
const CustomArrow = () => {
  return (
    <div
      className='select-custom-arrow MuiSvgIcon-root MuiSelect-icon'
      style={{ pointerEvents: 'none', marginLeft: '20', marginRight: '20' }}
    >
      <CustomArrowImage />
    </div>
  );
};
