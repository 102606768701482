import React from 'react';
import { Button, styled } from '@mui/material';

const EDButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    borderRadius: '0.25rem',
    padding: '0.625rem 1rem',
    textTransform: 'none',
    fontWeight: '700',
    lineHeight: 1.64
  },
  '&.MuiButton-text': {
    color: theme.palette.grey[500],
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  '&.MuiButton-textSecondary': {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

export default EDButton;
