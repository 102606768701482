import { useEffect, useState } from 'react';
import { fetchWithToken } from '../../../utils/helpers/fetcher';
import { createColumnHelper } from '@tanstack/react-table';

const usePrograms = () => {
  const columnHelper = createColumnHelper();
  const pageSize = 10;

  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);

  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      id: 'name',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('publication_name', {
      header: 'Publication Name',
      id: 'publication_name',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('discipline_name', {
      header: 'Subject',
      id: 'discipline_name',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('education_level_name', {
      header: 'Education level',
      id: 'education_level_name',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('education_year_name', {
      header: 'Education Year',
      id: 'education_year_name',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('languages', {
      header: 'languages',
      id: 'languages',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <p>
          {info.row.original.langs.map((language) => (
            <span>{language.description}</span>
          ))}
        </p>
      )
    }),
    columnHelper.accessor('number_of_lessons', {
      header: 'Number of lessons',
      id: 'number_of_lessons',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('code', {
      header: 'Code',
      id: 'code',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    })
  ];

  const getData = async () => {
    setIsLoading(true);
    let response = await fetchWithToken({
      path: `lms/courses?isEditorial=1&hasBlueBerry=1`
      //   &lang[]=en&educationLevel[]=&educationYear[]&discipline[]&pageSize=${pageSize}&offset=${offset}
    });
    setPrograms(response.data.courses);
    setTotal(response.data.total);
    setOffset(response.data.offset);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    total,
    offset,
    columns,
    programs,
    pageSize,
    isLoading
  };
};
export default usePrograms;
