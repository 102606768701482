import { useEffect, useState } from 'react';
import { fetchWithToken } from '../../../../utils/helpers/fetcher';
import { createColumnHelper } from '@tanstack/react-table';

const useCourses = () => {
  const columnHelper = createColumnHelper();
  const pageSize = 10;

  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);

  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      id: 'name',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('education_year_guid', {
      header: 'Education year',
      id: 'education_year_guid',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('education_discipline_guid', {
      header: 'Discipline',
      id: 'education_discipline_guid',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('school_group_guid', {
      header: 'School Group',
      id: 'school_group_guid',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    })
  ];

  const getData = async () => {
    setIsLoading(true);
    let response = await fetchWithToken({
      path: `/front/courses?pageSize=${pageSize}&offset=${offset}`
    });
    setCourses(response.data.courses);
    setTotal(response.data.total);
    setOffset(response.data.offset);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    total,
    offset,
    columns,
    courses,
    pageSize,
    isLoading
  };
};
export default useCourses;
