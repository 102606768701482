import {
  ArrowDownward,
  ArrowUpward,
  Delete,
  MoreHoriz
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ACTIVITY_TYPES,
  ActivityBlueberryComponent
} from '../../../../../../_practice-components';
import { ReactComponent as GenerateIcon } from '../../../../../../assets/icons/ai.svg';
import BBTooltip from '../../../../../../atoms/tooltip/BBTooltip';
import i18nInstance from '../../../../../../i18n/i18n';
import { useLocalStorage } from '../../../../../../utils/hooks/useLocalStorage';
import { localStorageKeys } from '../../../../../../utils/localStorageKeys';
import StandardChips from '../../../CreateQuiz/components/standardChips/StandardChips';
import QuizBadge from '../quizBadge/QuizBadge';
import './QuizQuestionCard.scss';

const QuizQuestionCard = ({
  guid,
  unit,
  lesson,
  readOnly,
  question,
  standards,
  educationYear,
  questionNumber,
  isLastQuestion,
  studentResponse,
  isFirstQuestion,
  onDeleteQuestion,
  allowShowSolution,
  isDeletingQuestion,
  onRegenerateQuestion,
  questionBeingDeleted,
  isRegeneratingQuestion,
  questionBeingRegenerated,
  studentsWithWrongAnswers,
  onChangePositionOfQuestion,
  studentsWithCorrectAnswers
}) => {
  const { t } = useTranslation();
  const { getItem } = useLocalStorage();

  const [showSolution, setShowSolution] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(
    isRegeneratingQuestion && questionBeingRegenerated === guid
  );

  const [uiActivitiesLanguage, setUiActivitiesLanguage] = useState('en');

  useEffect(() => {
    setUiActivitiesLanguage(
      getItem(localStorageKeys.language) || i18nInstance.language || 'en'
    );
  }, []);

  const toggleSolution = () => {
    setShowSolution(!showSolution);
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const open = Boolean(menuAnchorEl);
  const handleClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    setIsRegenerating(
      isRegeneratingQuestion && questionBeingRegenerated === guid
    );
  }, [isRegeneratingQuestion, questionBeingRegenerated]);

  return (
    <div className='quiz-question-card' key={question.reference}>
      <div className='quiz-question-card__header'>
        <div className='quiz-question-card__header--left'>
          <QuizBadge
            tooltip={unit?.name + ' → ' + lesson?.name}
            label={lesson?.name}
            badge={educationYear}
          />
          <StandardChips standards={standards} />
        </div>
        <div>
          {!readOnly && (
            <IconButton
              onClick={handleClick}
              disabled={isDeletingQuestion && questionBeingDeleted === guid}
            >
              {isDeletingQuestion && questionBeingDeleted === guid ? (
                <CircularProgress />
              ) : (
                <MoreHoriz />
              )}
            </IconButton>
          )}
        </div>
      </div>
      <Divider />
      <div className='quiz-question-card__content'>
        <div className='quiz-question-card__number'>
          <div>{questionNumber}</div>
        </div>
        <div className='quiz-question-card__question'>
          <div className='quiz-question-card__question--preview'>
            <ActivityBlueberryComponent
              activityType={ACTIVITY_TYPES.QUIZ}
              activity={question}
              disabledQuestion={true}
              isHideFooter={true}
              {...(studentResponse ? { studentResponse } : {})}
              showAllFeedback={false}
              showSolutionQuestionQuizPreview={showSolution}
              uiLanguage={uiActivitiesLanguage}
              mathRenderEngine='katex'
            />
          </div>

          <div className='quiz-question-card__question--actions'>
            <div className='quiz-question-card__action'>
              {!readOnly && (
                <Button
                  variant='outlined'
                  disableElevation
                  onClick={() => onRegenerateQuestion({ questionGuid: guid })}
                  disabled={isRegenerating}
                  {...(isRegenerating ? {} : { startIcon: <GenerateIcon /> })}
                >
                  {isRegenerating ? (
                    <CircularProgress color='secondary' size={24} />
                  ) : (
                    t('quiz_regenerate_question')
                  )}
                </Button>
              )}
            </div>
            {allowShowSolution && (
              <FormGroup>
                <FormControlLabel
                  variant='switch'
                  control={<Switch onChange={() => toggleSolution()} />}
                  label={t('Show solution')}
                />
              </FormGroup>
            )}
          </div>
        </div>
      </div>
      {studentsWithCorrectAnswers && (
        <div className='quiz-question-card__students'>
          <div className='quiz-question-card__students-container'>
            <div className='quiz-question-card__students--text'>
              {t('quiz_correct')}
            </div>
            <div className='quiz-question-card__students--avatars'>
              <div className='quiz-question-card__students--number'>
                {studentsWithCorrectAnswers.length}
              </div>
              {studentsWithCorrectAnswers.map((student) => (
                <BBTooltip key={student.guid} text={student.name}>
                  <Avatar src={student.avatar} className='avatar' />
                </BBTooltip>
              ))}
            </div>
          </div>

          <div className='quiz-question-card__students-container'>
            <div className='quiz-question-card__students--text'>
              {t('quiz_incorrect')}
            </div>
            <div className='quiz-question-card__students--avatars'>
              <div className='quiz-question-card__students--number'>
                {studentsWithWrongAnswers.length}
              </div>
              {studentsWithWrongAnswers.map((student) => (
                <BBTooltip key={student.guid} text={student.name}>
                  <Avatar src={student.avatar} className='avatar' />
                </BBTooltip>
              ))}
            </div>
          </div>
        </div>
      )}

      <Menu
        id='question-card-menu'
        anchorEl={menuAnchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {!isFirstQuestion && (
          <MenuItem
            onClick={() => {
              onChangePositionOfQuestion({
                newIndex: questionNumber - 2,
                oldIndex: questionNumber - 1
              });
              handleClose();
            }}
          >
            <ListItemIcon>
              <ArrowUpward fontSize='small' />
            </ListItemIcon>
            <ListItemText>{t('move_activity_of_quiz_up')}</ListItemText>
          </MenuItem>
        )}
        {!isLastQuestion && (
          <MenuItem
            onClick={() => {
              onChangePositionOfQuestion({
                newIndex: questionNumber,
                oldIndex: questionNumber - 1
              });
              handleClose();
            }}
          >
            <ListItemIcon>
              <ArrowDownward fontSize='small' />
            </ListItemIcon>
            <ListItemText>{t('move_activity_of_quiz_down')}</ListItemText>
          </MenuItem>
        )}
        {!(isFirstQuestion && isLastQuestion) && <Divider />}
        <MenuItem
          onClick={() => {
            onDeleteQuestion({ questionGuid: guid });
            handleClose();
          }}
        >
          <ListItemIcon variant='danger'>
            <Delete fontSize='small' />
          </ListItemIcon>
          <ListItemText variant='danger'>{t('delete')}</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default QuizQuestionCard;
