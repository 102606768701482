import { Grid, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDCard from '../../../../../atoms/EDCard/EDCard';
import Toast from '../../../../../atoms/Toast/Toast';
import TableDashboard from '../../../../../view/tableDashboard/TableDashboard';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog';
import CreateEditClassDialog from './dialogs/CreateEditClassDialog';

const SchoolClasses = ({
  classes,
  programs,
  groups,
  onCreateNewClass,
  onEditClass,
  onDeleteClass,
  totalClassesInSchool
}) => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name'
    }),
    columnHelper.accessor('code', {
      id: 'code',
      header: 'Code'
    }),
    columnHelper.accessor('school_group_year_name', {
      id: 'school_group_year_name',
      header: 'School group year'
    }),
    columnHelper.accessor('school_group_name', {
      id: 'school_group_name',
      header: 'School group'
    }),

    columnHelper.accessor('programName', {
      id: 'programName',
      header: 'Program'
    }),

    columnHelper.accessor('status', {
      id: 'status',
      header: 'Status'
    })
  ];

  const { t } = useTranslation();

  const [messageToast, setMessageToast] = useState({ type: '', text: '' });
  const [showToast, setShowToast] = useState(false);

  const [isCreateNewClassOpen, setIsCreateNewClassOpen] = useState(false);
  const [hasSchoolGroups, setHasSchoolGroups] = useState(false);
  const [classesWithProgramName, setClassesWithProgramName] = useState([]);
  const [selectedClassToEdit, setSelectedClassToEdit] = useState(null);
  const [isEditClassOpen, setIsEditClassOpen] = useState(false);
  const [isConfirmDeleteClassOpen, setIsConfirmDeleteClassOpen] =
    useState(false);
  const [selectedClassToDelete, setSelectedClassToDelete] = useState(null);

  useEffect(() => {
    let canCreateClass = false;
    if (groups && groups.length > 0) {
      canCreateClass = groups.some(
        (item) => item.amount_students > 0 && item.amount_teachers > 0
      );
    }

    setHasSchoolGroups(canCreateClass);
  }, [groups]);

  useEffect(() => {
    if (programs && programs.length > 0) {
      const tempClasses = classes.map((item) => ({
        ...item,
        programName: programs.find(
          (program) => program.guid === item.program_guid
        ).name,
        status: item.is_active ? 'Active' : 'Inactive'
      }));

      setClassesWithProgramName(tempClasses);
    }
  }, [classes]);

  const onClose = () => {
    setIsCreateNewClassOpen(false);
    setIsEditClassOpen(false);
    setSelectedClassToEdit(null);
  };

  const onCreate = () => {
    if (hasSchoolGroups) {
      setIsCreateNewClassOpen(true);
    } else {
      setMessageToast({
        type: 'info',
        text: t('class_cant_create_groups_missing_users')
      });
      setShowToast(true);
    }
  };

  const onCloseDeleteClassDialog = () => {
    setIsConfirmDeleteClassOpen(false);
    setSelectedClassToDelete(null);
  };

  const tableRowOptions = [
    {
      label: t('edit'),
      onClick: (row) => {
        setSelectedClassToEdit(row);
        setIsEditClassOpen(true);
      }
    },
    {
      label: t('delete'),
      onClick: (row) => {
        setSelectedClassToDelete(row);
        setIsConfirmDeleteClassOpen(true);
      }
    }
  ];

  return (
    <Grid item xs={12}>
      <EDCard elevation={0}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2'>Classes</Typography>
          </Grid>
          <Grid item xs={12}>
            <TableDashboard
              filters={[]}
              onSearch={() => {}}
              onFilter={() => {}}
              tableData={classesWithProgramName}
              onRowClick={() => {}}
              tableColumns={columns}
              totalEntries={totalClassesInSchool}
              onClickOnCreate={onCreate}
              singleEntryLabel={'class'}
              pluralEntryLabel={'classes'}
              isTableDataLoading={false}
              searchInputPlaceholder={'Search class'}
              options={tableRowOptions}
            />
          </Grid>
        </Grid>
      </EDCard>
      {hasSchoolGroups && (isCreateNewClassOpen || isEditClassOpen) && (
        <CreateEditClassDialog
          programs={programs}
          groups={groups}
          isOpen={isCreateNewClassOpen || isEditClassOpen}
          onClose={onClose}
          isEditMode={isEditClassOpen}
          classDetails={selectedClassToEdit}
          onSubmit={(params) => {
            if (isEditClassOpen) {
              onEditClass({
                classData: params,
                courseGuid: selectedClassToEdit.guid
              });
            } else {
              onCreateNewClass(params);
            }
            onClose();
          }}
        />
      )}

      <ConfirmDeleteDialog
        isOpen={isConfirmDeleteClassOpen}
        onClose={onCloseDeleteClassDialog}
        onConfirm={() => {
          onDeleteClass(
            selectedClassToDelete.school_group_guid,
            selectedClassToDelete
          );
          onCloseDeleteClassDialog();
        }}
        itemToBeDeletedName={selectedClassToDelete?.name}
        itemToBeDeletedDescription={t('class_delete_confirm_message')}
      />

      {showToast && (
        <Toast
          handleShowToast={setShowToast}
          type={messageToast.type}
          text={messageToast.text}
        />
      )}
    </Grid>
  );
};

export default SchoolClasses;
