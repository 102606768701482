import React from 'react';
import { useTranslation } from 'react-i18next';
import useGroups from './useGroups';
import PageWithHeader from '../../../../layouts/pageWithHeader/PageWithHeader';
import TableDashboard from '../../../../view/tableDashboard/TableDashboard';
import EDDashboardTabs from '../../../../components/EDDashboardTabs/EDDashboardTabs';

const Groups = () => {
  const {
    total,
    offset,
    columns,
    groups,
    onClickOnSchool,
    pageSize,
    isLoading
  } = useGroups();

  console.log('Groups groups', groups)
  console.log('Groups columns', columns)

  return (
    <PageWithHeader
      left={
        <EDDashboardTabs
          tabs={[
            { name: 'Users', to: '/users', key: 'users' },
            { name: 'Groups', to: '/groups', key: 'groups' },
            { name: 'Schools', to: '/schools', key: 'schools' }
          ]}
          currentTab={'groups'}
        />
      }
    >
      <TableDashboard
        filters={[
          {
            id: 'status',
            key: 'status',
            title: 'Status',
            type: 'radio',
            options: [
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' }
            ]
          }
        ]}
        onSearch={() => {}}
        onFilter={() => {}}
        tableData={groups}
        onRowClick={onClickOnSchool}
        tableColumns={columns}
        totalEntries={total}
        onClickOnCreate={() => {}}
        singleEntryLabel='group'
        pluralEntryLabel='groups'
        searchInputPlaceholder='Search groups'
        numberOfFilters={0}
        isTableDataLoading={isLoading}
      />
    </PageWithHeader>
  );
};

export default Groups;
