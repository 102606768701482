import { CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDEducationLevelFilter from '../../../components/filters/EDEducationLevelFilter';
import NodesGraphContainer from './NodesGraphContainer';
import useNodesVisualizer from './useNodesVisualizer';

const NodesVisualizer = () => {
  const { t } = useTranslation();
  const [selectedEducationYear, setSelectedEducationYear] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const languages = [
    { value: 'es', label: 'Spanish' },
    { value: 'en', label: 'English' },
    { value: 'pt', label: 'Portuguese' }
  ];

  const { jsonData, lastUpdateTimestamp, isLoading } = useNodesVisualizer({
    selectedEducationYear,
    selectedLanguage,
    selectedDepartment
  });

  const onChangeSelectedYear = (value) => {
    setSelectedEducationYear(value);
  };

  const onChangeLanguage = (value) => {
    setSelectedLanguage(value);
  };

  const onChangeDepartment = (value) => {
    setSelectedDepartment(value?.guid);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack direction='row' spacing={2} justifyContent='space-between'>
          <Stack direction='row' spacing={2} flex={1}>
            <Typography variant='h1'>{t('knowledge_graph')}</Typography>
          </Stack>
          <Stack direction='row' spacing={2} flex={1}>
            {/* VARIABLE */}
            <div style={{ width: '200px' }}>
              <EDAutocompleteWithLabel
                label={t('demo_language')}
                options={languages}
                value={selectedLanguage}
                onChange={(e, value) => onChangeLanguage(value)}
                disableClearable
                showLabel={true}
                disabled={isLoading}
                fullWidth
              />
            </div>

            <div style={{ width: '220px' }}>
              <EDEducationLevelFilter
                onChange={onChangeSelectedYear}
                value={selectedEducationYear}
                disabled={isLoading}
                showLabel
                fullWidth
              />
            </div>

            {/* <EDDepartmentFilter
              language={selectedLanguage.value}
              onChange={onChangeDepartment}
              defaultValue={selectedDepartment}
              showLabel
              fullWidth
            /> */}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {!jsonData && !isLoading && (
          <div
            style={{
              background: '#faf9ff',
              border: '1px solid #C4CEFC',
              borderRadius: '3px',
              height: '800px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography variant='h3'>{t('knowledge_graph_help')}</Typography>
          </div>
        )}
        {isLoading && (
          <div
            style={{
              background: '#faf9ff',
              border: '1px solid #C4CEFC',
              borderRadius: '3px',
              height: '800px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress />
          </div>
        )}
        {jsonData && !isLoading && <NodesGraphContainer data={jsonData} />}
      </Grid>
      <Grid item xs={12}>
        {lastUpdateTimestamp && (
          <div>
            {t('nodes_visualizer_last_updated')}: {lastUpdateTimestamp}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default NodesVisualizer;
