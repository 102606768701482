import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDrawer from '../../../../atoms/filterDrawer/FilterDrawer';
import FilterItem from '../../../../atoms/filterItem/FilterItem';
import EDDepartmentMainFilter from '../../../../components/filters/EDDepartmentMainFilter';
import EDEducationLevelProgramFilter from '../../../../components/filters/EDEducationLevelProgramFilter';
import EDLearningObjectiveFilter from '../../../../components/filters/EDLearningObjectiveFilter';
import EDLemonadeTemplateProgramFilter from '../../../../components/filters/EDLemonadeTemplateProgramFilter';
import EDResponsibleProgramFilter from '../../../../components/filters/EDResponsibleProgramFilter';

const ActivitiesFilter = ({
  isDrawerOpen,
  onCloseDrawer,
  onSearch,
  educationYears,
  currentFilters
}) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState(currentFilters);

  useEffect(() => {
    setFilters(currentFilters);
  }, [currentFilters]);

  const onChangeFilter = (filter) => {
    console.log(filter);
    if (filter?.value?.length === 0) {
      let tempFilters = filters;
      delete tempFilters[filter.id];
      setFilters(tempFilters);
    } else {
      setFilters({
        ...filters,
        [filter.id]: filter.value
      });
    }
  };

  return (
    <FilterDrawer
      title={t('Filter')}
      isDrawerOpen={isDrawerOpen}
      onCloseDrawer={onCloseDrawer}
      onReset={() => {
        onSearch({});
        setFilters({});
        onCloseDrawer();
      }}
      onSearch={() => onSearch(filters)}
      isSearchDisabled={Object.keys(filters).length === 0}
    >
      <FilterItem
        title={t('activity_education_level')}
        type='custom'
        defaultSelection={filters.educationYearGuid || []}
        customFilter={
          <EDEducationLevelProgramFilter
            multiple
            defaultValue={filters.educationYearGuid || []}
            onChange={(value) => {
              onChangeFilter({ id: 'educationYearGuid', value });
            }}
          />
        }
      />

      <FilterItem
        title={t('learning_outcome_column_department')}
        type='custom'
        defaultSelection={filters.departments || []}
        customFilter={
          <EDDepartmentMainFilter
            multiple={false}
            defaultValue={filters.departments || null}
            onChange={(value) => {
              onChangeFilter({ id: 'departments', value });
            }}
          />
        }
      />

      <FilterItem
        title='LO'
        type='custom'
        defaultSelection={filters.learningObjective || []}
        customFilter={
          <EDLearningObjectiveFilter
            multiple
            defaultValue={filters.learningObjective || []}
            onChange={(value) => {
              onChangeFilter({ id: 'learningObjective', value });
            }}
            isFilter={true}
          />
        }
      />

      <FilterItem
        title={t('activity_phase')}
        type='checkbox'
        multiple
        options={[
          { label: t('Evoke'), value: 'evocar' },
          { label: t('Identify'), value: 'identificar' },
          { label: t('Apply'), value: 'aplicar' }
        ]}
        defaultSelection={filters.phase || []}
        onChange={(value) => onChangeFilter({ id: 'phase', value })}
      />

      <FilterItem
        title={t('activity_seedTemplate')}
        type='custom'
        defaultSelection={filters.seedTemplate || []}
        customFilter={
          <EDLemonadeTemplateProgramFilter
            multiple={false}
            defaultValue={filters.seedTemplate || []}
            onChange={(value) => {
              onChangeFilter({ id: 'seedTemplate', value });
            }}
          />
        }
      />

      <FilterItem
        title={t('activity_App')}
        type='checkbox'
        options={[
          { label: t('has_mobile_version'), value: 1 },
          { label: t('no_mobile_version'), value: 0 }
        ]}
        defaultSelection={filters.hasDataApp || ''}
        onChange={(value) => {
          onChangeFilter({ id: 'hasDataApp', value });
        }}
      />

      <FilterItem
        title={t('activity_status')}
        type='checkbox'
        multiple
        options={[
          { label: t('to_do'), value: 'to_do' },
          { label: t('needs_revision'), value: 'needs_revision' },
          { label: t('needs_correction'), value: 'needs_correction' },
          { label: t('validated'), value: 'validated' },
          { label: t('json_to_do'), value: 'json_to_do' },
          { label: t('json_revision'), value: 'json_revision' },
          { label: t('pending_image'), value: 'pending_image' },
          { label: t('done'), value: 'done' }
        ]}
        defaultSelection={filters.status || []}
        onChange={(value) => onChangeFilter({ id: 'status', value })}
      />

      <FilterItem
        title={t('activity_responsible')}
        type='custom'
        id='responsible'
        defaultSelection={filters.responsible || []}
        customFilter={
          <EDResponsibleProgramFilter
            multiple={false}
            defaultValue={filters.responsible || []}
            onChange={(value) => {
              onChangeFilter({ id: 'responsible', value: value });
            }}
          />
        }
      />

      {/* <FilterItem
        title={t('activity_language')}
        type='dropdown'
        options={[
          { label: t('Spanish'), value: 'es' },
          { label: t('English'), value: 'en' },
          { label: t('Portuguese'), value: 'pt' }
        ]}
        defaultSelection={filters.language || []}
        onChange={(value) => {
          onChangeFilter({ id: 'language', value });
        }}
      /> */}
    </FilterDrawer>
  );
};

export default ActivitiesFilter;
