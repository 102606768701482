import { fetchWithToken } from '../helpers/fetcher';

export const getNodesGraph = async ({
  internalEducationYearGuid,
  language
}) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `nodes/graph?langCode=${language}&internalEducationYearGuid=${internalEducationYearGuid}`
  });

  if (response?.status === 'success') {
    return response?.data;
  }
};
