import { DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ACTIVITY_TYPES,
  ActivityBlueberryComponent
} from '../../../../_practice-components';
import EDButton from '../../../../atoms/EDButton/EDButton';
import EDCircularLoading from '../../../../atoms/EDCircularLoading/EDCircularLoading';
import EDDialog from '../../../../atoms/EDDialog/EDDialog';
import i18nInstance from '../../../../i18n/i18n';
import { fetchWithToken } from '../../../../utils/helpers/fetcher';
import { useLocalStorage } from '../../../../utils/hooks/useLocalStorage';
import useUserSettings from '../../../../utils/hooks/useUserSettings';
import { localStorageKeys } from '../../../../utils/localStorageKeys';

const PreviewActivityDialog = ({ lemonadeJSON, seedId, isOpen, onClose }) => {
  const { t } = useTranslation();
  const { getItem } = useLocalStorage();
  const [lemonadeJSONResponse, setLemonadeJSONResponse] = useState(null);
  const { getPreviewLanguageSettings } = useUserSettings();
  const [previewLanguage, setPreviewLanguage] = useState(null);
  const [uiActivitiesLanguage, setUiActivitiesLanguage] = useState('en');

  useEffect(() => {
    const _language = getPreviewLanguageSettings();
    setPreviewLanguage(_language);
    setUiActivitiesLanguage(
      getItem(localStorageKeys.language) || i18nInstance.language || 'en'
    );
  }, []);

  useEffect(() => {
    if (previewLanguage) {
      updatePreviewData();
    }
  }, [lemonadeJSON, previewLanguage]);

  const updatePreviewData = async () => {
    if (!lemonadeJSON) return;
    let previewResponse = await fetchWithToken({
      path: `/seeds/preview-json-lemonade`,
      method: 'POST',
      data: {
        data: JSON.stringify(lemonadeJSON),
        lang: previewLanguage
      }
    });
    setLemonadeJSONResponse(previewResponse?.data);
  };

  return (
    <EDDialog open={isOpen} onClose={onClose} maxWidth='lg' fullWidth>
      <DialogContent>
        {seedId && (
          <Stack direction='row' spacing={2} justifyContent='center'>
            <Typography variant='h3' gutterBottom>
              {seedId}
            </Typography>
          </Stack>
        )}
        <div style={{ height: '600px', width: '100%', marginTop: 20 }}>
          {lemonadeJSONResponse ? (
            <ActivityBlueberryComponent
              activityType={ACTIVITY_TYPES.PREVIEW}
              activity={lemonadeJSONResponse}
              uiLanguage={uiActivitiesLanguage}
              mathRenderEngine='katex'
            />
          ) : (
            <EDCircularLoading position='absolute' fullscreen />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <EDButton onClick={onClose} color='primary'>
          {t('close')}
        </EDButton>
      </DialogActions>
    </EDDialog>
  );
};

export default PreviewActivityDialog;
