import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDInput from '../../atoms/EDInput/EDInput';
import { fetchWithToken } from '../../utils/helpers/fetcher';

const EDDepartmentFilter = ({
  defaultValue,
  showLabel,
  multiple,
  required,
  disabled,
  language,
  onChange
}) => {
  const { t } = useTranslation();
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState(-1);
  const [total, setTotal] = useState(0);

  const translateDepartmentName = (department) => {
    switch (department) {
      case 'Geometry':
        return t('demo_geometry');
      case 'Magnitudes and measure':
        return t('demo_magnitudes');
      case 'Numbers and operations':
        return t('demo_numbers');
      case 'Statistic and probability':
        return t('demo_statistics');
      default:
        return department;
    }
  };

  const getData = async () => {
    setIsLoading(true);

    let departmentsResponse = await fetchWithToken({
      method: 'GET',
      path: `/departments?pageSize=25&offset=0${
        language ? `&langCode[]=${language.toUpperCase()}` : ''
      }`
    });

    setDepartments(
      departmentsResponse.data.departments?.map((department) => ({
        value: department.guid,
        label:
          department.departmentLanguages.find(
            (departmentLanguage) =>
              departmentLanguage.lang.code === language.toUpperCase()
          )?.name || translateDepartmentName(department.name)
      }))
    );

    setTotal(departmentsResponse.data.count);
    setOffset(departmentsResponse.data.offset);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [language]);

  return (
    <EDAutocompleteWithLabel
      label={t('demo_departments')}
      showLabel={showLabel}
      multiple={multiple}
      required={required}
      disabled={disabled}
      defaultValue={defaultValue}
      isLoading={isLoading}
      options={departments}
      disableClearable
      onChange={(e, value) => onChange(value)}
      renderInput={(params) => (
        <EDInput {...params} placeholder={t('select_placeholder')} />
      )}
    />
  );
};

EDDepartmentFilter.defaultProps = {
  showLabel: true,
  multiple: false,
  required: false,
  disabled: false,
  language: 'ES',
  onChange: () => {}
};

EDDepartmentFilter.propTypes = {
  showLabel: PropTypes.bool,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  language: PropTypes.oneOf(['ES', 'EN', 'PT', 'es', 'en', 'pt']),
  onChange: PropTypes.func
};

export default EDDepartmentFilter;
