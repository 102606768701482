import { CardContent, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EDCard from '../../../../atoms/EDCard/EDCard';
import EDInputWithLabel from '../../../../atoms/EDInput/EDInputWithLabel';
import EDAutocompleteWithLabel from '../../../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import { update } from 'lodash';
import { t } from 'i18next';

const STANDARD_OPTIONS = [
  { value: 'BNCC', label: 'BNCC' },
  { value: 'CC', label: 'CC' }
];

const COUNTRY_OPTIONS = [
  { value: 'ES', label: 'España' },
  { value: 'BR', label: 'Brasil' },
  { value: 'PT', label: 'Portugal' },
  { value: 'US', label: 'United States' }
];

const ProgramDetails = ({
  program,
  educationYears,
  onUpdateProgram,
  tenants,
  phasers
}) => {
  useEffect(() => {
    // console.log('Loading --------', program);
    // First load
    const result = program?.tenants.map((item) => ({
      value: item?.guid,
      label: item?.name
    }));
    // console.log('LOADING RESULT', result);
  }),
    [];

  return (
    <Grid item xs={12}>
      <EDCard elevation={0}>
        <CardContent>
          {program && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='h2'>
                  {t('program_metadata_tab_title')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <EDInputWithLabel
                  required
                  label={t('program_metadata_label_name')}
                  defaultValue={program?.name}
                  onChange={({ target: { value } }) =>
                    onUpdateProgram({
                      name: value
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <EDAutocompleteWithLabel
                  required
                  showLabel
                  label={t('program_metadata_label_country')}
                  options={COUNTRY_OPTIONS}
                  defaultValue={program?.country?.name}
                  disableClearable
                  onChange={(e, country) => {
                    onUpdateProgram({
                      countryCode: country.value
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <EDAutocompleteWithLabel
                  required
                  showLabel
                  label={t('program_metadata_label_standard')}
                  options={STANDARD_OPTIONS}
                  defaultValue={program?.learningObjective?.guid}
                  disableClearable
                  onChange={(e, standard) => {
                    onUpdateProgram({
                      learningObjectiveGuid: standard.value
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <EDAutocompleteWithLabel
                  showLabel
                  label={t('program_metadata_label_education_level')}
                  options={educationYears.map((educationYear) => ({
                    value: educationYear?.guid,
                    label: educationYear?.name
                  }))}
                  defaultValue={
                    educationYears.find(
                      (educationYear) =>
                        educationYear.guid === program?.educationYear?.guid
                    )?.name
                  }
                  required
                  disableClearable
                  onChange={(e, educationYear) => {
                    onUpdateProgram({
                      educationYearGuid: educationYear.value
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <EDAutocompleteWithLabel
                  showLabel
                  label={t('program_metadata_label_phaser_template')}
                  required
                  disableClearable
                  options={phasers.map((phaserTemplate) => ({
                    value: phaserTemplate?.guid,
                    label: phaserTemplate?.name
                  }))}
                  onChange={(e, phaserTemplate) => {
                    onUpdateProgram({
                      phaserTemplateGuid: phaserTemplate.value
                    });
                  }}
                  value={program?.phaserMasterTemplate?.name}
                />
              </Grid>

              <Grid item xs={12}>
                <EDAutocompleteWithLabel
                  showLabel
                  label={t('program_metadata_label_tenant')}
                  multiple
                  defaultValue={program?.tenants.map((item) => ({
                    value: item?.guid,
                    label: item?.name
                  }))}
                  options={tenants.map((tenant) => ({
                    value: tenant?.guid,
                    label: tenant?.name
                  }))}
                  onChange={(e, selectedTenants) => {
                    onUpdateProgram({
                      tenantsGuid: selectedTenants.map((tenant) => tenant.value)
                    });
                  }}
                />
              </Grid>
            </Grid>
          )}
        </CardContent>
      </EDCard>
    </Grid>
  );
};

export default ProgramDetails;
