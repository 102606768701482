import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchWithToken } from '../../utils/helpers/fetcher';

const useDemoUsers = () => {
  const PAGE_SIZE = 10;
  const columnHelper = createColumnHelper();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(total / PAGE_SIZE);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isCreatingNewUser, setIsCreatingNewUser] = useState(false);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false);

  const columns = [
    columnHelper.accessor('name', {
      header: t('name'),
      id: 'name',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => info.renderValue()
    }),
    columnHelper.accessor('lastName', {
      header: t('lastname'),
      id: 'lastName',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => info.renderValue()
    }),
    columnHelper.accessor('email', {
      header: t('email'),
      id: 'email',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => info.renderValue()
    }),
    columnHelper.accessor('role', {
      header: t('role'),
      id: 'role',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => info.renderValue()
    })
  ];

  const onChangePage = (e, page) => {
    setCurrentPage(page);
    setOffset(page - 1);
  };

  const getData = async () => {
    setIsLoading(true);

    const queryParameters = [
      `offset=${offset}`,
      `pageSize=${PAGE_SIZE}`,
      'orderBy=createdAt',
      'orderType=DESC'
    ];

    const queryParams = queryParameters.filter(Boolean).join('&');
    const url = `/users?${queryParams}`;

    const response = await fetchWithToken({
      method: 'GET',
      path: url
    });
    setTotal(response.data.count);
    setData(
      response.data.user.map((user) => ({
        id: user.guid,
        name: user.name,
        lastName: user.lastname,
        email: user.username,
        role: t(user.rolePerson.role)
      }))
    );
    setIsLoading(false);
  };

  const onCreateUser = async (data) => {
    setIsCreatingNewUser(true);
    const response = await fetchWithToken({
      method: 'POST',
      path: '/users',
      data
    });
    console.log(response);
    if (response.status === 'success') {
      setIsCreateDialogOpen(false);
      getData();
    } else {
      setError(
        typeof response.data.message === 'string'
          ? response.data.message
          : response.data.message.join(' - ')
      );
      setShowToast(true);
    }
    setIsCreatingNewUser(false);
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  return {
    data,
    total,
    error,
    columns,
    showToast,
    isLoading,
    currentPage,
    totalPages,
    setShowToast,
    onCreateUser,
    onChangePage,
    isCreatingNewUser,
    isCreateDialogOpen,
    setIsCreatingNewUser,
    setIsCreateDialogOpen
  };
};

export default useDemoUsers;
