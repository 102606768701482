import {
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import React, { useEffect, useState } from 'react';
import EDInputWithLabel from '../../../../atoms/EDInput/EDInputWithLabel';
import EDButton from '../../../../atoms/EDButton/EDButton';
import EDAutocompleteWithLabel from '../../../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import { useTranslation } from 'react-i18next';

const COUNTRY_OPTIONS = [
  { value: 'ES', label: 'España' },
  { value: 'BR', label: 'Brasil' },
  { value: 'PT', label: 'Portugal' },
  { value: 'US', label: 'United States' }
];

const STANDARD_OPTIONS = [
  { value: '', label: 'Seleccionar...' },
  { value: 'BNCC', label: 'BNCC' },
  { value: 'CC', label: 'CC' }
];

const CreateNewProgramDialog = ({
  isOpen,
  onClose,
  tenants,
  phasers,
  educationYears,
  onCreateNewProgram
}) => {
  const { t } = useTranslation();

  const [newProgram, setNewProgram] = useState({
    name: '',
    countryCode: '',
    learningObjectiveGuid: '',
    educationLevelGuid: '',
    educationYearGuid: '',
    tenantsGuid: [],
    phaserTemplateGuid: 'default'
  });

  const [canCreateProgram, setCanCreateProgram] = useState(false);

  const phaserTemplateDefault = {
    value: 'default',
    label: 'Default'
  };

  const learningObjectiveGuid = {
    value: null,
    label: 'Seleccionar...'
  };

  useEffect(() => {
    setCanCreateProgram(
      newProgram.name !== '' &&
        newProgram.countryCode !== '' &&
        newProgram.learningObjectiveGuid !== '' &&
        newProgram.educationYearGuid !== '' &&
        newProgram.phaserTemplateGuid !== ''
    );
  }, [newProgram]);

  return (
    <Dialog fullWidth maxWidth='sm' open={isOpen} onClose={onClose}>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack>
              <Typography variant='h2'>
                {t('program_content_create_program')}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <EDInputWithLabel
              label={t('program_metadata_label_name')}
              required
              onChange={(e) => {
                setNewProgram({
                  ...newProgram,
                  name: e.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDAutocompleteWithLabel
              showLabel
              label={t('program_metadata_label_country')}
              options={COUNTRY_OPTIONS}
              required
              disableClearable
              onChange={(e, country) => {
                setNewProgram({
                  ...newProgram,
                  countryCode: country.value
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDAutocompleteWithLabel
              required
              showLabel
              label={t('program_metadata_label_standard')}
              options={STANDARD_OPTIONS}
              defaultValue={learningObjectiveGuid}
              disableClearable
              onChange={(e, standard) => {
                setNewProgram({
                  ...newProgram,
                  learningObjectiveGuid: standard.value
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <EDAutocompleteWithLabel
              showLabel
              label={t('program_metadata_label_education_level')}
              options={educationYears.map((educationYear) => ({
                value: educationYear.guid,
                label: educationYear.name
              }))}
              required
              disableClearable
              onChange={(e, educationYear) => {
                setNewProgram({
                  ...newProgram,
                  educationYearGuid: educationYear.value
                });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <EDAutocompleteWithLabel
              showLabel
              label={t('program_metadata_label_phaser_template')}
              required
              disableClearable
              options={phasers.map((phaserTemplate) => ({
                value: phaserTemplate.guid,
                label: phaserTemplate.name
              }))}
              onChange={(e, phaserTemplate) => {
                setNewProgram({
                  ...newProgram,
                  phaserTemplateGuid: phaserTemplate.value
                });
              }}
              defaultValue={phaserTemplateDefault}
            />
          </Grid>

          <Grid item xs={12}>
            <EDAutocompleteWithLabel
              showLabel
              label={t('program_metadata_label_tenant')}
              multiple
              options={tenants
                .filter((item) => !newProgram.tenantsGuid.includes(item.guid))
                .map((tenant) => ({
                  value: tenant.guid,
                  label: tenant.name
                }))}
              onChange={(e, tenants) => {
                setNewProgram({
                  ...newProgram,
                  tenantsGuid: tenants.map((tenant) => tenant.value)
                });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack direction='row' justifyContent='flex-end'>
              <EDButton variant='text' onClick={onClose}>
                {t('cancel')}
              </EDButton>
              <EDButton
                disabled={!canCreateProgram}
                variant='contained'
                disableElevation
                onClick={() => onCreateNewProgram(newProgram)}
              >
                {t('save')}
              </EDButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CreateNewProgramDialog;
