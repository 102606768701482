import { useNavigate } from 'react-router-dom';
import { isDeviceSupported } from '../../utils/helpers/device';
import { getPlatform } from '../../utils/helpers/platform';
import useAuth from '../../utils/hooks/useAuth';
import LoginForm from './LoginForm';

const LoginPage = () => {
  const {
    error,
    setError,
    onLoginWithEmailAndPassword,
    onLoginWithGoogle,
    isLoading
  } = useAuth();

  const navigate = useNavigate();

  const checkSupportedDevice = (functionLogin, args) => {
    // Se comprueba antes de entrar si el dispositivo es admitido
    if (getPlatform() === 'teacher') {
      if (!isDeviceSupported()) {
        navigate('/device-error', { replace: true });
      } else {
        functionLogin(args);
      }
    } else {
      functionLogin(args);
    }
  };

  return (
    <LoginForm
      error={error}
      setError={setError}
      isLoading={isLoading}
      onLoginWithGoogle={(credentials) => {
        checkSupportedDevice(onLoginWithGoogle, credentials);
      }}
      onLoginWithEmailAndPassword={(credentials) => {
        checkSupportedDevice(onLoginWithEmailAndPassword, credentials);
      }}
    />
  );
};

export default LoginPage;
