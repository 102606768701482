import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDInput from '../../atoms/EDInput/EDInput';

const EDLanguageFilter = ({
  showLabel,
  required,
  defaultValue,
  isLoading: isInitialLoad,
  onChange
}) => {
  const { t } = useTranslation();

  const LANGUAGE_OPTIONS = [
    { label: t('Spanish'), code: 'ES' },
    { label: t('English'), code: 'EN' },
    { label: t('Portuguese'), code: 'PT' }
  ];

  const languageCodeToOption = {
    ES: { label: t('Spanish'), code: 'ES' },
    EN: { label: t('English'), code: 'EN' },
    PT: { label: t('Portuguese'), code: 'PT' }
  };

  const [inputValue, setInputValue] = useState(
    languageCodeToOption[defaultValue]
  );

  const handleInputChange = (event, value) => {
    setInputValue(value.code);
  };

  useEffect(() => {
    onChange(inputValue);
  }, [inputValue]);

  return (
    <>
      {isInitialLoad ? (
        <>
          <Skeleton variant='text' />
          <Skeleton variant='rectangle' />
        </>
      ) : (
        <EDAutocompleteWithLabel
          showLabel={showLabel}
          required={required}
          disableClearable
          label={t('language_label')}
          defaultValue={languageCodeToOption[defaultValue]}
          options={LANGUAGE_OPTIONS}
          multiple={false}
          onChange={(e, value) => handleInputChange(e, value)}
          renderInput={(params) => (
            <EDInput
              {...params}
              variant='outlined'
              placeholder={t('language_placeholder')}
            />
          )}
        />
      )}
    </>
  );
};

export default EDLanguageFilter;
