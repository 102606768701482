import React from 'react';
import './gameQuizHeader.scss';

const GameQuizHeader = ({ leftComponent, centerComponent, rightComponent }) => {
  return (
    <div className='game-quiz-header'>
      <div className='game-quiz-header__left'>{leftComponent}</div>
      <div className='game-quiz-header__center'>{centerComponent}</div>
      <div className='game-quiz-header__right'>{rightComponent}</div>
    </div>
  );
};

export default GameQuizHeader;
