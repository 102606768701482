import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import { PostHogProvider, usePostHog } from 'posthog-js/react';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../utils/contexts/AuthContext';
import { getOrganization as getOrganizationData } from '../utils/crud/organization';
import './TeacherDashboardWrapper.scss';

const IS_LOCALHOST = window.location.href.indexOf('localhost') > -1;

const TeacherDashboardWrapper = () => {
  const { isLoggedIn, user } = useContext(AuthContext);
  const ph = usePostHog();

  // TODO: convert to api based environment detection
  //   prod
  const [isLoading, setIsLoading] = useState(true);
  const [isProductionEnvironment, setIsProductionEnvironment] = useState(false);
  const [logo, setLogo] = useState('');
  const [isMultiCountry, setIsMultiCountry] = useState(false);
  const [googleClientId, setGoogleClientId] = useState('');
  const [lang, setLang] = useState('es');

  const [isHotjarInitialized, setIsHotjarInitialized] = useState(false);
  const [isPostHogInitialized, setIsPostHogInitialized] = useState(false);
  const [isSegmentInitialized, setIsSegmentInitialized] = useState(false);
  const location = useLocation();

  const initializeHotjar = (isProduction) => {
    if (!isHotjarInitialized) {
      const siteId = isProduction
        ? import.meta.env.VITE_HOTJAR_SITE_ID
        : import.meta.env.VITE_HOTJAR_SITE_ID_DEV;
      const hotjarVersion = import.meta.env.VITE_HOTJAR_VERSION;

      // Initializing with `debug` option:
      Hotjar.init(siteId, hotjarVersion, {
        debug: !isProduction
      });

      setIsHotjarInitialized(true);
    }

    Hotjar.identify(user?.guid, user);
  };

  const initializePostHog = () => {
    if (isLoggedIn) {
      if (!isPostHogInitialized) {
        posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
          api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
          autocapture: !Boolean(user?.is_demo),
          disable_session_recording: Boolean(user?.is_demo)
        });

        setIsPostHogInitialized(true);
      }

      ph.identify(user?.guid, user);
      return;
    }

    ph.reset();
  };

  const initializeSentryUser = () => {
    if (Sentry.getClient() && user) {
      Sentry.setUser(user);
    }
  };

  const initializeSegment = (isProduction) => {
    if (!isSegmentInitialized) {
      const siteId = isProduction
        ? import.meta.env.VITE_SEGMENT_SITE_ID
        : import.meta.env.VITE_SEGMENT_SITE_ID_DEV;

      // analytics.load(siteId);
      analytics.load(siteId, {
        integrations: { 'Segment.io': { apiHost: segmentAPI + '/v1' } }
      });
      setIsSegmentInitialized(true);
    }
    analytics.identify(user?.guid, user);
    analytics.page();
  };

  const getOrganization = async () => {
    setIsLoading(true);

    const org = await getOrganizationData();
    setIsProductionEnvironment(org?.tenant?.isProduction);
    setLogo(org?.logo);
    setIsMultiCountry(org?.tenant?.is_multi_country);
    setGoogleClientId(org?.tenant?.google_client_id);
    setLang(org?.tenant?.lang_id);

    setIsLoading(false);
  };

  const initializePageAnalytics = () => {
    analytics.page();
  };

  useEffect(() => {
    initializePageAnalytics();
  }, [location]);

  useEffect(() => {
    getOrganization();
  }, []);

  useEffect(() => {
    if (isLoading) return; // Guard until organization data is fetched to know if production tenant or not

    localStorage.setItem(
      'bb_is_production',
      isProductionEnvironment ? '1' : '0'
    );

    const forceDisableAnalytics = localStorage.getItem('bb_disable_analytics');

    if (IS_LOCALHOST) return;

    if (
      isLoggedIn &&
      !user?.is_demo &&
      !IS_LOCALHOST &&
      !forceDisableAnalytics
    ) {
      initializeHotjar(isProductionEnvironment);
      initializeSegment(isProductionEnvironment);
    }

    const IS_DEV_QA_ENVIRONMENT = !isProductionEnvironment && !IS_LOCALHOST;

    if (IS_DEV_QA_ENVIRONMENT) {
      return;
    }

    // ONLY FOR PRODUCTION USERS
    // PostHog is used for feature flags. In order to view the same view as the user, we need to keep it enabled.
    initializePostHog();
    // All incidents on production for all users are sent to sentry
    initializeSentryUser();
  }, [isLoggedIn, isProductionEnvironment, user?.is_demo, isLoading]);

  if (!isProductionEnvironment || IS_LOCALHOST) {
    return (
      <Fragment>
        <ToastContainer />
        <Outlet />
      </Fragment>
    );
  }

  // FOR PRODUCTION ENVIRONMENTS
  return (
    <PostHogProvider client={posthog}>
      <ToastContainer />
      <Outlet />
    </PostHogProvider>
  );
};

export default TeacherDashboardWrapper;
