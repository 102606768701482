import { CardContent, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import EDCard from '../../../../atoms/EDCard/EDCard';
import Toast from '../../../../atoms/Toast/Toast';
import TableDashboard from '../../../../view/tableDashboard/TableDashboard';
import PreviewActivityDialog from '../../activitiesManager/components/PreviewActivityDialog';
import useProgramActivities from './useProgramActivities';

const ProgramActivities = ({ program }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    isLoading,
    showToast,
    totalPages,
    currentPage,
    toastMessage,
    programSeeds,
    totalEntries,
    programSeedsTableColumns,
    onChangePage,
    onChangeSearch
  } = useProgramActivities({ programGuid: program.guid, program });

  const [selectedActivity, setSelectedActivity] = useState(null);
  const [isPreviewActivityDialogOpen, setIsPreviewActivityDialogOpen] =
    useState(false);
  const [previewLemonadeJSON, setPreviewLemonadeJSON] = useState(null);

  const onClosePreview = () => {
    setIsPreviewActivityDialogOpen(false);
    setSelectedActivity(null);
    setPreviewLemonadeJSON(null);
  };

  return (
    <Grid item xs={12}>
      <EDCard elevation={0}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h2'>
                {t('program_activities_tab_title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableDashboard
                isTableDataLoading={isLoading}
                tableName='program-activities'
                disableSelection
                disableFilters
                showPagination
                totalPages={totalPages}
                currentPage={parseInt(currentPage) || 1}
                onChangePage={onChangePage}
                tableColumns={programSeedsTableColumns}
                tableData={programSeeds}
                totalEntries={totalEntries}
                onSearch={onChangeSearch}
                onRowClick={(seed) => {
                  navigate(`/activities/${seed.seedGuid}`);
                }}
                options={[
                  {
                    label: t('activity_preview'),
                    onClick: (row) => {
                      setSelectedActivity(row);
                      setPreviewLemonadeJSON(row.seedData);
                      setIsPreviewActivityDialogOpen(true);
                    }
                  }
                ]}
                isCustomizable={true}
                tableColumnsVisibility={{
                  unitName: false,
                  lessonName: false
                }}
              />
            </Grid>
          </Grid>
        </CardContent>

        {isPreviewActivityDialogOpen && (
          <PreviewActivityDialog
            onClose={onClosePreview}
            lemonadeJSON={previewLemonadeJSON}
            seedId={selectedActivity?.seedName}
            isOpen={isPreviewActivityDialogOpen}
          />
        )}

        {showToast && (
          <Toast
            handleShowToast={setShowToast}
            type={toastMessage.type}
            text={toastMessage.message}
          />
        )}
      </EDCard>
    </Grid>
  );
};

export default ProgramActivities;
