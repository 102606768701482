import { ButtonGroup, styled } from '@mui/material';

const EDButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  '&.MuiButtonGroup-root': {
    borderRadius: '2rem',
    backgroundColor: theme.palette.grey[100],
    '& .MuiButtonGroup-grouped': {
      border: 'none',
      '&(:first-of-type)': {
        marginRight: 1,
        marginLeft: 0
      },
      '&:not(:last-of-type)': {
        marginLeft: 1,
        marginRight: 0
      },
      marginRight: 1,
      marginLeft: 1
    }
  }
}));

export default EDButtonGroup;
