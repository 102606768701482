import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EDButton from '../EDButton/EDButton';
import { ReactComponent as DangerIconCircle } from '../../assets/icons/danger_circle.svg';
import './AlertDialogWithIcon.scss';

export default function AlertDialogWithIcon({
  title,
  description,
  isOpen,
  onCancel,
  onConfirm,
  confirmText,
  cancelText
}) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      className='alert-dialog-with-icon'
      onClose={(event, reason) => onCancel()}
    >
      <div className='alert-dialog-with-icon__icon'>
        <DangerIconCircle />
      </div>

      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <EDButton onClick={onCancel}>{cancelText}</EDButton>
        <EDButton
          disableElevation
          color='primary'
          variant='contained'
          onClick={onConfirm}
        >
          {confirmText}
        </EDButton>
      </DialogActions>
    </Dialog>
  );
}
