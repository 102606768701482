import { useEffect, useState } from 'react';
import { fetchWithToken } from '../../../../utils/helpers/fetcher';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

const useLicenses = () => {
  const navigate = useNavigate();

  const columnHelper = createColumnHelper();
  const pageSize = 10;

  const [licenses, setLicenses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);

  const columns = [
    columnHelper.accessor('code', {
      header: 'Code',
      id: 'code',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('uses', {
      header: 'Uses',
      id: 'uses',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('countdown', {
      header: 'Total',
      id: 'countdown',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('created_at', {
      header: 'Start Date',
      id: 'created_at',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('expired_at', {
      header: 'Expiry Date',
      id: 'expired_at',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('expires_after', {
      header: 'Expires After',
      id: 'expires_after',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    })
  ];

  const onClickOnLicense = (e) => {
    navigate(`/licenses/${e.guid}`);
  };

  const getData = async () => {
    setIsLoading(true);
    let response = await fetchWithToken({
      path: `/licenses/personals?pageSize=${pageSize}&offset=${offset}`
    });
    setLicenses(response.data.personalLicenses);
    setTotal(response.data.total);
    setOffset(response.data.offset);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    total,
    offset,
    columns,
    licenses,
    pageSize,
    isLoading,
    onClickOnLicense
  };
};
export default useLicenses;
