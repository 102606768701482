import {
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography
} from '@mui/material';
import React from 'react';
import EDCard from '../../../../../atoms/EDCard/EDCard';
import EDInputWithLabel from '../../../../../atoms/EDInput/EDInputWithLabel';
import EDAutocompleteWithLabel from '../../../../../atoms/EDAutocomplete/EDAutocompleteWithLabel';

const UserDetails = ({
  name,
  lastname,
  username,
  userSchool,
  userGroups,
  email,
  role,
  schools,
  groups,
  isDemoUser,
  onUpdateUser,
  jsonSettings
}) => {
  const jsonSettingsObj = JSON.parse(jsonSettings);
  const isDevMode = jsonSettingsObj?.blueberry?.devmode;
  const upperCaseRequired =
    jsonSettingsObj?.blueberry?.letter_case === 'upperCase';

  const onUpdateSettings = (property, value) => {
    const newSettingsObj = {
      ...jsonSettingsObj,
      blueberry: {
        ...jsonSettingsObj?.blueberry,
        [property]: value
      }
    };

    onUpdateUser({
      json_settings: JSON.stringify(newSettingsObj)
    });
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EDCard>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='h2'>Details</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant='h2'>
                  <EDInputWithLabel
                    label='Name'
                    defaultValue={name}
                    onChange={({ target: { value } }) => {
                      onUpdateUser({ name: value });
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <EDInputWithLabel
                  label='Last Name'
                  defaultValue={lastname}
                  onChange={({ target: { value } }) => {
                    onUpdateUser({ lastname: value });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <EDInputWithLabel
                  label='Username / Email Address'
                  defaultValue={username || email}
                  onChange={({ target: { value } }) => {
                    onUpdateUser({ name: value });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <EDAutocompleteWithLabel
                  label='Role'
                  showLabel
                  defaultValue={role}
                  disableClearable
                  options={[
                    { label: 'Admin', value: 'R04' },
                    { label: 'School Admin', value: 'R03' },
                    { label: 'Teacher', value: 'R02' },
                    { label: 'Student', value: 'R01' }
                  ]}
                  onChange={(e, value) => {
                    onUpdateUser({ role_guid: value.value });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked={Boolean(upperCaseRequired)}
                        onChange={(e) =>
                          onUpdateSettings(
                            'letter_case',
                            e.target.checked ? 'upperCase' : 'regularCase'
                          )
                        }
                      />
                    }
                    label='Uppercase Required (Texts in uppercase for this user)'
                  />
                </FormGroup>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked={Boolean(isDevMode)}
                        onChange={(e) =>
                          onUpdateSettings('devmode', e.target.checked ? 1 : 0)
                        }
                      />
                    }
                    label='Dev Mode (Enables skipping exercises and reset game options)'
                  />
                </FormGroup>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked={Boolean(isDemoUser)}
                        onChange={(e) => {
                          onUpdateUser({ is_demo: e.target.checked ? 1 : 0 });
                        }}
                      />
                    }
                    label='Demo User (Disables analytics)'
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </EDCard>
        </Grid>
        <Grid item xs={12}>
          <EDCard pb={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='h2'>School</Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <EDAutocompleteWithLabel
                  label='School'
                  showLabel
                  options={schools}
                  value={userSchool}
                  disableClearable
                  onChange={(e, value) => {
                    onUpdateUser({ userSchool: value });
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <EDAutocompleteWithLabel
                  label='Groups'
                  showLabel
                  options={groups}
                  value={userGroups}
                  multiple
                  disableClearable
                  onChange={(e, value) => {
                    onUpdateUser({ userGroups: value });
                  }}
                />
              </Grid>
            </Grid>
          </EDCard>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserDetails;
