import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDCard from '../../../../../atoms/EDCard/EDCard';
import Toast from '../../../../../atoms/Toast/Toast';
import TableDashboard from '../../../../../view/tableDashboard/TableDashboard';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog';
import CreateEditGroupDialog from './dialogs/CreateEditGroupDialog';
import ListUsersInGroupDialog from './dialogs/ListUsersInGroupDialog';
import useSchoolGroups from './useSchoolGroups';

const SchoolGroups = ({
  groups,
  schoolGuid,
  schoolYears,
  educationYears,
  educationLevels,
  totalGroupsInSchool,
  onDeleteUserFromGroup,
  users,
  onUpdateGroup,
  getSchoolGroups,
  onCreateNewGroup
}) => {
  const { t } = useTranslation();

  const [hasSchoolYears, setHasSchoolYears] = useState(false);
  const [isCreateNewGroupOpen, setIsCreateNewGroupOpen] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [messageToast, setMessageToast] = useState({ type: '', text: '' });
  const [selectedGroupToDelete, setSelectedGroupToDelete] = useState(null);
  const [selectedGroupToEdit, setSelectedGroupToEdit] = useState(null);
  const [isEditGroupDialogOpen, setIsEditGroupDialogOpen] = useState(false);

  const {
    columns,
    usersInGroup,
    selectedGroup,
    isGroupsDataLoading,
    isLoadingUsersInGroup,
    isListOfUsersInGroupOpen,
    isConfirmDeleteGroupDialogOpen,
    onDeleteGroup,
    setGroupToDelete,
    onGetUsersInGroup,
    onCloseListOfUsersInGroup,
    setIsConfirmDeleteGroupDialogOpen
  } = useSchoolGroups({
    schoolGuid,
    users,
    onUpdateGroup,
    getSchoolGroups,
    setShowToast,
    setMessageToast
  });

  useEffect(() => {
    setHasSchoolYears(schoolYears && schoolYears.length > 0);
  }, [schoolYears]);

  const onClose = () => {
    setIsCreateNewGroupOpen(false);
    setIsEditGroupDialogOpen(false);
    setSelectedGroupToEdit(null);
  };

  const onCreate = () => {
    if (hasSchoolYears) {
      setIsCreateNewGroupOpen(true);
    } else {
      setMessageToast({
        type: 'info',
        text: t('group_cant_create_missing_school_year')
      });
      setShowToast(true);
    }
  };

  const tableOptions = [
    {
      label: t('edit'),
      onClick: (group) => {
        setSelectedGroupToEdit(group);
        setIsEditGroupDialogOpen(true);
      }
    },
    {
      label: t('delete'),
      onClick: (group) => {
        setGroupToDelete(group);
        setSelectedGroupToDelete(group);
        setIsConfirmDeleteGroupDialogOpen(true);
      }
    }
  ];

  return (
    <Grid item xs={12}>
      <EDCard elevation={0}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2'>Groups</Typography>
          </Grid>
          <Grid item xs={12}>
            <TableDashboard
              filters={[]}
              onSearch={() => {}}
              onFilter={() => {}}
              tableData={groups}
              onRowClick={onGetUsersInGroup}
              tableColumns={columns}
              totalEntries={totalGroupsInSchool}
              onClickOnCreate={onCreate}
              singleEntryLabel={'group'}
              pluralEntryLabel={'groups'}
              isTableDataLoading={isGroupsDataLoading}
              searchInputPlaceholder={'Search groups'}
              options={tableOptions}
            />
          </Grid>
        </Grid>
      </EDCard>

      {hasSchoolYears && (isCreateNewGroupOpen || isEditGroupDialogOpen) && (
        <CreateEditGroupDialog
          educationYears={educationYears}
          educationLevels={educationLevels}
          schoolYears={schoolYears}
          isOpen={isCreateNewGroupOpen || isEditGroupDialogOpen}
          onClose={onClose}
          isEditing={isEditGroupDialogOpen}
          groupData={selectedGroupToEdit}
          onSubmit={(params) => {
            if (isEditGroupDialogOpen) {
              onUpdateGroup({ ...selectedGroupToEdit, ...params });
            } else {
              onCreateNewGroup(params);
            }
            onClose();
          }}
        />
      )}

      {isListOfUsersInGroupOpen && (
        <ListUsersInGroupDialog
          group={selectedGroup}
          onClose={onCloseListOfUsersInGroup}
          usersInGroup={usersInGroup}
          isOpen={isListOfUsersInGroupOpen}
          isLoading={isLoadingUsersInGroup}
          onDeleteUserFromGroup={onDeleteUserFromGroup}
        />
      )}

      <ConfirmDeleteDialog
        isOpen={isConfirmDeleteGroupDialogOpen}
        onClose={() => {
          setGroupToDelete(null);
          setSelectedGroupToDelete(null);
          setIsConfirmDeleteGroupDialogOpen(false);
        }}
        onConfirm={onDeleteGroup}
        itemToBeDeletedName={selectedGroupToDelete?.name}
        itemToBeDeletedDescription={t('delete_group_confirmation_message')}
      />

      {showToast && (
        <Toast
          handleShowToast={setShowToast}
          type={messageToast.type}
          text={messageToast.text}
        />
      )}
    </Grid>
  );
};

export default SchoolGroups;
