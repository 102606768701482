import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import { TreeView } from '@mui/lab';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { CircularProgress, Stack, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import SvgIcon from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import { animated, useSpring } from '@react-spring/web';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDButton from '../../../../atoms/EDButton/EDButton';

function MinusSquare(props) {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z' />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z' />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)'
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`
    }
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool
};

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3
    }
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
  },
  [`& .${treeItemClasses.content}`]: {
    paddingTop: 5,
    paddingBottom: 5
  }
}));

export default function KCsAndLOsTree({
  kCsAndLOs,
  onClickOnLO,
  isLoading,
  isLoadingMore,
  hasMoreToLoad,
  onLoadMore,
  isLoadingSeeds
}) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
    // console.log(nodeIds);

    const los = kCsAndLOs.map((kc) => kc.learningOutcomes).flat();
    const selectedLO = los.filter((lo) => nodeIds.includes(lo.guid))[0];
    onClickOnLO(selectedLO);
  };

  // const handleExpandClick = () => {
  //   setExpanded((oldExpanded) =>
  //     oldExpanded.length === 0 ? ['1', '5', '6', '7'] : []
  //   );
  // };

  // const handleSelectClick = () => {
  //   setSelected((oldSelected) =>
  //     oldSelected.length === 0
  //       ? ['1', '2', '3', '4', '5', '6', '7', '8', '9']
  //       : []
  //   );
  // };

  useEffect(() => {
    console.log('kCsAndLOs', kCsAndLOs);
  }, []);

  if (isLoading) {
    return (
      <Stack
        sx={{ width: '100%' }}
        spacing={2}
        direction='row'
        alignItems='center'
        justifyContent='center'
      >
        <CircularProgress />
      </Stack>
    );
  }

  if (kCsAndLOs.length === 0) {
    return (
      <Typography variant='caption' color='GrayText'>
        {t('demo_no_kc_and_lo')}
      </Typography>
    );
  }

  return (
    <>
      <TreeView
        aria-label='customized'
        defaultExpanded={['1']}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<ExitToAppRoundedIcon />}
        sx={{ flexGrow: 1, overflowY: 'auto' }}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        multiSelect
      >
        {kCsAndLOs.map((kc) => (
          <StyledTreeItem
            key={kc.guid}
            nodeId={kc.guid}
            label={
              <Typography variant='body2'>
                {kc.description || kc.name}
              </Typography>
            }
          >
            {kc.learningOutcomes.map((lo) => (
              <StyledTreeItem
                key={lo.guid}
                nodeId={lo.guid}
                label={
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent={'space-between'}
                    spacing={1}
                    sx={{ width: '100%' }}
                  >
                    <Typography variant='body2'>
                      {lo.description || lo.name}
                    </Typography>
                    {isLoadingSeeds && selected[0] === lo.guid && (
                      <CircularProgress size={12} />
                    )}
                  </Stack>
                }
              />
            ))}
          </StyledTreeItem>
        ))}
      </TreeView>
      {hasMoreToLoad && (
        <EDButton onClick={onLoadMore} disabled={isLoadingMore}>
          {isLoadingMore && <CircularProgress />}
          {!isLoadingMore && t('load_more')}
        </EDButton>
      )}
    </>
  );
}
