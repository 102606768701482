import { useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { AnalysisSegment } from '../../../utils/analysis';
import { CourseContext } from '../../../utils/contexts/CourseContext';
import { getStudentDetails } from '../../../utils/crud/student';

const useStudentDetails = () => {
  const { studentGuid } = useParams();

  const { selectedCourse } = useContext(CourseContext);

  const [isLoading, setIsLoading] = useState(true);
  const [student, setStudent] = useState({});
  const [activities, setActivities] = useState([]);
  const [insights, setInsights] = useState([]);

  const getStudent = async () => {
    setIsLoading(true);

    const studentResponse = await getStudentDetails({
      courseGuid: selectedCourse.guid,
      studentGuid
    });

    setStudent({
      ...studentResponse.student,
      school: selectedCourse.school_group_name,
      grade: selectedCourse.education_year_name,
      registered: selectedCourse.created_at,
      fullName: `${studentResponse.student.name} ${studentResponse.student.lastname}`
    });
    setActivities(studentResponse.activities);
    setInsights(studentResponse.insights);

    setIsLoading(false);
  };

  useEffect(() => {
    if (studentGuid && selectedCourse?.guid) {
      getStudent();
    }
  }, [studentGuid, selectedCourse]);

  useEffect(() => {
    if (!selectedCourse) return;
    AnalysisSegment.sendSegmentTrackEvent(
      AnalysisSegment.SEGMENT_EVENTS.Student_Profile_Viewed,
      {
        class: selectedCourse?.guid,
        program_id: selectedCourse?.program_guid,
        student_id: studentGuid
      }
    );
  }, [selectedCourse]);

  return { student, activities, insights, isLoading };
};

export default useStudentDetails;
