export const lastUpdate = (date, user, translate) => {
  const now = new Date();
  const then = new Date(date);
  const diff = Math.abs(now - then) / 1000; // Convert difference to seconds
  let timeAgo, unit;

  if (diff < 60) {
    timeAgo = Math.round(diff);
    unit = translate('second', { count: timeAgo });
  } else if (diff < 3600) {
    timeAgo = Math.round(diff / 60);
    unit = translate('minute', { count: timeAgo });
  } else if (diff < 86400) {
    timeAgo = Math.round(diff / 3600);
    unit = translate('hour', { count: timeAgo });
  } else if (diff < 2592000) {
    timeAgo = Math.round(diff / 86400);
    unit = translate('day', { count: timeAgo });
  } else if (diff < 31536000) {
    timeAgo = Math.round(diff / 2592000);
    unit = translate('month', { count: timeAgo });
  } else {
    timeAgo = Math.round(diff / 31536000);
    unit = translate('year', { count: timeAgo });
  }

  return translate('last_update_date', {
    timeAgo: timeAgo,
    unit: unit,
    user: user
  });
};
