import React from 'react';
import { useCSVReader } from 'react-papaparse';
import { useTranslation } from 'react-i18next';
import EDButton from '../../../../../../../atoms/EDButton/EDButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import './FileReader.scss'

const FileReader = ({ onUploadAccepted }) => {
  const { t } = useTranslation();

  const { CSVReader } = useCSVReader();

  return (
    <div className='file-reader'>
      <CSVReader onUploadAccepted={onUploadAccepted}>
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
          <>
            <div className='file-reader__input'>
              <EDButton
                variant='contained'
                disableElevation
                startIcon={<CloudUploadIcon />}
                {...getRootProps()}
              >
                Select a file to upload
              </EDButton>

              <div className='file-reader__accepted-file'>
                {acceptedFile && acceptedFile.name}
              </div>

              {/* <button {...getRemoveFileProps()} className='file-reader__clear'>
                Clear
              </button> */}
            </div>

            <ProgressBar
              className='file-reader__progress-bar'
              style={{ backgroundColor: 'red' }}
            />
          </>
        )}
      </CSVReader>
    </div>
  );
};

export default FileReader;
