import { ThemeProvider, createTheme } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';
import DashboardWithTopbarMenu from '../layouts/dashboardWithTopbarMenu/DashboardWithTopbarMenu';
import ClassProgress from '../pages/teacherDashboard/classProgress/ClassProgress';
import ClassProgressStandards from '../pages/teacherDashboard/classProgress/standard/ClassProgressStandards';
import ClassProgressTimeline from '../pages/teacherDashboard/classProgress/timeline/ClassProgressTimeline';
import ClassProgressUnits from '../pages/teacherDashboard/classProgress/units/ClassProgressUnits';
import ContentsPage from '../pages/teacherDashboard/contentAnalysis/ContentsPage';
import ContentsStandardAnalysis from '../pages/teacherDashboard/contentAnalysis/standard/ContentsStandardAnalysis';
import ContentsUnitsAnalysis from '../pages/teacherDashboard/contentAnalysis/units/ContentsUnitsAnalysis';
import Profile from '../pages/teacherDashboard/profile/Profile';
import StandardDetails from '../pages/teacherDashboard/standardDetails/StandardDetails';
import StudentsPage from '../pages/teacherDashboard/studentAnalysis/StudentsPage';
import StudentsStandardsAnalysis from '../pages/teacherDashboard/studentAnalysis/standard/StudentsStandardsAnalysis';
import StudentsUnitsAnalysis from '../pages/teacherDashboard/studentAnalysis/units/StudentsUnitsAnalysis';
import StudentDetails from '../pages/teacherDashboard/studentDetails/StudentDetails';
import UnitDetails from '../pages/teacherDashboard/unitDetails/unitDetails';
import XRay from '../pages/teacherDashboard/xRay/XRay';
import { CourseProvider } from '../utils/contexts/CourseContext';
import TeacherDashboardWrapper from '../wrapper/TeacherDashboardWrapper';
// import { OrganizationProvider } from '../utils/contexts/OrganizationContext';
import GeneralError from '../pages/general-error/GeneralError';
import CreateQuiz from '../pages/teacherDashboard/practices/CreateQuiz/CreateQuiz';
import ListQuizzes from '../pages/teacherDashboard/practices/listQuizzes/ListQuizzes';
import PlayQuiz from '../pages/teacherDashboard/practices/playQuiz/PlayQuiz';
import ViewQuiz from '../pages/teacherDashboard/practices/viewQuiz/ViewQuiz';
import ListReports from '../pages/teacherDashboard/reports/ListReports';

const upperNavigation = [
  {
    name: 'home',
    href: '/class-progress/units',
    icon: 'home',
    key: ['class-progress'],
    flag: false
  },
  {
    name: 'content',
    href: '/content/units',
    icon: 'document',
    key: ['content'],
    flag: false
  },
  {
    name: 'students',
    href: '/students/units',
    icon: 'users',
    key: ['students'],
    flag: false
  },
  {
    name: 'x-ray',
    href: '/xray',
    icon: 'chip',
    key: ['xray'],
    flag: false
  },
  {
    name: 'Quiz',
    href: '/quizzes',
    icon: 'bar-menu-license',
    key: ['quizzes'],
    flag: 'enableQuizFeature'
  },
  {
    name: 'Reports',
    href: '/reports',
    icon: 'bar-menu-license',
    key: ['reports'],
    flag: 'enableTeacherReportsFeature'
  },
  {
    type: 'separator',
    key: ['separator1'],
    flag: false
  }
];

const theme = createTheme({
  palette: {
    common: {
      black: '#303949'
    },
    primary: {
      main: '#5A34E1',
      light: '#a2a2f6',
      dark: '#3e249d'
    },
    success: {
      main: '#00e19a',
      light: '#B7F1E2',
      dark: '#13B481'
    },
    error: {
      main: '#ff3d30',
      light: '#FFE5E2',
      dark: '#DE2A1E'
    },
    warning: {
      main: '#ffae56',
      light: '#FFF0DE',
      dark: '#E8963D'
    },
    info: {
      main: '#45dbf4',
      light: '#DDF9FF',
      dark: '#1FB9D2'
    },
    text: {
      primary: '#303949'
    },
    grey: {
      50: '#FDFDFD',
      100: '#F4F6FA',
      200: '#F4F6FA',
      300: '#E7E7E9',
      400: '#C3C0DC',
      500: '#9997AE',
      600: '#3E3A68',
      700: '#1B1A26'
    }
  },
  typography: {
    fontFamily: 'Poppins',
    h1: {
      fontSize: '1.75rem',
      fontWeight: 700,
      lineHeight: 1.2143
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: 1.25
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: 1.25
    },
    h4: {
      fontSize: '0.875rem',
      lineHeight: 1.4286
    }
  },
  shape: {
    borderRadius: '0.5rem'
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          fontSize: '14px',
          color: '#444E61',
          fontWeight: '500',
          border: '2px solid  #EEF2F7',
          borderRadius: '8px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          fontSize: '14px',
          color: '#444E61',
          fontWeight: '500',
          border: '2px solid  #EEF2F7',
          borderRadius: '8px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '&.MuiOutlinedInput-root.MuiAutocomplete-input': {
            padding: 0
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          fontSize: '14px',
          color: '#444E61',
          fontWeight: '500',
          border: '2px solid  #EEF2F7',
          borderRadius: '8px',
          '& .MuiOutlinedInput-root': {
            border: 'none',
            padding: 'padding: 12px 16px'
          }
        },
        paper: {
          fontFamily: 'Poppins',
          fontSize: '14px',
          color: '#444E61',
          fontWeight: '500'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Quicksand',
          fontSize: '13px',
          color: '#444E61',
          fontWeight: '500',
          lineHeight: '14px',
          padding: '8px 8px',
          margin: '0 8px',
          borderRadius: '8px',
          boxShadow: 'none'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        elevation8: {
          boxShadow: 'none',
          border: '2px solid  #EEF2F7'
        }
      }
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'transparent' },
          style: {
            borderRadius: 12,
            background: 'rgba(0, 0, 0, 0.10)',
            '& svg': {
              fill: '#ffffff'
            }
          }
        }
      ]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 36,
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: '700',
          lineHeight: '24px',
          padding: '12px 24px'
        }
      },
      variants: [
        {
          props: { variant: 'text' },
          style: {
            padding: 0,
            color: '#5A34E1',
            ':hover': {
              backgroundColor: 'transparent',
              color: '#30079A'
            }
          }
        },
        {
          props: { variant: 'contained', color: 'grey' },
          style: {
            color: '#444E61',
            backgroundColor: 'transparent',
            ':hover': {
              color: '#444E61',
              backgroundColor: 'transparent'
            },
            '& svg': {
              color: '#444E61'
            },
            '&.Mui-disabled ': {
              opacity: 0.7,
              backgroundColor: 'transparent'
            }
          }
        },
        {
          props: { variant: 'text', color: 'info' },
          style: {
            color: '#444E61',
            backgroundColor: 'transparent',
            ':hover': {
              color: '#444E61',
              backgroundColor: 'transparent'
            }
          }
        },

        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            borderWidth: 2,
            borderColor: '#5A34E1',
            '& .ai-icon path': {
              fill: '#5A34E1'
            },
            ':hover': {
              borderWidth: 2,
              color: '#ffffff',
              backgroundColor: '#5A34E1',

              '& .ai-icon path': {
                fill: '#ffffff'
              }
            },
            ':active': {
              color: '#ffffff',
              backgroundColor: '#5A34E1'
            },
            ':disabled': {
              borderWidth: 2,
              color: '#ffffff',
              backgroundColor: '#5A34E1',
              fill: '#ffffff',

              '& .ai-icon path': {
                fill: '#ffffff'
              },

              '& svg ': {
                color: 'white'
              }
            }
          }
        },

        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            color: '#ffffff',
            '& .ai-icon path': {
              fill: '#ffffff'
            },
            ':disabled': {
              '& .ai-icon path': {
                fill: '#8892A7'
              }
            }
          }
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: '#ffffff',
            color: '#5A34E1',
            '& svg': {
              fill: '#5A34E1'
            },
            ':hover': {
              backgroundColor: '#3e249d',
              color: '#ffffff',
              '& svg': {
                fill: '#ffffff'
              }
            }
          }
        },
        {
          props: { variant: 'contained', color: 'info' },
          style: {
            backgroundColor: '#444E61',
            color: '#ffffff',
            ':hover': {
              backgroundColor: '#444E61'
            }
          }
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            backgroundColor: '#ffffff',
            color: '#5A34E1',
            border: '2px solid #5A34E1',
            '& svg': {
              fill: '#5A34E1'
            },
            ':hover': {
              backgroundColor: '#f4f1ff',
              border: '2px solid #5A34E1',
              color: '#5A34E1',
              '& svg': {
                fill: '#5A34E1'
              }
            }
          }
        }
      ]
    }
  }
});

export const teacherDashboardRoutes = [
  {
    element: (
      <CourseProvider>
        <Outlet />
      </CourseProvider>
    ),
    children: [
      {
        element: (
          <ThemeProvider theme={theme}>
            <TeacherDashboardWrapper />
          </ThemeProvider>
        ),
        children: [
          {
            path: '/',
            exact: true,
            element: (
              <Navigate
                to={`/class-progress/units${window.location.search}`}
                replace
              />
            )
          },
          {
            element: (
              <DashboardWithTopbarMenu upperNavigation={upperNavigation} />
            ),
            children: [
              {
                path: '/class-progress',
                element: <ClassProgress />,
                children: [
                  {
                    index: true,
                    path: '/class-progress/units',
                    element: <ClassProgressUnits />
                  },
                  {
                    path: '/class-progress/standards',
                    element: <ClassProgressStandards />
                  },
                  {
                    path: '/class-progress/timeline',
                    element: <ClassProgressTimeline />
                  },
                  {
                    path: '/class-progress',
                    element: <Navigate to='/class-progress/units' replace />
                  }
                ]
              },
              {
                path: '/content',
                element: <ContentsPage />,
                children: [
                  {
                    index: true,
                    path: '/content/units',
                    exact: true,
                    element: <ContentsUnitsAnalysis />
                  },
                  {
                    path: '/content/standards',
                    exact: true,
                    element: <ContentsStandardAnalysis />
                  },
                  {
                    path: '/content',
                    element: <Navigate to='/content/units' replace />
                  }
                ]
              },
              {
                path: '/students',
                element: <StudentsPage />,
                children: [
                  {
                    index: true,
                    path: '/students/units',
                    element: <StudentsUnitsAnalysis />
                  },
                  {
                    path: '/students/standards',
                    element: <StudentsStandardsAnalysis />
                  },
                  {
                    path: '/students',
                    element: <Navigate to='/students/units' replace />
                  }
                ]
              },
              {
                path: '/quizzes',
                element: <ListQuizzes />
              },
              {
                path: '/xray',
                element: <XRay />
              },
              {
                path: '/error404',
                element: <GeneralError code={404} fullscreen={false} />
              },
              {
                path: '/error500',
                element: <GeneralError code={500} fullscreen={false} />
              },
              {
                path: '/reports',
                element: <ListReports />
              }
            ]
          },
          {
            path: '/quizzes/:quizGuid',
            element: <ViewQuiz />
          },
          {
            path: '/quizzes/create',
            element: <CreateQuiz />
          },
          {
            path: '/quizzes/play/:quizGuid',
            element: <PlayQuiz />
          },
          {
            path: '/students/:studentGuid',
            element: <StudentDetails />
          },
          {
            path: '/content/units/:lessonGuid',
            element: <UnitDetails />
          },
          {
            path: '/content/standards/:standardGuid',
            element: <StandardDetails />
          },
          {
            path: '/content/standards/:standardGuid',
            element: <StandardDetails />
          },
          {
            path: '/user',
            element: <Profile />
          },
          {
            path: '*',
            element: <GeneralError code={404} />
          }
        ]
      }
    ]
  }
];
