import { Grid, Typography } from '@mui/material';
import React from 'react';
import EDCard from '../../../../../atoms/EDCard/EDCard';
import TableDashboard from '../../../../../view/tableDashboard/TableDashboard';
import { createColumnHelper } from '@tanstack/react-table';

const UserLicenses = ({ licenses }) => {
  console.log('licenses', licenses);
  const columnHelper = createColumnHelper();

  function formatISODate(isoDate) {
    const date = new Date(isoDate);
    const formattedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    return formattedDate;
  }

  const columns = [
    columnHelper.accessor('code', {
      header: 'Code',
      id: 'code'
    }),
    columnHelper.accessor('linked_at', {
      header: 'Start date',
      id: 'linked_at',
      cell: (info) => formatISODate(info.row.original.linked_at)
    }),
    columnHelper.accessor('expired_at', {
      header: 'End date',
      id: 'expired_at',
      cell: (info) => formatISODate(info.row.original.expired_at)
    }),
    columnHelper.accessor('programs.length', {
      header: 'Number of programs',
      id: 'programs'
    })
  ];

  return (
    <Grid item xs={12}>
      <EDCard>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2'>Licenses</Typography>
          </Grid>
          <Grid item xs={12}>
            <TableDashboard
              disableFiltersAndSearch
              tableData={licenses}
              tableColumns={columns}
            />
          </Grid>
        </Grid>
      </EDCard>
    </Grid>
  );
};

export default UserLicenses;
