import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import EDInputWithLabel from '../../../../../atoms/EDInput/EDInputWithLabel';
import EDAutocompleteWithLabel from '../../../../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDButton from '../../../../../atoms/EDButton/EDButton';
import { useTranslation } from 'react-i18next';

const CreateNewUserDialog = ({
  isOpen,
  onClose,
  onSubmit,
  roles,
  newUserSavedState,
  setNewUserSavedState,
  groups
}) => {
  const { t } = useTranslation();

  const [newUser, setNewUser] = useState(
    newUserSavedState
      ? newUserSavedState.newUser
      : {
          name: '',
          lastname: '',
          email: '',
          role: {
            label: '',
            value: ''
          },
          password: '',
          is_demo: 0,
          teacher_school_license: false,
          json_settings: {
            blueberry: {
              devmode: 0
            }
          }
        }
  );

  const [canCreate, setCanCreate] = useState(
    newUserSavedState ? newUserSavedState.canCreate : false
  );

  const [confirmPassword, setConfirmPassword] = useState(
    newUserSavedState ? newUserSavedState.confirmPassword : ''
  );

  useEffect(() => {
    setNewUserSavedState &&
      setNewUserSavedState({ newUser, confirmPassword, canCreate });
    setCanCreate(isUserMandatoryDataFilledCorrectly(getParsedUserData()));
  }, [newUser, confirmPassword]);

  const getParsedUserData = () => {
    return {
      ...newUser,
      role: newUser.role.value,
      teacher_school_license:
        newUser.role.value === 'R02' || newUser.role.value === 'R04',
      json_settings: JSON.stringify(newUser.json_settings)
    };
  };

  const isUserMandatoryDataFilledCorrectly = (userData) => {
    return (
      userData.name &&
      userData.lastname &&
      userData.email &&
      userData.role &&
      userData.password &&
      userData.json_settings &&
      userData.password === confirmPassword
    );
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2'>{t('create_user')}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <EDInputWithLabel
              label={t('name')}
              value={newUser.name}
              onChange={({ target: { value } }) =>
                setNewUser({ ...newUser, name: value })
              }
              disableAutocomplete
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDInputWithLabel
              label={t('lastname')}
              value={newUser.lastname}
              onChange={({ target: { value } }) =>
                setNewUser({ ...newUser, lastname: value })
              }
              disableAutocomplete
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDInputWithLabel
              label={t('email')}
              value={newUser.email}
              onChange={({ target: { value } }) =>
                setNewUser({ ...newUser, email: value })
              }
              disableAutocomplete
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDAutocompleteWithLabel
              label={t('role')}
              value={newUser.role}
              inputProps={{ autoComplete: 'new-password' }}
              options={roles.map((role) => {
                return {
                  label: role.role,
                  value: role.guid
                };
              })}
              onChange={(e, value) => {
                setNewUser({ ...newUser, role: value });
              }}
              showLabel
              disableClearable
              disableAutocomplete
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDInputWithLabel
              type='password'
              label={t('password')}
              autoComplete='off'
              inputProps={{ autoComplete: 'new-password' }}
              value={newUser.password}
              onChange={({ target: { value } }) =>
                setNewUser({ ...newUser, password: value })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDInputWithLabel
              type='password'
              label={t('confirm_password')}
              inputProps={{ autoComplete: 'new-password' }}
              autoComplete='off'
              value={confirmPassword}
              onChange={({ target: { value } }) => setConfirmPassword(value)}
            />
          </Grid>
          {groups && groups.length > 0 && (
            <Grid item xs={12}>
              <EDAutocompleteWithLabel
                showLabel
                label='Group'
                disableClearable
                options={groups.map((group) => {
                  return {
                    label: group.name,
                    value: group.guid
                  };
                })}
                onChange={(e, value) => {
                  setNewUser({ ...newUser, group: value });
                }}
                disableAutocomplete
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <FormGroup aria-label='position' row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        newUser.json_settings.blueberry.letter_case ===
                        'upperCase'
                      }
                      onChange={(e, value) => {
                        setNewUser({
                          ...newUser,
                          json_settings: {
                            blueberry: {
                              ...newUser.json_settings.blueberry,
                              letter_case: value ? 'upperCase' : 'regularCase'
                            }
                          }
                        });
                      }}
                    />
                  }
                  label='Uppercase Required (Texts in uppercase for this user)'
                  labelPlacement='end'
                />
              </FormGroup>
            </FormControl>

            <FormControl component='fieldset'>
              <FormGroup aria-label='position' row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newUser.json_settings.blueberry.devmode === 1}
                      onChange={(e, value) => {
                        setNewUser({
                          ...newUser,
                          json_settings: {
                            blueberry: {
                              ...newUser.json_settings.blueberry,
                              devmode: value ? 1 : 0
                            }
                          }
                        });
                      }}
                    />
                  }
                  label={t('users_dev_mode_label')}
                  labelPlacement='end'
                />
              </FormGroup>
            </FormControl>

            <FormControl component='fieldset'>
              <FormGroup aria-label='position' column>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newUser.is_demo}
                      onChange={(e, value) => {
                        setNewUser({
                          ...newUser,
                          is_demo: e.target.checked ? 1 : 0
                        });
                      }}
                    />
                  }
                  label={t('users_demo_user_label')}
                  labelPlacement='end'
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <EDButton variant='text' onClick={onClose}>
          {t('cancel')}
        </EDButton>
        <EDButton
          variant='contained'
          disableElevation
          disabled={!canCreate}
          onClick={() => onSubmit(getParsedUserData())}
        >
          {t('Create')}
        </EDButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewUserDialog;
