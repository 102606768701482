import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SearchWithFilterButton from '../../../atoms/SearchWithFilterButton/SearchWithFilterButton';
import Toast from '../../../atoms/Toast/Toast';
import EDConfirmDeleteDialog from '../../../components/EDConfirmDeleteDialog/EDConfirmDeleteDialog';
import EDDashboardTabs from '../../../components/EDDashboardTabs/EDDashboardTabs';
import Table from '../../../components/table/Table';
import PageWithHeader from '../../../layouts/pageWithHeader/PageWithHeader';
import ActivitiesFilter from './components/ActivitiesFilter';
import NewActivityDialog from './components/NewActivityDialog';
import PreviewActivityDialog from './components/PreviewActivityDialog';
import useActivitiesManager from './useActivitiesManager';

const ActivitiesManager = () => {
  const {
    isUserProgramEditor,
    isUserReviewer,
    tabs,
    data,
    columns,
    total,
    filters,
    search,
    currentTab,
    numberOfFilters,
    isLoading,
    currentPage,
    totalPages,
    isNewActivityDialogOpen,
    showToast,
    toastMessage,
    tableColumnsVisibility,
    setShowToast,
    onChangeFilter,
    onChangeSearch,
    onChangePage,
    onDeleteActivity,
    onCreateActivity,
    handleOpenActivityDialog,
    handleCloseActivityDialog
  } = useActivitiesManager();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [previewLemonadeJSON, setPreviewLemonadeJSON] = useState(null);
  const [isPreviewActivityDialogOpen, setIsPreviewActivityDialogOpen] =
    useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const onClosePreview = () => {
    setIsPreviewActivityDialogOpen(false);
    // setSelectedActivity(null); // Persist the selected activity to keep it highlighted
    setPreviewLemonadeJSON(null);
  };

  return (
    <PageWithHeader
      left={<EDDashboardTabs tabs={tabs} currentTab={currentTab} />}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px'
        }}
      >
        <SearchWithFilterButton
          onClick={toggleDrawer}
          value={search}
          onChange={onChangeSearch}
          numberOfFilters={numberOfFilters}
          placeholder={t('search_placeholder')}
        />

        {!isUserReviewer && !isUserProgramEditor && (
          <Button
            disableElevation
            color='primary'
            variant='contained'
            onClick={handleOpenActivityDialog}
            sx={{
              padding: '0.75rem 1.5rem',
              textTransform: 'none'
            }}
          >
            {t('Create')}
          </Button>
        )}
      </div>
      <div>
        <div
          style={{
            marginBottom: '19px',
            color: '#8c99ad',
            fontSize: '12px',
            fontWeight: '600'
          }}
        >
          <span
            style={{
              color: '#414b5c'
            }}
          >
            {Intl.NumberFormat().format(total)}
          </span>{' '}
          {t('activities_have_been_found')}
        </div>
      </div>
      <Table
        name='seeds'
        data={data}
        columns={columns}
        isLoading={isLoading || !total}
        onRowClick={(e) => {
          navigate('/activities/' + e.id);
        }}
        showPagination
        showTopPagination
        paginationSize='large'
        totalPages={totalPages}
        currentPage={parseInt(currentPage) || 1}
        onChangePage={onChangePage}
        isCustomizable
        tableColumnsVisibility={tableColumnsVisibility}
        isFirstColumnSticky
        isLastColumnSticky
        customHighlightRow={{
          key: 'id',
          value: selectedActivity?.id
        }}
        useApiSorting={totalPages > 1}
        onChangeSort={onChangeFilter}
        options={
          isUserReviewer || isUserProgramEditor
            ? [
                {
                  icon: <VisibilityOutlinedIcon />,
                  label: t('activity_preview'),
                  onClick: (row) => {
                    setSelectedActivity(row);
                    setPreviewLemonadeJSON(row.lemonadeJSON);
                    setIsPreviewActivityDialogOpen(true);
                  }
                }
              ]
            : [
                {
                  icon: <VisibilityOutlinedIcon />,
                  label: t('activity_preview'),
                  onClick: (row) => {
                    setSelectedActivity(row);
                    setPreviewLemonadeJSON(row.lemonadeJSON);
                    setIsPreviewActivityDialogOpen(true);
                  }
                },
                {
                  icon: <BorderColorOutlinedIcon />,
                  label: t('edit_option_activity_table'),
                  onClick: (row) => {
                    navigate('/activities/' + row.id);
                  }
                },
                {
                  icon: <DeleteOutlineIcon />,
                  label: t('delete_option_activity_table'),
                  onClick: (activity) => {
                    setItemToDelete(activity);
                    setIsConfirmDeleteDialogOpen(true);
                  }
                }
              ]
        }
      />

      {showToast && (
        <Toast
          handleShowToast={setShowToast}
          type={toastMessage.type}
          text={toastMessage.text}
        />
      )}

      <NewActivityDialog
        isOpen={isNewActivityDialogOpen}
        onClose={handleCloseActivityDialog}
        onCreateActivity={onCreateActivity}
      />

      <ActivitiesFilter
        isDrawerOpen={isDrawerOpen}
        currentFilters={filters}
        onSearch={(value) => {
          onChangeFilter(value);
          setIsDrawerOpen(false);
        }}
        onCloseDrawer={() => setIsDrawerOpen(false)}
      />

      {/* Force reload of states */}
      {isPreviewActivityDialogOpen && (
        <PreviewActivityDialog
          onClose={onClosePreview}
          lemonadeJSON={previewLemonadeJSON}
          isOpen={isPreviewActivityDialogOpen}
          seedId={selectedActivity?.name}
        />
      )}

      <EDConfirmDeleteDialog
        isOpen={isConfirmDeleteDialogOpen}
        onClose={() => {
          setItemToDelete(null);
          setIsConfirmDeleteDialogOpen(false);
        }}
        onConfirm={() => {
          onDeleteActivity(itemToDelete.id);
          setItemToDelete(null);
          setIsConfirmDeleteDialogOpen(false);
        }}
        itemToBeDeletedName={itemToDelete?.name}
        itemToBeDeletedDescription=''
      />
    </PageWithHeader>
  );
};

export default ActivitiesManager;
