import { Avatar, Typography } from '@mui/material';
import React from 'react';
import './EDTabs.scss';

const EDTabs = ({ tabs, currentTab, onClick }) => {
  return (
    <div className='ed-link-tabs__container'>
      {tabs.map((tab, index) => (
        <div
          key={tab.key}
          className={`ed-link-tabs__link ${
            currentTab === tab.key ? 'ed-link-tabs__link--active' : ''
          }`}
          onClick={() => {
            onClick(tab);
          }}
        >
          <Avatar
            sx={{ width: 20, height: 20, fontSize: 14 }}
            className='ed-link-tabs__link__avatar'
          >
            {index + 1}
          </Avatar>
          <Typography variant='h3'>{tab.name}</Typography>
        </div>
      ))}
    </div>
  );
};

export default EDTabs;
