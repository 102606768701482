import { CircularProgress, NativeSelect } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './ListReports.scss';
import UserReport from './components/userReport/UserReport';
import useListReports from './useListReports';

const ListReports = () => {
  const { t } = useTranslation();

  const {
    report,
    isLoading,
    selectedCourse,
    daysReportSelect,
    selectedDayReport,
    setSelectedDayReport
  } = useListReports(t);

  if (isLoading) {
    return (
      <div className='reports-container'>
        <div className='reports-container-loading'>
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className='reports-container'>
      <div className='reports-title'>
        <h2>{t('reports_title')}</h2>
        <div className='reports-date'>
          <NativeSelect
            value={selectedDayReport}
            onChange={(event) => {
              console.log('event.target.value -- ', event.target.value);
              setSelectedDayReport(event.target.value);
            }}
            inputProps={{
              name: 'day-report',
              id: 'days-report-selector'
            }}
          >
            {daysReportSelect.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </NativeSelect>
        </div>
      </div>
      <div className='reports-users'>
        <div className='reports-users-header'>
          <div>{t('reports_student_name')}</div>
          <div className='reports-users-header__text'>
            {t('reports_performed_activities')}
          </div>
          <div className='reports-users-header__text'>
            {t('reports_practice_time')}
          </div>
        </div>
        {report.students?.map((student, index) => {
          return (
            <UserReport
              key={student?.guid || index}
              user={student}
              courseGuid={selectedCourse}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ListReports;
