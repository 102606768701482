import React, { useEffect, useState } from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css'; //Example style, you can use another
import Toast from '../../atoms/Toast/Toast';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';

import AdvancedEditor from './AdvancedEditor';
import Icon from '../../atoms/icon';
import './BBJSONEditor.scss';

const BBJSONEditor = ({
  defaultJSON,
  onSetEditorCodeString,
  onHasError,
  source,
  json,
  readOnly

}) => {
  const { t } = useTranslation();

  const [code, setCode] = useState(
    typeof defaultJSON === 'string'
      ? defaultJSON
      : JSON.stringify(defaultJSON, null, 4)
  );
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    onSetEditorCodeString(code);
  }, [code]);

  const copyToClipboard = () => {
    setShowToast(true);
    navigator.clipboard.writeText(code);
  };

  useEffect(() => {
    if (json) {
      // console.log(
      //   typeof json === 'string' ? json : JSON.stringify(json, null, 4)
      // );
      setCode('');
      setCode(typeof json === 'string' ? json : JSON.stringify(json, null, 4));
    }
  }, [json]);

  const onChange = (
    updatedContent,
    previousContent,
    { contentErrors, patchResult }
  ) => {
    // content is an object { json: JSONValue } | { text: string }
    // console.log('onChange: ', {
    //   updatedContent,
    //   previousContent,
    //   contentErrors,
    //   patchResult
    // });
    // console.log('updatedContent errors', contentErrors);
    // console.log('originalJSON', originalJSON);
    // console.log('updatedContent,previousContent', updatedContent);

    if (contentErrors) {
      onHasError(true);
    } else {
      onHasError(false);
      setCode(updatedContent.text);
    }
  };

  return (
    <div className='bb-json-editor__container'>
      <div
        className='bb-json-editor-copy'
        title='Copy JSON'
        onClick={copyToClipboard}
      >
        <Icon type='copy' format='outline' />
      </div>

      <AdvancedEditor
        content={{
          text: code
        }}
        readOnly={readOnly}
        onChange={onChange}
        mode='text'
        mainMenuBar={true}
      />

      {showToast && (
        <Toast
          handleShowToast={setShowToast}
          type='info'
          text={t('json_copied_message')}
        />
      )}
    </div>
  );
};

export default BBJSONEditor;
