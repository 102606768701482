import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from '../../../atoms/Toast/Toast';
import EDDashboardTabs from '../../../components/EDDashboardTabs/EDDashboardTabs';
import PageWithHeader from '../../../layouts/pageWithHeader/PageWithHeader';
import TableDashboard from '../../../view/tableDashboard/TableDashboard';
import CreateDepartmentDialog from './dialogs/CreateDepartmentDialog';
import useDepartments from './useDepartments';

const Departments = () => {
  const {
    isUserProgramEditor,
    total,
    columns,
    data,
    onCreateDepartment,
    onDeleteDepartment,
    showToast,
    setShowToast,
    toastMessage,
    onChangeSearch,
    onChangePage,
    totalPages
  } = useDepartments();
  const { t } = useTranslation();

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const onSubmitNewDialog = (newDialogData) => {
    setIsCreateDialogOpen(false);
    onCreateDepartment(newDialogData);
  };

  return (
    <PageWithHeader
      left={
        <EDDashboardTabs
          tabs={[
            {
              name: t('learning_outcomes_tab_title'),
              to: '/learning-outcomes',
              key: 'learning-outcomes'
            },
            {
              name: t('knowledge_components_tab_title'),
              to: '/knowledge-components',
              key: 'knowledge-components'
            },
            {
              name: t('departments_tab_title'),
              to: '/departments',
              key: 'departments'
            }
          ]}
          currentTab={'departments'}
        />
      }
    >
      <TableDashboard
        tableColumns={columns}
        tableData={data}
        totalEntries={total}
        totalPages={totalPages}
        showPagination
        disableFiltersAndSearch={false}
        disableFilters={true}
        onSearch={onChangeSearch}
        onChangePage={onChangePage}
        onClickOnCreate={
          isUserProgramEditor
            ? null
            : () => {
                setIsCreateDialogOpen(true);
              }
        }
        isLastColumnSticky={true}
        options={
          !isUserProgramEditor && [
            // {
            //   icon: <BorderColorOutlinedIcon />,
            //   label: t('edit'),
            //   onClick: (rowData) => {
            //     console.log(rowData);
            //   }
            // },
            {
              icon: <DeleteOutlineIcon />,
              label: t('delete'),
              onClick: (rowData) => {
                onDeleteDepartment(rowData);
              }
            }
          ]
        }
        singleEntryLabel={t('departments_plural_label')}
        pluralEntryLabel={t('departments_single_label')}
      />

      {isCreateDialogOpen && (
        <CreateDepartmentDialog
          isOpen={isCreateDialogOpen}
          onClose={() => setIsCreateDialogOpen(false)}
          onSubmit={onSubmitNewDialog}
        />
      )}

      {showToast && (
        <Toast
          handleShowToast={setShowToast}
          type={toastMessage.type}
          text={toastMessage.text}
        />
      )}
    </PageWithHeader>
  );
};

export default Departments;
