import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const BackButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick}>
      <ArrowBack sx={{ color: 'white' }} />
    </IconButton>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

BackButton.defaultProps = {
  onClick: () => {
    history.back();
  }
};

export default BackButton;
