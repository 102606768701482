import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import EDInputWithLabel from '../../../../../atoms/EDInput/EDInputWithLabel';
import EDButton from '../../../../../atoms/EDButton/EDButton';
import CountriesFilter from '../../../../../components/filters/CountriesFilter';
import { isEmpty } from 'lodash';

const CreateSchoolDialog = ({ isOpen, onClose, onSubmit }) => {
  const [newSchoolDetails, setNewSchoolDetails] = useState({
    name: '',
    address: ''
  });

  const [canProceed, setCanProceed] = useState(false);

  useEffect(() => {
    if (newSchoolDetails.name && newSchoolDetails.address) {
      setCanProceed(true);
    } else {
      setCanProceed(false);
    }
  }, [newSchoolDetails]);

  const onCancelDialog = () => {
    setNewSchoolDetails({
      name: '',
      address: ''
    });
    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onCancelDialog}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack>
              <Typography variant='h2'>Create School</Typography>
              {/* Add close icon */}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <EDInputWithLabel
              label='School name'
              onChange={({ target: { value } }) => {
                setNewSchoolDetails({ ...newSchoolDetails, name: value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <EDInputWithLabel
              label='Address'
              onChange={({ target: { value } }) => {
                setNewSchoolDetails({ ...newSchoolDetails, address: value });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <EDButton variant='text' onClick={onCancelDialog}>
          cancel
        </EDButton>
        <EDButton
          variant='contained'
          disableElevation
          disabled={!canProceed}
          onClick={() => {
            onSubmit(newSchoolDetails);
          }}
        >
          Create
        </EDButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSchoolDialog;
