import {
  Drawer,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ACTIVITY_TYPES,
  ActivityBlueberryComponent
} from '../../../../_practice-components';
import { ReactComponent as DesktopIcon } from '../../../../assets/icons/desktop.svg';
import { ReactComponent as MobileIcon } from '../../../../assets/icons/mobile.svg';
import { ReactComponent as TabletIcon } from '../../../../assets/icons/tablet.svg';
import EDButton from '../../../../atoms/EDButton/EDButton';
import EDCircularLoading from '../../../../atoms/EDCircularLoading/EDCircularLoading';
import Icon from '../../../../atoms/icon';
import BBJSONEditor from '../../../../components/JSONEditor/BBJSONEditor';
import i18nInstance from '../../../../i18n/i18n';
import FullPageLayout from '../../../../layouts/dashboardFullPage/FullPageLayout';
import { useLocalStorage } from '../../../../utils/hooks/useLocalStorage';
import { localStorageKeys } from '../../../../utils/localStorageKeys';
import './FullScreenPreview.scss';

const FullScreenPreview = ({
  isLoading,
  onClose,
  editorCodeString,
  onSetEditorCodeString,
  onHasError,
  onUpdatePreview,
  previewDataJSON,
  deviceMode,
  onGetMultilanguageKeys,
  previewLanguage,
  onHandlePreviewLanguageChange,
  readOnly
}) => {
  const { t } = useTranslation();
  const { getItem } = useLocalStorage();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [uiActivitiesLanguage, setUiActivitiesLanguage] = useState('en');

  useEffect(() => {
    setUiActivitiesLanguage(
      getItem(localStorageKeys.language) || i18nInstance.language || 'en'
    );
  }, []);
  // Preview modes
  // desktop
  // tablet landscape w-1024px
  // tablet portrait w-768px
  // mobile w-416px

  const [previewMode, setPreviewMode] = useState(
    deviceMode === 'desktop' ? 'desktop' : 'mobile'
  );

  const previewTitle = {
    desktop: 'Desktop mode',
    landscape: 'Landscape mode (1024x768)',
    portrait: 'Portrait mode (768x1024)',
    mobile: 'Mobile mode (360x740)'
  };

  const onToggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const onUpdateFullScreenPreview = async () => {
    onUpdatePreview();
  };

  useEffect(() => {
    onUpdateFullScreenPreview();
  }, [previewLanguage]);

  useEffect(() => {
    if (isLoading) return;
    onUpdateFullScreenPreview();
  }, [isLoading]);

  return (
    <div className='full-screen-preview'>
      <FullPageLayout
        left={
          <div className='full-screen-preview__edit'>
            <EDButton
              size='medium'
              onClick={onToggleDrawer}
              startIcon={<Icon type='edit' format='outline' />}
            >
              {t('Editor')}
            </EDButton>
          </div>
        }
        subtitle={previewTitle[previewMode] || 'Desktop mode'}
        right={
          <div className='full-screen-preview__header-right'>
            {deviceMode === 'mobile' && (
              <div
                className='full-screen-preview__mobile-btn'
                onClick={() => setPreviewMode('mobile')}
                title='Mobile mode (360x740)'
              >
                <MobileIcon />
              </div>
            )}
            <div
              className='full-screen-preview__portrait-btn'
              onClick={() => setPreviewMode('portrait')}
              title='Portrait mode (768x1024)'
            >
              <TabletIcon />
            </div>
            <div
              className='full-screen-preview__landscape-btn'
              onClick={() => setPreviewMode('landscape')}
              title='Landscape mode (1024x768)'
            >
              <TabletIcon />
            </div>

            {deviceMode === 'desktop' && (
              <div
                className='full-screen-preview__desktop-btn'
                onClick={() => setPreviewMode('desktop')}
                title='Desktop mode'
              >
                <DesktopIcon />
              </div>
            )}

            <div className='full-screen-preview__close' onClick={onClose}>
              <Icon type='clear' format='outline' />
            </div>
          </div>
        }
        fullWidth
        fullScreenModal
      >
        <Drawer
          className='full-screen-preview__drawer'
          anchor={'left'}
          open={isDrawerOpen}
          onClose={onToggleDrawer}
        >
          <Grid
            container
            spacing={3}
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item>
              <Typography variant='h2'>{t('editor_subtitle')}</Typography>
            </Grid>
            <Grid item>
              <EDButton
                variant='outlined'
                size='large'
                onClick={onUpdateFullScreenPreview}
              >
                {t('update_preview_button')}
              </EDButton>
            </Grid>
            <Grid item xs={12}>
              <BBJSONEditor
                defaultJSON={editorCodeString}
                onSetEditorCodeString={onSetEditorCodeString}
                onHasError={onHasError}
                source={'fullscreen'}
                readOnly={readOnly}
              />
            </Grid>
          </Grid>
        </Drawer>

        <Grid
          container
          xs={12}
          justifyContent='center'
          sx={{ marginTop: '1em' }}
        >
          {!previewDataJSON && <EDCircularLoading fullScreen />}

          {onGetMultilanguageKeys() && (
            <div className='full-screen-preview__languages-selector'>
              <ToggleButtonGroup
                color='primary'
                value={previewLanguage}
                exclusive
                onChange={onHandlePreviewLanguageChange}
                aria-label='Language'
                size='small'
              >
                {onGetMultilanguageKeys().map((langItem, index) => (
                  <ToggleButton value={langItem} key={index}>
                    {langItem.toUpperCase()}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
          )}

          <div
            className={`fullscreen-activity-preview-container fullscreen-activity-preview-container--${previewMode}`}
          >
            <div className='app-practice-page'>
              {previewDataJSON && (
                <ActivityBlueberryComponent
                  activityType={ACTIVITY_TYPES.PREVIEW}
                  activity={previewDataJSON}
                  uiLanguage={uiActivitiesLanguage}
                  mathRenderEngine='katex'
                />
              )}
            </div>
          </div>
        </Grid>
      </FullPageLayout>
    </div>
  );
};

export default FullScreenPreview;
