export const localStorageKeys = {
  userGuid: 'bb_user_guid',
  token: 'bb_user_token',
  firstName: 'bb_user_first_name',
  lastName: 'bb_user_last_name',
  email: 'bb_user_email',
  role: 'bb_user_role',
  whoAmI: 'bb_user_whoami',
  language: 'bb_user_language',
  activitiesLanguage: 'bb_user_activities_language',
  loginTimeStamp: 'bb_user_login_timestamp',
  userSettings: 'bb_user_settings',
  providerLogin: 'bb_user_provider_login',
  traceIsLoginHome: 'bb_user_trace_is_login_home'
};
