import Table from '../../../../components/table/Table';

import useStudentsUnitsAnalysis from './useStudentsUnitsAnalysis';

const StudentsUnitsAnalysis = () => {
  const { data, columns, isLoading, onClickOnStudent, error } =
    useStudentsUnitsAnalysis();

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Table
      data={data}
      columns={columns}
      isFirstColumnSticky={true}
      pagination={false}
      isLoading={isLoading}
      onRowClick={onClickOnStudent}
    />
  );
};

export default StudentsUnitsAnalysis;
