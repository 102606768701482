import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CellSeedStatusChip from '../../../components/table/cell/cellTypes/CellSeedStatusChip';
import CellWithOneLineOfText from '../../../components/table/cell/cellTypes/CellWithOneLineOfText';
import { fetchWithToken } from '../../../utils/helpers/fetcher';
import useEditorDashboardPermissions from '../../../utils/hooks/useEditorDashboardPermissions';

const useSingleLearningOutcome = () => {
  const { t } = useTranslation();
  const { isUserProgramEditor } = useEditorDashboardPermissions();

  const { learningOutcomeGuid } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [learningOutcome, setLearningOutcome] = useState({});
  const [activities, setActivities] = useState([]);
  const [currentTab, setCurrentTab] = useState('metadata');

  const columnHelper = createColumnHelper();

  const [tabs, setTabs] = useState(
    isUserProgramEditor
      ? [
          {
            name: t('activities_tab_title'),
            key: 'activities'
          }
        ]
      : [
          {
            name: t('metadata_tab_title'),
            key: 'metadata'
          },
          {
            name: t('activities_tab_title'),
            key: 'activities'
          }
        ]
  );

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ type: '', text: '' });

  const getStimulus = (data) => {
    let stimulus;
    if (data && data.stimulus !== undefined) {
      // simple activity
      stimulus = data?.stimulus;
      if (stimulus === 'stimulus') {
        // multilanguage
        stimulus = Object.values(data?.locales)[0].stimulus;
      }
    } else {
      if (
        data &&
        data.scaffolding &&
        data.scaffolding[0].stimulus !== undefined
      ) {
        // scaffolding activity
        stimulus = data?.scaffolding[0]?.stimulus;
      } else {
        // JSON without stimulus
        stimulus = '';
      }
    }
    return stimulus;
  };

  const parseJSON = (json) => {
    try {
      return JSON.parse(json);
    } catch (e) {
      return {};
    }
  };

  const getLearningOutcome = async () => {
    setIsLoading(true);
    let response = await fetchWithToken({
      method: 'GET',
      path: `/nodes/${learningOutcomeGuid}/seeds`
    });

    // create en, es and pt languages if they don't exist
    if (response.data.descriptionLanguages.length !== 3) {
      if (response.data.descriptionLanguages.length === 0) {
        await createLanguage('ES');
        await createLanguage('EN');
        await createLanguage('PT');

        response = await fetchWithToken({
          method: 'GET',
          path: `/nodes/${learningOutcomeGuid}/seeds`
        });
      }
    }

    setLearningOutcome(response.data);

    setActivities(
      response.data.loNodeSeeds.map((item) => ({
        // make first letter uppercase of phase for translation purposes
        phase: t(item.phase.charAt(0).toUpperCase() + item.phase.slice(1)),
        node: item.node,
        language: item.loSeed?.lang?.code,
        guid: item.loSeed?.guid,
        name: item.loSeed?.name,
        status: item.loSeed?.status,
        seedData: item.loSeed?.data,
        stimulus: item.loSeed ? getStimulus(parseJSON(item.loSeed.data)) : null
      }))
    );
    setIsLoading(false);
  };

  const createLanguage = async (language) => {
    if (isUserProgramEditor) return;

    return fetchWithToken({
      method: 'POST',
      path: `/nodes/node-languages`,
      data: {
        nodeGuid: learningOutcomeGuid,
        langCode: language,
        description: '-'
      }
    });
  };

  const onChange = (newLO) => {
    setLearningOutcome({ ...learningOutcome, ...newLO });
  };

  const activitiesTableColumns = [
    columnHelper.accessor('name', {
      header: t('name'),
      id: 'name',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => <CellWithOneLineOfText text={props.row.original.name} />
    }),
    columnHelper.accessor('name', {
      header: t('stimulus'),
      id: 'stimulus',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => (
        <CellWithOneLineOfText text={props.row.original.stimulus} />
      )
    }),
    columnHelper.accessor('phase', {
      header: t('activity_phase'),
      id: 'phase',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => <CellWithOneLineOfText text={props.row.original.phase} />
    }),
    columnHelper.accessor('language', {
      header: t('activity_language'),
      id: 'language',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => (
        <CellWithOneLineOfText text={props.row.original.language} />
      )
    }),
    columnHelper.accessor('status', {
      header: t('activity_status'),
      id: 'status',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      enableHiding: false,
      cell: (props) => {
        return <CellSeedStatusChip status={props.row.original.status} />;
      }
    })
  ];

  const onSaveLearningOutcomeMetadata = async (newLO) => {
    setIsUpdating(true);

    let nodeLanguages = newLO.descriptionLanguages;

    delete newLO.descriptionLanguages;

    const response = await fetchWithToken({
      method: 'PATCH',
      path: `/nodes/${learningOutcomeGuid}`,
      data: newLO
    });

    // update node languages
    await Promise.all(
      nodeLanguages.map(async (item) => {
        await fetchWithToken({
          method: 'PATCH',
          path: `/nodes/node-languages/${item.node}/languages/${item.lang}`,
          data: {
            description: item.description
          }
        });
      })
    );

    newLO.descriptionLanguages = nodeLanguages;

    if (response.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_lo_saved_success')
      });

      getLearningOutcome();
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_lo_saved_error')
      });
    }

    setShowToast(true);
    setIsUpdating(false);
  };

  useEffect(() => {
    getLearningOutcome();
    setCurrentTab(isUserProgramEditor ? 'activities' : 'metadata');
  }, []);

  return {
    tabs,
    onChange,
    isLoading,
    isUpdating,
    learningOutcome,
    activitiesTableColumns,
    activities,
    onSaveLearningOutcomeMetadata,
    showToast,
    toastMessage,
    setShowToast,
    setToastMessage,
    currentTab,
    setCurrentTab
  };
};

export default useSingleLearningOutcome;
