import { Grid, Typography } from '@mui/material';
import React from 'react';
import EDCard from '../../../../atoms/EDCard/EDCard';
import TableDashboard from '../../../../view/tableDashboard/TableDashboard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SingleActivityPrograms = ({ columns, data, isLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} justifyContent='center'>
        <Grid item xs={12} md={8}>
          <EDCard>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h2'>{t('program_tab_title')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TableDashboard
                  tableColumns={columns}
                  tableData={data}
                  disableFiltersAndSearch
                  totalEntries={data.length}
                  singleEntryLabel={t('program')}
                  pluralEntryLabel={t('programs')}
                  isTableDataLoading={isLoading}
                  onRowClick={(row) => {
                    navigate(`/programs/${row.guid}`);
                  }}
                />
              </Grid>
            </Grid>
          </EDCard>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SingleActivityPrograms;
