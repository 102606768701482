const updatedSerializeToGraph = ({
  entities,
  departments,
  relationships
  // standards
}) => {
  // For now, standards are not used
  const graphData = {
    nodes: [],
    edges: []
  };

  const duplicateNodes = [];

  const departmentsObject = {};
  departments.map((department) => {
    departmentsObject[department.guid] = department.name;
  });

  graphData.nodes = entities.map((entity) => ({
    id: entity?.guid,
    label: entity?.name || entity?.title || '',
    subLabel: entity?.description,
    subLabelVisible: false,
    labelVisible: false,
    data: { department: departmentsObject[entity?.department] || 'Unknown' },
    type: entity?.type,
    fill:
      entity?.type === 'concept'
        ? '#5461c7'
        : entity?.type === 'outcome'
        ? '#be6fae'
        : 'red'
  }));

  graphData.edges = relationships.map((relationship) => ({
    source: relationship.sourceGuid,
    target: relationship.targetGuid,
    label: 'dependency',
    id: `${relationship.sourceGuid}->${relationship.targetGuid}-dependency`,
    edgeArrowPosition: 'none',
    size: 3
  }));

  return { graphData, duplicateNodes };
};

// --- useNodesGraph Hook ---
const useNodesGraph = ({ data }) => {
  const { graphData, duplicateNodes } = updatedSerializeToGraph(data);

  return { duplicateNodes, graphData };
};

export default useNodesGraph;
