import { createColumnHelper } from '@tanstack/react-table';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CellTag from '../../../components/table/cell/cellTypes/CellTag';
import CellWithOneLineOfText from '../../../components/table/cell/cellTypes/CellWithOneLineOfText';
import { fetchWithToken } from '../../../utils/helpers/fetcher';
import { lastUpdate } from '../../../utils/helpers/lastUpdate';
import useEditorDashboardPermissions from '../../../utils/hooks/useEditorDashboardPermissions';

const useLearningOutcomesManager = () => {
  const PAGE_SIZE = 25;
  const DEFAULT_LANG = 'ES';
  const columnHelper = createColumnHelper();
  const { t } = useTranslation();
  const { isUserProgramEditor } = useEditorDashboardPermissions();

  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState({
    langCode: 'en'
  });
  const [numberOfFilters, setNumberOfFilters] = useState(0);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(total / PAGE_SIZE);

  const [toastMessage, setToastMessage] = useState(null);

  const columns = [
    columnHelper.accessor('name', {
      header: t('learning_outcome_column_name'),
      id: 'name',
      enableSorting: true,
      sortingFn: 'cellWithOneLineOfText',
      enableHiding: false,
      cell: (props) => <CellWithOneLineOfText text={props.row.original.name} />
    }),

    columnHelper.accessor('description', {
      header: t('learning_outcome_column_description'),
      id: 'description',
      enableSorting: false,
      sortingFn: 'alphanumeric',
      cell: (props) => (
        <CellWithOneLineOfText text={props.row.original.description} />
      )
    }),

    columnHelper.accessor('knowledgeComponents', {
      header: t('learning_outcome_column_knowledge_components'),
      id: 'knowledgeComponents',
      enableSorting: false,
      sortingFn: 'cellTag',
      cell: (props) => <CellTag tags={props.row.original.knowledgeComponents} />
    }),

    columnHelper.accessor('department', {
      header: t('learning_outcome_column_department'),
      id: 'department',
      enableSorting: false,
      sortingFn: 'alphanumeric',
      cell: (info) => info.renderValue()
    }),

    columnHelper.accessor('learningStandards', {
      header: t('learning_outcome_column_standards'),
      id: 'learningStandards',
      enableSorting: false,
      sortingFn: 'alphanumeric',
      cell: (props) => <CellTag tags={props.row.original.learningStandards} />
    }),
    columnHelper.accessor('lastUpdate', {
      header: t('learning_outcome_column_updated'),
      id: 'updatedAt',
      enableSorting: true,
      sortingFn: 'datetime',
      cell: (info) => info.renderValue()
    })
  ];

  const onChangeFilter = (newFilters) => {
    let tempFilters = { ...newFilters };
    // remove page from filters to avoid pagination issues when changing filters
    delete tempFilters.page;
    // filters are added if they do not exist and replaced if they do
    setFilters({ ...tempFilters, search: search });
    // save filters on url
    saveFiltersInURL({ ...tempFilters, search: search });
    // update number of filters
    Object.keys(
      Object.keys(tempFilters).filter(
        (item) => !['search', 'orderBy', 'orderType'].includes(item)
      )
    ).length - 1;

    getData();
  };

  const saveFiltersInURL = (newFilters) => {
    // check if all filters are empty
    const allFiltersEmpty = Object.values(newFilters).every(
      (filter) => filter === '' || filter === null || filter === undefined
    );

    // if all filters are empty, remove filters from url
    if (allFiltersEmpty) {
      const searchParams = new URLSearchParams(window.location.search);

      const URLfilters = Object.fromEntries(searchParams);
      Object.keys(URLfilters).forEach((key) => {
        // delete except search
        if (!['search', 'orderBy', 'orderType'].includes(key)) {
          searchParams.delete(key);
        }
      });

      const newUrl = `${window.location.pathname}${
        searchParams.toString() !== ''
          ? `?${searchParams.toString()}`
          : searchParams.toString()
      }`;

      history.pushState(null, '', newUrl);
      return;
    }

    const searchParams = new URLSearchParams();
    const tempFilters = { ...newFilters };
    delete tempFilters.offset;

    Object.keys(tempFilters).forEach((key) => {
      if (
        tempFilters[key] !== '' &&
        tempFilters[key] !== null &&
        tempFilters[key] !== undefined
      ) {
        searchParams.append(
          key,
          typeof tempFilters[key] === 'object'
            ? JSON.stringify(tempFilters[key])
            : tempFilters[key]
        );
      }
    });
    // searchParams.set('filters', JSON.stringify(newFilters));
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    history.pushState(null, '', newUrl);
  };

  const getFiltersFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    // get all search params
    let URLfilters = {};
    try {
      URLfilters = Object.fromEntries(searchParams);
      Object.keys(URLfilters).forEach((key) => {
        // check if value is a stringified object
        if (URLfilters[key].startsWith('{')) {
          URLfilters[key] = JSON.parse(URLfilters[key]);
        }

        // check if value is a stringified array
        if (URLfilters[key].startsWith('[')) {
          URLfilters[key] = JSON.parse(URLfilters[key]);
        }
      });
    } catch (e) {
      console.log('error parsing filters', e);
    }

    setFilters(URLfilters);
    setSearch(URLfilters.search);
    setOffset(((URLfilters.page || 1) - 1) * PAGE_SIZE);
    setCurrentPage(URLfilters.page || 1);

    // update number of filters
    setNumberOfFilters(
      Object.keys(URLfilters).filter(
        (item) =>
          !['search', 'page', 'orderBy', 'orderType'].includes(item) &&
          URLfilters[item] !== '' &&
          URLfilters[item] !== null &&
          URLfilters[item] !== undefined
      ).length
    );

    return { ...URLfilters, offset: ((URLfilters.page || 1) - 1) * PAGE_SIZE };
  };

  const onChangeSearch = (searchValue) => {
    setSearch(searchValue);
    debounceSearch(searchValue);
  };

  const onChangePage = (e, page) => {
    setCurrentPage(page);
    setOffset((page - 1) * PAGE_SIZE);

    saveFiltersInURL({ ...filters, page: page });
  };

  const getDescriptionByLang = (descriptionLanguages = []) => {
    return (
      descriptionLanguages?.find((item) => item.lang === DEFAULT_LANG)
        ?.description ||
      descriptionLanguages[0]?.description ||
      '-'
    );
  };

  const getData = async () => {
    // to avoid multiple calls
    if (isLoading) return;
    setIsLoading(true);

    const filtersFromURL = getFiltersFromURL();

    const queryParameters = [
      `offset=${
        filtersFromURL.offset !== undefined && filtersFromURL.offset !== null
          ? filtersFromURL.offset
          : offset
      }`,
      `pageSize=${PAGE_SIZE}`,
      'type[]=outcome', // tipo para LOs
      filtersFromURL.search && `search=${filtersFromURL.search}`,
      filtersFromURL.departments &&
        `departmentGuid[]=${filtersFromURL.departments}`,
      filtersFromURL.educationYearGuid &&
        `internalEducationYearGuid=${filtersFromURL.educationYearGuid}`,
      filtersFromURL.parentGuid?.length > 0 &&
        filtersFromURL.parentGuid
          .map((item) => `parentGuid[]=${item}`)
          .join('&'),
      `orderBy=${filtersFromURL.orderBy || 'updatedAt'}`,
      `orderType=${filtersFromURL.orderType || 'DESC'}`,
      `includeDepartments=1`,
      `includeSeeds=0`,
      `includeLinkTo=0`
    ];

    const queryParams = queryParameters.filter(Boolean).join('&');
    const url = `/nodes?${queryParams}`;

    const response = await fetchWithToken({
      method: 'GET',
      path: url
    });

    setTotal(response.data.count);

    const data = response.data.nodes.map((_lo) => ({
      guid: _lo.guid,
      name: _lo.name,
      department: _lo.department?.name || '-',
      description: getDescriptionByLang(
        _lo?.descriptionLanguages,
        DEFAULT_LANG
      ),

      knowledgeComponents: _lo.linkFrom
        .filter((node) => node.type === 'concept')
        .map((node) => ({
          label: node.name,
          description: getDescriptionByLang(
            node?.descriptionLanguages,
            DEFAULT_LANG
          )
        })),

      lastUpdate: lastUpdate(
        _lo?.updatedAt || _lo?.createdAt || new Date().toISOString(),
        (_lo.updatedBy || _lo.createdBy)?.name || 'Unknown',
        t
      ),

      learningStandards: _lo.standards.map((item) => ({
        label: item.name,
        description: item.objectives
      }))
    }));

    setData(data);

    setIsLoading(false);
  };

  const onCreateLearningObjective = async (newLo) => {
    const createLOResponse = await fetchWithToken({
      method: 'POST',
      path: '/nodes',
      data: newLo
    });

    if (createLOResponse.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_lo_saved_success')
      });
      // Reload the table with the new data
      getData();
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_lo_saved_error')
      });
    }
  };

  const onDeleteLearningObjective = async (lo) => {
    const deleteLOResponse = await fetchWithToken({
      method: 'DELETE',
      path: `/nodes/${lo.guid}`
    });

    if (deleteLOResponse.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_lo_deleted_success')
      });
      getData();
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_lo_deleted_error')
      });
    }
  };

  const debounceSearch = useCallback(
    debounce((searchValue) => {
      let filtersFromURL = getFiltersFromURL();
      delete filtersFromURL.page;
      saveFiltersInURL({ ...filtersFromURL, search: searchValue });
      getData();
    }, 500),
    []
  );

  useEffect(() => {
    getData();
  }, [currentPage]);

  return {
    isUserProgramEditor,
    data,
    total,
    search,
    columns,
    filters,
    isLoading,
    setFilters,
    onChangeSearch,
    onChangeFilter,
    numberOfFilters,
    currentPage,
    totalPages,
    onChangePage,
    toastMessage,
    onCreateLearningObjective,
    onDeleteLearningObjective
  };
};

export default useLearningOutcomesManager;
