import Table from '../../../../components/table/Table';

import useContentsUnitsAnalysis from './useContentsUnitsAnalysis';

const ContentsUnitsAnalysis = () => {
  const { data, columns, isLoading, onClickOnUnit } =
    useContentsUnitsAnalysis();

  return (
    <Table
      data={data}
      columns={columns}
      isLoading={isLoading}
      showMessageIfNoData={true}
      onRowClick={onClickOnUnit}
    />
  );
};

export default ContentsUnitsAnalysis;
