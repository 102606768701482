import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { FormControlLabel, FormGroup, Switch, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ACTIVITY_TYPES,
  ActivityBlueberryComponent
} from '../../../../../_practice-components';
import i18nInstance from '../../../../../i18n/i18n';
import { useLocalStorage } from '../../../../../utils/hooks/useLocalStorage';
import { localStorageKeys } from '../../../../../utils/localStorageKeys';
import './ViewActivity.scss';
import useViewActivity from './useViewActivity';

const ViewActivity = ({ attempt }) => {
  const { t } = useTranslation();
  const { getItem } = useLocalStorage();

  const {
    showActivity,
    setShowActivity,
    htmlNameActivity,
    timeActivity,
    dateAttempt
  } = useViewActivity(t, attempt);

  const [showSolution, setShowSolution] = useState(false);

  const [uiActivitiesLanguage, setUiActivitiesLanguage] = useState('en');

  useEffect(() => {
    setUiActivitiesLanguage(
      getItem(localStorageKeys.language) || i18nInstance.language || 'en'
    );
  }, []);

  const toggleSolution = () => {
    setShowSolution(!showSolution);
  };

  return (
    <>
      <div className='report-user-detail-activity-container'>
        <div className='report-user-detail-phase'>
          {attempt?.phase === 'evocar'
            ? t('reports_phase_evoke')
            : attempt?.phase === 'identificar'
            ? t('reports_phase_identify')
            : attempt?.phase === 'aplicar'
            ? t('reports_phase_apply')
            : ''}
        </div>
        <div className='report-user-detail-date'>{dateAttempt}</div>
        <div className='report-user-detail-question'>
          {(attempt?.is_ok === null || attempt?.is_ok !== 1) && (
            <Tooltip
              title={
                attempt?.is_ok === null
                  ? t('reports_message_tooltip_icon_orange')
                  : t('reports_message_tooltip_icon_red')
              }
            >
              <CloseIcon
                className={
                  attempt?.is_ok === null
                    ? 'report-user-detail-question-no_answered'
                    : attempt?.is_ok === 1
                    ? 'report-user-detail-question-success'
                    : 'report-user-detail-question-error'
                }
              />
            </Tooltip>
          )}
          {attempt?.is_ok === 1 && (
            <Tooltip title={t('reports_message_tooltip_icon_green')}>
              <CheckIcon
                className={`${
                  attempt?.is_ok === null
                    ? 'report-user-detail-question-no_answered'
                    : attempt?.is_ok === 1
                    ? 'report-user-detail-question-success'
                    : 'report-user-detail-question-error'
                } `}
              />
            </Tooltip>
          )}

          <div
            dangerouslySetInnerHTML={{
              __html: htmlNameActivity
            }}
            className='report-user-detail-activity-truncate'
          />
        </div>

        <div className='report-user-detail-date'>
          {timeActivity &&
            `${
              timeActivity?.minutes > 0 ? timeActivity?.minutes + ' min' : ''
            } ${timeActivity.seconds} s`}
          &nbsp;
          {timeActivity?.seconds < 2 && (
            <>
              <Tooltip title={t('reports_message_warning_seconds')}>
                <ErrorIcon className='report-user-detail-question-error' />
              </Tooltip>
            </>
          )}
        </div>
        <div className='report-user-detail-preview'>
          <button
            onClick={() => setShowActivity(!showActivity)}
            className='reports-user-detail__button'
          >
            {!showActivity && (
              <VisibilityIcon className='reports-user-detail__button_icon' />
            )}
            {showActivity && (
              <VisibilityOffIcon className='reports-user-detail__button_icon' />
            )}
          </button>
        </div>
      </div>

      {showActivity && attempt?.question && (
        <div className='report-user-detail-activity-preview'>
          <ActivityBlueberryComponent
            activityType={ACTIVITY_TYPES.QUIZ}
            activity={attempt?.question}
            showSolutionQuestionQuizPreview={showSolution}
            disabledQuestion={true}
            isHideFooter={true}
            studentResponse={attempt?.question_response}
            showAllFeedback={false}
            uiLanguage={uiActivitiesLanguage}
            mathRenderEngine='katex'
          />
          {attempt?.is_ok !== 1 && (
            <div className='report-user-detail-activity-preview-solution'>
              <FormGroup>
                <FormControlLabel
                  variant='switch'
                  control={<Switch onChange={() => toggleSolution()} />}
                  label={t('Show solution')}
                />
              </FormGroup>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ViewActivity;
