import { useTranslation } from 'react-i18next';
import './DeviceError.scss';
import background from './assets/backgroundHeader.svg';
import blueberro from './assets/blueberro.svg';
import logo from './assets/logo_blanco_blueberry.svg';
import tablet from './assets/tablet.svg';

const DeviceError = () => {
  const { t } = useTranslation();

  return (
    <div className='device-error__container'>
      <div className='device-error__header'>
        <img className='device-error__logo' src={logo} alt='Blueberry Math' />
        <img
          className='device-error__header-background'
          src={background}
          alt='background'
        />
      </div>
      <div className='device-error__content'>
        <div className='device-error__images'>
          <img
            className='device-error__blueberro'
            src={blueberro}
            alt='Blueberro confuso'
          />
          <img className='device-error__tablet' src={tablet} alt='Tablet' />
        </div>
        <div className='device-error__text'>
          <div className='device-error__title'>{t('title_error_device')}</div>
          <div className='device-error__text-info'>
            {t('message_error_device')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceError;
