import { useEffect } from 'react';
import usePlayQuiz from './usePlayQuiz';
import './playQuiz.scss';

import GameLoading from './components/gameLoading/GameLoading';
import PlayQuizLobby from './playQuizLobby/PlayQuizLobby';
import PlayQuizCountdown from './playQuizCountdown/PlayQuizCountdown';
import PlayQuizQuestion from './playQuizQuestion/PlayQuizQuestion';
import PlayQuizRanking from './playQuizRanking/PlayQuizRanking';
import PlayQuizPodium from './playQuizPodium/PlayQuizPodium';
import ReactHowler from 'react-howler';

const PlayQuiz = () => {
  const {
    quizName,
    isLoading,
    totalPlayers,
    playerList,
    setPlayerList,
    questions,
    currentQuestion,
    setCurrentQuestion,
    numberOfQuestions,
    currentGameView,
    setSeconds,
    setTimerId,
    playSoundEffect,
    soundFile,
    howlerFXRef,
    isPlaying,
    EFFECTS_VOLUME,
    setIsPlaying,
    isLoop,

    correctAnswerUserGuids,
    totalAnswers,

    gotoLobby,
    gotoCountdown,
    gotoQuestionView,
    gotoRanking,
    gotoPodium,
    sendShowSolution,
    GAME_VIEW,
    seconds,
    syncTimestamp,
    currentGameGuid
  } = usePlayQuiz();

  useEffect(() => {
    console.log('Quiz Questions', questions);
  }, [questions]);

  useEffect(() => {
    console.log('🔥playquiz gameGuid', currentGameGuid);
  }, []);

  return (
    <div className='play-quiz-page'>
      <div style={{ display: 'none' }}>
        {soundFile && (
          <ReactHowler
            ref={howlerFXRef}
            src={soundFile}
            playing={isPlaying}
            volume={EFFECTS_VOLUME}
            loop={isLoop}
            onPlay={() => {}}
            onLoad={() => setIsPlaying(true)}
            onEnd={() => !isLoop && setIsPlaying(false)}
            onLoadError={() => console.error('Sound FX load error')}
            onPlayError={(id, errorCode) =>
              console.error('Music play error: ', errorCode)
            }
          />
        )}
      </div>
      {isLoading ? (
        <GameLoading />
      ) : (
        <div className='play-quiz-page__content'>
          {currentGameView === GAME_VIEW.LOBBY_WAITING && (
            <PlayQuizLobby
              quizName={quizName}
              totalPlayers={totalPlayers}
              playerList={playerList}
              setPlayerList={setPlayerList}
              gotoCountdown={gotoCountdown}
              playSoundEffect={playSoundEffect}
              currentGameGuid={currentGameGuid}
            />
          )}
          {currentGameView === GAME_VIEW.QUESTION_COUNTDOWN && (
            <PlayQuizCountdown
              quizName={quizName}
              gotoQuestionView={gotoQuestionView}
              playSoundEffect={playSoundEffect}
            />
          )}
          {currentGameView === GAME_VIEW.QUESTION_PLAYING && (
            <PlayQuizQuestion
              questions={questions}
              totalPlayers={totalPlayers}
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
              numberOfQuestions={numberOfQuestions}
              gotoRanking={gotoRanking}
              sendShowSolution={sendShowSolution}
              playerList={playerList}
              setPlayerList={setPlayerList}
              correctAnswers={correctAnswerUserGuids}
              totalAnswers={totalAnswers}
              setSeconds={setSeconds}
              seconds={seconds}
              syncTimestamp={syncTimestamp}
              playSoundEffect={playSoundEffect}
            />
          )}

          {currentGameView === GAME_VIEW.QUESTION_RANKING && (
            <PlayQuizRanking
              quizName={quizName}
              totalAnswers={totalAnswers}
              totalPlayers={totalPlayers}
              playerList={playerList}
              setPlayerList={setPlayerList}
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
              numberOfQuestions={numberOfQuestions}
              gotoQuestionView={gotoQuestionView}
              gotoPodium={gotoPodium}
              playSoundEffect={playSoundEffect}
            />
          )}
          {currentGameView === GAME_VIEW.FINISHED_PODIUM && (
            <PlayQuizPodium
              quizName={quizName}
              playerList={playerList}
              setPlayerList={setPlayerList}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PlayQuiz;
