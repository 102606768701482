import { useTranslation } from 'react-i18next';
import './CellProgramStatusChip.scss';

const CellSeedStatusChip = ({ status }) => {
  const { t } = useTranslation();

  const statusTranslationKey = {
    PUBLISHED: 'programs_table_status_published',
    PENDING: 'programs_table_status_pending',
    DRAFT: 'programs_table_status_draft',
    'PUBLISHING-IN-PROGRESS': 'programs_table_status_publishing'
  };

  return (
    <div
      className={`cell__container cell-program-status cell-program-status--${status.toLowerCase()}`}
    >
      <span
        className='cell-program-status__text'
        title={t(statusTranslationKey[status])}
      >
        {t(statusTranslationKey[status])}
      </span>
    </div>
  );
};

export default CellSeedStatusChip;
