import {
  ButtonGroup,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import PillTabs from '../../../../components/pillTabs/PillTabs';
import EDInputWithLabel from '../../../../atoms/EDInput/EDInputWithLabel';
import EDAutocompleteWithLabel from '../../../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import Table from '../../../../components/table/Table';
import EDButton from '../../../../atoms/EDButton/EDButton';
import { fetchWithToken } from '../../../../utils/helpers/fetcher';
import CellWithCheckBox from '../../../../components/table/cell/cellTypes/CellWithCheckbox';
import CellTag from '../../../../components/table/cell/cellTypes/CellTag';
import CellWithOneLineOfText from '../../../../components/table/cell/cellTypes/CellWithOneLineOfText';

const PAGE_SIZE = 8;

const AddKCsDialog = ({
  isOpen,
  onClose,
  existingKCs,
  educationYears,
  onAddKCs,
  onRemoveKCs
}) => {
  const [selectedTab, setSelectedTab] = useState('search');
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageOfSelectedTab, setCurrentPageOfSelectedTab] = useState(1);
  const [offsetOfSelectedTab, setOffsetOfSelectedTab] = useState(0);
  const [filters, setFilters] = useState({
    educationYear: null
  });
  const [knowledgeComponents, setKnowledgeComponents] = useState([]);

  const [selectedKnowledgeComponents, setSelectedKnowledgeComponents] =
    useState(existingKCs);
  const [
    fixedCopyOfSelectedKnowledgeComponents,
    setFixedCopyOfSelectedKnowledgeComponents
  ] = useState(existingKCs);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('isSelected', {
      header: '',
      id: 'isSelected',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellWithCheckBox
          checked={info.row.original.isSelected}
          onChange={() => {
            if (info.row.original.isSelected) {
              setSelectedKnowledgeComponents(
                selectedKnowledgeComponents.filter(
                  (item) => item.guid !== info.row.original.guid
                )
              );
            } else {
              setSelectedKnowledgeComponents([
                ...selectedKnowledgeComponents,
                info.row.original
              ]);
            }
            // set knowledgeComponents as selected or not
            setKnowledgeComponents(
              knowledgeComponents.map((item) => {
                if (item.guid === info.row.original.guid) {
                  return {
                    ...item,
                    isSelected: !item.isSelected
                  };
                }
                return item;
              })
            );

            setFixedCopyOfSelectedKnowledgeComponents(
              fixedCopyOfSelectedKnowledgeComponents.map((item) => {
                if (item.guid === info.row.original.guid) {
                  return {
                    ...item,
                    isSelected: !item.isSelected
                  };
                }
                return item;
              })
            );
          }}
        />
      )
    }),
    columnHelper.accessor('guid', {
      header: 'Name',
      id: 'name',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellTag
          tags={[
            {
              label: info.row.original.name,
              description: info.row.original.loNodeSeeds?.length + ' seeds'
            }
          ]}
        />
      )
    }),
    columnHelper.accessor('name', {
      header: 'Description',
      id: 'description',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellWithOneLineOfText text={info.row.original.description} />
      )
    })
  ];

  const onChangePage = (e, page) => {
    setCurrentPage(page);
    setOffset((page - 1) * PAGE_SIZE);
  };

  const onChangePageOfSelectedTab = (e, page) => {
    setCurrentPageOfSelectedTab(page);
    setOffsetOfSelectedTab((page - 1) * PAGE_SIZE);
  };

  let searchDebounceTimer = null;

  const onSearch = (value) => {
    clearTimeout(searchDebounceTimer);
    if (selectedTab === 'search') {
      searchDebounceTimer = setTimeout(() => {
        setSearchText(value);
      }, 300);
    }
  };

  const getData = async () => {
    setIsLoading(true);
    const queryParameters = [
      `offset=${offset}`,
      `pageSize=${PAGE_SIZE}`,
      // filters.updatedBy && `updatedBy=${filters.updatedBy}`,
      // filters.createdBy && `createdBy=${filters.createdBy}`,
      // filters.search && `name=${filters.search}`,
      // filters.language && `langCode=${filters.langCode}`,
      filters.educationYear && `educationYearGuid=${filters.educationYear}`,
      searchText && `search=${searchText}`,
      'orderBy=createdAt',
      'orderType=DESC',
      'type[]=concept'
    ];

    const queryParams = queryParameters.filter(Boolean).join('&');
    const url = `/nodes?${queryParams}`;

    const response = await fetchWithToken({
      method: 'GET',
      path: url
    });

    setKnowledgeComponents(
      response.data.nodes.map((node) => ({
        ...node,
        isSelected: selectedKnowledgeComponents.some(
          (kc) => kc.guid === node.guid
        )
      }))
    );
    setOffset(response.data.offset);
    setTotal(Math.ceil(response.data.count / PAGE_SIZE));
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [offset, filters, searchText]);

  useEffect(() => {
    setFixedCopyOfSelectedKnowledgeComponents(
      selectedKnowledgeComponents.map((item) => ({ ...item, isSelected: true }))
    );
  }, [selectedTab]);

  const onClickAddKC = () => {
    const KCsToDelete = existingKCs.filter(
      (item) => !selectedKnowledgeComponents.some((lo) => lo.guid === item.guid)
    );
    const KCsToAdd = selectedKnowledgeComponents.filter(
      (item) => !existingKCs.some((kc) => kc.guid === item.guid)
    );

    if (KCsToAdd.length > 0) onAddKCs(KCsToAdd.map((item) => item.guid));
    if (KCsToDelete.length > 0)
      onRemoveKCs(KCsToDelete.map((item) => item.guid));
    onClose();
  };

  return (
    <Dialog maxWidth='md' fullWidth open={isOpen} onClose={onClose}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack
              direction='row'
              spacing={2}
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography variant='h6'>Manage KCs</Typography>
              <PillTabs
                tabs={[
                  { name: 'Search', key: 'search' },
                  {
                    name: `Selected (${selectedKnowledgeComponents.length})`,
                    key: 'selectedKCs'
                  }
                ]}
                currentTab={selectedTab}
                onChangeTab={(tab) => {
                  setSelectedTab(tab);
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <EDInputWithLabel
              label='Search'
              fullWidth
              placeholder='Name, description ..'
              onChange={(e) => onSearch(e.target.value)}
              disabled={selectedTab !== 'search'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <EDAutocompleteWithLabel
              showLabel={true}
              label='Education level'
              options={educationYears.map((item) => ({
                label: item.name,
                value: item.guid
              }))}
              onChange={(e, selectedItem) => {
                setFilters({
                  ...filters,
                  educationYear: selectedItem?.value
                });
              }}
              disabled={selectedTab !== 'search'}
            />
          </Grid>
          <Grid item xs={12} style={{ minHeight: 655 }}>
            {selectedTab === 'search' && (
              <Table
                columns={columns}
                data={knowledgeComponents}
                isSelectable={true}
                showPagination={true}
                totalPages={total}
                currentPage={currentPage}
                onChangePage={onChangePage}
                isLoading={isLoading}
              />
            )}

            {selectedTab === 'selectedKCs' && (
              <Table
                columns={columns}
                data={[...fixedCopyOfSelectedKnowledgeComponents].slice(
                  (currentPageOfSelectedTab - 1) * PAGE_SIZE,
                  currentPageOfSelectedTab * PAGE_SIZE + offsetOfSelectedTab
                )}
                isSelectable={true}
                showPagination={true}
                totalPages={Math.ceil(
                  fixedCopyOfSelectedKnowledgeComponents.length / PAGE_SIZE
                )}
                currentPage={currentPageOfSelectedTab}
                onChangePage={onChangePageOfSelectedTab}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Stack direction='row' spacing={3} justifyContent='flex-end'>
              <EDButton variant='text' onClick={onClose}>
                Cancel
              </EDButton>
              <EDButton
                variant='contained'
                disableElevation
                onClick={onClickAddKC}
              >
                Update
              </EDButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddKCsDialog;
