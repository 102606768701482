import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDInputWithLabel from '../../../atoms/EDInput/EDInputWithLabel';
import EDButton from '../../../atoms/EDButton/EDButton';
import EDAutocompleteWithLabel from '../../../atoms/EDAutocomplete/EDAutocompleteWithLabel';

const CreateUserDialog = ({ onCreate, onClose, isLoading, isOpen, error }) => {
  const { t } = useTranslation();
  const [canSubmit, setCanSubmit] = useState(false);
  const [user, setUser] = useState({
    name: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    roleGuid: { value: 'R04', label: t('Guess') }
  });

  useEffect(() => {
    const { name, lastName, email, password, confirmPassword } = user;
    setCanSubmit(
      name &&
        lastName &&
        email &&
        password &&
        // check password strength to include at least 1 number and 1 capital letter and 8 characters at least and 1 special character
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/.test(
          password
        ) &&
        confirmPassword &&
        password === confirmPassword
    );
  }, [user]);

  useEffect(() => {
    if (error) {
      setCanSubmit;
    }
  }, [error]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth='md' fullWidth>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2'>{t('create_user')}</Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <EDInputWithLabel
              required={true}
              label={t('name')}
              value={user.name}
              onChange={({ target: { value } }) =>
                setUser({ ...user, name: value })
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <EDInputWithLabel
              required
              label={t('lastname')}
              value={user.lastName}
              onChange={({ target: { value } }) =>
                setUser({ ...user, lastName: value })
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <EDInputWithLabel
              required
              label={t('email')}
              type='email'
              value={user.email}
              onChange={({ target: { value } }) =>
                setUser({ ...user, email: value })
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <EDAutocompleteWithLabel
              required
              showLabel={true}
              inputProps={{ autoComplete: 'new-password' }}
              options={[
                { value: 'R01', label: t('admin_label') },
                { value: 'R03', label: t('author_label') },
                { value: 'R02', label: t('editor_label') },
                { value: 'R04', label: t('guest_label') },
                { value: 'R05', label: t('program_editor_label') }
              ]}
              label={t('role')}
              value={user.roleGuid}
              multiple={false}
              disableClearable={true}
              onChange={(e, value) => setUser({ ...user, roleGuid: value })}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <EDInputWithLabel
              required
              label={t('password')}
              type='password'
              inputProps={{ autoComplete: 'new-password' }}
              autoComplete='off'
              value={user.password}
              onChange={({ target: { value } }) =>
                setUser({ ...user, password: value })
              }
            />
            <Grid item mt={1} ml={1}>
              <Typography variant='caption' sx={{ fontStyle: 'italic' }}>
                {t('password_label')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <EDInputWithLabel
              required
              label={t('confirm_password')}
              type='password'
              inputProps={{ autoComplete: 'new-password' }}
              autoComplete='off'
              value={user.confirmPassword}
              onChange={({ target: { value } }) =>
                setUser({ ...user, confirmPassword: value })
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <EDButton variant='outlined' onClick={onClose}>
          {t('cancel')}
        </EDButton>
        <EDButton
          disabled={isLoading || !canSubmit}
          disableElevation
          variant='contained'
          onClick={() => {
            const payload = {
              ...user,
              username: user.email,
              lastname: user.lastName,
              roleGuid: user.roleGuid.value,
              avatar: 'www'
            };
            delete payload.confirmPassword;
            delete payload.lastName;
            delete payload.email;

            onCreate(payload);
          }}
        >
          {t('Save')}
        </EDButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUserDialog;
