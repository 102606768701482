import { useEffect, useRef } from 'react';
import { GraphCanvas, RadialMenu, useSelection } from 'reagraph';

const NodesGraph = ({ graphData }) => {
  const graphRef = useRef(null);

  const showSelectionDetails = (selections, actives) => {
    console.log('Selections:', selections);
    console.log('Actives:', actives);
  };

  const {
    selections,
    actives,
    onNodeClick,
    onCanvasClick,
    onNodePointerOver,
    onNodePointerOut
  } = useSelection({
    ref: graphRef,
    nodes: graphData.nodes,
    edges: graphData.edges,
    pathHoverType: 'all',
    pathSelectionType: 'out',
    onSelection: (selections, actives) => {
      showSelectionDetails(selections, actives);
    }
  });

  const canvasTheme = {
    canvas: {
      background: '#faf9ff'
    },
    node: {
      fill: '#0c3564',
      activeFill: '#0c3564',
      opacity: 1,
      selectedOpacity: 1,
      inactiveOpacity: 0.2,
      label: {
        color: '#0c3564',
        stroke: '#fff',
        activeColor: '#0c3564',
        fontSize: '22px'
      },
      subLabel: {
        color: '#0c3564',
        fontSize: '8px',
        stroke: '#fff',
        activeColor: '#0c3564'
      }
    },
    lasso: {
      border: '1px solid #55aaff',
      background: 'rgba(75, 160, 255, 0.1)'
    },
    ring: {
      fill: '#D8E6EA',
      activeFill: '#0c3564'
    },
    edge: {
      fill: '#D8E6EA',
      activeFill: '#0c3564',
      opacity: 1,
      selectedOpacity: 1,
      inactiveOpacity: 0.1,
      label: {
        stroke: '#fff',
        color: '#0c3564',
        activeColor: '#0c3564',
        fontSize: 6
      }
    },
    arrow: {
      fill: '#D8E6EA',
      activeFill: '#0c3564'
    },
    cluster: {
      stroke: '#D8E6EA',
      opacity: 1,
      selectedOpacity: 1,
      inactiveOpacity: 0.1,
      label: {
        stroke: '#fff',
        color: '#0c3564'
      }
    }
  };

  const contextMenu = ({
    data,
    canCollapse,
    isCollapsed,
    onCollapse,
    onClose
  }) => (
    <RadialMenu
      onClose={onClose}
      items={[
        {
          label: 'Add Node',
          onClick: () => {
            alert('Add a node');
            onClose();
          }
        },
        {
          label: 'Remove',
          onClick: () => {
            alert('Remove the node');
            onClose();
          }
        },
        ...(canCollapse
          ? [
              {
                label: isCollapsed ? 'Expand' : 'Collapse',
                onClick: onCollapse
              }
            ]
          : [])
      ]}
    />
  );

  useEffect(() => {
    console.log('change because of data');
  }, [graphData]);

  return (
    <div>
      <GraphCanvas
        // contextMenu={contextMenu}
        draggable
        ref={graphRef}
        minNodeSize={10}
        maxNodeSize={25}
        actives={actives}
        theme={canvasTheme}
        minDistance={100}
        sizingType='centrality'
        nodes={graphData.nodes}
        edges={graphData.edges}
        selections={selections}
        clusterAttribute='department'
        onNodeClick={onNodeClick}
        onCanvasClick={onCanvasClick}
        onNodePointerOut={onNodePointerOut}
        onNodePointerOver={onNodePointerOver}
      />
    </div>
  );
};

export default NodesGraph;
