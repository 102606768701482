import React from 'react';
import './CellSeedStatusChip.scss';
import { ReactComponent as ToDoIcon } from '../../../../assets/icons/status_todo.svg';
import { ReactComponent as NeedsRevisionIcon } from '../../../../assets/icons/status_needs_revision.svg';
import { ReactComponent as NeedsCorrectionIcon } from '../../../../assets/icons/status_needs_correction.svg';
import { ReactComponent as ValidatedIcon } from '../../../../assets/icons/status_validated.svg';
import { ReactComponent as JsonPendingIcon } from '../../../../assets/icons/status_json_pending.svg';
import { ReactComponent as PendingImageIcon } from '../../../../assets/icons/status_pending_image.svg';
import { ReactComponent as JsonRevisionIcon } from '../../../../assets/icons/status_json_revision.svg';
import { ReactComponent as CompletedIcon } from '../../../../assets/icons/status_completed.svg';
import { useTranslation } from 'react-i18next';

const CellSeedStatusChip = ({ status }) => {
  const { t } = useTranslation();

  const statusIcon = {
    to_do: <ToDoIcon />,
    needs_revision: <NeedsRevisionIcon />,
    needs_correction: <NeedsCorrectionIcon />,
    validated: <ValidatedIcon />,
    json_to_do: <JsonPendingIcon />,
    pending_image: <PendingImageIcon />,
    json_revision: <JsonRevisionIcon />,
    done: <CompletedIcon />
  };

  return (
    <div className={`cell__container cell-seed-status`}>
      <span className='cell-seed-status__icon'>{statusIcon[status]}</span>
      <span className='cell-seed-status__text' title={t(status)}>
        {t(status)}
      </span>
    </div>
  );
};

export default CellSeedStatusChip;
