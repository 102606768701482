import { useEffect, useState } from 'react';
import { fetchWithToken } from '../../../../utils/helpers/fetcher';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

const useGroups = () => {
  const navigate = useNavigate();

  const columnHelper = createColumnHelper();
  const pageSize = 10;

  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);

  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name'
    }),
    columnHelper.accessor('school_name', {
      id: 'school_name',
      header: 'Name'
    }),
    columnHelper.accessor('code', {
      id: 'code',
      header: 'Code'
    }),
    columnHelper.accessor('school_year_name', {
      id: 'school_year_name',
      header: 'School year'
    }),
    columnHelper.accessor('amount_teachers', {
      id: 'amount_teachers',
      header: 'Teachers'
    }),
    columnHelper.accessor('amount_students', {
      id: 'amount_students',
      header: 'Students'
    }),
    columnHelper.accessor('is_active', {
      id: 'is_active',
      header: 'Status'
    })
  ];

  const onClickOnGroup = (e) => {
    console.log(e);
    navigate(`/groups/${e.guid}`);
  };

  const getData = async () => {
    setIsLoading(true);
    let response = await fetchWithToken({
      path: `/front/groups?pageSize=${pageSize}&offset=${offset}`
    });
    setGroups(response.data.schoolGroups);
    setTotal(response.data.length);
    setOffset(response.data.offset);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    total,
    offset,
    columns,
    groups,
    pageSize,
    isLoading,
    onClickOnGroup
  };
};
export default useGroups;
