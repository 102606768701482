import React from 'react';
import EDInput from '../../atoms/EDInput/EDInput';
import EDAutocompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';

const EDPhaseFilter = ({
  showLabel,
  required,
  defaultValue,
  isLoading: isInitialLoad,
  onChange,
  disabled
}) => {
  const { t } = useTranslation();

  const PHASE_OPTIONS = [
    { label: t('Evoke'), value: 'evocar' },
    { label: t('Identify'), value: 'identificar' },
    { label: t('Apply'), value: 'aplicar' }
  ];

  const phaseNameToOption = {
    evocar: { label: t('Evoke'), value: 'evocar' },
    identificar: { label: t('Identify'), value: 'identificar' },
    aplicar: { label: t('Apply'), value: 'aplicar' }
  };

  return (
    <>
      {isInitialLoad ? (
        <>
          <Skeleton variant='text' />
          <Skeleton variant='rectangle' />
        </>
      ) : (
        <EDAutocompleteWithLabel
          showLabel={showLabel}
          required={required}
          disabled={disabled}
          label={t('phase_label')}
          disableClearable
          defaultValue={phaseNameToOption[defaultValue]}
          options={PHASE_OPTIONS}
          multiple={false}
          onChange={(event, value) => {
            onChange(value ? value.value : null);
          }}
          renderInput={(params) => (
            <EDInput
              {...params}
              variant='outlined'
              placeholder={t('select_placeholder')}
            />
          )}
        />
      )}
    </>
  );
};

export default EDPhaseFilter;
