import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, styled, tooltipClasses } from '@mui/material';

const StyledLargeTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 380,
    lineHeight: '18px',
    padding: "8px 16px"
  }
}));

const LargeTooltip = ({ children, text, ...props }) => {
  return (
    <StyledLargeTooltip {...props} title={text}>
      {children}
    </StyledLargeTooltip>
  );
};

LargeTooltip.propTypes = {
  arrow: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.node,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

LargeTooltip.defaultProps = {
  arrow: true,
  placement: 'bottom',
  text: ''
};

export default LargeTooltip;
