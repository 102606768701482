import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import EDInputWithLabel from '../../../../../../atoms/EDInput/EDInputWithLabel';
import EDButton from '../../../../../../atoms/EDButton/EDButton';
import Toast from '../../../../../../atoms/Toast/Toast';

const CreateContractDialog = ({ isOpen, onClose, onSubmit }) => {
  const currentYear = new Date().getFullYear();
  const baseSchoolYearData = {
    name: '',
    school_year: currentYear,
    expired_at: ''
  };

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [canProceed, setCanProceed] = useState(false);
  const [newContractDetails, setNewContractDetails] =
    useState(baseSchoolYearData);

  useEffect(() => {
    setCanProceed(checkCanProceed());
  }, [newContractDetails]);

  const checkCanProceed = () => {
    let can =
      newContractDetails.name &&
      newContractDetails.school_year &&
      newContractDetails.expired_at;

    if (!can) {
      return false;
    }

    can = newContractDetails.school_year >= currentYear;

    if (!can) {
      setToastMessage(t('class_wrong_input_year'));
      setShowToast(true);

      return false;
    }

    // can =
    // parseInt(newContractDetails.expired_at.split('-')[0]) >=
    // newContractDetails.school_year;

    if (!can) {
      setToastMessage(t('class_wrong_expiration_date'));
      setShowToast(true);

      return false;
    }

    return true;
  };

  const onCancelDialog = () => {
    setNewContractDetails(baseSchoolYearData);
    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onCancelDialog}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack>
              <Typography variant='h2'>Create School Year</Typography>
              {/* Add close icon */}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <EDInputWithLabel
              label='Name'
              onChange={({ target: { value } }) => {
                setNewContractDetails({ ...newContractDetails, name: value });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDInputWithLabel
              label='School year'
              type='number'
              value={newContractDetails.school_year}
              onChange={({ target: { value } }) => {
                setNewContractDetails({
                  ...newContractDetails,
                  school_year: value
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDInputWithLabel
              type='date'
              label='Expiration date'
              value={newContractDetails.expired_at}
              onChange={({ target: { value } }) => {
                setNewContractDetails({
                  ...newContractDetails,
                  expired_at: value
                });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <EDButton variant='text' onClick={onCancelDialog}>
          Cancel
        </EDButton>
        <EDButton
          variant='contained'
          disableElevation
          disabled={!canProceed}
          onClick={() => {
            onSubmit(newContractDetails);
          }}
        >
          Create
        </EDButton>
      </DialogActions>

      {showToast && (
        <Toast handleShowToast={setShowToast} type='info' text={toastMessage} />
      )}
    </Dialog>
  );
};

export default CreateContractDialog;
