import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../assets/bb-math-logo.svg';
import { ReactComponent as ButtonGenerateTraces } from '../../assets/images/generate_traces.svg';
import { ReactComponent as ButtonGenerateTracesWhite } from '../../assets/images/generate_traces_white.svg';
import AlertDialog from '../../atoms/AlertDialog/AlertDialog';
import ClassSelectorMenuItem from '../../components/classSelectorMenuItem/ClassSelectorMenuItem';
import ProfileMenuItem from '../../components/profileMenuItem/ProfileMenuItem';
import { goToGame } from '../../utils/helpers/goToGame';
import { getPlatform } from '../../utils/helpers/platform';
import useAuth from '../../utils/hooks/useAuth';
import DashboardMenuItem from './DashboardMenuItem';

import { Button, CircularProgress, Tooltip } from '@mui/material';

import './dashboardWithTopbarMenu.scss';
import useGenerateTraces from './useGenerateTraces';

const DashboardWithTopbarMenu = ({ upperNavigation, lowerItems }) => {
  const { user } = useAuth();

  const [isLoginAsStudentOpen, setIsLoginAsStudentOpen] = useState(false);
  const { t } = useTranslation();

  const location = useLocation();

  const {
    selectedCourse,
    isGenerateTracesLoading,
    lastDateGenerateTraces,
    setIsGenerateTracesDialog,
    isGenerateTracesDialog,
    onConfirmGenerateTracesDialog,
    handleCloseGenerateTracesDialog
  } = useGenerateTraces();

  const loginAsStudent = () => {
    setIsLoginAsStudentOpen(true);
  };

  const onClickForAction = (action) => {
    if (action === 'loginAsStudent') {
      loginAsStudent();
      return;
    }
  };

  function onConfirmDialog() {
    handleCloseDialog();
    goToGame();
  }

  function handleCloseDialog() {
    setIsLoginAsStudentOpen(false);
  }

  // console.log('upperNavigation', upperNavigation);

  const checkPermissions = (item) => {
    // TODO: CHANGE TO PERMISSIONS ARRAY IN UPPER NAVIGATION
    // TODO: Check other backoffices
    const platform = getPlatform();

    if (platform !== 'editor') {
      return true;
    }

    switch (user?.rolePerson?.guid) {
      // REVIEWER
      case 'R02':
        return item.isReviewer;

      // GUEST
      case 'R04':
        return item.isDemo;

      default:
        return true;
    }
  };

  return (
    <div className='dashboard-with-topbar-menu'>
      <div className='dashboard-with-topbar-menu__topbar'>
        <div className='dashboard-with-topbar-menu__topbar-left'>
          <div className='dashboard-with-topbar-menu__logo'>
            <Logo />
          </div>
          <nav className='dashboard-with-topbar-menu__navigation'>
            {upperNavigation
              ?.filter((item) => checkPermissions(item))
              .map((item) => (
                <div
                  key={item.key[0]}
                  className='dashboard-with-topbar-menu__navigation-item'
                >
                  {item.type === 'separator' ? (
                    <div className='dashboard-with-topbar-menu__navigation-separator' />
                  ) : (
                    <DashboardMenuItem
                      name={item?.name}
                      action={item?.action}
                      href={item?.href}
                      key={item.key}
                      pageKey={item.key}
                      icon={item?.icon}
                      flag={item?.flag}
                    />
                  )}
                </div>
              ))}
          </nav>
        </div>
        <div className='dashboard-with-topbar-menu__topbar-right'>
          {user?.is_demo === 1 &&
            user?.devmode === 1 &&
            (isGenerateTracesLoading ? (
              <Tooltip title={t('button_generate_traces_generating')}>
                <div className='dashboard-with-topbar-menu__topbar-traces-loading'>
                  <CircularProgress />
                </div>
              </Tooltip>
            ) : (
              <Tooltip
                title={`${t('button_generate_traces_hover')}  ${
                  lastDateGenerateTraces
                    ? ' ' +
                      t('button_generate_traces_tooltip', {
                        dateTraces: lastDateGenerateTraces
                      })
                    : ''
                }`}
              >
                <Button
                  onClick={() => {
                    setIsGenerateTracesDialog(true);
                  }}
                >
                  <ButtonGenerateTraces />
                </Button>
              </Tooltip>
            ))}

          <ClassSelectorMenuItem type='topbar' />
          <ProfileMenuItem
            type='topbar'
            handleLoginAsStudent={loginAsStudent}
          />
        </div>
      </div>
      <div className='dashboard-with-topbar-menu__main-content'>
        <main>
          <Outlet />
        </main>

        <AlertDialog
          isOpen={isLoginAsStudentOpen}
          title={t('Are you sure you want to enter student view?')}
          description={t('A new tab will open in the browser')}
          onConfirm={onConfirmDialog}
          onCancel={handleCloseDialog}
          confirmText={t('Yes')}
          cancelText={t('No')}
        />

        <AlertDialog
          isOpen={isGenerateTracesDialog}
          title={t('button_generate_traces_title_dialog', {
            nameClass: selectedCourse?.school_group_name
          })}
          description={t('button_generate_traces_description_dialog')}
          onConfirm={onConfirmGenerateTracesDialog}
          onCancel={handleCloseGenerateTracesDialog}
          confirmText={t('button_generate_traces_generate')}
          cancelText={t('button_generate_traces_cancel')}
          className='dashboard-with-topbar-menu-dialog'
          iconButtonConfirm={<ButtonGenerateTracesWhite />}
        />
      </div>
    </div>
  );
};

export default DashboardWithTopbarMenu;
