import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUsers from './useUsers';
import PageWithHeader from '../../../../layouts/pageWithHeader/PageWithHeader';
import TableDashboard from '../../../../view/tableDashboard/TableDashboard';
import EDDashboardTabs from '../../../../components/EDDashboardTabs/EDDashboardTabs';
import CreateNewUserDialog from './Dialogs/CreateNewUserDialog';
import Toast from '../../../../atoms/Toast/Toast';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EDConfirmDeleteDialog from '../../../../components/EDConfirmDeleteDialog/EDConfirmDeleteDialog';

const Users = () => {
  const {
    tabs,
    total,
    users,
    roles,
    offset,
    columns,
    pageSize,
    isLoading,
    totalPages,
    currentPage,
    onChangePage,
    showToast,
    toastMessage,
    setShowToast,
    onClickOnUser,
    onClickOnCreate,
    onCreateNewUser,
    isCreateNewUserDialogOpen,
    onCloseCreateNewUserDialog,
    onSearch,
    onDeleteUser
  } = useUsers();

  const { t } = useTranslation();

  const [itemToDelete, setItemToDelete] = useState(null);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  return (
    <PageWithHeader left={<EDDashboardTabs tabs={tabs} currentTab={'users'} />}>
      <TableDashboard
        disableFilters
        filters={[
          {
            id: 'status',
            key: 'status',
            title: 'Status',
            type: 'radio',
            options: [
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' }
            ]
          }
        ]}
        onSearch={(value) => {
          onSearch(value);
        }}
        onFilter={() => {}}
        tableData={users}
        onRowClick={onClickOnUser}
        tableColumns={columns}
        totalEntries={total}
        onClickOnCreate={onClickOnCreate}
        singleEntryLabel='user'
        pluralEntryLabel='users'
        searchInputPlaceholder='Search users'
        numberOfFilters={0}
        isTableDataLoading={isLoading}
        showPagination={true}
        totalPages={totalPages}
        currentPage={currentPage}
        onChangePage={onChangePage}
        options={[
          {
            icon: <BorderColorOutlinedIcon />,
            label: t('edit'),
            onClick: onClickOnUser
          },
          {
            icon: <DeleteOutlineIcon />,
            label: t('delete'),
            onClick: (user) => {
              setItemToDelete(user);
              setIsConfirmDeleteDialogOpen(true);
            }
          }
        ]}
      />
      <CreateNewUserDialog
        roles={roles}
        onSubmit={onCreateNewUser}
        isOpen={isCreateNewUserDialogOpen}
        onClose={onCloseCreateNewUserDialog}
      />

      <EDConfirmDeleteDialog
        isOpen={isConfirmDeleteDialogOpen}
        onClose={() => {
          setItemToDelete(null);
          setIsConfirmDeleteDialogOpen(false);
        }}
        onConfirm={() => {
          onDeleteUser(itemToDelete);
          setItemToDelete(null);
          setIsConfirmDeleteDialogOpen(false);
        }}
        itemToBeDeletedName={itemToDelete?.name}
        itemToBeDeletedDescription={itemToDelete?.username}
      />

      {showToast && (
        <Toast
          handleShowToast={setShowToast}
          type={toastMessage.type}
          text={toastMessage.text}
        />
      )}
    </PageWithHeader>
  );
};

export default Users;
