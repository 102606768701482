import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CourseContext } from '../../../../utils/contexts/CourseContext';
import { getCourses } from '../../../../utils/crud/course';
import { getQuizContent, startQuiz } from '../../../../utils/crud/quiz';
import useSound from '../../../../utils/hooks/useSound';
import useQuizSocket from './useQuizSocket';

const usePlayQuiz = () => {
  const { quizGuid } = useParams();

  const GAME_VIEW = {
    LOBBY_WAITING: 'lobby_waiting',
    QUESTION_COUNTDOWN: 'question_countdown',
    QUESTION_PLAYING: 'question_playing',
    QUESTION_RANKING: 'question_ranking',
    FINISHED_PODIUM: 'finished_podium'
  };

  const [currentGameView, setCurrentGameView] = useState(
    GAME_VIEW.LOBBY_WAITING
  );

  const { selectedCourse, availableCourses } = useContext(CourseContext);

  const [isLoading, setIsLoading] = useState(true);

  const [programLanguage, setProgramLanguage] = useState('');

  const [currentGameGuid, setCurrentGameGuid] = useState(null);
  const [quizName, setQuizName] = useState('');

  const [playerList, setPlayerList] = useState([]);
  const [totalPlayers, setTotalPlayers] = useState(0);

  const [totalAnswers, setTotalAnswers] = useState(0);
  const [correctAnswerUserGuids, setCorrectAnswerUserGuids] = useState([]);

  const [questions, setQuestions] = useState([]);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const COUNTDOWN = 60;
  const [seconds, setSeconds] = useState(COUNTDOWN);
  const [syncTimestamp, setSyncTimestamp] = useState(null);

  const {
    playSoundEffect,
    soundFile,
    howlerFXRef,
    isPlaying,
    EFFECTS_VOLUME,
    setIsPlaying,
    isLoop
  } = useSound();

  const getCourseInfo = async () => {
    // const selectedCourse = localStorage.getItem('bb_current_course');

    setProgramLanguage(selectedCourse?.program_lang);
    console.log('PLAYERS original', selectedCourse);

    // Esto es para obtener los usuarios justo antes de iniciar práctica,
    //  por si han cambiado de avatar
    const courses = await getCourses();
    // const courses = availableCourses;
    let updatedUsers = null;
    if (!Array.isArray(courses)) {
      if (selectedCourse.guid === courses.guid) updatedUsers = courses?.users;
    } else {
      for (const courseIndex in courses) {
        if (courses[courseIndex].guid === selectedCourse.guid) {
          updatedUsers = courses[courseIndex]?.users;
          break;
        }
      }
    }

    const quizUsers = updatedUsers ? updatedUsers : selectedCourse?.users;

    const _players = quizUsers?.filter(
      (player) => player?.role_name === 'Student'
    );

    console.log('PLAYERS original', _players);

    const _parsedPlayers = _players?.map((player) => {
      const jsonSettings = JSON.parse(player.json_settings);
      return {
        name: player.name + ' ' + player.lastname.charAt(0) + '.',
        user_guid: player.person_guid,
        avatar: jsonSettings?.blueberry?.avatar?.single_image,
        is_online: false,
        points: 0
      };
    });
    console.log('PLAYERS', _parsedPlayers);

    setPlayerList(_parsedPlayers);
    setTotalPlayers(_parsedPlayers?.length);
  };

  const initializeQuiz = async () => {
    setIsLoading(true);

    let response = await getQuizContent({ quizGuid });

    setQuizName(response?.name);

    if (response && response?.questions && response?.questions?.length > 0) {
      setQuestions(
        response?.questions?.map((question) => {
          const unitGuid = response?.lessons?.filter(
            (lesson) => lesson.guid === question?.lesson?.guid
          )[0].unit_guid;

          const parentUnit = response?.units?.filter(
            (unit) => unit.guid === unitGuid
          )[0];

          return {
            ...question,
            unit: {
              name: parentUnit.name,
              guid: parentUnit.guid
            }
          };
        })
      );

      setNumberOfQuestions(response.questions.length);
    } else {
      console.log('No tiene preguntas este Quiz');
    }

    setIsLoading(false);
  };

  const onStartQuiz = async () => {
    // const courseGuid = selectedCourse?.guid;
    const courseGuid = localStorage.getItem('bb_current_course_guid');

    const response = await startQuiz({
      courseGuid: courseGuid,
      quizGuid
    });

    console.log('onStartQuiz response', response);

    if (response) {
      console.log('conecta y desconecta onStartQuiz');

      disconnectSocket();
      connectSocket();
    }
    // setTimeout(() => connectSocket(), 3000);
    // alert('Quiz Started');
  };

  // Socket info updates
  const {
    connectSocket,
    disconnectSocket,

    sendShowQuestion,
    sendShowSolution,
    sendShowRanking,
    sendEndPractice,
    closeGame,
    sendStatusGame,
    setCheckedStatusGame,

    socketConnectedUsers,
    socketDisconnectedUsers,

    socketAnswerEvents,
    resetAnswerEvents,

    gameGuid,
    checkedStatusGame
  } = useQuizSocket();

  const updateConnectedPlayerList = (socketConnectedUsers) => {
    // Para que no se re-renderize vista podium (lo que resetea la animaciones)
    if (currentGameView !== GAME_VIEW.FINISHED_PODIUM) {
      const _connectedPlayerGuids = socketConnectedUsers?.map(
        (obj) => obj.guid
      );
      console.log('UPDATED USERS playerList', playerList);

      const _newPlayerList = playerList
        ?.map((player) => ({
          ...player,
          is_online: _connectedPlayerGuids?.includes(player.user_guid)
        }))
        .sort((a, b) => b.is_online - a.is_online);
      console.log('UPDATED USERS', _newPlayerList);
      setPlayerList(_newPlayerList);
    }
  };

  const updateDisconnectedPlayerList = (
    socketConnectedUsers,
    usersDisconnected
  ) => {
    // Para que no se re-renderize vista podium (lo que resetea la animaciones)
    if (currentGameView !== GAME_VIEW.FINISHED_PODIUM) {
      const _connectedPlayerGuids = socketConnectedUsers?.map(
        (obj) => obj.guid
      );
      console.log('UPDATED USERS _connectedPlayerGuids', _connectedPlayerGuids);
      console.log('UPDATED USERS playerList', playerList);
      console.log('UPDATED USERS usersDisconnected', usersDisconnected);
      const userGuids = usersDisconnected.map((user) => user.guid);

      const _newPlayerList = playerList
        ?.map((player) => ({
          ...player,
          is_online: userGuids.includes(player.user_guid)
            ? false
            : _connectedPlayerGuids?.includes(player.user_guid)
        }))
        .sort((a, b) => b.is_online - a.is_online);

      console.log('UPDATED USERS', _newPlayerList);
      setPlayerList(_newPlayerList);
    }
  };

  const updateCorrectAnswerUserGuids = (socketAnswerEvents) => {
    const _filteredUserGuids = socketAnswerEvents
      .filter((answerEvent) => answerEvent.resultAnswer === 1)
      .map((answerEvent) => answerEvent.userGuid);
    setCorrectAnswerUserGuids(_filteredUserGuids);
    console.log('UPDATED CORRECT ANSWER USERS', _filteredUserGuids);
  };

  // const calculatePoints = (timeInMs) => {
  //   return Math.round(1000 + -0.0333 * timeInMs);
  // };

  const calculatePoints = (timeInMs) => {
    const maxTimeInMs = 30000;
    return Math.round(
      (1 - timeInMs / (maxTimeInMs * (numberOfQuestions + 1))) * 1000
    );
  };

  const updatePointsPlayerList = async (socketAnswerEvents) => {
    // sacar eventos con respuesta correcta
    const filteredCorrectEvents = socketAnswerEvents.filter(
      (event) => event.resultAnswer === 1
    );

    // calcular los puntos para cada evento
    const updatedSocketAnswerEvents = filteredCorrectEvents.map((event) => ({
      ...event,
      calculatedPoints: calculatePoints(event.timeInMs)
    }));

    // actualizar el estado playerList
    const updatedPlayerList = playerList.map((player) => {
      const updatedPlayer = updatedSocketAnswerEvents.find(
        (event) => event.userGuid === player.user_guid
      );
      if (updatedPlayer) {
        return {
          ...player,
          points: player.points + updatedPlayer.calculatedPoints
        };
      } else {
        return player;
      }
    });
    setPlayerList(updatedPlayerList);
    // Devuelve la lista actualizada de jugadores
    return updatedPlayerList;
  };

  // Navigation

  const gotoLobby = () => {
    setCurrentQuestion(null);
    setCurrentGameView(GAME_VIEW.LOBBY_WAITING);
  };

  const gotoCountdown = () => {
    setIsPlaying(false);
    setCurrentQuestion(0);
    setCurrentGameView(GAME_VIEW.QUESTION_COUNTDOWN);
    playSoundEffect('simpleClick5');
  };

  const gotoQuestionView = () => {
    setSeconds(COUNTDOWN);
    setCurrentGameView(GAME_VIEW.QUESTION_PLAYING);
  };

  const gotoRanking = async () => {
    // Update points in player list
    const updatedPlayerList = await updatePointsPlayerList(socketAnswerEvents);
    if (currentQuestion + 1 < numberOfQuestions) {
      sendShowRanking(quizGuid, gameGuid);
      setCurrentGameView(GAME_VIEW.QUESTION_RANKING);
      playSoundEffect('lightDroneSound22');
    } else {
      gotoPodium(updatedPlayerList);
    }
  };

  const gotoPodium = (updatedPlayerList = playerList) => {
    sendEndPractice(updatedPlayerList);
    setCurrentGameView(GAME_VIEW.FINISHED_PODIUM);
    playSoundEffect('powerUp44');
    setTimeout(() => {
      playSoundEffect('applause');
    }, 3200);
  };

  // Initializing things and getting info
  useEffect(() => {
    initializeQuiz();
  }, [quizGuid]);

  useEffect(() => {
    if (selectedCourse) {
      console.log('useEffect selectedCourse', selectedCourse);
      getCourseInfo();
    }
  }, [selectedCourse]);

  useEffect(() => {
    // Reset number and correct answers users when we change the question
    setSeconds(COUNTDOWN);
    resetAnswerEvents();
    setCorrectAnswerUserGuids([]);
    setTotalAnswers(0);

    const _syncTimestamp = new Date().getTime();
    setSyncTimestamp(_syncTimestamp);

    // Send event for new screen
    sendShowQuestion(
      quizGuid,
      gameGuid,
      currentQuestion,
      COUNTDOWN,
      _syncTimestamp
    );
  }, [currentQuestion]);

  useEffect(() => {
    if (socketAnswerEvents.length > 0) {
      console.log('USE EFFECT socketAnswerEvents', socketAnswerEvents);

      // Update total answers received
      setTotalAnswers(socketAnswerEvents.length || 0);

      // Update correct answers guid list
      updateCorrectAnswerUserGuids(socketAnswerEvents);
    }
  }, [socketAnswerEvents]);

  useEffect(() => {
    if (checkedStatusGame) {
      const payload = {
        status: currentGameView,
        numberQuestion: currentQuestion ? currentQuestion : null,
        timer: seconds ? seconds : null,
        playerList: playerList ? playerList : playerList
      };
      sendStatusGame(payload);
      setCheckedStatusGame(false);
    }
  }, [checkedStatusGame]);

  useEffect(() => {
    onStartQuiz();

    window.addEventListener('beforeunload', closeGame);

    return () => {
      // Destroy room when we close the tab
      window.removeEventListener('beforeunload', closeGame);
      // disconnectSocket();
    };
  }, []);

  useEffect(() => {
    setCurrentGameGuid(gameGuid);
  }, [gameGuid]);

  useEffect(() => {
    // Updates players status if they connect or disconnect
    if (socketConnectedUsers.length > 0)
      updateConnectedPlayerList(socketConnectedUsers);
  }, [socketConnectedUsers]);

  useEffect(() => {
    // Updates players status if they connect or disconnect
    if (socketDisconnectedUsers.length > 0)
      updateDisconnectedPlayerList(
        socketConnectedUsers,
        socketDisconnectedUsers
      );
  }, [socketDisconnectedUsers]);

  return {
    quizGuid,
    quizName,
    isLoading,
    programLanguage,
    playerList,
    setPlayerList,
    totalPlayers,
    questions,
    numberOfQuestions,
    onStartQuiz,
    currentQuestion,
    setCurrentQuestion,
    currentGameView,
    setSeconds,
    playSoundEffect,
    soundFile,
    howlerFXRef,
    isPlaying,
    EFFECTS_VOLUME,
    setIsPlaying,
    isLoop,

    correctAnswerUserGuids,
    totalAnswers,

    gotoLobby,
    gotoCountdown,
    gotoQuestionView,
    gotoRanking,
    gotoPodium,
    sendShowSolution,
    GAME_VIEW,
    seconds,
    syncTimestamp,
    currentGameGuid
  };
};

export default usePlayQuiz;
