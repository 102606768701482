import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDButton from '../../../../atoms/EDButton/EDButton';
import EDDialog from '../../../../atoms/EDDialog/EDDialog';
import EDInputWithLabel from '../../../../atoms/EDInput/EDInputWithLabel';
import EDEducationLevelFilter from '../../../../components/filters/EDEducationLevelFilter';
import EDKnowledgeComponentFilter from '../../../../components/filters/EDKnowledgeComponentFilter';
import EDStandardsFilter from '../../../../components/filters/EDStandardFilter';

const NewLearningOutcomeDialog = ({ isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const [newLO, setNewLO] = useState({
    type: 'outcome',
    standards: [],
    name: '',
    description: 'test',
    linkFromGuid: [],
    department: ''
  });

  const [BNCC, setBNCC] = useState(null);
  const [CC, setCC] = useState(null);

  const [canSend, setCanSend] = useState(false);

  useEffect(() => {
    if (newLO.name && newLO.description && newLO.linkFromGuid.length > 0) {
      setCanSend(true);
    } else {
      setCanSend(false);
    }
  }, [newLO]);

  useEffect(() => {
    const bnccTemp = BNCC ? BNCC.map((item) => item.id) : [];
    const ccTemp = CC ? CC.map((item) => item.id) : [];

    setNewLO({ ...newLO, standards: [...bnccTemp, ...ccTemp] });
  }, [BNCC, CC]);

  useEffect(() => {
    // reset on close
    if (!isOpen) {
      setNewLO({
        type: 'outcome',
        standards: [],
        name: '',
        description: 'test',
        linkFromGuid: []
      });
    }
  }, [isOpen]);

  return (
    <EDDialog open={isOpen} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>
        <Typography variant='h2'>Create LO</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <EDKnowledgeComponentFilter
              required
              showLabel
              disableClearable
              onChange={(kc, allData) => {
                setNewLO({
                  ...newLO,
                  linkFromGuid: [kc],
                  department: allData[0]?.department,
                  name: allData[0]?.name
                });
              }}
            />
          </Grid>
          <Grid item md={6}>
            <EDInputWithLabel
              required
              label={t('name')}
              placeholder={t('name')}
              onChange={(e) => setNewLO({ ...newLO, name: e.target.value })}
              value={newLO?.name}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <EDInputWithLabel
              required
              label='Description'
              placeholder='Write'
              onChange={(e) =>
                setNewLO({ ...newLO, description: e.target.value })
              }
            />
          </Grid> */}
          <Grid item md={4}>
            <EDStandardsFilter
              showLabel
              type='BNCC'
              multiple={true}
              onChange={(value) => {
                setBNCC(value);
              }}
            />
          </Grid>
          <Grid item md={4}>
            <EDStandardsFilter
              showLabel
              type='CC'
              multiple={true}
              onChange={(value) => {
                setCC(value);
              }}
            />
          </Grid>
          <Grid item md={4}>
            <EDEducationLevelFilter
              showLabel
              onChange={(value) => {
                setNewLO({ ...newLO, internalEducationYearGuid: value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <EDInputWithLabel
              label={t('lo_observations_label')}
              rows={3}
              placeholder={t('lo_observations_placeholder')}
              multiline
              onChange={(e) =>
                setNewLO({ ...newLO, observation: e.target.value })
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <EDButton
          onClick={onClose}
          color='primary'
          variant='text'
          disableElevation
        >
          {t('Cancel')}
        </EDButton>
        <EDButton
          onClick={() => {
            onSubmit(newLO);
          }}
          color='primary'
          variant='contained'
          disableElevation
          disabled={!canSend}
        >
          {t('Save')}
        </EDButton>
      </DialogActions>
    </EDDialog>
  );
};

export default NewLearningOutcomeDialog;
