import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import EDCard from '../../../../atoms/EDCard/EDCard';
import TableDashboard from '../../../../view/tableDashboard/TableDashboard';
import { useTranslation } from 'react-i18next';
import PreviewActivityDialog from '../../activitiesManager/components/PreviewActivityDialog';

const LearningOutcomeActivities = ({ columns, data, isLoading }) => {
  const { t } = useTranslation();

  const [selectedActivity, setSelectedActivity] = useState(null);
  const [isPreviewActivityDialogOpen, setIsPreviewActivityDialogOpen] =
    useState(false);
  const [previewLemonadeJSON, setPreviewLemonadeJSON] = useState(null);

  const onClosePreview = () => {
    setIsPreviewActivityDialogOpen(false);
    setSelectedActivity(null);
    setPreviewLemonadeJSON(null);
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} justifyContent='center'>
        <Grid item xs={12}>
          <EDCard>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h2'>
                  {t('activities_tab_title')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableDashboard
                  tableColumns={columns}
                  tableData={data}
                  disableFiltersAndSearch
                  totalEntries={data.length}
                  singleEntryLabel={t('activities')}
                  pluralEntryLabel={t('activities')}
                  isTableDataLoading={isLoading}
                  onRowClick={(row) => {
                    window.open(
                      window.location.origin + `/activities/${row.guid}`,
                      '_blank'
                    );
                  }}
                  options={[
                    {
                      label: t('activity_preview'),
                      onClick: (row) => {
                        setSelectedActivity(row);
                        setPreviewLemonadeJSON(JSON.parse(row.seedData));
                        setIsPreviewActivityDialogOpen(true);
                      }
                    }
                  ]}
                />
              </Grid>
            </Grid>
          </EDCard>
        </Grid>

        {isPreviewActivityDialogOpen && (
          <PreviewActivityDialog
            onClose={onClosePreview}
            seedId={selectedActivity?.name}
            lemonadeJSON={previewLemonadeJSON}
            isOpen={isPreviewActivityDialogOpen}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default LearningOutcomeActivities;
