import React from 'react';
import EDCard from '../../../../../atoms/EDCard/EDCard';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from '@mui/material';
import EDInputWithLabel from '../../../../../atoms/EDInput/EDInputWithLabel';

const UserPassword = () => {
  return (
    <Grid item xs={12}>
      <EDCard>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2'>Manage Password</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <EDInputWithLabel
              type='password'
              autoComplete='off'
              label='New password'
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <EDInputWithLabel
              type='password'
              autoComplete='off'
              label='Confirm password'
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <FormGroup row>
                <FormControlLabel
                  value='right'
                  control={<Checkbox />}
                  label='Notify user of password change'
                  labelPlacement='right'
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </EDCard>
    </Grid>
  );
};

export default UserPassword;
