import NodesGraph from './NodesGraph';
import useNodesGraph from './useNodesGraph';

const NodesGraphContainer = ({ data }) => {
  const { graphData } = useNodesGraph({ data });

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '800px',
        border: '1px solid #C4CEFC',
        backgroundColor: '#faf9ff'
      }}
    >
      <NodesGraph
        graphData={graphData}
        onClickOnNode={() => {
          console.log('Clicked on node');
        }}
      />
    </div>
  );
};

export default NodesGraphContainer;
