import { useState } from 'react';

const useTableDashboard = ({ onSearch, onFilter }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [filterValues, setFilterValues] = useState([]);

  const onCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const onOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const onChangeSearch = (value) => {
    // add debounce
    setSearch(value);
    onSearch(value);
  };

  const onChangeFilterValues = (filter) => {
    let tempFilterValues = [...filterValues];
    const index = tempFilterValues.findIndex((f) => f.id === filter.id);
    if (index === -1) {
      tempFilterValues.push(filter);
    } else {
      tempFilterValues[index] = filter;
    }
    setFilterValues(tempFilterValues);
  };

  return {
    search,
    isDrawerOpen,
    onOpenDrawer,
    filterValues,
    onCloseDrawer,
    onChangeSearch,
    onChangeFilterValues
  };
};

export default useTableDashboard;
