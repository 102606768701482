import { createColumnHelper } from '@tanstack/react-table';
import { chain } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CellStatusChip from '../../../../components/table/cell/cellTypes/CellStatusChip';
import CellWithAvatarAndName from '../../../../components/table/cell/cellTypes/CellWithAvatarAndName';
import { useCourse } from '../../../../utils/contexts/CourseContext';
import { getStudentsWithUnits } from '../../../../utils/crud/student';

const useStudentsUnitsAnalysis = () => {
  const columnHelper = createColumnHelper();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState(null);

  const { selectedCourse } = useCourse();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const getData = async () => {
    setIsLoading(true);
    let studentUnitsData = await getStudentsWithUnits({
      courseGuid: selectedCourse.guid,
      ...(searchParams.get('unit')
        ? { unitGuid: searchParams.get('unit') }
        : {})
    });

    if (!studentUnitsData || !studentUnitsData?.students) {
      setError(t('student_analysis_error'));
      setIsLoading(false);
      return;
    }

    setData(
      studentUnitsData?.students.map((student) => ({
        firstName: student?.firstname,
        lastName: student?.lastname,
        avatar: student?.avatar,
        guid: student?.studentGuid,
        ...student?.units
          .map((unit) => ({
            [unit?.lessonName]: unit?.status
          }))
          .reduce((prev, curr) => ({ ...prev, ...curr }), {})
      }))
    );

    setColumns([
      columnHelper.accessor('student', {
        header: t('Student'),
        id: 'student',
        enableSorting: true,
        sortingFn: 'alphanumeric',
        cell: (info) => (
          <CellWithAvatarAndName
            firstName={info.row.original?.firstName}
            lastName={info.row.original?.lastName}
          />
        )
      }),
      ...chain(studentUnitsData?.students[0]?.units)
        .groupBy('unitName')
        .map((value, key) =>
          columnHelper.group({
            header: key,
            enableSorting: false,
            columns: value.map((unit) =>
              columnHelper.accessor(unit?.lessonName, {
                header: unit?.lessonName,
                id: unit?.lessonName,
                enableSorting: true,
                sortingFn: 'cellWithStatus',
                cell: (info) => (
                  <CellStatusChip
                    status={info.row.original[unit?.lessonName]}
                    LOData={[]}
                  />
                )
              })
            )
          })
        )
        .value()
    ]);

    setIsLoading(false);
  };

  const onClickOnStudent = (student) => {
    navigate(`/students/${student.guid}`);
  };

  useEffect(() => {
    if (!selectedCourse?.guid) return;
    getData();
  }, [selectedCourse, searchParams.get('unit')]);

  return {
    data,
    error,
    columns,
    isLoading,
    onClickOnStudent
  };
};

export default useStudentsUnitsAnalysis;
