import { createColumnHelper } from '@tanstack/react-table';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CellTag from '../../../../components/table/cell/cellTypes/CellTag';
import CellWithOneLineOfText from '../../../../components/table/cell/cellTypes/CellWithOneLineOfText';
import { fetchWithToken } from '../../../../utils/helpers/fetcher';
import { tryJsonParse } from '../../../../utils/helpers/tryJsonParse';

const useProgramActivities = ({ programGuid, program }) => {
  const PAGE_SIZE = 10;
  const columnHelper = createColumnHelper();
  const { t } = useTranslation();

  console.log(program);

  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [programSeeds, setProgramSeeds] = useState([]);
  const [toastMessage, setToastMessage] = useState({ type: '', message: '' });
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [search, setSearch] = useState('');

  const programSeedsTableColumns = [
    columnHelper.accessor('seedGuid', {
      header: t('program_seeds_table_column_id'),
      id: 'seedGuid',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      enableHiding: false,
      cell: (props) => (
        <CellWithOneLineOfText text={props.row.original.seedGuid} />
      )
    }),
    columnHelper.accessor('seedName', {
      header: t('program_seeds_table_column_name'),
      id: 'seedName',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => (
        <CellWithOneLineOfText text={props.row.original.seedName} />
      )
    }),
    columnHelper.accessor('unitName', {
      header: t('program_seeds_table_column_unit'),
      id: 'unitName',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => (
        <CellWithOneLineOfText text={props.row.original.unitName} />
      )
    }),
    columnHelper.accessor('lessonName', {
      header: t('program_seeds_table_column_lesson'),
      id: 'lessonName',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => (
        <CellWithOneLineOfText text={props.row.original.lessonName} />
      )
    }),
    columnHelper.accessor('nodes', {
      header: t('program_seeds_table_column_learning_objectives'),
      id: 'nodes',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => (
        <CellTag
          tags={props.row.original.nodes.map((node) => ({
            label: node.nodeGuid,
            description: node.nodeName
          }))}
        />
      )
    })
  ];

  function getUnitsAndLessonsWithLearningOutcomes(units, targetOutcomes) {
    const targetOutcomeGuids = new Set(targetOutcomes.map((o) => o.guid));
    const matchingUnits = [];
    const matchingLessons = [];

    for (const unit of Object.values(units)) {
      let unitHasMatch = false; // Flag to track if unit has a match

      for (const lesson of unit.lessons) {
        if (
          lesson.nodes.some((outcome) => targetOutcomeGuids.has(outcome.guid))
        ) {
          matchingLessons.push(lesson);
          unitHasMatch = true; // Set flag to true when a match is found in the lesson
          break; // No need to check other lessons in this unit
        }
      }

      if (unitHasMatch) {
        matchingUnits.push(unit);
      }
    }

    return { units: matchingUnits, lessons: matchingLessons };
  }

  const parseSeed = (seed) => {
    // 1. Extract Relevant Learning Outcomes
    const relevantLearningOutcomes = seed.loNodeSeeds.map((node) => node.node);

    // 2. Determine Units and Lessons
    const relevantUnitsAndLessons = getUnitsAndLessonsWithLearningOutcomes(
      program.units,
      relevantLearningOutcomes
    );

    const relevantUnits = relevantUnitsAndLessons.units;
    const relevantLessons = relevantUnitsAndLessons.lessons;

    // 3. Prepare Node Information (with truncation)
    const nodeInfo = relevantLearningOutcomes.map((node) => ({
      nodeGuid:
        node?.name?.slice(0, 30) + (node?.name?.length > 30 ? '...' : '') ||
        t('no_data'),
      nodeName: node?.description || t('no_data')
    }));

    // 4. Create the Program Seed Object (with defaults)
    return {
      seedGuid: seed.guid || t('no_data'),
      seedName: seed.name || t('no_data'),
      unitName:
        relevantUnits.map((unit) => unit.name).join(', ') || t('no_data'),
      lessonName:
        relevantLessons.map((lesson) => lesson.name).join(', ') || t('no_data'),
      nodes: nodeInfo,
      responsible: seed.responsible
        ? `${seed.responsible.name} ${seed.responsible.lastname}`
        : t('no_data'),
      seedTemplate: seed.template || t('no_data'),
      seedData: tryJsonParse(seed.data) || {}, // Provide default empty object if parsing fails
      hasDataApp: seed.haveDataApp || false // Provide default 'false'
    };
  };

  // Getter & Setter
  const fetchProgramSeeds = async ({ search, currentPage }) => {
    setIsLoading(true);

    const queryParameters = [
      `pageSize=${PAGE_SIZE}`,
      `offset=${(currentPage - 1) * PAGE_SIZE}`,
      `program[]=${programGuid}`,
      search && `search=${search}`
    ]
      .filter((param) => param)
      .join('&'); // Remove falsy values to avoid empty strings and trailing '&'

    const response = await fetchWithToken({
      method: 'GET',
      path: `seeds?${queryParameters}`
    });

    if (
      response.status !== 'success' ||
      !response.data?.loSeeds ||
      !Array.isArray(response.data?.loSeeds)
    ) {
      setIsLoading(false);
      setShowToast(true);
      setToastMessage({
        type: 'error',
        message: t('program_seeds_fetch_error')
      });
      return;
    }

    setTotalEntries(response.data.count);
    setTotalPages(Math.ceil(response.data.count / PAGE_SIZE));

    const tempSeeds = response.data?.loSeeds || [];
    const tempProgramSeeds = tempSeeds.map(parseSeed);

    setProgramSeeds(tempProgramSeeds);

    setIsLoading(false);
  };

  const debounceSearch = useCallback(
    debounce((searchValue) => {
      fetchProgramSeeds({ currentPage, search: searchValue });
      return;
    }, 500),
    []
  );

  const onChangeSearch = (searchValue) => {
    setSearch(searchValue);
    debounceSearch(searchValue);
  };

  const onChangePage = (e, page) => {
    setCurrentPage(page);
    setOffset((page - 1) * PAGE_SIZE);
  };

  useEffect(() => {
    if (!programGuid) return;

    fetchProgramSeeds({
      search,
      currentPage
    });
  }, [currentPage]);

  return {
    isLoading,
    showToast,
    totalPages,
    currentPage,
    toastMessage,
    programSeeds,
    totalEntries,
    programSeedsTableColumns,
    onChangePage,
    onChangeSearch
  };
};

export default useProgramActivities;
