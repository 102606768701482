import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GameQuizHeader from '../components/gameQuizHeader/GameQuizHeader';
import GameQuizTitle from '../components/gameQuizTitle/GameQuizTitle';
import './playQuizCountdown.scss';

const PlayQuizCountdown = ({ quizName, gotoQuestionView, playSoundEffect }) => {
  const { t } = useTranslation();

  const TIME_INTERVAL = 1000; // ms
  const TEXTS = ['3', '2', '1', t('quiz_go')];

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => {        
        const nextIndex = (prevIndex + 1) % TEXTS.length;        
        if (nextIndex === TEXTS.length - 1) {
          playSoundEffect('powerUp44');
          clearInterval(intervalId);
          setTimeout(gotoQuestionView, TIME_INTERVAL);
        } else {
          playSoundEffect('simpleClick5');
        }
        return nextIndex;
      });
    }, TIME_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className='play-quiz-countdown'>
      <div className='play-quiz-countdown__header'>
        <GameQuizHeader centerComponent={<GameQuizTitle title={quizName} />} />
      </div>

      <div className='play-quiz-countdown__content'>
        <div className='play-quiz-countdown__count'>{TEXTS[index]}</div>
      </div>
    </div>
  );
};

export default PlayQuizCountdown;
