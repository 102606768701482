import { createColumnHelper } from '@tanstack/react-table';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CellWithLabelAndProgressBar from '../../../../components/table/cell/cellTypes/CellWithLabelAndProgressBar';
import CellWithTitleAndSubtitle from '../../../../components/table/cell/cellTypes/CellWithTitleAndSubtitle';
import CellWithTwoFontWeights from '../../../../components/table/cell/cellTypes/CellWithTwoFontWeights';
import { CourseContext } from '../../../../utils/contexts/CourseContext';
import { getCourseStandardsWithStatus } from '../../../../utils/crud/content';
import { handleNaN } from '../../../../utils/helpers/handleNaN';
import { useLocalStorage } from '../../../../utils/hooks/useLocalStorage';
import { localStorageKeys } from '../../../../utils/localStorageKeys';

const useContentsStandardAnalysis = () => {
  const columnHelper = createColumnHelper();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { getItem } = useLocalStorage();
  const { t } = useTranslation();

  const { selectedCourse, availableCourses } = useContext(CourseContext);

  const [isPreloading, setIsPreloading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const [columns] = useState([
    columnHelper.accessor('standardGuid', {
      header: t('Standards'),
      id: 'standardGuid',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellWithTitleAndSubtitle
          title={info.row.original.standardSkill}
          aboveTitle={info.row.original.standardGuid}
        />
      )
    }),
    columnHelper.accessor('numberOfStudentsFraction', {
      header: t('Available for'),
      id: 'numberOfStudentsFraction',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellWithTwoFontWeights
          bold={info.row.original.numberOfStudentsFraction}
          regular={t(info.row.original.students + '_lowercase')}
        />
      )
    }),
    columnHelper.accessor('achievedProgress', {
      header: t('MASTERED'),
      id: 'achievedProgress',
      enableSorting: true,
      sortingFn: 'cellWithLabelAndProgressBar',
      cell: (info) => (
        <CellWithLabelAndProgressBar
          label={info.row.original.achieved}
          progress={info.row.original.achievedProgress}
          color='success'
        />
      )
    }),
    columnHelper.accessor('inProgressProgress', {
      header: t('PROGRESS'),
      id: 'inProgressProgress',
      enableSorting: true,
      sortingFn: 'cellWithLabelAndProgressBar',
      cell: (info) => (
        <CellWithLabelAndProgressBar
          label={info.row.original.inProgress}
          progress={info.row.original.inProgressProgress}
          color='info'
        />
      )
    }),
    columnHelper.accessor('needsHelpProgress', {
      header: t('HELP'),
      id: 'needsHelpProgress',
      enableSorting: true,
      sortingFn: 'cellWithLabelAndProgressBar',
      cell: (info) => (
        <CellWithLabelAndProgressBar
          label={info.row.original.needsHelp}
          progress={info.row.original.needsHelpProgress}
          color='warning'
        />
      )
    }),
    columnHelper.accessor('needsEncouragementProgress', {
      header: t('MOTIVATION'),
      id: 'needsEncouragementProgress',
      enableSorting: true,
      sortingFn: 'cellWithLabelAndProgressBar',
      cell: (info) => (
        <CellWithLabelAndProgressBar
          label={info.row.original.needsEncouragement}
          progress={info.row.original.needsEncouragementProgress}
          color='danger'
        />
      )
    })
  ]);

  const getPercentage = (numerator, denominator) => {
    return `${Math.round(handleNaN(numerator / denominator) * 100)}%`;
  };

  const getData = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const userLanguage = getItem(localStorageKeys.language).toUpperCase();
    let tempData = await getCourseStandardsWithStatus({
      courseGuid: selectedCourse.guid,
      ...(searchParams.get('standard')
        ? { standardGuid: searchParams.get('standard') }
        : {})
    });

    // Se obtienen los standars de los programas
    const courses = availableCourses;
    let standardsByProgram = null;
    if (!Array.isArray(courses)) {
      standardsByProgram = courses?.bb_program_standard;
    } else {
      for (const courseIndex in courses) {
        standardsByProgram = courses[courseIndex]?.bb_program_standard;
      }
    }

    let startsWithValuesStandards = [];
    if (standardsByProgram && standardsByProgram === 'BNCC') {
      startsWithValuesStandards.push('EF');
    }

    if (standardsByProgram && standardsByProgram === 'CC') {
      startsWithValuesStandards.push('CCSS');
    }

    const getStandardTranslation = (item) => {
      // Takes the user language, if it's not defined, takes the first one available
      const translation =
        item?.standardLanguages?.find(
          (item) => item.langCode === userLanguage
        ) || item?.standardLanguages?.[0];
      return translation;
    };

    const apiStandards = tempData?.standards || [];
    const totalStudents = tempData?.totalStudents || 0;

    const parsedData = apiStandards
      .filter((standard) =>
        startsWithValuesStandards.some((value) =>
          standard?.standardName?.startsWith(value)
        )
      )
      .map((unit) => {
        return {
          id: 1,
          standardGuid: unit?.standardGuid,
          standardName: unit?.standardName,
          standardSkill: getStandardTranslation(unit)?.skills,
          numberOfStudentsFraction: `${unit?.students}/${totalStudents}`,
          students: 'students',
          achieved: getPercentage(unit?.mastered, totalStudents),
          achievedProgress: handleNaN(unit?.mastered / totalStudents),
          achievedColor: 'success',
          inProgress: getPercentage(unit?.inProgress, totalStudents),
          inProgressProgress: handleNaN(unit?.inProgress / totalStudents),
          inProgressColor: 'info',
          needsHelp: getPercentage(unit?.needHelp, totalStudents),
          needsHelpProgress: handleNaN(unit?.needHelp / totalStudents),
          needsHelpColor: 'warning',
          needsEncouragement: getPercentage(
            unit?.needMotivation,
            totalStudents
          ),
          needsEncouragementProgress: handleNaN(
            unit?.needMotivation / totalStudents
          ),
          needsEncouragementColor: 'danger'
        };
      });

    setData(parsedData);

    setIsLoading(false);
  };

  const onClickOnStandard = (standard) => {
    navigate(`/content/standards/${standard.standardGuid}`);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsPreloading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (isPreloading || !selectedCourse?.guid) return;
    getData();
  }, [isPreloading, selectedCourse, searchParams.get('standard')]);

  return {
    data,
    columns,
    isLoading: isLoading || isPreloading,
    onClickOnStandard
  };
};

export default useContentsStandardAnalysis;
