import React, { useEffect, useRef, useState } from 'react';

import JSONEditor from 'jsoneditor/index';
import 'jsoneditor/dist/jsoneditor.css';

import './UpdateQuestionJSONForm.scss';
import { Button } from '@mui/material';

const UpdateQuestionJSONForm = ({ json, guid, onSubmit }) => {
  const containerRef = useRef(null);
  const editorRef = useRef(null);
  const [JSONError, setJSONError] = useState(null);

  const handleSubmit = () => {
    try {
      const newJSON = editorRef.current.get();
      setJSONError(null);
      onSubmit(newJSON, guid);
    } catch (e) {
      setJSONError(e.message);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      editorRef.current = new JSONEditor(containerRef.current, {
        json,
        mode: 'code'
      });
      editorRef.current.set(json);
    }

    return () => {
      if (editorRef.current) {
        editorRef.current.destroy();
      }
    };
  }, [containerRef, json]);

  return (
    <div className='update-question-form'>
      {Boolean(JSONError) && (
        <div className='update-question-form_error'>{JSONError}</div>
      )}
      <div ref={containerRef} style={{ height: 500 }} />
      <div className='update-question-form__controls'>
        <Button variant='outlined' onClick={handleSubmit}>
          Update JSON
        </Button>
      </div>
    </div>
  );
};

export default UpdateQuestionJSONForm;
