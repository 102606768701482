import { Skeleton } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDInput from '../../atoms/EDInput/EDInput';
import { fetchWithToken } from '../../utils/helpers/fetcher';

const EDResponsibleProgramFilter = ({
  showLabel,
  onChange,
  defaultValue,
  multiple,
  required,
  isLoading: isInitialLoad
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(-1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const getData = async (searchValue) => {
    setIsLoading(true);

    let response = await fetchWithToken({
      method: 'GET',
      path: `/users?pageSize=1000&offset=${offset + 1}${
        searchValue ? `&name=${searchValue}` : ''
      }`
    });

    setData([
      ...data,
      ...response.data.user.map((user) => ({
        label: user.name + ' ' + user.lastname,
        id: user.guid
      }))
    ]);
    setTotal(response.data.count);
    setOffset(response.data.offset);
    setIsLoading(false);
  };

  const debouncedFetchOptions = useCallback(
    debounce((value) => {
      getData(value);
    }, 500),
    []
  );

  // const handleInputChange = (event, value) => {
  //   setOffset(-1);
  //   if (data.length > 0) {
  //     const optionValue = data?.find((elem) => elem.label === value);
  //     const optionId = optionValue.id;
  //     setInputValue(optionId);
  //   }
  //   debouncedFetchOptions(value);
  // };

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  useEffect(() => {
    getData();
  }, []);

  /*useEffect(() => {
    console.log('inputValue - ', inputValue);
    onChange(inputValue);
  }, [inputValue]);*/

  useEffect(() => {
    if (data) {
      const user = data.filter((user) => {
        return user.id == String(defaultValue);
      });
      user && user.length > 0 && setInputValue(user[0].label);
    }
  }, [defaultValue, data]);

  return (
    <>
      {isInitialLoad ? (
        <>
          <Skeleton variant='text' />
          <Skeleton variant='rectangle' />
        </>
      ) : (
        <EDAutocompleteWithLabel
          label={t('responsible_label')}
          placeholder={t('responsible_placeholder')}
          required={required}
          showLabel={showLabel}
          multiple={multiple}
          loading={isLoading}
          loadingText={t('loading_text')}
          noOptionsText={t('no_options_text')}
          value={inputValue}
          // defaultValue={defaultValueSelect}
          onChange={(event, value) => {
            if (multiple) {
              onChange(value);
            } else {
              console.log('onChange --- ', value);
              onChange(value ? value.id : null);
              // onChange(value ? [value] : []);
            }
          }}
          options={data}
          ListboxProps={{
            onScroll: (event) => {
              if (
                event.target.scrollTop + event.target.clientHeight ===
                event.target.scrollHeight
              ) {
                if ((offset + 1) * 100 < total) {
                  getData(inputValue);
                }
              }
            }
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.label + option.id}>
                {option.label}
              </li>
            );
          }}
          // onInputChange={(e, value) => handleInputChange(e, value)}
          renderInput={(params) => (
            <EDInput {...params} placeholder={t('select_placeholder')} />
          )}
        />
      )}
    </>
  );
};

export default EDResponsibleProgramFilter;
