import {
  editorDashboardEndpoints,
  teacherDashboardEndpoints
} from '../helpers/endpoints';
import { fetchWithoutToken } from '../helpers/fetcher';
import { getPlatform } from '../helpers/platform';

export const login = async ({ email, password }) => {
  let platform = getPlatform();

  const path =
    platform === 'teacher' || platform === 'tenant'
      ? teacherDashboardEndpoints.login
      : editorDashboardEndpoints.login;

  const params =
    platform === 'teacher' || platform === 'tenant'
      ? { email, password, admitted_roles: ['R02', 'R03', 'R04'] }
      : { username: email, password };

  return fetchWithoutToken({
    method: 'POST',
    path,
    data: params,
    disableCatchHandling: true
  });
};

export const oauthLogin = async (data) => {
  let platform = getPlatform();

  const path =
    platform === 'teacher' || platform === 'tenant'
      ? teacherDashboardEndpoints.oauthLogin
      : editorDashboardEndpoints.oauthLogin;

  return fetchWithoutToken({
    method: 'POST',
    path,
    data
  });
};
