import { Button, Stack } from '@mui/material';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EDCircularLoading from '../../atoms/EDCircularLoading/EDCircularLoading';
import SearchInput from '../../atoms/SearchInput/SearchInput';
import SearchWithFilterButton from '../../atoms/SearchWithFilterButton/SearchWithFilterButton';
import FilterDrawer from '../../atoms/filterDrawer/FilterDrawer';
import FilterItem from '../../atoms/filterItem/FilterItem';
import Table from '../../components/table/Table';
import useTableDashboard from './useTableDashboard';

const TableDashboard = ({
  filters = [],
  onSearch = () => {},
  onFilter = () => {},
  tableData = [],
  onRowClick,
  tableColumns = [],
  totalEntries = 0,
  onClickOnCreate,
  singleEntryLabel = '',
  pluralEntryLabel = '',
  isTableDataLoading = false,
  searchInputPlaceholder = '',
  disableFiltersAndSearch = false,
  disableFilters = false,
  showPagination,
  totalPages,
  currentPage,
  onChangePage,
  extraActions,
  options,
  isCustomizable,
  tableColumnsVisibility,
  isFirstColumnSticky,
  isLastColumnSticky,
  tableName
}) => {
  const {
    search,
    isDrawerOpen,
    onOpenDrawer,
    filterValues,
    onCloseDrawer,
    onChangeSearch,
    numberOfFilters,
    onChangeFilterValues
  } = useTableDashboard({
    onSearch,
    onFilter
  });

  const { t } = useTranslation();

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem'
        }}
      >
        {!Boolean(disableFiltersAndSearch) ? (
          Boolean(disableFilters) ? (
            <SearchInput
              value={search}
              onChange={onChangeSearch}
              placeholder={searchInputPlaceholder}
            />
          ) : (
            <SearchWithFilterButton
              onClick={onOpenDrawer}
              value={search}
              onChange={onChangeSearch}
              numberOfFilters={numberOfFilters}
              placeholder={searchInputPlaceholder}
            />
          )
        ) : (
          <div />
        )}
        <div>
          <Stack direction='row' spacing={2}>
            {Boolean(extraActions) &&
              extraActions.map((action, index) => (
                <Button
                  key={index}
                  disableElevation
                  color='primary'
                  variant={action.variant || 'contained'}
                  onClick={action.onClick}
                  sx={{
                    padding: '0.75rem 1.5rem',
                    textTransform: 'none'
                  }}
                >
                  {action.label}
                </Button>
              ))}
            {Boolean(onClickOnCreate) && (
              <Button
                disableElevation
                color='primary'
                variant='contained'
                onClick={onClickOnCreate}
                sx={{
                  padding: '0.75rem 1.5rem',
                  textTransform: 'none'
                }}
              >
                {t('Create')}
              </Button>
            )}
          </Stack>
        </div>
      </div>

      {isTableDataLoading && <EDCircularLoading />}

      {!isTableDataLoading && tableData.length > 0 && (
        <>
          <div>
            <div
              style={{
                marginBottom: '1rem',
                color: '#8c99ad',
                fontSize: '12px',
                fontWeight: '500'
              }}
            >
              <span
                style={{
                  color: '#414b5c'
                }}
              >
                {Intl.NumberFormat().format(totalEntries)}
              </span>{' '}
              {totalEntries === 1
                ? singleEntryLabel.toLowerCase()
                : pluralEntryLabel.toLowerCase()}{' '}
              {t('have been found')}
            </div>
          </div>
          <Table
            name={tableName}
            data={tableData}
            columns={tableColumns}
            totalPages={totalPages}
            currentPage={currentPage}
            onChangePage={onChangePage}
            isLoading={isTableDataLoading}
            isCustomizable={isCustomizable}
            tableColumnsVisibility={tableColumnsVisibility}
            showPagination={showPagination}
            isLastColumnSticky={isLastColumnSticky}
            isFirstColumnSticky={isFirstColumnSticky}
            showTopPagination
            paginationSize='large'
            {...(onRowClick ? { onRowClick } : {})}
            {...(options ? { options } : {})}
          />
        </>
      )}

      {!isTableDataLoading && tableData.length === 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '20vh'
          }}
        >
          <div
            style={{
              color: '#8c99ad',
              fontSize: '12px',
              fontWeight: '500'
            }}
          >
            {t('No data found')}
          </div>
        </div>
      )}

      {!Boolean(disableFiltersAndSearch) && (
        <FilterDrawer
          title={t('Filter')}
          isDrawerOpen={isDrawerOpen}
          onCloseDrawer={onCloseDrawer}
          onReset={() => {}}
          onSearch={() => {}}
          isSearchDisabled={Object.keys(filters).length === 0}
        >
          {filters.map((filter) => (
            <FilterItem
              {...filter}
              key={filter.key}
              onChange={(value) => {
                console.log(value);
                onChangeFilterValues({ id: filter.key, value });
              }}
            />
          ))}
        </FilterDrawer>
      )}
    </div>
  );
};

TableDashboard.propTypes = {
  filters: Proptypes.array,
  onSearch: Proptypes.func,
  onFilter: Proptypes.func,
  tableData: Proptypes.array,
  onRowClick: Proptypes.func,
  tableColumns: Proptypes.array,
  totalEntries: Proptypes.number,
  onClickOnCreate: Proptypes.func,
  singleEntryLabel: Proptypes.string,
  pluralEntryLabel: Proptypes.string,
  isTableDataLoading: Proptypes.bool,
  searchInputPlaceholder: Proptypes.string,
  disableFiltersAndSearch: Proptypes.bool,
  disableFilters: Proptypes.bool,
  showPagination: Proptypes.bool,
  totalPages: Proptypes.number,
  currentPage: Proptypes.number,
  onChangePage: Proptypes.func,
  extraActions: Proptypes.array
};

export default TableDashboard;
