import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { useTranslation } from 'react-i18next';
import './ProgramStatusTag.scss';

const InfoIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.334 2.75012H7.665C4.644 2.75012 2.75 4.88912 2.75 7.91612V16.0841C2.75 19.1111 4.635 21.2501 7.665 21.2501H16.333C19.364 21.2501 21.25 19.1111 21.25 16.0841V7.91612C21.25 4.88912 19.364 2.75012 16.334 2.75012Z'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.9946 16.0001V12.0001'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.9899 8.20435H11.9999'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const ProgramStatusTag = ({ isPublished, isPublishing }) => {
  const { t } = useTranslation();
  return isPublishing ? (
    <div className='program-status-tag'>
      <TimerOutlinedIcon />
      <div className='program-status-tag__text'>
        {t('program_content_publish_program_publishing')}
      </div>
    </div>
  ) : (
    isPublished && (
      <div className='program-status-tag'>
        <InfoIcon />
        <div className='program-status-tag__text'>
          {t('program_editor_status_tag')}
        </div>
      </div>
    )
  );
};

export default ProgramStatusTag;
