import React from 'react';
import { useTranslation } from 'react-i18next';
import PageWithHeader from '../../../../layouts/pageWithHeader/PageWithHeader';
import TableDashboard from '../../../../view/tableDashboard/TableDashboard';
import EDDashboardTabs from '../../../../components/EDDashboardTabs/EDDashboardTabs';
import useBatchLicenses from './useBatchLicenses';

const BatchLicenses = () => {
  const {
    total,
    offset,
    columns,
    batchLicenses,
    onClickOnLicense,
    pageSize,
    isLoading
  } = useBatchLicenses();

  return (
    <PageWithHeader
      left={
        <EDDashboardTabs
          tabs={[
            { name: 'Licenses', to: '/licenses', key: 'licenses' },
            {
              name: 'Batch Licenses',
              to: '/licenses-batch',
              key: 'batchLicenses'
            }
          ]}
          currentTab={'batchLicenses'}
        />
      }
    >
      <TableDashboard
        disableFiltersAndSearch
        filters={[
          {
            id: 'status',
            key: 'status',
            title: 'Status',
            type: 'radio',
            options: [
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' }
            ]
          }
        ]}
        onSearch={() => {}}
        onFilter={() => {}}
        tableData={batchLicenses}
        // onRowClick={onClickOnLicense}
        tableColumns={columns}
        totalEntries={total}
        // onClickOnCreate={() => {}}
        singleEntryLabel='license'
        pluralEntryLabel='licenses'
        searchInputPlaceholder='Search licenses'
        numberOfFilters={0}
        isTableDataLoading={isLoading}
      />
    </PageWithHeader>
  );
};

export default BatchLicenses;
