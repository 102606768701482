import React, { useCallback, useEffect, useState } from 'react';
import { fetchWithToken } from '../../utils/helpers/fetcher';
import { debounce } from 'lodash';
import EDInput from '../../atoms/EDInput/EDInput';
import EDAutocomplete from '../../atoms/EDAutocomplete/EDAutocomplete';
import EDAutocompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';

const EDKnowledgeComponentFilter = ({
  defaultValue,
  showLabel,
  multiple,
  required,
  disabled,
  onChange,
  isLoading: isInitialLoad
}) => {
  const { t } = useTranslation();
  const pageSize = 25;

  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState(defaultValue);

  const getData = async (searchValue) => {
    setIsLoading(true);
    let response = await fetchWithToken({
      method: 'GET',
      path: `/nodes?pageSize=${pageSize}&type[]=concept&offset=${
        currentPage * pageSize
      }${searchValue ? `&name=${searchValue}` : ''}`
    });

    setData([
      ...data,
      ...response.data.nodes.map((node) => ({
        ...node,
        label: node.name,
        id: node.guid
      }))
    ]);
    setTotal(response.data.count);
    setOffset(response.data.offset);

    setIsLoading(false);
  };

  const debouncedFetchOptions = useCallback(
    debounce((value) => {
      getData(value);
    }, 500),
    []
  );

  const handleInputChange = (event, value) => {
    setCurrentPage(0);
    setInputValue(value);
    debouncedFetchOptions(value);
  };

  useEffect(() => {
    setCurrentPage(0);
    // onChange(inputValue);
  }, [inputValue]);

  useEffect(() => {
    getData();
  }, [currentPage]);

  return (
    <>
      {isInitialLoad ? (
        <>
          <Skeleton variant='text' />
          <Skeleton variant='rectangle' />
        </>
      ) : (
        <EDAutocompleteWithLabel
          limitTags={2}
          showLabel={showLabel}
          disabled={disabled}
          required={required}
          label={t('kc_label')}
          multiple={multiple}
          loading={isLoading}
          loadingText={t('loading_text')}
          noOptionsText={t('no_options_text')}
          defaultValue={defaultValue}
          options={data}
          ListboxProps={{
            onScroll: (event) => {
              if (
                event.target.scrollTop + event.target.clientHeight ===
                event.target.scrollHeight
              ) {
                if (currentPage * pageSize <= total) {
                  setCurrentPage(currentPage + 1);
                }
              }
            }
          }}
          // renderOption={(props, option) => {
          //   return (
          //     <li {...props} key={option?.label + option.id}>
          //       {option?.label}
          //     </li>
          //   );
          // }}
          getOptionLabel={(option) => option.label || ''}
          onInputChange={handleInputChange}
          onChange={(event, value) => {
            if (multiple) {
              onChange(
                value,
                data.filter((item) =>
                  value.map((i) => i?.id).includes(item?.id)
                )
              );
            } else {
              onChange(
                value ? value.id : null,
                data.filter((item) => [value?.id].includes(item?.id))
              );
            }
          }}
          renderInput={(params) => (
            <EDInput {...params} placeholder={t('select_placeholder')} />
          )}
        />
      )}
    </>
  );
};

export default EDKnowledgeComponentFilter;
