import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CellTag from '../../../components/table/cell/cellTypes/CellTag';
import CellWithOneLineOfText from '../../../components/table/cell/cellTypes/CellWithOneLineOfText';
import { fetchWithToken } from '../../../utils/helpers/fetcher';
import useEditorDashboardPermissions from '../../../utils/hooks/useEditorDashboardPermissions';

const useSingleKnowledgeComponent = () => {
  const { t } = useTranslation();
  const { isUserProgramEditor } = useEditorDashboardPermissions();

  const { knowledgeComponentGuid } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [knowledgeComponent, setKnowledgeComponent] = useState({});
  const [dependencies, setDependencies] = useState([]);
  const [learningOutcomes, setLearningOutcomes] = useState([]);
  const [educationYears, setEducationYears] = useState([]);

  const columnHelper = createColumnHelper();

  const [tabs, setTabs] = useState([
    {
      name: t('metadata_tab_title'),
      key: 'metadata'
    },
    {
      name: t('dependencies_tab_title'),
      key: 'dependencies'
    },
    {
      name: t('learning_outcomes_tab_title'),
      key: 'los'
    }
  ]);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ type: '', text: '' });

  const getKnowledgeComponent = async () => {
    setIsLoading(true);
    let response = await fetchWithToken({
      method: 'GET',
      path: `/nodes/${knowledgeComponentGuid}/seeds`
    });

    // create en, es and pt languages if they don't exist
    if (response.data.descriptionLanguages.length !== 3) {
      if (response.data.descriptionLanguages.length === 0) {
        await createLanguage('ES');
        await createLanguage('EN');
        await createLanguage('PT');

        response = await fetchWithToken({
          method: 'GET',
          path: `/nodes/${knowledgeComponentGuid}/seeds`
        });
      }
    }

    setKnowledgeComponent(response.data);
    setDependencies(
      response.data.linkFrom.filter((item) => item.type === 'concept')
    );
    setLearningOutcomes(
      response.data.linkTo.filter((item) => item.type === 'outcome')
    );
    setIsLoading(false);
  };

  const generateEducationYear = (educationYear) => {
    return `${educationYear.year}º ${t(educationYear.educationLevel.guid)}`;
  };

  const getEducationYears = async () => {
    let response = await fetchWithToken({
      path: '/educations-years?pageSize=100&offset=0'
    });
    setEducationYears(
      response.data.educationYears.map((educationYear) => ({
        ...educationYear,
        name: generateEducationYear(educationYear)
      }))
    );
  };

  const createLanguage = async (language) => {
    if (isUserProgramEditor) return;

    return fetchWithToken({
      method: 'POST',
      path: `/nodes/node-languages`,
      data: {
        nodeGuid: knowledgeComponentGuid,
        langCode: language,
        description: '-'
      }
    });
  };

  const onChange = (newLO) => {
    setKnowledgeComponent({ ...knowledgeComponent, ...newLO });
  };

  const getDescription = (descriptionLanguages, lang) => {
    const description = descriptionLanguages.find(
      (item) => item.lang === lang
    )?.description;
    return description;
  };

  const dependenciesTableColumns = [
    columnHelper.accessor('name', {
      header: t('name'),
      id: 'name',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => <CellWithOneLineOfText text={props.row.original.name} />
    }),
    columnHelper.accessor('description', {
      header: t('description'),
      id: 'description',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => (
        <CellWithOneLineOfText
          text={
            getDescription(props.row.original.descriptionLanguages, 'ES') ||
            getDescription(props.row.original.descriptionLanguages, 'EN') ||
            getDescription(props.row.original.descriptionLanguages, 'PT') ||
            '-'
          }
        />
      )
    })
  ];

  const learningOutcomesTableColumns = [
    columnHelper.accessor('name', {
      header: t('name'),
      id: 'name',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => <CellWithOneLineOfText text={props.row.original.name} />
    }),
    columnHelper.accessor('description', {
      header: t('description'),
      id: 'description',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => (
        <CellWithOneLineOfText
          text={
            getDescription(props.row.original.descriptionLanguages, 'ES') ||
            getDescription(props.row.original.descriptionLanguages, 'EN') ||
            getDescription(props.row.original.descriptionLanguages, 'PT') ||
            '-'
          }
        />
      )
    }),
    columnHelper.accessor('cc', {
      header: t('CC'),
      id: 'cc',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => (
        <CellTag
          tags={props.row.original.standards
            ?.filter((item) => item.name.startsWith('CC'))
            .map((item) => ({ label: item.guid, description: item.name }))}
        />
      )
    }),
    columnHelper.accessor('bncc', {
      header: t('BNCC'),
      id: 'bncc',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (props) => (
        <CellTag
          tags={props.row.original.standards
            ?.filter((item) => item.name.startsWith('EF'))
            .map((item) => ({ label: item.guid, description: item.name }))}
        />
      )
    })
  ];

  const onSaveKnowledgeComponentMetadata = async (newLO) => {
    setIsUpdating(true);

    let nodeLanguages = newLO.descriptionLanguages;

    delete newLO.descriptionLanguages;

    const response = await fetchWithToken({
      method: 'PATCH',
      path: `/nodes/${knowledgeComponentGuid}`,
      data: newLO
    });

    // update node languages
    await Promise.all(
      nodeLanguages.map(async (item) => {
        await fetchWithToken({
          method: 'PATCH',
          path: `/nodes/node-languages/${item.node}/languages/${item.lang}`,
          data: {
            description: item.description
          }
        });
      })
    );

    if (response.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_kc_saved_success')
      });

      getKnowledgeComponent();
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_kc_saved_error')
      });
    }

    setShowToast(true);
    setIsUpdating(false);
  };

  const onAddDependencies = async (updatedDependencies) => {
    let response = await fetchWithToken({
      method: 'PATCH',
      path: `/nodes/${knowledgeComponentGuid}`,
      data: {
        guid: knowledgeComponentGuid,
        name: knowledgeComponent.name,
        linkFromGuid: [
          ...updatedDependencies,
          ...dependencies.map((item) => item.guid)
        ]
      }
    });

    console.log('response', response);

    if (response.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_kc_saved_success')
      });

      getKnowledgeComponent();
    }

    setShowToast(true);
  };

  const onRemoveDependencies = async (dependenciesToDelete) => {
    let response = await fetchWithToken({
      method: 'PATCH',
      path: `/nodes/${knowledgeComponentGuid}`,
      data: {
        guid: knowledgeComponentGuid,
        linkFromGuid: dependencies
          .filter((item) => !dependenciesToDelete.includes(item.guid))
          .map((item) => item.guid)
      }
    });

    console.log('response', response);

    if (response.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_kc_saved_success')
      });

      getKnowledgeComponent();
    }

    setShowToast(true);
  };

  useEffect(() => {
    getEducationYears();
    getKnowledgeComponent();
  }, []);

  return {
    tabs,
    onChange,
    isLoading,
    isUpdating,
    knowledgeComponent,
    dependenciesTableColumns,
    learningOutcomesTableColumns,
    dependencies,
    learningOutcomes,
    onSaveKnowledgeComponentMetadata,
    showToast,
    toastMessage,
    setShowToast,
    setToastMessage,
    educationYears,
    onAddDependencies,
    onRemoveDependencies
  };
};

export default useSingleKnowledgeComponent;
