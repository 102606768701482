import { useEffect, useState } from 'react';
import useAuth from './useAuth';

const useEditorDashboardPermissions = () => {
  const { user } = useAuth();

  // Admin
  const [isUserAdmin, setIsUserAdmin] = useState(
    user?.rolePerson?.guid === 'R01'
  );

  // Revisor - Solo lectura (Contenidos)
  const [isUserReviewer, setIsUserReviewer] = useState(
    user?.rolePerson?.guid === 'R02'
  );

  // Autor - Lectura y escritura (Contenidos)
  const [isUserAuthor, setIsUserAuthor] = useState(
    user?.rolePerson?.guid === 'R03'
  );

  // Guest - Invitado
  const [isUserGuest, setIsUserGuest] = useState(
    user?.rolePerson?.guid === 'R04'
  );

  // Program Editor - Solo lectura y edición de programas creados por él mismo
  const [isUserProgramEditor, setIsUserProgramEditor] = useState(
    user?.rolePerson?.guid === 'R05'
  );

  useEffect(() => {
    setIsUserAdmin(user?.rolePerson?.guid === 'R01');
    setIsUserReviewer(user?.rolePerson?.guid === 'R02');
    setIsUserAuthor(user?.rolePerson?.guid === 'R03');
    setIsUserGuest(user?.rolePerson?.guid === 'R04');
    setIsUserProgramEditor(user?.rolePerson?.guid === 'R05');
  }, [user]);

  return {
    isUserAdmin,
    isUserReviewer,
    isUserAuthor,
    isUserProgramEditor,
    isUserGuest
  };
};

export default useEditorDashboardPermissions;
