import { CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import EDCard from '../../../../../atoms/EDCard/EDCard';
import EDInputWithLabel from '../../../../../atoms/EDInput/EDInputWithLabel';
// import EDAutocompleteWithLabel from '../../../../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
// import CountriesFilter from '../../../../../components/filters/CountriesFilter';

const SchoolDetails = ({ name, address, onUpdateSchool }) => {
  return (
    <Grid item xs={12}>
      <EDCard elevation={0}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h2'>Details</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <EDInputWithLabel
                value={name}
                label='Name'
                onChange={({ target: { value } }) =>
                  onUpdateSchool({ name: value })
                }
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <EDInputWithLabel
                label='Address'
                defaultValue={address}
                onChange={({ target: { value } }) =>
                  onUpdateSchool({ address: value })
                }
              />
            </Grid>
            {/* Require api support */}
            {/* <Grid item xs={12} md={4}>
              <CountriesFilter
                onChange={(e) => console.log(e)}
                defaultValue={country}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <EDAutocompleteWithLabel
                label={'State'}
                disabled
                showLabel
                options={[]}
                onChange={(e) => console.log(e)}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <EDInputWithLabel
                defaultValue={zipcode}
                label='Postal Code'
                onChange={(e) => console.log(e)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <EDAutocompleteWithLabel
                showLabel
                disabled
                label='Town'
                options={[]}
                onChange={(e) => console.log(e)}
              />
            </Grid> */}
          </Grid>
        </CardContent>
      </EDCard>
    </Grid>
  );
};

export default SchoolDetails;
