import { useEffect, useState } from 'react';
import { formatNumericDateWithTime } from '../../../../../utils/helpers/dateFormatters';

const useViewActivity = (t, attempt) => {
  const [showActivity, setShowActivity] = useState(false);
  const [htmlNameActivity, setHtmlNameActivity] = useState(null);
  const [timeActivity, setTimeActivity] = useState(null);
  const [dateAttempt, setDateAttempt] = useState(null);

  const parseAttemptData = () => {
    // Primero, parseamos el HTML
    const parser = new DOMParser();
    const stimulusOfAttempt = attempt?.question?.data?.stimulus || '';
    const htmlDoc = parser.parseFromString(stimulusOfAttempt, 'text/html');

    // Luego, seleccionamos el primer elemento <p>
    const firstParagraph = htmlDoc.querySelector('p');

    // En este punto, podríamos extraer solo el texto del párrafo con firstParagraph.textContent,
    // pero ya que parece que quieres el HTML, usamos innerHTML
    const firstParagraphHTML = firstParagraph?.innerHTML || stimulusOfAttempt;

    setHtmlNameActivity(firstParagraphHTML);

    const timeActivityObj = convertSecondsToMinutes(
      attempt?.elapsedTimeInSeconds
    );

    setTimeActivity(timeActivityObj);

    const date = new Date(attempt?.start_at);
    const formattedDateTime = formatNumericDateWithTime(date);

    setDateAttempt(formattedDateTime);
  };

  useEffect(() => {
    parseAttemptData();
  }, []);

  const convertSecondsToMinutes = (seconds) => {
    var hour = Math.floor(seconds / 3600);
    hour = hour < 10 ? '0' + hour : hour;
    var minute = Math.floor((seconds / 60) % 60);
    var second = seconds % 60;
    return {
      hour: hour,
      minutes: minute,
      seconds: second
    };
  };

  return {
    showActivity,
    setShowActivity,
    htmlNameActivity,
    timeActivity,
    dateAttempt
  };
};

export default useViewActivity;
