import { Skeleton } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDInput from '../../atoms/EDInput/EDInput';
import { fetchWithToken } from '../../utils/helpers/fetcher';

const EDEducationLevelProgramFilter = ({
  defaultValue,
  showLabel,
  label,
  required,
  disabled,
  isLoading: isInitialLoad,
  onChange
}) => {
  const { t } = useTranslation();

  // ONLY FOR PRIMARY LEVEL
  const EDUCATION_LEVEL = 'nivel-1';

  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(-1);
  const [total, setTotal] = useState(0);
  const [educationLevelData, setEducationLevelData] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const generateEducationYear = (educationYear) => {
    return `${educationYear.year}º ${t(educationYear.educationLevel.guid)}`;
  };

  const getData = async () => {
    setIsLoading(true);

    let responseYears = await fetchWithToken({
      method: 'GET',
      path: `/educations-years?pageSize=100&offset=${
        offset + 1
      }&educationLevelGuid=${EDUCATION_LEVEL}`
    });

    setEducationLevelData([
      ...educationLevelData,
      ...responseYears.data.educationYears
        .filter((item) => item.year !== '7')
        .map((educationYear) => ({
          label: generateEducationYear(educationYear),
          year: educationYear.year,
          id: educationYear.guid
        }))
    ]);

    setTotal(responseYears.data.count);
    setOffset(responseYears.data.offset);
    setIsLoading(false);
  };

  const debouncedFetchOptions = useCallback(
    debounce(() => {
      getData();
    }, 500),
    []
  );

  const handleInputChange = (event, value) => {
    // Creates an array with the selected ids
    setInputValue(value.id);
    debouncedFetchOptions(value);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (inputValue && inputValue.length > 0) {
      onChange(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (educationLevelData && educationLevelData.length > 0) {
      const selectedEducationLevel = educationLevelData.find(
        (level) => level.id === defaultValue || level.id === inputValue
      );
      setInputValue(selectedEducationLevel || null);
    }
  }, [defaultValue, educationLevelData]);

  return (
    <>
      {isInitialLoad ? (
        <>
          <Skeleton variant='text' />
          <Skeleton variant='rectangle' />
        </>
      ) : (
        educationLevelData.length > 0 && (
          <EDAutocompleteWithLabel
            limitTags={2}
            showLabel={showLabel}
            required={required}
            label={label || t('education_level_label')}
            loading={isLoading}
            disabled={disabled}
            loadingText={t('loading_text')}
            noOptionsText={t('no_options_text')}
            value={inputValue}
            // defaultValue={defaultValue}
            options={educationLevelData}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.label}
                </li>
              );
            }}
            getOptionLabel={(option) => option.label}
            onChange={(event, value) => {
              onChange(value ? value.id : null);
              // onChange(value ? [value] : []);
            }}
            renderInput={(params) => (
              <EDInput
                {...params}
                placeholder={t('education_level_placeholder')}
              />
            )}
          />
        )
      )}
    </>
  );
};

export default EDEducationLevelProgramFilter;
