import axios from 'axios';
import { getPlatform } from './platform';
import { getToken } from './token';

const { origin } = window.location;
const isLocalhost = origin.includes('localhost');

const teacherAPI = isLocalhost
  ? 'https://blueberry.tangerine-dev1.oneclicklabs.es/api/'
  : origin + '/api/';
const editorAPI = isLocalhost
  ? 'https://editor-backoffice.tangerine-dev.oneclicklabs.es/api/'
  : origin + '/api/';
const tenantAPI = isLocalhost
  ? 'https://tenants-backoffice.tangerine-dev.oneclicklabs.es/api/'
  : origin + '/api/';

export const getAPIURL = () => {
  if (getPlatform() === 'editor') {
    return editorAPI;
  } else if (getPlatform() === 'tenant') {
    return tenantAPI;
  }

  return teacherAPI;
};

/**
 * @param {string} method - The HTTP method to use
 * @param {object} data - The data to send to the API
 * @param {string} path - The API endpoint
 * @param {object} config - The axios config
 * @returns {object} - The response data from the API
 */
const fetcher = async ({
  method = 'GET',
  data,
  path,
  config = {},
  disableCatchHandling
}) => {
  return axios({
    baseURL: getAPIURL(),
    url: path,
    method,
    data,
    ...config,
    headers: {
      'Content-Type': 'application/json',
      ...config?.headers
    }
  })
    .then((response) => {
      if (response?.status === 200 || response?.status === 'success') {
        return response.data;
      }
    })
    .catch((error) => {
      // Check for network errors
      if (!error.response) {
        if (error.code === 403) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = '/login';
          }, 1000);
        }
        if (error.code === 404) {
          setTimeout(() => {
            window.location.href = '/error404';
          }, 500);
        }
        if (error.code === 500) {
          setTimeout(() => {
            window.location.href = '/error500';
          }, 500);
        }
        return { error: 'Network error', errorCode: error.code };
      }

      if (disableCatchHandling) {
        return { ...error?.response?.data, errorCode: error?.response?.status };
      }

      // If the token is invalid, redirect to login
      if (error.response.status === 403 || error.response.status === 401) {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = '/login';
        }, 1000);
      }

      if (error.response.status === 404) {
        setTimeout(() => {
          window.location.href = '/error404';
        }, 500);
      }

      if (error.response.status === 500) {
        setTimeout(() => {
          window.location.href = '/error500';
        }, 500);
      }

      return error.response;
    });
};

export const fetchWithToken = async ({ method, data, path, config = {} }) => {
  if (getToken().length === 0) {
    throw { error: 'no token' };
  }

  const headerAuthorizationForEditor = {
    Authorization: `Bearer ${getToken()}`
  };

  const headerAuthorizationForTeacher = {
    Authorization: getToken()
  };

  const platform = getPlatform();

  const headerAuthorization =
    platform === 'teacher' || platform === 'tenant'
      ? headerAuthorizationForTeacher
      : headerAuthorizationForEditor;

  return fetcher({
    method,
    data,
    path,
    config: {
      ...config,
      headers: {
        ...headerAuthorization,
        ...config.headers
      }
    }
  });
};

export const fetchWithoutToken = async ({
  method,
  data,
  path,
  config = {},
  disableCatchHandling
}) => fetcher({ method, data, path, config, disableCatchHandling });

function redirectToLogin() {
  window.location.href = `${window.location.protocol}//${window.location.host}/login`;
}
