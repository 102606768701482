import { Button, styled } from '@mui/material';

const EDButtonRoundedTab = styled(Button)(({ theme }) => ({
  '&.MuiButton-root.MuiButtonGroup-grouped': {
    borderRadius: '2rem',
    padding: '0.625rem 1rem',
    textTransform: 'none',
    fontWeight: 'bold',
    lineHeight: 1.64
  },
  '&.MuiButton-text': {
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[100]
  }
}));

export default EDButtonRoundedTab;
