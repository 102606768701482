import { useState } from 'react';
import { fetchWithToken } from '../../../../../../utils/helpers/fetcher';

const useImportUsers = ({ schoolGuid }) => {
  const [fileRawData, setFileRawData] = useState(null);
  const [checksData, setChecksData] = useState(null);
  const [dataForApi, setDataForAPI] = useState(null);
  const [usernameErrorsCount, setUsernameErrorsCount] = useState(0);
  const [groupErrorsCount, setGroupErrorsCount] = useState(0);
  const [groupUnassignedCount, setGroupUnassignedCount] = useState(0);
  const [isCheckingUsers, setIsCheckingUsers] = useState(false);
  const [isCreatingUsers, setIsCreatingUsers] = useState(false);
  const [hasReadFile, setHasReadFile] = useState(false);
  const [creationStatus, setCreationStatus] = useState(false);

  const handleUploadData = (results) => {
    setFileRawData(results?.data);
    createDataForAPI(results?.data);
    setHasReadFile(true);
  };

  const createDataForAPI = (rawData) => {
    const dataForApi = rawData.slice(1);
    let dataArray = [];
    dataForApi.forEach((item) => {
      const userObject = {
        name: item[0],
        lastname: item[1],
        username: item[2],
        email: item[2],
        role: item[3]?.toLowerCase(),
        password: item[4],
        group: item[5],
        is_demo: item[6] ? (item[6]?.toLowerCase() === 'no' ? 0 : 1) : 0,
        devmode: item[7] ? (item[7]?.toLowerCase() === 'no' ? 0 : 1) : 0
      };
      dataArray.push(userObject);
    });

    setDataForAPI(dataArray);
  };

  const createCheckDataForTable = (data) => {
    let dataForTable = [];
    data.forEach((item) => {
      const checkObject = {
        username: item.username,
        username_check_message: item.username_check?.message,
        username_check_has_error: item.username_check?.code !== 200,
        group: item.group,
        group_check_message: item.group
          ? item.group_check?.message
          : 'No group',
        group_check_has_error:
          item.group_check?.code !== 200 && item.group_check?.code !== undefined
      };
      dataForTable.push(checkObject);
    });

    setUsernameErrorsCount(
      dataForTable.reduce(
        (acc, obj) => acc + (obj.username_check_has_error ? 1 : 0),
        0
      )
    );

    setGroupErrorsCount(
      dataForTable.reduce(
        (acc, obj) => acc + (obj.group_check_has_error ? 1 : 0),
        0
      )
    );

    setGroupUnassignedCount(
      dataForTable.reduce((acc, obj) => acc + (obj.group ? 0 : 1), 0)
    );
    setChecksData(dataForTable);
  };

  const onCheckBatchUsersData = async () => {
    setIsCheckingUsers(true);
    const response = await fetchWithToken({
      method: 'POST',
      path: `/front/school-admin/${schoolGuid}/check-users`,
      data: {
        users: dataForApi
      }
    });
    console.log('onCheckBatchUsersData response', response);
    if (response.status === 'success') {
      createCheckDataForTable(response?.data);
      console.log('⏫ Import Users - SUCCESS checking users');
    } else {
      console.log('⏫ Import Users - ERROR checking users', response.data);
    }
    setIsCheckingUsers(false);
  };

  const onCreateBatchUsers = async () => {
    console.log('onCreateBatchUsers schoolGuid', schoolGuid);
    setIsCreatingUsers(true);
    const response = await fetchWithToken({
      method: 'POST',
      path: `/front/school-admin/${schoolGuid}/users`,
      data: {
        users: dataForApi
      }
    });

    if (response.status === 'success') {
      setCreationStatus('success');
    } else {
      setCreationStatus('error');
    }
    setIsCreatingUsers(false);
  };

  return {
    handleUploadData,
    onCheckBatchUsersData,
    onCreateBatchUsers,
    fileRawData,
    checksData,
    usernameErrorsCount,
    groupErrorsCount,
    groupUnassignedCount,
    isCreatingUsers,
    isCheckingUsers,
    hasReadFile,
    creationStatus
  };
};

export default useImportUsers;
