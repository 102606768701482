import { Switch } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchWithToken } from '../../../../../utils/helpers/fetcher';

const useSchoolGroups = ({
  schoolGuid,
  onUpdateGroup,
  getSchoolGroups,
  setShowToast,
  setMessageToast
}) => {
  const { t } = useTranslation();

  const [usersInGroup, setUsersInGroup] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isListOfUsersInGroupOpen, setIsListOfUsersInGroupOpen] =
    useState(false);
  const [isLoadingUsersInGroup, setIsLoadingUsersInGroup] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [isConfirmDeleteGroupDialogOpen, setIsConfirmDeleteGroupDialogOpen] =
    useState(false);
  const [isGroupsDataLoading, setIsGroupsDataLoading] = useState(false);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name'
    }),
    columnHelper.accessor('code', {
      id: 'code',
      header: 'Code'
    }),
    columnHelper.accessor('school_year_name', {
      id: 'school_year_name',
      header: 'School year'
    }),
    columnHelper.accessor('amount_teachers', {
      id: 'amount_teachers',
      header: 'Teachers'
    }),
    columnHelper.accessor('amount_students', {
      id: 'amount_students',
      header: 'Students'
    }),
    columnHelper.accessor('bb_units_locked', {
      id: 'bb_units_locked',
      header: 'Every unit first lesson locked?',
      cell: (info) => (
        <Switch
          checked={info.row.original.bb_units_locked}
          onChange={(event) => onChangeUnitsStatus(event, info.row.original)}
        />
      )
    }),

    columnHelper.accessor('letter_case', {
      id: 'letter_case',
      header: 'Force uppercase?',
      cell: (info) => (
        <Switch
          checked={info.row.original.letter_case !== 'regularCase'}
          onChange={(event) => onChangeForceUpperCase(event, info.row.original)}
        />
      )
    }),

    columnHelper.accessor('is_active', {
      id: 'is_active',
      header: 'Status',
      cell: (info) => (
        <Switch
          checked={info.row.original.is_active}
          onChange={(event) => onChangeActive(event, info.row.original)}
        />
      )
    })
  ];

  const onChangeActive = async (event, group) => {
    setIsListOfUsersInGroupOpen(false);
    onUpdateGroup({
      guid: group.guid,
      is_active: event.target.checked ? 1 : 0
    });
  };

  const onChangeForceUpperCase = async (event, group) => {
    setIsListOfUsersInGroupOpen(false);
    onUpdateGroup({
      guid: group.guid,
      letter_case: event.target.checked ? 'upperCase' : 'regularCase'
    });
  };

  const onChangeUnitsStatus = async (event, group) => {
    setIsListOfUsersInGroupOpen(false);
    onUpdateGroup({
      guid: group.guid,
      bb_units_locked: event.target.checked ? 1 : 0
    });
  };

  const onGetUsersInGroup = async (group) => {
    setSelectedGroup(group);
    setIsListOfUsersInGroupOpen(true);
    setIsLoadingUsersInGroup(true);
    const groupUsersResponse = await fetchWithToken({
      path: `/front/school-admin/${schoolGuid}/groups/${group.guid}/users`
    });

    if (groupUsersResponse.status !== 'success') {
      return [];
    }

    setUsersInGroup(groupUsersResponse.data.users);
    setIsLoadingUsersInGroup(false);
  };

  const onCloseListOfUsersInGroup = () => {
    setIsListOfUsersInGroupOpen(false);
    setSelectedGroup(null);
    setUsersInGroup([]);
  };

  const onDeleteGroup = async () => {
    try {
      const response = await fetchWithToken({
        path: `front/school-admin/${schoolGuid}/groups`,
        method: 'DELETE',
        data: {
          guid: [groupToDelete.guid],
          removeRelatedCourses: 0
        }
      });

      if (response.data) {
        setIsGroupsDataLoading(true);
        await getSchoolGroups();
        setIsGroupsDataLoading(false);
        setMessageToast({
          type: 'success',
          text: t('group_deleted_successfully', {
            groupName: groupToDelete.name
          })
        });
      } else {
        throw new Error('Failed to delete group');
      }
    } catch (error) {
      console.error('Error deleting group:', error);
      setMessageToast({
        type: 'error',
        text: t('group_deleted_error', {
          groupName: groupToDelete.name
        })
      });
    } finally {
      setShowToast(true);
      setGroupToDelete(null);
      setIsConfirmDeleteGroupDialogOpen(false);
    }
  };

  return {
    columns,
    usersInGroup,
    selectedGroup,
    isGroupsDataLoading,
    isLoadingUsersInGroup,
    isListOfUsersInGroupOpen,
    isConfirmDeleteGroupDialogOpen,
    onDeleteGroup,
    setGroupToDelete,
    onGetUsersInGroup,
    onCloseListOfUsersInGroup,
    setIsConfirmDeleteGroupDialogOpen
  };
};

export default useSchoolGroups;
