import React, { memo, useEffect } from 'react';
import './playQuizPodium.scss';

import GameQuizHeader from '../components/gameQuizHeader/GameQuizHeader';
import GameQuizTitle from '../components/gameQuizTitle/GameQuizTitle';
import ImageCup from '../assets/ranking_cup.png';
import PodiumBlob1 from '../assets/podium_blob_1.svg';
import PodiumBlob2 from '../assets/podium_blob_2.svg';
import PodiumBlob3 from '../assets/podium_blob_3.svg';
import PodiumStar1 from '../assets/podium_star_1.svg';
import PodiumStar2 from '../assets/podium_star_2.svg';
import PodiumStar3 from '../assets/podium_star_3.svg';
import DefaultAvatar from '../assets/default_avatar.png';

import Bubble from '../assets/bubble.png';
import confetti from '../../../../../utils/confetti';
import { useTranslation } from 'react-i18next';

const PlayQuizPodium = memo(function PlayQuizPodium({
  quizName,
  playerList,
  setPlayerList
}) {
  const { t } = useTranslation();

  useEffect(() => {
    const sortedPlayers = [...playerList].sort((a, b) => b.points - a.points);
    setPlayerList(sortedPlayers);

    const conffetiTimeout = setTimeout(() => {
      confetti.maxCount = 250;
      confetti.start();
    }, 5500);

    return () => {
      clearTimeout(conffetiTimeout);
      confetti.remove();
    };
  }, []);

  const AvatarOnPodiumComponent = ({ position }) => {
    const userName = playerList[position].name;
    const userPoints = playerList[position].points;
    let podiumBlob = null;
    let podiumStar = null;
    let podiumClass = '';
    switch (position) {
      case 0:
        podiumBlob = PodiumBlob1;
        podiumStar = PodiumStar1;
        podiumClass = 'first';
        break;
      case 1:
        podiumBlob = PodiumBlob2;
        podiumStar = PodiumStar2;
        podiumClass = 'second';
        break;
      case 2:
        podiumBlob = PodiumBlob3;
        podiumStar = PodiumStar3;
        podiumClass = 'third';
        break;
    }

    return (
      <div className='play-quiz-podium__avatar-group'>
        <div className={'play-quiz-podium__podium-tag ' + podiumClass}>
          <div>{userName}</div>
          <div>{`${userPoints} ${t('quiz_points')}`}</div>
        </div>

        <div className={'play-quiz-podium__podium-avatar ' + podiumClass}>
          <img
            className='play-quiz-podium__podium-avatar_avatar'
            src={playerList[position].avatar || DefaultAvatar}
            alt={playerList[position].name}
          />
          <img
            className='play-quiz-podium__podium-avatar_bubble'
            src={Bubble}
          />
        </div>

        <img
          className={'play-quiz-podium__star ' + podiumClass}
          src={podiumStar}
          alt={userName}
        />

        <img
          className={'play-quiz-podium__blob ' + podiumClass}
          src={podiumBlob}
          alt={position}
        />
      </div>
    );
  };

  return (
    <div className='play-quiz-podium'>
      <GameQuizHeader centerComponent={<GameQuizTitle title={quizName} />} />

      <div className='play-quiz-podium__content'>
        <div className='play-quiz-podium__podium-wrapper'>
          <AvatarOnPodiumComponent position={1} />
          <AvatarOnPodiumComponent position={0} />
          <AvatarOnPodiumComponent position={2} />
        </div>

        <div className='play-quiz-podium__ranking-wrapper'>
          <div className='play-quiz-podium__ranking-title'>
            <img src={ImageCup} alt='TOP RANKING' />
            TOP RANKING
          </div>
          <div className='play-quiz-podium__ranking-list'>
            {playerList.slice(3).map((player, index) => (
              <div className='ranking-list__item' key={player.user_guid}>
                <div className='ranking-list__position'>{index + 4}</div>
                <div className='ranking-list__name'>{player.name}</div>
                <div className='ranking-list__points'>
                  {`${player.points} ${t('quiz_points')}`}
                </div>
                <div className='ranking-list__avatar'>
                  <img src={player.avatar || DefaultAvatar} alt={player.name} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default PlayQuizPodium;
