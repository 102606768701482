import { Dialog, DialogContent, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import EDButton from '../../../../../../atoms/EDButton/EDButton';
import { useTranslation } from 'react-i18next';

const ConfirmDeleteDialog = ({
  isOpen,
  onClose,
  onConfirm,
  itemToBeDeletedName,
  itemToBeDeletedDescription
}) => {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth maxWidth='md' open={isOpen} onClose={onClose}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2'>
              {t('confirm_delete_title', {
                title: itemToBeDeletedName
              })}{' '}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {t(itemToBeDeletedDescription)}
          </Grid>
          <Grid item xs={12}>
            <Stack direction='row' spacing={2} justifyContent='flex-end'>
              <EDButton variant='text' onClick={onClose}>
                {t('No')}
              </EDButton>
              <EDButton
                disableElevation
                variant='contained'
                color='primary'
                onClick={onConfirm}
              >
                {t('Yes')}
              </EDButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
