import { Link } from 'react-router-dom';
import { useFeatureFlag } from '../../utils/hooks/useFeatureFlag';

import { useTranslation } from 'react-i18next';
import Icon from '../../atoms/icon';
import BBTooltip from '../../atoms/tooltip/BBTooltip';
import getEnvironment from '../../utils/helpers/environment';

const DashboardMenuItem = ({ name, action, href, pageKey, icon, flag }) => {
  const { t } = useTranslation();

  const { isFlagEnabled } = useFeatureFlag({ flag });
  const environment = getEnvironment();

  const checkIsCurrentTab = (key) => {
    // key can be string or array
    if (typeof key === 'string') {
      return location.pathname.split('/').includes(key);
    } else if (Array.isArray(key)) {
      return key.some((k) => location.pathname.split('/').includes(k));
    }
  };

  if (flag && !isFlagEnabled) {
    return null;
  }

  return (
    // <BBTooltip text={t(name)} placement='bottom'>
    <div>
      {action ? (
        <BBTooltip text={t(name)} placement='bottom'>
          <div
            className='dashboard-with-topbar-menu__navigation-item'
            sx={{ padding: 0 }}
            onClick={() => onClickForAction(action)}
          >
            <Icon type={icon} format='outline' />
          </div>
        </BBTooltip>
      ) : (
        <Link
          to={href}
          className={`dashboard-with-topbar-menu__navigation-item ${
            checkIsCurrentTab(pageKey)
              ? 'dashboard-with-topbar-menu__navigation-item--active'
              : ''
          }`}
        >
          {t(name)}
        </Link>
      )}
    </div>
    // </BBTooltip>
  );
};

export default DashboardMenuItem;
