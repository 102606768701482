import React from 'react';
import LargeTooltip from '../../../../atoms/tooltip/LargeTooltip';
import './CellWithOneLineOfTextLarge.scss';

const CellWithOneLineOfTextLarge = ({ text }) => {
  return (
    <div className='cell__container cell--one-line-text-large'>
      <LargeTooltip text={text} placement='bottom'>
        <div>{text}</div>
      </LargeTooltip>
    </div>
  );
};

export default CellWithOneLineOfTextLarge;
