import React, { useEffect, useState } from 'react';
import './GetQuestionForm.scss';
import EDInputWithLabel from '../../../../atoms/EDInput/EDInputWithLabel';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

function GetQuestionForm({ error, onSubmit, isLoading }) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [questionGuid, setQuestionGuid] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!hasSubmitted) return;

    setIsDisabled(isLoading);
  }, [isLoading, hasSubmitted]);

  function handleSubmit() {
    setHasSubmitted(true);
    setIsDisabled(true);
    onSubmit(questionGuid);
  }

  return (
    <div className='get-question-form'>
      <div className='get-question-form__layout'>
        <div className='form-group'>
          <EDInputWithLabel
            id='name'
            label={'Question GUID'}
            value={questionGuid}
            isError={error?.name?.length > 0}
            onChange={({ target: { value } }) => setQuestionGuid(value)}
            inputType='text'
            placeholder={'XX-XXX-XX-X-X'}
            disabled={isDisabled}
          />
        </div>
        <div
          className='form-group'
          style={{ display: error ? 'block' : 'none' }}
        >
          <span className='text size-12 weight-500 red'>{t(error)}</span>
        </div>
      </div>

      <div className='get-question-form__controls'>
        <Button
          variant='outlined'
          disabled={isDisabled || !questionGuid}
          onClick={handleSubmit}
        >
          Get Question
        </Button>
      </div>
    </div>
  );
}

export default GetQuestionForm;
