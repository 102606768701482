import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../../../../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDButton from '../../../../../../atoms/EDButton/EDButton';
import EDInputWithLabel from '../../../../../../atoms/EDInput/EDInputWithLabel';

const CreateEditClassDialog = ({
  isOpen,
  onClose,
  programs,
  groups,
  onSubmit,
  isEditMode,
  classDetails
}) => {
  const { t } = useTranslation();
  const baseClassDetails = {
    name: '',
    is_referenced: 1,
    blueberry_ranking: 1
  };

  const [selectedGroup, setSelectedGroup] = useState({
    label: groups[0].name,
    value: groups[0].guid
  });
  const [groupsOptions, setGroupsOptions] = useState(groups);
  const [programsOptions, setProgramsOptions] = useState(programs);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [newClassDetails, setNewClassDetails] = useState(baseClassDetails);

  const [canProceed, setCanProceed] = useState(false);

  useEffect(() => {
    const auxGroupsOptions = groups.filter(
      (item) => item.amount_students > 0 && item.amount_teachers > 0
    );
    setGroupsOptions(auxGroupsOptions);
    if (!isEditMode) {
      setSelectedGroup({
        label: auxGroupsOptions[0].name,
        value: auxGroupsOptions[0].guid
      });
    }
  }, [groups, isEditMode]);

  useEffect(() => {
    if (isEditMode && classDetails) {
      setNewClassDetails(classDetails);
      const group = groups.find(
        (group) => group.guid === classDetails.school_group_guid
      );
      setSelectedGroup({
        label: group.name,
        value: group.guid
      });
      console.log('classDetails', classDetails);
      const program = programs.find(
        (program) => program.guid === classDetails.program_guid
      );
      setSelectedProgram({
        label: `${
          program.education_year_name ? program.education_year_name : ''
        } ${
          program.education_level_name ? program.education_level_name : ''
        } - ${program.name}`,
        value: program.guid
      });
    } else {
      setSelectedProgram(null);
    }
    setProgramsOptions(getFilteredPrograms());
  }, [isEditMode, classDetails]);

  useEffect(() => {
    setCanProceed(checkCanProceed());
  }, [newClassDetails, selectedProgram]);

  const checkCanProceed = () => {
    return newClassDetails.name && selectedGroup?.value && selectedProgram;
  };

  const onCancelDialog = () => {
    setNewClassDetails(baseClassDetails);
    setSelectedProgram(null);
    onClose();
  };

  const onGroupChange = (e, value) => {
    setSelectedGroup(value);
  };

  const getFilteredPrograms = () => {
    const groupData = groups.find((item) => item.guid === selectedGroup.value);
    return programs.filter((item) => item.is_accessible && item.is_active);
  };

  const padTo2Digits = (num) => num.toString().padStart(2, '0');

  const onCreateOrEdit = () => {
    const groupData = groups.find((item) => item.guid === selectedGroup.value);
    const programData = programs.find(
      (item) => item.guid === selectedProgram.value
    );

    const timeZoneOffset = new Date().getTimezoneOffset();
    const hours = padTo2Digits(Math.floor(Math.abs(timeZoneOffset) / 60));
    const minutes = padTo2Digits(Math.abs(timeZoneOffset) % 60);
    const timeZone = (timeZoneOffset < 0 ? '-' : '+') + hours + ':' + minutes;

    let classData = {
      name: newClassDetails.name,
      is_referenced: newClassDetails.is_referenced,
      blueberry_ranking: newClassDetails.blueberry_ranking,
      school_group_guid: groupData.guid,
      education_year_guid: groupData.education_year_guid,
      education_discipline_guid: programData.education_discipline_guid,
      programs: [programData.guid],
      time_zone: timeZone
    };

    if (isEditMode) {
      classData = {
        name: newClassDetails.name,
        blueberry_ranking: newClassDetails.blueberry_ranking,
        school_group_guid: groupData.guid,
        course_template_guid: programData.guid
      };
    }

    onSubmit(classData);
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onCancelDialog}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack>
              <Typography variant='h2'>
                {isEditMode ? t('edit_class') : t('create_class')}
              </Typography>
              {/* Add close icon */}
            </Stack>
          </Grid>

          {isEditMode && (
            <Grid item xs={12}>
              <Alert severity='warning' variant='standard'>
                {t('edit_traces_loss_warning')}
              </Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <EDInputWithLabel
              label='Class name'
              value={newClassDetails.name}
              onChange={({ target: { value } }) => {
                setNewClassDetails({ ...newClassDetails, name: value });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDAutocompleteWithLabel
              showLabel
              label='Group'
              options={groupsOptions.map((item) => ({
                label: item.name,
                value: item.guid
              }))}
              value={selectedGroup}
              disableClearable
              onChange={onGroupChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDAutocompleteWithLabel
              showLabel
              label='Blueberry ranking'
              options={['Yes', 'No']}
              value={newClassDetails.blueberry_ranking ? 'Yes' : 'No'}
              disableClearable
              onChange={(e, value) => {
                setNewClassDetails({
                  ...newClassDetails,
                  blueberry_ranking: value === 'Yes'
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <EDAutocompleteWithLabel
              showLabel
              label='Program'
              options={programsOptions.map((item) => ({
                label: `${
                  item.education_year_name ? item.education_year_name : ''
                }  ${
                  item.education_level_name ? item.education_level_name : ''
                } - ${item.name}`,
                value: item.guid
              }))}
              value={selectedProgram}
              disableClearable
              onChange={(e, value) => {
                setSelectedProgram(value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <EDButton variant='text' onClick={onCancelDialog}>
          {t('cancel')}
        </EDButton>
        <EDButton
          variant='contained'
          disableElevation
          disabled={!canProceed}
          onClick={onCreateOrEdit}
        >
          {isEditMode ? t('save') : t('Create')}
        </EDButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEditClassDialog;
