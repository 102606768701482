import { AllQuestions } from 'react-lemonade-editor';
import './LemonadeAllQuestions.scss';

const LemonadeAllQuestions = () => {
  return (
    <div className='lemonade-all-questions'>
      <div className='lemonade-all-questions__title'>
        Lemonade All Questions
      </div>
      <div className='lemonade-all-questions__questions activity-view__exercise regular-practice-app'>
        <AllQuestions />
      </div>
    </div>
  );
};

export default LemonadeAllQuestions;
