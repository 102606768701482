import { createTheme } from '@mui/material/styles';

const quizTheme = createTheme({
  palette: {
    primary: {
      main: '#5A34E1'
    },
    grey: {
      50: '#EEF2F7',
      100: '#8892A7',
      200: '#444E61'
    }
  },
  shape: {
    borderRadius: 8
  },
  typography: {
    fontFamily: 'Poppins',

    subtitle1: {
      fontSize: '14px',
      color: '#8892A7',
      fontWeight: '700',
      lineHeight: '24px'
    },
    h2: {
      fontSize: '20px',
      color: '#444E61',
      fontWeight: '700',
      lineHeight: '28px'
    },
    h3: {
      fontSize: '19px',
      color: '#8892A7',
      fontWeight: '700',
      lineHeight: '24px'
    },
    h6: {
      fontSize: '16px',
      color: '#444E61',
      fontWeight: '700',
      lineHeight: '28px'
    },
    caption: {
      fontFamily: 'Quicksand',
      fontSize: '12px',
      color: '#8892A7',
      fontWeight: '700',
      lineHeight: '14px'
    },
    langTag: {
      fontSize: '15px',
      color: '#5A34E1',
      fontWeight: '700',
      lineHeight: '24px',
      textTransform: 'uppercase',
      padding: '0 8px',
      borderRadius: 8,
      background: ' #EEF2F7'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 36,
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: '700',
          lineHeight: '24px',
          padding: '12px 24px'
        }
      },
      variants: [
        {
          props: { variant: 'text' },
          style: {
            padding: 0,
            color: '#5A34E1',
            ':hover': {
              backgroundColor: 'transparent',
              color: '#30079A'
            }
          }
        },
        {
          props: { variant: 'contained', color: 'grey' },
          style: {
            color: '#444E61',
            backgroundColor: 'transparent',
            ':hover': {
              color: '#444E61',
              backgroundColor: 'transparent'
            },
            '& svg': {
              color: '#444E61'
            },
            '&.Mui-disabled ': {
              opacity: 0.7,
              backgroundColor: 'transparent'
            }
          }
        },
        {
          props: { variant: 'text', color: 'info' },
          style: {
            color: '#444E61',
            backgroundColor: 'transparent',
            ':hover': {
              color: '#444E61',
              backgroundColor: 'transparent'
            }
          }
        },

        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            borderWidth: 2,
            borderColor: '#5A34E1',
            '& .ai-icon path': {
              fill: '#5A34E1'
            },
            ':hover': {
              borderWidth: 2,
              color: '#ffffff',
              backgroundColor: '#5A34E1',

              '& .ai-icon path': {
                fill: '#ffffff'
              }
            },
            ':active': {
              color: '#ffffff',
              backgroundColor: '#5A34E1'
            },
            ':disabled': {
              borderWidth: 2,
              color: '#ffffff',
              backgroundColor: '#5A34E1',
              fill: '#ffffff',

              '& .ai-icon path': {
                fill: '#ffffff'
              },

              '& svg ': {
                color: 'white'
              }
            }
          }
        },

        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            color: '#ffffff',
            '& .ai-icon path': {
              fill: '#ffffff'
            },
            ':disabled': {
              '& .ai-icon path': {
                fill: '#8892A7'
              }
            }
          }
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: '#ffffff',
            color: '#5A34E1',
            '& svg': {
              fill: '#5A34E1'
            },
            ':hover': {
              backgroundColor: '#3e249d',
              color: '#ffffff',
              '& svg': {
                fill: '#ffffff'
              }
            }
          }
        },
        {
          props: { variant: 'contained', color: 'info' },
          style: {
            backgroundColor: '#444E61',
            color: '#ffffff',
            ':hover': {
              backgroundColor: '#444E61'
            }
          }
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            backgroundColor: '#ffffff',
            color: '#5A34E1',
            border: '2px solid #5A34E1',
            '& svg': {
              fill: '#5A34E1'
            },
            ':hover': {
              backgroundColor: '#f4f1ff',
              border: '2px solid #5A34E1',
              color: '#5A34E1',
              '& svg': {
                fill: '#5A34E1'
              }
            }
          }
        }
      ]
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          background: '#F3F6F9',
          '& svg': {
            fill: '#303949'
          }
        }
      },
      variants: [
        {
          props: { variant: 'transparent' },
          style: {
            borderRadius: 12,
            background: 'rgba(0, 0, 0, 0.10)',
            '& svg': {
              fill: '#ffffff'
            }
          }
        },
        {
          props: { variant: 'more' },
          style: {
            borderRadius: 8,
            background: 'transparent',
            '& svg': {
              fill: '#8892A7'
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }
        },
        {
          props: { variant: 'counter' },
          style: {
            borderRadius: '50%',
            background: '#5A34E1',
            padding: 0,
            border: 0,
            height: 32,
            width: 32,
            '& svg': {
              height: 20,
              width: 20,
              fill: '#ffffff'
            },
            '&:hover': {
              background: '#3e249d'
            },
            '&.Mui-disabled': {
              background: '#EEF2F7',
              '& svg': {
                fill: '#8892A7'
              }
            }
          }
        }
      ]
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 24,
          border: '2px solid #EEF2F7',
          padding: '24px 32px',
          '& .MuiCardHeader-root': {
            padding: 0,
            justifyContent: 'space-between',
            alignItems: 'center'
          },
          '& .MuiCardActions-root': {
            padding: 0,
            justifyContent: 'space-between',
            alignItems: 'center'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        elevation8: {
          boxShadow: 'none',
          border: '1px solid  #EEF2F7'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          fontFamily: 'Quicksand',
          fontSize: '13px',
          color: '#444E61',
          fontWeight: '700',
          lineHeight: '14px'
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 24,
          height: 16,
          padding: 0,
          display: 'flex'
        },
        switchBase: {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(8px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: '#5A34E1'
            }
          }
        },
        track: {
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor: 'rgba(0,0,0,.25)',
          boxSizing: 'border-box'
        },
        thumb: {
          width: 12,
          height: 12,
          borderRadius: 6
        }
      }
    },
    MuiFormControlLabel: {
      variants: [
        {
          props: { variant: 'switch' },
          style: {
            fontFamily: 'Inter',
            fontSize: '14px',
            color: '#7A8598',
            fontWeight: '400',
            lineHeight: '20px',
            '& .MuiTypography-root': {
              marginLeft: '8px'
            }
          }
        }
      ]
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          fontSize: '14px',
          color: '#444E61',
          fontWeight: '500',
          border: '2px solid  #EEF2F7',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Quicksand',
          fontSize: '13px',
          color: '#444E61',
          fontWeight: '500',
          lineHeight: '14px',
          padding: '8px 8px',
          margin: '0 8px',
          borderRadius: '8px',
          boxShadow: 'none'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          fontSize: '14px',
          color: '#444E61',
          fontWeight: '500',
          border: '2px solid  #EEF2F7',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          border: '2px solid #5A34E1',
          background: '#fff',
          height: 24,
          width: 24,
          '& svg': {
            fill: '#fff'
          },
          '&.Mui-checked': {
            '& svg ': {
              borderRadius: '4px',
              boxShadow: 'inset 0 0 0 4px #5A34E1'
            },
            '& svg path': {
              fill: '#5A34E1'
            }
          },
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: 12
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: '4px',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '20px',
          padding: '6px 8px',
          backgroundColor: '#444E61',
          '& .MuiTooltip-arrow': {
            color: '#444E61'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-endAdornment': {
            display: 'flex',
            gap: 8
          }
        }
      }
    },
    MuiListItemIcon: {
      variants: [
        {
          props: { variant: 'danger' },
          style: {
            color: '#F02959'
          }
        }
      ]
    },
    MuiListItemText: {
      variants: [
        {
          props: { variant: 'danger' },
          style: {
            color: '#F02959'
          }
        }
      ]
    }
  }
});

export default quizTheme;
