import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import EDCard from '../../../../atoms/EDCard/EDCard';

const NoSchoolFound = () => {
  return (
    <Grid item xs={12}>
      <EDCard elevation={0}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              spacing={2}
            >
              <Typography
                variant='h3'
                sx={{
                  py: 3
                }}
              >
                No school has been found. Please contact support.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </EDCard>
    </Grid>
  );
};

export default NoSchoolFound;
