import { fetchWithToken } from '../helpers/fetcher';

export const getStudentsWithUnits = async ({ courseGuid, unitGuid }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/teacher/${courseGuid}/student/unit${
      unitGuid ? `?unitGuid=${unitGuid}` : ''
    }`
  });

  return response.data;
};

export const getStudentsWithStandards = async ({
  courseGuid,
  standardGuid
}) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/teacher/${courseGuid}/student/standard${
      standardGuid ? `?thematicUnitGuid=${standardGuid}` : ''
    }`
  });

  return response.data;
};

export const getStudentDetails = async ({ courseGuid, studentGuid }) => {
  let response = await fetchWithToken({
    method: 'GET',
    path: `blueberry/teacher/${courseGuid}/student/${studentGuid}/detail`
  });

  return response.data;
};
