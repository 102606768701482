import { Button, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import EDCard from '../../../../../atoms/EDCard/EDCard';
import { createColumnHelper } from '@tanstack/react-table';
import CreateContractDialog from './dialogs/CreateContractDialog';
import TableDashboard from '../../../../../view/tableDashboard/TableDashboard';

const SchoolYear = ({
  schoolContracts,
  onCreateNewContract,
  totalSchoolContracts
}) => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name'
    }),
    columnHelper.accessor('school_year', {
      id: 'school_year',
      header: 'School Year'
    }),
    columnHelper.accessor('expiredAtDateString', {
      id: 'expiredAtDateString',
      header: 'End Date'
    }),
    columnHelper.accessor('remainingDays', {
      id: 'remainingDays',
      header: 'Remaining days'
    }),
    columnHelper.accessor('is_active', {
      id: 'is_active',
      header: 'Status'
    })
  ];
  const [isCreateContractOpen, setIsCreateContractOpen] = useState(false);

  const onClose = () => {
    setIsCreateContractOpen(false);
  };

  return (
    <Grid item xs={12}>
      <EDCard elevation={0}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              spacing={2}
            >
              <Typography variant='h2'>School year</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <TableDashboard
              disableFiltersAndSearch={true}
              tableData={schoolContracts.map((contract) => {
                return {
                  ...contract,
                  remainingDays:
                    Math.floor(
                      (new Date(contract.expired_at) - new Date()) /
                        (1000 * 60 * 60 * 24)
                    ) + ' days',
                  expiredAtDateString: Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  }).format(new Date(contract.expired_at))
                };
              })}
              tableColumns={columns}
              totalEntries={totalSchoolContracts}
              onClickOnCreate={() => setIsCreateContractOpen(true)}
              singleEntryLabel={'School year'}
              pluralEntryLabel={'School years'}
              isTableDataLoading={false}
              searchInputPlaceholder={'Search school years'}
            />
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </EDCard>
      <CreateContractDialog
        isOpen={isCreateContractOpen}
        onClose={onClose}
        onSubmit={(params) => {
          onCreateNewContract(params);
          onClose();
        }}
      />
    </Grid>
  );
};

export default SchoolYear;
