import { Alert, Grid, SpeedDial, SpeedDialIcon } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AlertDialogWithIcon from '../../../../atoms/AlertDialogWithIcon/AlertDialogWithIcon';
import Toast from '../../../../atoms/Toast/Toast';
import { fetchWithToken } from '../../../../utils/helpers/fetcher';
import UnitWithLessonsCard from './components/UnitsWithLessonsCard/UnitWithLessonsCard';
import AddLOsDialog from './dialogs/AddLOsDialog';
import CreateAndEditDialog from './dialogs/CreateAndEditDialog';
import DeleteDialog from './dialogs/DeleteDialog';

function reorderItem(list, oldIndex, newIndex) {
  if (oldIndex === newIndex) {
    return list; // No change needed
  }

  const itemToMove = list[oldIndex];
  const newList = [...list];

  if (oldIndex < newIndex) {
    // Move the item to a higher index
    newList.splice(newIndex + 1, 0, itemToMove);
    newList.splice(oldIndex, 1);
  } else {
    // Move the item to a lower index
    newList.splice(oldIndex, 1);
    newList.splice(newIndex, 0, itemToMove);
  }

  return newList;
}

const MAX_UNITS = 12;
const MAX_LESSONS = 16;
const MAX_LOS = 12;

const ProgramContent = ({
  program,
  onCreateUnit,
  onCreateLesson,
  onEditUnit,
  onEditLesson,
  onReorderUnit,
  onReorderLesson,
  onDeleteUnit,
  onDeleteLesson,
  onAddLOsToLesson,
  onRemoveLOsFromLesson,
  educationYears,
  programErrors,
  canEditProgram,
  programPreviewLanguage
}) => {
  const [isCreateAndEditDialogOpen, setIsCreateAndEditDialogOpen] =
    useState(false);
  const [typeOfCreateOrEdit, setTypeOfCreateOrEdit] = useState(false);
  const [entityToEdit, setEntityToEdit] = useState({
    guid: '',
    name: '',
    description: '',
    languages: []
  });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSelectingLOs, setIsSelectingLOs] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isMaxItemsDialogOpen, setIsMaxItemsDialogOpen] = useState(false);
  const [maxItemsDialogTitle, setMaxItemsDialogTitle] = useState('');
  const [maxItemsDialogDescription, setMaxItemsDialogDescription] =
    useState('');
  const [showPublishAlert, setShowPublishAlert] = useState(
    program?.status === 'PENDING'
  );
  const [showErrorsAlert, setShowErrorsAlert] = useState(
    programErrors?.nodesWithErrors?.length > 0
  );

  const [programLOsInUseGuids, setProgramLOsInUseGuids] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    setShowPublishAlert(program?.status === 'PENDING');
  }, [program]);

  useEffect(() => {
    setShowErrorsAlert(programErrors?.nodesWithErrors?.length > 0);
  }, [programErrors]);

  useEffect(() => {
    // get LOs used in the rest of the lessons
    if (!selectedLesson) return;
    const LOguids = program?.units
      .flatMap((item) =>
        item.lessons.map((lesson) =>
          lesson.guid === selectedLesson?.guid ? [] : lesson.nodes
        )
      )
      .flat()
      .map((node) => node.guid);

    setProgramLOsInUseGuids(LOguids);
  }, [selectedLesson]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        {program.units.map((unit) => (
          <UnitWithLessonsCard
            key={unit.guid}
            unit={unit}
            canEditProgram={canEditProgram}
            totalUnits={program?.units?.length}
            lessons={unit?.lessons}
            programErrors={programErrors}
            programStandard={program?.learningObjective?.guid}
            onAddLOs={({ lesson }) => {
              if (lesson.nodes.length < MAX_LOS) {
                setSelectedUnit(unit);
                setSelectedLesson(lesson);
                setIsSelectingLOs(true);
              } else {
                setMaxItemsDialogTitle(t('program_max_los_dialog_title'));
                setMaxItemsDialogDescription(
                  t('program_max_los_dialog_description')
                );
                setIsMaxItemsDialogOpen(true);
              }
            }}
            onCreateLesson={() => {
              if (unit.lessons.length < MAX_LESSONS) {
                setSelectedUnit(unit);
                setTypeOfCreateOrEdit('lesson');
                setIsCreateAndEditDialogOpen(true);
              } else {
                setMaxItemsDialogTitle(t('program_max_lessons_dialog_title'));
                setMaxItemsDialogDescription(
                  t('program_max_lessons_dialog_description')
                );
                setIsMaxItemsDialogOpen(true);
              }
            }}
            onEditLesson={async (lesson) => {
              const lessonAllData = await fetchWithToken({
                path: `/lessons/${lesson.guid}`
              });

              if (lessonAllData.status === 'success' && lessonAllData.data) {
                const _lesson = lessonAllData?.data;

                setSelectedUnit(unit);
                setTypeOfCreateOrEdit('lesson');
                setEntityToEdit({
                  guid: _lesson?.guid,
                  name: _lesson?.name,
                  description: _lesson?.description,
                  languages: _lesson?.languages
                });
                setIsCreateAndEditDialogOpen(true);
              }
            }}
            onEditUnit={async () => {
              const unitAllData = await fetchWithToken({
                path: `/units/${unit.guid}`
              });

              if (unitAllData.status === 'success' && unitAllData.data) {
                const _unit = unitAllData?.data;

                setSelectedUnit(_unit);
                setTypeOfCreateOrEdit('unit');
                setEntityToEdit({
                  guid: _unit?.guid,
                  name: _unit?.name,
                  description: _unit?.description,
                  languages: _unit?.languages
                });

                setIsCreateAndEditDialogOpen(true);
              }
            }}
            onReorderLesson={({ lesson, newOrder }) => {
              const newOrderArray = reorderItem(
                unit?.lessons?.map((item) => item.guid),
                lesson?.order - 1,
                newOrder - 1
              );
              onReorderLesson({
                lessonGuids: newOrderArray,
                unitGuid: unit.guid
              });
            }}
            onReorderUnit={({ newOrder }) => {
              const newOrderArray = reorderItem(
                program?.units?.map((item) => item.guid),
                unit?.order - 1,
                newOrder - 1
              );
              onReorderUnit({
                unitGuid: unit.guid,
                unitGuids: newOrderArray
              });
            }}
            onDeleteLesson={(lesson) => {
              setItemToDelete({
                type: 'lesson',
                item: { ...lesson, unitGuid: unit.guid }
              });
              setIsDeleteDialogOpen(true);
            }}
            onDeleteUnit={(unit) => {
              setItemToDelete({ type: 'unit', item: unit });
              setIsDeleteDialogOpen(true);
            }}
          />
        ))}
        {isCreateAndEditDialogOpen && (
          <CreateAndEditDialog
            isOpen={isCreateAndEditDialogOpen}
            guid={entityToEdit.guid}
            languages={entityToEdit.languages}
            type={typeOfCreateOrEdit}
            onClose={() => {
              setEntityToEdit({
                guid: '',
                name: '',
                description: '',
                languages: []
              });
              setIsCreateAndEditDialogOpen(false);
            }}
            onSubmit={(entity) => {
              setIsCreateAndEditDialogOpen(false);
              if (entity.guid) {
                if (typeOfCreateOrEdit === 'unit') {
                  onEditUnit({
                    name: entity.name,
                    unitGuid: entity.guid,
                    description: entity.description,
                    languages: entity.languages
                  });
                }
                if (typeOfCreateOrEdit === 'lesson') {
                  onEditLesson({
                    name: entity.name,
                    lessonGuid: entity.guid,
                    description: entity.description,
                    languages: entity.languages
                  });
                }
              } else {
                if (typeOfCreateOrEdit === 'unit') {
                  onCreateUnit({
                    name: entity.name,
                    description: entity.description,
                    languages: entity.languages
                  });
                }
                if (typeOfCreateOrEdit === 'lesson') {
                  onCreateLesson({
                    name: entity.name,
                    unitGuid: selectedUnit.guid,
                    description: entity.description,
                    languages: entity.languages
                  });
                }
              }

              setEntityToEdit({
                guid: '',
                name: '',
                description: '',
                languages: []
              });
            }}
          />
        )}
        {isSelectingLOs && (
          <AddLOsDialog
            isOpen={isSelectingLOs}
            existingLOs={selectedLesson.nodes}
            programLOsInUseGuids={programLOsInUseGuids}
            educationYears={educationYears}
            programStandard={program?.learningObjective?.guid}
            onClose={() => {
              setIsSelectingLOs(false);
            }}
            onAddLOs={(selectedLOs) => {
              setIsSelectingLOs(false);
              onAddLOsToLesson({
                lessonGuid: selectedLesson.guid,
                unitGuid: selectedUnit.guid,
                nodes: selectedLOs
              });
            }}
            onRemoveLOs={(selectedLOs) => {
              setIsSelectingLOs(false);
              onRemoveLOsFromLesson({
                lessonGuid: selectedLesson.guid,
                unitGuid: selectedUnit.guid,
                nodes: selectedLOs
              });
            }}
            programPreviewLanguage={programPreviewLanguage}
          />
        )}
        {isDeleteDialogOpen && (
          <DeleteDialog
            isOpen={isDeleteDialogOpen}
            onClose={() => {
              setItemToDelete(null);
              setIsDeleteDialogOpen(false);
            }}
            onConfirmDelete={() => {
              if (itemToDelete.type === 'unit') {
                onDeleteUnit({ unitGuid: itemToDelete.item.guid });
              } else {
                onDeleteLesson({
                  lessonGuid: itemToDelete.item.guid,
                  unitGuid: itemToDelete.item.unitGuid
                });
              }
              setItemToDelete(null);
              setIsDeleteDialogOpen(false);
            }}
          />
        )}
        <AlertDialogWithIcon
          isOpen={isMaxItemsDialogOpen}
          title={maxItemsDialogTitle}
          description={maxItemsDialogDescription}
          onConfirm={() => {
            setIsMaxItemsDialogOpen(false);
          }}
          onCancel={() => {
            setIsMaxItemsDialogOpen(false);
          }}
          cancelText={t('cancel')}
          confirmText={t('okay')}
        />
        {showPublishAlert && (
          <div
            className='toast'
            style={{ position: 'fixed', bottom: 80, left: 48 }}
          >
            <Alert
              onClose={() => setShowAlert(false)}
              severity={'warning'}
              variant='outlined'
              sx={{ width: '100%' }}
            >
              {t('program_editor_pending_publishing')}
            </Alert>
          </div>
        )}
        {showErrorsAlert && (
          <Toast
            handleShowToast={setShowErrorsAlert}
            type={'error'}
            text={
              <>
                {programErrors?.nodesWithoutSeeds?.length > 0 && (
                  <p>{t('program_editor_program_errors_seeds')}</p>
                )}
                {programErrors?.nodesWithoutKC?.length > 0 && (
                  <p>{t('program_editor_program_errors_kc')}</p>
                )}
                {programErrors?.nodesWithDependencyProblems?.length > 0 && (
                  <p>{t('program_editor_program_errors_kc')}</p>
                )}
                {programErrors?.lessonsWithoutLOs?.length > 0 && (
                  <p>{t('program_editor_program_errors_lessons_los')}</p>
                )}
              </>
            }
            autoHide={false}
          />
        )}
        {canEditProgram && (
          <SpeedDial
            ariaLabel='Create Unit'
            open={false}
            sx={{ position: 'fixed', bottom: 80, right: 32 }}
            icon={<SpeedDialIcon />}
            onClick={() => {
              if (program.units.length < MAX_UNITS) {
                setTypeOfCreateOrEdit('unit');
                setIsCreateAndEditDialogOpen(true);
              } else {
                setMaxItemsDialogTitle(t('program_max_units_dialog_title'));
                setMaxItemsDialogDescription(
                  t('program_max_units_dialog_description')
                );
                setIsMaxItemsDialogOpen(true);
              }
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ProgramContent;
