import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDButton from '../../../../atoms/EDButton/EDButton';
import EDInputWithLabel from '../../../../atoms/EDInput/EDInputWithLabel';
import EDLanguageFilter from '../../../../components/filters/EDLanguageFilter';
import EDLearningObjectiveFilter from '../../../../components/filters/EDLearningObjectiveFilter';
import EDPhaseFilter from '../../../../components/filters/EDPhaseFilter';
import EDResponsibleFilter from '../../../../components/filters/EDResponsibleFilter';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '1rem'
  },
  '& .MuiDialogContent-root': {
    paddingBottom: theme.spacing(5)
  },
  '& .MuiDialogActions-root': {
    backgroundColor: 'rgba(247, 249, 252, 0.5)',
    padding: '1rem 1.5rem'
  },
  '& .MuiTypography-h2': {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#414b5c'
  }
}));

const NewActivityDialog = ({ isOpen, onClose, onCreateActivity }) => {
  const { t } = useTranslation();

  const EMPTY_FORM = {
    name: null,
    responsible: null,
    nodeGuid: null,
    phase: null,
    langCode: null,
    status: 'to_do'
  };

  const [newActivityData, setNewActivityData] = useState(EMPTY_FORM);

  const [isDataValid, setIsDataValid] = useState(false);

  const checkValidData = () => {
    setIsDataValid(
      newActivityData.name &&
        newActivityData.responsible &&
        newActivityData.langCode &&
        newActivityData.nodeGuid &&
        newActivityData.phase
    );
  };

  useEffect(() => {
    checkValidData();
  }, [newActivityData]);

  useEffect(() => {
    // Reset form on popup close
    setNewActivityData(EMPTY_FORM);
  }, [onClose]);

  return (
    <StyledDialog open={isOpen} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>
        <Typography variant='h2'>{t('new_activity_title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <EDLearningObjectiveFilter
              multiple={false}
              showLabel
              required
              onChange={(inputValue) => {
                setNewActivityData({
                  ...newActivityData,
                  nodeGuid: inputValue?.length ? inputValue[0].id : '',
                  name: inputValue?.length ? inputValue[0].label : ''
                });
              }}
            />
          </Grid>
          <Grid item md={6}>
            <EDInputWithLabel
              label={t('name')}
              placeholder={t('name')}
              onChange={({ target: { value } }) => {
                console.log('value', value);
                setNewActivityData({
                  ...newActivityData,
                  name: value
                });
              }}
              value={newActivityData?.name}
            />
          </Grid>
          <Grid item md={6}>
            <EDResponsibleFilter
              multiple={false}
              showLabel
              onChange={(inputValue) => {
                setNewActivityData({
                  ...newActivityData,
                  responsible: inputValue
                });
              }}
            />
          </Grid>
          <Grid item md={6}>
            <EDPhaseFilter
              showLabel
              required
              onChange={(inputValue) => {
                setNewActivityData({
                  ...newActivityData,
                  phase: inputValue
                });
              }}
            />
          </Grid>
          <Grid item md={6}>
            <EDLanguageFilter
              showLabel={true}
              required
              onChange={(inputValue) => {
                setNewActivityData({
                  ...newActivityData,
                  langCode: inputValue
                });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <EDButton
          onClick={onClose}
          color='primary'
          variant='text'
          disableElevation
        >
          {t('cancel')}
        </EDButton>
        <EDButton
          onClick={() => onCreateActivity(newActivityData)}
          color='primary'
          variant='contained'
          disableElevation
          disabled={!isDataValid}
        >
          {t('save')}
        </EDButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default NewActivityDialog;
