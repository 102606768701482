import React from 'react';
import { useTranslation } from 'react-i18next';
import PageWithHeader from '../../../layouts/pageWithHeader/PageWithHeader';
import TableDashboard from '../../../view/tableDashboard/TableDashboard';
import usePrograms from './usePrograms';
import EDDashboardTabs from '../../../components/EDDashboardTabs/EDDashboardTabs';

const Programs = () => {
  const { total, offset, columns, programs, pageSize, isLoading } =
    usePrograms();
  const { t } = useTranslation();

  return (
    <PageWithHeader
      left={
        <EDDashboardTabs
          tabs={[
            { name: 'Courses', to: '/courses', key: 'courses' },
            { name: 'Programs', to: '/programs', key: 'programs' }
          ]}
          currentTab={'programs'}
        />
      }
    >
      <TableDashboard
        disableFiltersAndSearch
        filters={[
          {
            id: 'status',
            key: 'status',
            title: 'Status',
            type: 'radio',
            options: [
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' }
            ]
          }
        ]}
        onSearch={() => {}}
        onFilter={() => {}}
        tableData={programs}
        // onRowClick={(e) => {
        //   console.log(e);
        // }}
        tableColumns={columns}
        totalEntries={total}
        // onClickOnCreate={() => {}}
        singleEntryLabel={t('program')}
        pluralEntryLabel={t('programs')}
        searchInputPlaceholder={t('search_programs')}
        numberOfFilters={0}
        isTableDataLoading={isLoading}
      />
    </PageWithHeader>
  );
};

export default Programs;
