import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AnalysisSegment } from '../../../../utils/analysis';
import { CourseContext } from '../../../../utils/contexts/CourseContext';
import { deleteQuiz, getQuizzes } from '../../../../utils/crud/quiz';
import QuizToast from '../viewQuiz/components/quizToast/QuizToast';

const useListQuizzes = () => {
  const { selectedCourse } = useContext(CourseContext);
  const [prevCourseGuid, setPreviousCourseGuid] = useState({ current: null });
  const [isLoading, setIsLoading] = useState(true);
  const [quizzes, setQuizzes] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeletingQuiz, setIsDeletingQuiz] = useState(false);
  const [selectedQuizGuid, setSelectedQuizGuid] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [isEditNameDialogOpen, setIsEditNameDialogOpen] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);

  const toastSettings = {
    position: 'bottom-left',
    autoClose: 3000,
    hideProgressBar: true
  };

  const loadQuizzes = async (isStart = true) => {
    const response =
      (await getQuizzes({ courseGuid: selectedCourse.guid })) || [];
    const tempListOfQuizzes =
      response?.map((quiz) => ({
        guid: quiz?.guid,
        name: quiz?.name,
        lang: quiz?.lang?.id,
        numberOfActivities: quiz?.number_of_questions
      })) || [];

    setQuizzes(tempListOfQuizzes);
    if (isStart) {
      let totalGames = response?.reduce(
        (total, obj) => total + obj.number_of_games,
        0
      );
      AnalysisSegment.sendSegmentTrackEvent(
        AnalysisSegment.SEGMENT_EVENTS.Practice_Page_Viewed,
        {
          class: selectedCourse?.guid,
          program_id: selectedCourse?.program_guid,
          practices_created: response?.length || 0,
          practices_played: totalGames
        }
      );
    }
    setIsLoading(false);
  };

  const onDeleteQuiz = async (quizGuid) => {
    setSelectedQuizGuid(quizGuid);
    setIsDeleteDialogOpen(true);
  };

  const onConfirmDeleteQuiz = async () => {
    setIsDeleteDialogOpen(false);
    setIsDeletingQuiz(true);
    if (!selectedQuizGuid) return;
    await deleteQuiz({ quizGuid: selectedQuizGuid });
    await loadQuizzes(false);

    setIsDeletingQuiz(false);
    toast(<QuizToast variant='quiz-deleted-successfully' />, toastSettings);
  };

  const onCancelDeleteQuiz = () => {
    setIsDeleteDialogOpen(false);
    setSelectedQuizGuid(null);
    setIsDeletingQuiz(false);
  };

  // This function checks in localStorage for the flag that indicates that a quiz has been deleted from the quiz page.
  // We use this method to confirm to the user that the quiz has been deleted successfully. Silent success messages are not user-friendly.
  const checkIfDeletedQuizFlagExists = () => {
    const deletedQuizFlag = localStorage.getItem('deletedQuizFlag');
    if (deletedQuizFlag) {
      toast(<QuizToast variant='quiz-deleted-successfully' />, toastSettings);
      localStorage.removeItem('deletedQuizFlag');
    }
  };

  useEffect(() => {
    if (!selectedCourse || prevCourseGuid === selectedCourse?.guid) return;
    setPreviousCourseGuid(selectedCourse.guid);
    checkIfDeletedQuizFlagExists();
    loadQuizzes(true);
  }, [selectedCourse]);

  const onConfirmEditName = async ({ name }) => {
    setIsEditingName(true);

    loadQuizzes();

    setIsEditingName(false);
    setIsEditNameDialogOpen(false);
    setSelectedQuizGuid(null);
    setSelectedQuiz(null);
  };

  const onEditName = ({ quizGuid }) => {
    setIsEditNameDialogOpen(true);
    setSelectedQuizGuid(quizGuid);
    setSelectedQuiz(quizzes.find((quiz) => quiz.guid === quizGuid));
  };

  const onCancelEditName = () => {
    setIsEditNameDialogOpen(false);
  };

  return {
    quizzes,
    isLoading,
    selectedQuiz,
    isEditingName,
    isDeletingQuiz,
    selectedQuizGuid,
    isDeleteDialogOpen,
    isEditNameDialogOpen,
    onEditName,
    onDeleteQuiz,
    onConfirmEditName,
    onCancelEditName,
    onConfirmDeleteQuiz,
    onCancelDeleteQuiz
  };
};

export default useListQuizzes;
