import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import EDButton from '../../../../atoms/EDButton/EDButton';
import EDInputWithLabel from '../../../../atoms/EDInput/EDInputWithLabel';
import { useTranslation } from 'react-i18next';
import EDKnowledgeComponentFilter from '../../../../components/filters/EDKnowledgeComponentFilter';
import EDDepartmentFilter from '../../../../components/filters/EDDepartmentFilter';
import EDEducationLevelFilter from '../../../../components/filters/EDEducationLevelFilter';
import EDDialog from '../../../../atoms/EDDialog/EDDialog';

const NewKnowledgeComponentDialog = ({ isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const [newKC, setNewKC] = useState({
    name: '',
    description: '',
    departmentGuid: '',
    internalEducationYearGuid: '',
    linkFromGuid: [],
    type: 'concept'
  });

  const [canSend, setCanSend] = useState(false);

  useEffect(() => {
    setCanSend(newKC.name && newKC.departmentGuid);
  }, [newKC]);

  useEffect(() => {
    if (!isOpen) {
      setNewKC({
        name: '',
        description: '',
        departmentGuid: '',
        internalEducationYearGuid: '',
        linkFromGuid: [],
        type: 'concept'
      });
    }
  }, [isOpen]);

  return (
    <EDDialog open={isOpen} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>
        <Typography variant='h2'>{t('create_kc_dialog_title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <EDInputWithLabel
              label={t('name')}
              placeholder={t('name')}
              required
              onChange={(e) => setNewKC({ ...newKC, name: e.target.value })}
            />
          </Grid>
          <Grid item md={6}>
            <EDKnowledgeComponentFilter
              multiple={true}
              showLabel
              onChange={(kcs) => {
                setNewKC({ ...newKC, linkFromGuid: kcs.map((kc) => kc.guid) });
              }}
            />
          </Grid>
          <Grid item md={6}>
            <EDDepartmentFilter
              multiple={false}
              showLabel
              required
              onChange={(value) => {
                setNewKC({ ...newKC, departmentGuid: value.value });
              }}
            />
          </Grid>
          <Grid item md={6}>
            <EDEducationLevelFilter
              multiple={false}
              showLabel
              onChange={(value) => {
                setNewKC({ ...newKC, internalEducationYearGuid: value });
              }}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <EDInputWithLabel
              label={t('kc_description_label')}
              placeholder={t('kc_description_placeholder')}
              multiline
              required
              rows={3}
              onChange={(e) =>
                setNewKC({ ...newKC, description: e.target.value })
              }
            />
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <EDButton
          onClick={onClose}
          color='primary'
          variant='text'
          disableElevation
        >
          {t('Cancel')}
        </EDButton>
        <EDButton
          onClick={() => {
            onSubmit(newKC);
          }}
          color='primary'
          variant='contained'
          disableElevation
          disabled={!canSend}
        >
          {t('Save')}
        </EDButton>
      </DialogActions>
    </EDDialog>
  );
};

export default NewKnowledgeComponentDialog;
