import React, { useEffect } from 'react';
import FullPageLayout from '../../../../layouts/dashboardFullPage/FullPageLayout';
import BackButton from '../../../../atoms/BackButton/BackButton';
import EDTabs from '../../../../components/EDTabs/EDTabs';
import EDCard from '../../../../atoms/EDCard/EDCard';
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@mui/material';
import useEditUser from './useEditUser';
import UserDetails from './tabs/UserDetails';
import UserPassword from './tabs/UserPassword';
import UserLicenses from './tabs/UserLicenses';
import Toast from '../../../../atoms/Toast/Toast';
import EDButton from '../../../../atoms/EDButton/EDButton';
import { OpenInNew } from '@mui/icons-material';

const EditUser = () => {
  const {
    user,
    groups,
    schools,
    licenses,
    isLoading,
    currentTab,
    showToast,
    isSaving,
    toastMessage,
    isLoginAsUserLoading,
    onSaveUser,
    onChangeTab,
    setShowToast,
    onUpdateUser,
    onCreateNewUser,
    onCreateNewGroup,
    onCreateNewContract,
    onLoginAsUser
  } = useEditUser();

  return (
    <FullPageLayout
      left={<BackButton />}
      title={<Typography variant='h2'>Edit User</Typography>}
      right={
        <EDButton
          onClick={onLoginAsUser}
          variant='outlined'
          color='inherit'
          disabled={isLoginAsUserLoading || isLoading}
          disableElevation
          endIcon={!isLoginAsUserLoading && !isLoading && <OpenInNew />}
        >
          {isLoading || isLoginAsUserLoading ? (
            <CircularProgress color='inherit' size={20} />
          ) : (
            'Login as user'
          )}
        </EDButton>
      }
      footerRight={
        <EDButton
          onClick={onSaveUser}
          variant='contained'
          disabled={isSaving || isLoading}
          disableElevation
        >
          {isSaving ? 'Saving...' : 'Save'}
        </EDButton>
      }
    >
      <Container maxWidth='xl'>
        <Grid container spacing={3} mb={20}>
          <Grid item xs={12}>
            <EDTabs
              tabs={[
                { name: 'Details', key: 'details' },
                { name: 'Password', key: 'password' },
                { name: 'Licenses', key: 'licenses' }
              ]}
              currentTab={currentTab}
              onClick={onChangeTab}
            />
          </Grid>

          {isLoading && (
            <Grid item xs={12}>
              <EDCard>
                <div>Loading...</div>
              </EDCard>
            </Grid>
          )}
          {!isLoading && currentTab === 'details' && (
            <UserDetails
              {...user}
              isDemoUser={user?.is_demo}
              schools={schools}
              groups={groups}
              jsonSettings={user?.json_settings}
              onUpdateUser={onUpdateUser}
            />
          )}
          {!isLoading && currentTab === 'password' && (
            <UserPassword onUpdatePassword={() => {}} />
          )}
          {!isLoading && currentTab === 'licenses' && (
            <UserLicenses
              licenses={licenses}
              onRedeemLicense={onCreateNewGroup}
            />
          )}
        </Grid>

        {showToast && (
          <Toast
            handleShowToast={setShowToast}
            type={toastMessage.type}
            text={toastMessage.text}
          />
        )}
      </Container>
    </FullPageLayout>
  );
};

export default EditUser;
