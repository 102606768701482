import { Card, styled } from '@mui/material';

const EDCard = styled(Card)(({ theme }) => ({
  '&.MuiCard-root': {
    borderRadius: theme.shape.borderRadius * 3,
    padding: '0.625rem 1rem'
  },
  '&.MuiCard-text': {
    color: theme.palette.grey[500],
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

export default EDCard;
