import { flattenDeep } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CourseContext } from '../../../../utils/contexts/CourseContext';
import {
  createQuizContent,
  getPrograms,
  getQuizzes,
  getStandards,
  getUnitsAndLessonsFromProgram
} from '../../../../utils/crud/quiz';
import { localStorageKeys } from '../../../../utils/localStorageKeys';
import QuizToast from '../viewQuiz/components/quizToast/QuizToast';

const useCreateQuiz = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedCourse, availableCourses } = useContext(CourseContext);
  const bbUserLanguage = localStorage.getItem(localStorageKeys.language);
  const defaultLanguage =
    !bbUserLanguage || bbUserLanguage === 'null' ? 'en' : bbUserLanguage;

  const [isLoading, setIsLoading] = useState(true);
  const [isReadyToLoadUnits, setIsReadyToLoadUnits] = useState(false);
  const [totalUnits, setTotalUnits] = useState(0);
  const [totalLessons, setTotalLessons] = useState(0);
  const [unitsAndLessons, setUnitsAndLessons] = useState([]);
  const [educationYearName, setEducationYearName] = useState('');
  const [educationYear, setEducationYear] = useState('');
  const [lessonsToList, setLessonsToList] = useState([]);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState({});
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [quizName, setQuizName] = useState('');
  const [isCreatingQuiz, setIsCreatingQuiz] = useState(false);
  const [canCreateQuiz, setCanCreateQuiz] = useState(false);
  const [quizLanguage, setQuizLanguage] = useState(defaultLanguage);
  const [standards, setStandards] = useState([]);
  const [standardsAll, setStandardsAll] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [standardsByProgram, setStandardsByProgram] = useState(null);

  const toastSettings = {
    position: 'bottom-left',
    autoClose: 3000,
    hideProgressBar: true,
    icon: false
  };

  const parseUnitsAndLessons = ({ data, listOfStandards }) => {
    return data.map((unit) => ({
      guid: unit.guid,
      name: unit.section,
      numberOfActivities: unit.number_of_seeds,
      lessons: unit.items
        .filter((lesson) => lesson.number_of_seeds > 0)
        .map((lesson) => ({
          guid: lesson.lesson_guid,
          isSelected: false,
          unitName: unit.section,
          unitGuid: unit.guid,
          name: lesson.lesson_name,
          numberOfActivities: lesson.number_of_seeds,
          educationYear: selectedProgram?.education_year?.name,
          standards: lesson.standars
            .filter((item) => item !== 'CC' && item.indexOf('CCSS') === -1) //temporary manual filter for CC codes
            .map((standard) => {
              const standardObj = listOfStandards.find(
                (std) => std?.guid === standard
              );

              return {
                guid: standardObj?.guid || standard,
                name: standardObj?.name || standard,
                description: standardObj?.skills || standard
              };
            })
        }))
    }));
  };

  const parseEducationYearName = () => {
    if (!selectedCourse) return '';
    const educationYear = selectedProgram?.education_year?.name;
    const educationLevel = selectedProgram?.education_level?.name;
    return `${educationYear} ${educationLevel}`;
  };

  const generateNextQuizName = (quizNames) => {
    const nameSet = new Set(quizNames); // Create a Set for quick lookup
    let nextNumber = 1; // Start with "Práctica 1"

    while (nameSet.has(`${t('Quiz_singular')} ${nextNumber}`)) {
      nextNumber++; // Increment if the name exists
    }

    return `${t('Quiz_singular')} ${nextNumber}`; // Return the first non-existent name
  };

  const loadQuizzes = async () => {
    // Load quizzes
    if (!selectedCourse) return;
    let quizzes = await getQuizzes({
      courseGuid: selectedCourse.guid
    });

    const quizzesNames = quizzes.map((quiz) => quiz.name);

    setQuizName(generateNextQuizName(quizzesNames));
  };

  const loadPrograms = async () => {
    // Get available programs / education years
    const responsePrograms = await getPrograms();
    setPrograms(responsePrograms?.programs);

    // Set program if not selected
    if (!selectedProgram) setSelectedProgram(responsePrograms?.programs[0]);
  };

  const loadStandards = async () => {
    const responseOfStandards = await getStandards();
    let listOfStandards = responseOfStandards.standars;

    const flattenChildren = (item) => {
      if (item.children) {
        return item.children.map((child) => {
          return flattenChildren(child);
        });
      } else {
        return item;
      }
    };
    listOfStandards = flattenDeep(
      Object.keys(listOfStandards).map((key) =>
        flattenChildren(listOfStandards[key])
      )
    );
    setStandardsAll(listOfStandards);
  };

  const loadUnits = async () => {
    setIsLoading(true);
    setSelectedUnit({});
    setLessonsToList([]);
    // setSelectedLessons([]);

    let listOfStandards = standards;

    const selectedProgramGuid =
      selectedProgram?.program_guid || programs[0]?.program_guid;

    if (!selectedProgramGuid) {
      setIsLoading(false);
      toast.error(<QuizToast variant='quiz-generic-error' />, toastSettings);
      return;
    }

    const response = await getUnitsAndLessonsFromProgram({
      programGuid: selectedProgramGuid,
      langId: quizLanguage
    });

    // Se obtienen los standars de las unidades del curso
    const standardsByCourse = response?.items?.reduce((acc, currentItem) => {
      const currentItemStandards = currentItem?.items?.map(
        (item) => item.standars
      );
      return acc.concat(currentItemStandards);
    }, []);

    // Para aplanar el array de arrays
    const flatStandardsByCourse = [].concat.apply([], standardsByCourse);

    const uniqueFlatStandardsByCourse = [...new Set(flatStandardsByCourse)];

    /*const uniqueFlatStandardsSet = new Set(
      uniqueFlatStandardsByCourse.filter(
        (item) => item !== 'CC' && item.indexOf('CCSS') === -1
      )
    ); // temporary remove CC and CCSS standards
    let standardsEnd = uniqueFlatStandardsSet;
    if(!isTeacherBrasil){
      let uniqueFlatStandardsArray = Array.from(uniqueFlatStandardsSet);
      standardsEnd = new Set(uniqueFlatStandardsArray.filter((item) => !item.startsWith('EF') && !item.startsWith('BNCC')));
    }*/

    // Se obtienen los standars de los programas
    const courses = availableCourses;
    let standardsByProgramApi = null;
    if (!Array.isArray(courses)) {
      standardsByProgramApi = courses?.bb_program_standard;
    } else {
      for (const courseIndex in courses) {
        standardsByProgramApi = courses[courseIndex]?.bb_program_standard;
      }
    }
    setStandardsByProgram(standardsByProgramApi);

    let uniqueFlatStandardsArray = Array.from(uniqueFlatStandardsByCourse);
    let standardsEnd = new Set(uniqueFlatStandardsArray);
    if (standardsByProgramApi) {
      switch (standardsByProgramApi) {
        case 'BNCC':
          standardsEnd = new Set(
            uniqueFlatStandardsArray.filter(
              (item) => item.startsWith('EF') || item.startsWith('BNCC')
            )
          );
          break;

        case 'CC':
          standardsEnd = new Set(
            uniqueFlatStandardsArray.filter(
              (item) => item.startsWith('CC') || item.startsWith('CCSS')
            )
          );
          break;

        default:
          standardsEnd = new Set(uniqueFlatStandardsArray);
          break;
      }
    }

    // Del listado total de Standards, filtramos y nos quedamos solo con los de la lección
    const filteredStandards = standardsAll.filter((standard) => {
      return standardsEnd.has(standard.guid);
    });

    setStandards(filteredStandards);

    console.log('>> unitsAndLessons response', response);

    const parsedUnitsAndLessons = parseUnitsAndLessons({
      data: response.items,
      listOfStandards
    });

    setUnitsAndLessons(parsedUnitsAndLessons);
    setTotalUnits(response.items.length);
    setTotalLessons(
      response.items.reduce((acc, unit) => acc + unit.items.length, 0)
    );

    if (response.items.length > 0) {
      const firstUnit = parsedUnitsAndLessons[0];
      setSelectedUnit(firstUnit); // Set the first unit as default
      setLessonsToList(firstUnit.lessons);
    }

    setIsLoading(false);
  };

  const onSelectUnit = (unit) => {
    setLessonsToList(unit.lessons);
    setSelectedUnit(unit);
  };

  const onFilterAndSearch = ({ searchText, standardGuid }) => {
    if (!searchText && !standardGuid) {
      setLessonsToList(selectedUnit.lessons);
      return;
    }
    const lessons = unitsAndLessons.reduce((prev, unit) => {
      let filteredLessons = unit.lessons.filter(
        (lesson) => lesson.numberOfActivities > 0
      );

      if (searchText) {
        // normalize text and search
        const normalizedSearchText = searchText
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');

        const normalizedLessonNames = filteredLessons.map((lesson) =>
          lesson?.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        );

        filteredLessons = filteredLessons.filter((lesson, index) =>
          normalizedLessonNames[index]
            .toLowerCase()
            .includes(normalizedSearchText.toLowerCase())
        );
      }

      if (standardGuid) {
        filteredLessons = filteredLessons.filter((lesson) =>
          lesson.standards.some((standard) => standard.guid === standardGuid)
        );
      }
      return [...prev, ...filteredLessons];
    }, []);

    setLessonsToList(lessons);
  };

  const onSelectLesson = (lessonGuid) => {
    const lesson = lessonsToList.find((lesson) => lesson.guid === lessonGuid);
    const isSelected = lesson.isSelected;
    const updatedLessons = lessonsToList.map((lesson) => {
      if (lesson.guid === lessonGuid) {
        return {
          ...lesson,
          isSelected: !isSelected
        };
      }
      return lesson;
    });

    setLessonsToList(updatedLessons);

    if (isSelected) {
      setSelectedLessons((prev) =>
        prev.filter((selectedLesson) => selectedLesson.guid !== lessonGuid)
      );
    } else {
      setSelectedLessons((prev) => [...prev, lesson]);
    }
  };

  const onSelectAllLessons = () => {
    // if one is missing Add all the lessonsToList to existing selectedLessons and mark isSelected
    // Otherwise remove the items of lessonsToList from selectedLessons and keep the others
    const isSelected = lessonsToList.reduce((prev, curr) => {
      return prev && curr.isSelected;
    }, true);

    if (isSelected) {
      const updatedLessons = selectedLessons.filter((selectedLesson) => {
        return !lessonsToList.some(
          (lessonToList) => lessonToList.guid === selectedLesson.guid
        );
      });
      setSelectedLessons(updatedLessons);
      const updatedLessonsToList = lessonsToList.map((lesson) => {
        return {
          ...lesson,
          isSelected: false
        };
      });
      setLessonsToList(updatedLessonsToList);
    }

    if (!isSelected) {
      const updatedLessons = [
        ...selectedLessons,
        ...lessonsToList.filter(
          (lessonToList) =>
            !selectedLessons.some(
              (selectedLesson) => selectedLesson.guid === lessonToList.guid
            )
        )
      ];
      setSelectedLessons(updatedLessons);
      const updatedLessonsToList = lessonsToList.map((lesson) => {
        return {
          ...lesson,
          isSelected: true
        };
      });
      setLessonsToList(updatedLessonsToList);
    }
  };

  const onRemoveLesson = (lessonGuid) => {
    const updatedLessons = selectedLessons.filter(
      (selectedLesson) => selectedLesson.guid !== lessonGuid
    );
    setSelectedLessons(updatedLessons);
    const updatedLessonsToList = lessonsToList.map((lesson) => {
      if (lesson.guid === lessonGuid) {
        return {
          ...lesson,
          isSelected: false
        };
      }
      return lesson;
    });
    setLessonsToList(updatedLessonsToList);
  };

  const onRemoveAllLessons = () => {
    setSelectedLessons([]);

    const updatedLessonsToList = lessonsToList.map((lesson) => {
      return {
        ...lesson,
        isSelected: false
      };
    });

    setLessonsToList(updatedLessonsToList);
  };

  const onCreateQuiz = async () => {
    if (selectedLessons.length === 0) {
      toast.error(
        <QuizToast variant='quiz-no-lessons-selected' />,
        toastSettings
      );
    }

    if (numberOfQuestions === 0 || numberOfQuestions < selectedLessons.length) {
      toast.error(
        <QuizToast variant='quiz-no-questions-selected' />,
        toastSettings
      );
    }

    if (quizName === '') {
      toast.error(<QuizToast variant='quiz-no-name' />, toastSettings);
    }

    if (!canCreateQuiz) return;

    setIsCreatingQuiz(true);
    let response = await createQuizContent({
      courseGuid: selectedCourse.guid,
      lessonGuids: selectedLessons.map((lesson) => lesson.guid),
      langId: quizLanguage,
      numberOfQuestions,
      quizName
    });
    navigate(`/quizzes/${response.guid}`);
    setIsCreatingQuiz(true);
  };

  const onChangeNumberOfQuestions = (number) => {
    setNumberOfQuestions(number);
  };

  const onChangeQuizName = (name) => {
    setQuizName(name);
  };

  const onChangeLanguage = (lang) => {
    setQuizLanguage(lang);
  };

  const onChangeProgram = (program) => {
    setSelectedProgram(program);
  };

  const initialize = async () => {
    await loadPrograms();
    await loadStandards();
    await loadQuizzes();
    setIsReadyToLoadUnits(true);
  };

  useEffect(() => {
    if (!selectedCourse) return;
    initialize();
  }, [selectedCourse]);

  useEffect(() => {
    if (!isReadyToLoadUnits) return;
    loadUnits();
    setEducationYearName(parseEducationYearName());
    setEducationYear(selectedProgram?.education_year?.name);
  }, [selectedProgram, quizLanguage, isReadyToLoadUnits]);

  useEffect(() => {
    // Quiz name is required
    // (number of questions > subtemas) && greater 0
    setCanCreateQuiz(
      selectedLessons.length > 0 &&
        quizName.length > 0 &&
        numberOfQuestions > 0 &&
        numberOfQuestions >= selectedLessons.length
    );
  }, [selectedLessons, quizName, numberOfQuestions]);

  useEffect(() => {
    // update isSelected so we keep the info between units/years

    if (lessonsToList && lessonsToList.length > 0) {
      const selectedGuids = selectedLessons.map((lesson) => lesson.guid);
      const updatedLessonsToList = lessonsToList.map((lesson) => ({
        ...lesson,
        isSelected: selectedGuids.includes(lesson.guid)
      }));

      setLessonsToList(updatedLessonsToList);
    }
  }, [lessonsToList]);

  return {
    quizName,
    isLoading,
    standards,
    totalUnits,
    totalLessons,
    selectedUnit,
    lessonsToList,
    canCreateQuiz,
    selectedCourse,
    isCreatingQuiz,
    unitsAndLessons,
    selectedLessons,
    educationYearName,
    educationYear,
    setQuizName,
    onCreateQuiz,
    onSelectUnit,
    onRemoveLesson,
    onRemoveAllLessons,
    onSelectLesson,
    onChangeQuizName,
    onChangeLanguage,
    onFilterAndSearch,
    onSelectAllLessons,
    onChangeNumberOfQuestions,
    programs,
    onChangeProgram,
    selectedProgram,
    standardsByProgram
  };
};

export default useCreateQuiz;
