import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import EDCard from '../../../../atoms/EDCard/EDCard';
import TableDashboard from '../../../../view/tableDashboard/TableDashboard';
import { useTranslation } from 'react-i18next';
import PreviewActivityDialog from '../../activitiesManager/components/PreviewActivityDialog';

const KnowledgeComponentLOs = ({ columns, data, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} justifyContent='center'>
        <Grid item xs={12}>
          <EDCard>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h2'>
                  {t('learning_outcomes_tab_title')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableDashboard
                  tableColumns={columns}
                  tableData={data}
                  disableFiltersAndSearch
                  totalEntries={data.length}
                  singleEntryLabel={t('learning_outcomes_tab_title')}
                  pluralEntryLabel={t('learning_outcomes_tab_title')}
                  isTableDataLoading={isLoading}
                  onRowClick={(row) => {
                    window.open(
                      window.location.origin + `/learning-outcomes/${row.guid}`,
                      '_blank'
                    );
                  }}
                />
              </Grid>
            </Grid>
          </EDCard>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KnowledgeComponentLOs;
