import React from 'react';
import { CircularProgress } from '@mui/material';
import './EDCircularLoading.scss';

const EDCircularLoading = ({ fullScreen = false, position }) => {
  return (
    <div
      className={`ed-circular-loading ${
        fullScreen
          ? 'ed-circular-loading--fullScreen'
          : 'ed-circular-loading--block'
      } ${
        position === 'absolute'
          ? 'ed-circular-loading--absolute'
          : 'ed-circular-loading--relative'
      }`}
    >
      <CircularProgress />
    </div>
  );
};

export default EDCircularLoading;
