import React from 'react';
import DefaultAvatar from '../../assets/default_avatar.png';

import './playerCard.scss';

const PlayerCard = ({ name, avatar, isConnected, points, size }) => {
  return (
    <div
      className={`player-card ${
        isConnected ? 'player-card--enabled' : 'player-card--disabled'
      } player-card--${size || 'large'}`}
    >
      <div className='player-card__avatar'>
        <img src={avatar || DefaultAvatar} alt={name} />
      </div>
      <div className='player-card__name'>{name}</div>
      {points?.toString() && (
        <div className='player-card__points'>{points}</div>
      )}
    </div>
  );
};

export default PlayerCard;
