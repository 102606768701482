import { Alert, Snackbar } from '@mui/material';
import { useState } from 'react';
import './Toast.scss';

const Toast = ({
  handleShowToast,
  type = 'info',
  text = 'This is a message!',
  autoHide = true
}) => {
  // Possible types -> [error, warning, info, success];
  const TOAST_DURATION_MS = 4000;
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway' && autoHide) {
      return;
    }

    setOpen(false);
    handleShowToast(false);
  };
  return (
    <div className='toast'>
      <Snackbar
        open={open}
        ClickAwayListenerProps={autoHide ? {} : { onClickAway: () => null }}
        autoHideDuration={autoHide ? TOAST_DURATION_MS : null}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Alert
          onClose={handleClose}
          severity={type}
          variant='outlined'
          sx={{ width: '100%' }}
        >
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Toast;
