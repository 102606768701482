import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './SavingTag.scss';

const SavingTag = ({ handleShowSavingTag }) => {
  const { t } = useTranslation();
  const [blink, setBlink] = useState(false);

  const BLINK_DURATION_MS = 500;
  const MESSAGE_DURATION_MS = 2250;

  useEffect(() => {
    const interval = setInterval(() => {
      setBlink((prevBlink) => !prevBlink);
    }, BLINK_DURATION_MS);

    setTimeout(() => {
      handleShowSavingTag(false);
    }, MESSAGE_DURATION_MS);

    return () => {
      clearInterval(interval);
      clearTimeout();
    };
  }, []);

  return (
    <div className='saving-tag'>
      <CircularProgress size={18} />
      <div className={`saving-tag__text ${blink ? 'blink' : ''}`}>
        {t('saving')}
      </div>
    </div>
  );
};

export default SavingTag;
