import CheckIcon from '@mui/icons-material/Check';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ViewActivity from '../viewActivity/ViewActivity';
import './DetailLoDialog.scss';
import useDetailLoDialog from './useDetailLoDialog';

const DetailLoDialog = ({
  isOpen = false,
  onClose = () => {},
  lo,
  courseGuid,
  userGuid
}) => {
  const { t } = useTranslation();

  const { isLoading, reportDetail, displayedAttempts } = useDetailLoDialog(
    t,
    courseGuid,
    userGuid,
    lo
  );

  if (isLoading) {
    return (
      <div className='reports-container'>
        <div className='reports-container-loading'>
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      fullWidth
      maxWidth='lg'
    >
      <DialogTitle>
        <Stack spacing={3}>
          <Typography variant='h5' className='report-user-detail-text'>
            {lo.name} -{' '}
            <span>
              {lo?.unit?.name} - {lo?.lesson?.name}
            </span>
          </Typography>
          <div className='report-user-detail-header'>
            <Tooltip title={t('reports_message_phase')}>
              <table>
                <tbody>
                  <tr>
                    {reportDetail?.lo?.have_identificar === 1 && (
                      <td
                        className={`report-user-detail-header-identificar ${
                          lo?.phase === 'identificar' &&
                          !lo?.insights?.isMastered &&
                          'report-user-detail-header-phase__current'
                        }`}
                      >
                        {(lo?.phase === 'evocar' ||
                          lo?.phase === 'aplicar' ||
                          (lo?.phase === 'identificar' &&
                            lo?.insights?.isMastered)) && (
                          <CheckIcon className='report-user-detail-header-phase__completed' />
                        )}
                        {t('reports_phase_identify')}
                      </td>
                    )}
                    {reportDetail?.lo?.have_evocar === 1 && (
                      <td
                        className={`report-user-detail-header-evocar ${
                          lo?.phase === 'evocar' &&
                          !lo?.insights?.isMastered &&
                          'report-user-detail-header-phase__current'
                        }`}
                      >
                        {(lo?.phase === 'aplicar' ||
                          (lo?.phase === 'evocar' &&
                            lo?.insights?.isMastered)) && (
                          <CheckIcon className='report-user-detail-header-phase__completed' />
                        )}
                        {t('reports_phase_evoke')}
                      </td>
                    )}
                    {reportDetail?.lo?.have_aplicar === 1 && (
                      <td
                        className={`report-user-detail-header-aplicar ${
                          lo?.phase === 'aplicar' &&
                          !lo?.insights?.isMastered &&
                          'report-user-detail-header-phase__current'
                        }`}
                      >
                        {lo?.phase === 'aplicar' &&
                          lo?.insights?.isMastered && (
                            <CheckIcon className='report-user-detail-header-phase__completed' />
                          )}
                        {t('reports_phase_apply')}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </Tooltip>
          </div>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <div className='report-user-detail-container'>
            <ul>
              {displayedAttempts?.map((attempt, index) => {
                return (
                  <li key={`${index}`}>
                    <ViewActivity attempt={attempt} />
                  </li>
                );
              })}
            </ul>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions className='add-activity-to-quiz-dialog__actions-container'>
        <div className='add-activity-to-quiz-dialog__actions'>
          <Stack direction='row' spacing={3}>
            <Button variant='text' color='info' disableRipple onClick={onClose}>
              {t('close')}
            </Button>
          </Stack>
        </div>
      </DialogActions>
    </Dialog>
  );
};

DetailLoDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default DetailLoDialog;
