import { Checkbox } from '@mui/material';

const CellWithCheckBox = ({ checked, onChange, disabled }) => {
  return (
    <div className={`cell__container`}>
      <Checkbox
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </div>
  );
};

export default CellWithCheckBox;
