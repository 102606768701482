import { useContext, useEffect, useState } from 'react';
import { CourseContext } from '../../../../../../utils/contexts/CourseContext';
import {
  getPrograms,
  getUnitsAndLessonsFromProgram
} from '../../../../../../utils/crud/quiz';

const useAddActivityToQuizDialog = ({
  subTopics,
  allStandards,
  quizLanguage,
  standardsByProgram
}) => {
  const { selectedCourse } = useContext(CourseContext);

  const [isLoading, setIsLoading] = useState(true);
  const [totalLessons, setTotalLessons] = useState(0);
  const [unitsAndLessons, setUnitsAndLessons] = useState([]);
  const [educationYearName, setEducationYearName] = useState('');
  const [educationYear, setEducationYear] = useState('');
  const [lessonsToList, setLessonsToList] = useState([]);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [selectedLessonsToAdd, setSelectedLessonsToAdd] = useState([]);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [canAddQuestions, setCanAddQuestions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStandardGuid, setSelectedStandardGuid] = useState('');
  const [programs, setPrograms] = useState([]);
  const [standards, setStandards] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);

  const parseUnitsAndLessons = ({ data }) => {
    return data.map((unit) => ({
      guid: unit.guid,
      name: unit.section,
      numberOfActivities: unit.number_of_seeds,
      lessons: unit.items
        .filter((lesson) => lesson.number_of_seeds > 0)
        .map((lesson) => ({
          guid: lesson.lesson_guid,
          isSelected: false,
          unitName: unit.section,
          unitGuid: unit.guid,
          name: lesson.lesson_name,
          numberOfActivities: lesson.number_of_seeds,
          standards: lesson.standars
            .filter((item) => item !== 'CC' && item.indexOf('CCSS') === -1) //temporary manual filter for CC codes
            .map((standard) => {
              const standardObj = standards.find(
                (std) => std?.guid === standard
              );

              return {
                guid: standardObj?.guid || standard,
                name: standardObj?.name || standard,
                description: standardObj?.objectives || standard
              };
            })
        }))
    }));
  };

  const parseEducationYearName = () => {
    if (!selectedCourse) return '';
    const educationYear = selectedProgram?.education_year?.name;
    const educationLevel = selectedProgram?.education_level?.name;
    return `${educationYear} ${educationLevel}`;
  };

  const loadPrograms = async () => {
    setIsLoading(true);

    // / Get available programs / education years
    const responsePrograms = await getPrograms();
    const tempListOfPrograms = responsePrograms?.programs;

    setPrograms(tempListOfPrograms);

    const quizProgramGuid = selectedCourse?.program_guid;

    setSelectedProgram(
      tempListOfPrograms.find(
        (program) => program.program_guid === quizProgramGuid
      )
    );
  };

  const loadUnits = async () => {
    setIsLoading(true);
    setLessonsToList([]);
    // setSelectedLessons([]);

    const response = await getUnitsAndLessonsFromProgram({
      programGuid: selectedProgram?.program_guid,
      langId: quizLanguage
    });

    // Se obtienen los standars de las unidades del curso
    const standarsByCourse = response.items.reduce((acc, currentItem) => {
      const currentItemStandards = currentItem.items.map(
        (item) => item.standars
      );
      return acc.concat(currentItemStandards);
    }, []);

    // Para aplanar el array de arrays
    const flatStandardsByCourse = [].concat.apply([], standarsByCourse);

    const uniqueFlatStandardsByCourse = [...new Set(flatStandardsByCourse)];

    /*const uniqueFlatStandardsSet = new Set(uniqueFlatStandardsByCourse.filter((item) => item !== 'CC' && item.indexOf('CCSS') === -1)); // temporary remove CC and CCSS standards
    let standardsEnd = uniqueFlatStandardsSet;
    if(!isTeacherBrasil){
      let uniqueFlatStandardsArray = Array.from(uniqueFlatStandardsSet);
      standardsEnd = new Set(uniqueFlatStandardsArray.filter((item) => !item.startsWith('EF') && !item.startsWith('BNCC')));
    }*/

    let uniqueFlatStandardsArray = Array.from(uniqueFlatStandardsByCourse);
    let standardsEnd = new Set(uniqueFlatStandardsArray);
    if (standardsByProgram) {
      switch (standardsByProgram) {
        case 'BNCC':
          standardsEnd = new Set(
            uniqueFlatStandardsArray.filter(
              (item) => item.startsWith('EF') || item.startsWith('BNCC')
            )
          );
          break;

        case 'CC':
          standardsEnd = new Set(
            uniqueFlatStandardsArray.filter(
              (item) => item.startsWith('CC') || item.startsWith('CCSS')
            )
          );
          break;

        default:
          standardsEnd = new Set(uniqueFlatStandardsArray);
          break;
      }
    }

    // Del listado total de Standards, filtramos y nos quedamos solo con los de la lección
    const filteredStandards = allStandards.filter((standard) => {
      return standardsEnd.has(standard.guid);
    });

    setStandards(filteredStandards);

    const parsedUnitsAndLessons = parseUnitsAndLessons({
      data: response.items
    });

    setUnitsAndLessons(parsedUnitsAndLessons);
    setTotalLessons(
      response.items.reduce((acc, unit) => acc + unit.items.length, 0)
    );

    if (response.items.length > 0) {
      const allUnitLessons =
        parsedUnitsAndLessons?.map((item) => item?.lessons)?.flat() || [];
      setLessonsToList(allUnitLessons);
    }

    setIsLoading(false);
  };

  const onFilterAndSearch = ({
    searchText,
    standardGuid,
    showOnlyTopicsInUse
  }) => {
    setSearchQuery(searchText);
    setSelectedStandardGuid(standardGuid);

    if (!searchText && !standardGuid) {
      let tempLessons = unitsAndLessons
        .map((unit) => unit.lessons)
        .flat()
        .map((lesson) => {
          const selectedLesson = selectedLessonsToAdd.find(
            (selectedLesson) => selectedLesson.guid === lesson.guid
          );
          return {
            ...lesson,
            isSelected: selectedLesson ? true : false,
            numberOfActivitiesToAdd: selectedLesson
              ? selectedLesson.numberOfActivitiesToAdd
              : 0
          };
        });

      if (showOnlyTopicsInUse) {
        tempLessons = tempLessons.filter((lesson) =>
          subTopics.find((subTopic) => subTopic.guid === lesson.guid)
        );
      }
      setLessonsToList(tempLessons);
      return;
    }

    const lessons = unitsAndLessons.reduce((prev, unit) => {
      let filteredLessons = unit.lessons
        .map((lesson) => {
          const selectedLesson = selectedLessonsToAdd.find(
            (selectedLesson) => selectedLesson.guid === lesson.guid
          );
          return {
            ...lesson,
            isSelected: selectedLesson ? true : false,
            numberOfActivitiesToAdd: selectedLesson
              ? selectedLesson.numberOfActivitiesToAdd
              : 0
          };
        })
        .filter((lesson) => lesson.numberOfActivities > 0);

      if (searchText) {
        // normalize text and search
        const normalizedSearchText = searchText
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');

        const normalizedLessonNames = filteredLessons.map((lesson) =>
          lesson?.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        );

        filteredLessons = filteredLessons.filter((lesson, index) =>
          normalizedLessonNames[index]
            .toLowerCase()
            .includes(normalizedSearchText.toLowerCase())
        );
      }

      if (standardGuid) {
        filteredLessons = filteredLessons.filter((lesson) =>
          lesson.standards.some((standard) => standard.guid === standardGuid)
        );
      }

      if (showOnlyTopicsInUse) {
        filteredLessons = filteredLessons.filter((lesson) =>
          subTopics.find((subTopic) => subTopic.guid === lesson.guid)
        );
      }

      return [...prev, ...filteredLessons];
    }, []);

    setLessonsToList(lessons);
  };

  const onSelectLesson = ({ lessonGuid, numberOfActivitiesToAdd }) => {
    // add lesson to selected lessons with number of questions. If lesson already exists, update the number of questions. if number of questions is 0, remove the lesson from selected lessons
    const lessonToAdd = {
      guid: lessonGuid,
      numberOfActivitiesToAdd
    };

    const lessonExists = selectedLessonsToAdd.find(
      (lesson) => lesson.guid === lessonGuid
    );

    const updatedLessonsToList = lessonsToList.map((lesson) => {
      if (lesson.guid === lessonGuid) {
        return {
          ...lesson,
          isSelected: !lessonExists || numberOfActivitiesToAdd > 0,
          numberOfActivitiesToAdd
        };
      }
      return lesson;
    });

    if (lessonExists && numberOfActivitiesToAdd > 0) {
      const updatedLessons = selectedLessonsToAdd.map((lesson) => {
        if (lesson.guid === lessonGuid) {
          return lessonToAdd;
        }
        return lesson;
      });
      setSelectedLessonsToAdd(updatedLessons);
    }

    if (numberOfActivitiesToAdd === 0) {
      const updatedLessons = selectedLessonsToAdd.filter(
        (lesson) => lesson.guid !== lessonGuid
      );
      setSelectedLessonsToAdd(updatedLessons);
    }

    if (!lessonExists && numberOfActivitiesToAdd > 0) {
      setSelectedLessonsToAdd([...selectedLessonsToAdd, lessonToAdd]);
    }

    setLessonsToList(updatedLessonsToList);
  };

  const onRemoveLesson = (lessonGuid) => {
    const updatedLessons = selectedLessons.filter(
      (selectedLesson) => selectedLesson.guid !== lessonGuid
    );
    setSelectedLessons(updatedLessons);
    const updatedLessonsToList = lessonsToList.map((lesson) => {
      if (lesson.guid === lessonGuid) {
        return {
          ...lesson,
          isSelected: false
        };
      }
      return lesson;
    });
    setLessonsToList(updatedLessonsToList);
  };

  const onChangeNumberOfQuestions = (numberOfQuestions) => {
    setNumberOfQuestions(numberOfQuestions);
    setCanAddQuestions(numberOfQuestions > 0);
  };

  const onChangeProgram = (program) => {
    console.log('program', program);
    setSelectedProgram(program);
  };

  useEffect(() => {
    if (!selectedProgram && !isLoading) return;
    loadPrograms();
  }, [selectedCourse]);

  useEffect(() => {
    if (!selectedProgram && !selectedProgram?.program_guid) return;
    loadUnits();
    setEducationYearName(parseEducationYearName());
    setEducationYear(selectedCourse?.education_year_name);
  }, [selectedProgram]);

  return {
    programs,
    isLoading,
    standards,
    totalLessons,
    lessonsToList,
    educationYear,
    selectedCourse,
    unitsAndLessons,
    selectedProgram,
    selectedLessons,
    canAddQuestions,
    educationYearName,
    selectedLessonsToAdd,
    onChangeProgram,
    onRemoveLesson,
    onSelectLesson,
    onFilterAndSearch,
    onChangeNumberOfQuestions
  };
};

export default useAddActivityToQuizDialog;
