import { useCallback, useEffect, useState } from 'react';
import { fetchWithToken } from '../../../utils/helpers/fetcher';
import { debounce } from 'lodash';
import { createColumnHelper } from '@tanstack/react-table';
import CellTag from '../../../components/table/cell/cellTypes/CellTag';
import { useTranslation } from 'react-i18next';
import { lastUpdate } from '../../../utils/helpers/lastUpdate';
import useEditorDashboardPermissions from '../../../utils/hooks/useEditorDashboardPermissions';

const useDepartments = () => {
  const PAGE_SIZE = 10;
  const columnHelper = createColumnHelper();
  const { t } = useTranslation();
  const { isUserProgramEditor } = useEditorDashboardPermissions();

  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState({
    langCode: 'en'
  });
  const [numberOfFilters, setNumberOfFilters] = useState(0);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(total / PAGE_SIZE);

  const [toastMessage, setToastMessage] = useState({});
  const [showToast, setShowToast] = useState(false);

  const columns = [
    // name displays the guid in reality (check the get function)
    columnHelper.accessor('name', {
      header: t('department_column_name'),
      id: 'name',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      enableHiding: false,
      cell: (props) => <CellTag tags={props.row.original.name} />
    }),

    columnHelper.accessor('availableLanguages', {
      header: t('department_column_available_languages'),
      id: 'availableLanguages',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      enableHiding: false,
      cell: (props) => <CellTag tags={props.row.original.availableLanguages} />
    }),

    columnHelper.accessor('lastUpdate', {
      header: t('department_column_updated'),
      id: 'lastUpdate',
      enableSorting: true,
      sortingFn: 'datetime',
      cell: (info) => info.renderValue()
    })
  ];

  const onChangeFilter = (newFilters) => {
    // filters are added if they do not exist and replaced if they do
    setFilters(newFilters);
    setNumberOfFilters(Object.keys(newFilters).length);
    getData(newFilters);
  };

  const onChangeSearch = (searchValue) => {
    setSearch(searchValue);
    debounceSearch(searchValue);
  };

  const onChangePage = (e, page) => {
    setCurrentPage(page);
    setOffset((page - 1) * PAGE_SIZE);
  };

  const getData = async (filters) => {
    setIsLoading(true);

    const queryParameters = [
      `offset=${offset}`,
      `pageSize=${PAGE_SIZE}`,
      'orderBy=updatedAt',
      'orderType=DESC',
      filters.search && `name=${filters.search}`
    ];

    const queryParams = queryParameters.filter(Boolean).join('&');
    const url = `/departments?${queryParams}`;

    const response = await fetchWithToken({
      method: 'GET',
      path: url
    });

    setTotal(response.data.count);

    const data = response.data.departments.map((department) => ({
      guid: department.guid,
      name: [
        {
          label: department.name,
          description: department.guid
        }
      ],
      availableLanguages: department.departmentLanguages.map((language) => ({
        label: language.lang.code,
        description: language.name
      })),
      lastUpdate: lastUpdate(
        department?.updatedAt ||
          department?.createdAt ||
          new Date().toISOString(),
        (department.updatedBy || department.createdBy)?.name || 'user',
        t
      )
    }));

    setData(data);

    setIsLoading(false);
  };

  const onCreateDepartment = async (newDepartment) => {
    const createDepartmentResponse = await fetchWithToken({
      method: 'POST',
      path: '/departments',
      data: newDepartment
    });

    if (createDepartmentResponse.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_department_created_success')
      });
      // Reload the table with the new data
      getData({});
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_department_created_error')
      });
    }

    setShowToast(true);
  };

  const onDeleteDepartment = async (department) => {
    const deleteDepartmentResponse = await fetchWithToken({
      method: 'DELETE',
      path: `/departments/${department.guid}`
    });

    if (deleteDepartmentResponse.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_department_deleted_success')
      });
      getData({});
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_department_deleted_error')
      });
    }

    setShowToast(true);
  };

  const debounceSearch = useCallback(
    debounce((searchValue) => {
      getData({ ...filters, search: searchValue });
    }, 500),
    []
  );

  useEffect(() => {
    getData({ ...filters, search: search });
  }, [currentPage]);

  return {
    isUserProgramEditor,
    data,
    total,
    search,
    columns,
    filters,
    isLoading,
    setFilters,
    onChangeSearch,
    onChangeFilter,
    numberOfFilters,
    currentPage,
    totalPages,
    onChangePage,
    toastMessage,
    showToast,
    setShowToast,
    onCreateDepartment,
    onDeleteDepartment
  };
};

export default useDepartments;
