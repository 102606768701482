import React from 'react';
import Table from '../../../../components/table/Table';

import useStudentsStandardsAnalysis from './useStudentsStandardsAnalysis';

const StudentsStandardsAnalysis = () => {
  const { data, columns, isLoading } = useStudentsStandardsAnalysis();

  return (
    <Table
      data={data}
      columns={columns}
      isFirstColumnSticky={true}
      pagination={false}
      isLoading={isLoading}
    />
  );
};

export default StudentsStandardsAnalysis;
