import React, { useState, useEffect } from 'react';
import FullPageLayout from '../../../layouts/dashboardFullPage/FullPageLayout';
import BackButton from '../../../atoms/BackButton/BackButton';
import { Container, Grid, Typography } from '@mui/material';
import EDTabs from '../../../components/EDTabs/EDTabs';
import useSingleLearningOutcome from './useSingleLearningOutcome';
import LearningOutcomeMetadata from './components/LearningOutcomeMetadata';
import EDButton from '../../../atoms/EDButton/EDButton';
import Toast from '../../../atoms/Toast/Toast';
import { useTranslation } from 'react-i18next';
import LearningOutcomeActivities from './components/LearningOutcomeActivities';

const SingleLearningOutcome = () => {
  const {
    tabs,
    isLoading,
    learningOutcome,
    isUpdating,
    onSaveLearningOutcomeMetadata,
    showToast,
    toastMessage,
    activities,
    setShowToast,
    activitiesTableColumns,
    currentTab,
    setCurrentTab
  } = useSingleLearningOutcome();

  const { t } = useTranslation();
  const [tempLO, setTempLO] = useState(learningOutcome);

  const onChangeLO = (value) => {
    setTempLO(value);
  };

  return (
    <FullPageLayout
      left={<BackButton onClick={() => history.back()} />}
      title={
        <Typography variant='h3'>
          Edit Learning Outcome "{learningOutcome?.name}"
        </Typography>
      }
      footerRight={
        <EDButton
          disableElevation
          color='primary'
          variant='contained'
          sx={{
            padding: '0.75rem 1.5rem',
            textTransform: 'none'
          }}
          onClick={() => onSaveLearningOutcomeMetadata(tempLO)}
          disabled={isUpdating}
        >
          {isUpdating ? '...' : t('Save')}
        </EDButton>
      }
    >
      <Container maxWidth='xl'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EDTabs
              tabs={tabs}
              currentTab={currentTab}
              onClick={(selectedTab) => {
                setCurrentTab(selectedTab.key);
              }}
            />
          </Grid>
          {currentTab === 'metadata' && (
            <LearningOutcomeMetadata
              isLoading={isLoading}
              learningOutcome={learningOutcome}
              onChange={onChangeLO}
            />
          )}
          {currentTab === 'activities' && (
            <LearningOutcomeActivities
              columns={activitiesTableColumns}
              data={activities}
              isLoading={false}
            />
          )}
        </Grid>
        {showToast && (
          <Toast
            handleShowToast={setShowToast}
            type={toastMessage.type}
            text={toastMessage.text}
          />
        )}
      </Container>
    </FullPageLayout>
  );
};

export default SingleLearningOutcome;
