import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SearchWithFilterButton from '../../../atoms/SearchWithFilterButton/SearchWithFilterButton';
import EDDashboardTabs from '../../../components/EDDashboardTabs/EDDashboardTabs';
import Table from '../../../components/table/Table';
import PageWithHeader from '../../../layouts/pageWithHeader/PageWithHeader';
import ConfirmDeleteDialog from '../../tenantDashboard/schools/Edit/tabs/dialogs/ConfirmDeleteDialog';
import StandardsFilter from './components/StandardsFilter';
import useStandardsManager from './useStandardsManager';

const StandardsManager = () => {
  const {
    isUserProgramEditor,
    data,
    columns,
    total,
    filters,
    search,
    numberOfFilters,
    isLoading,
    currentPage,
    totalPages,
    onChangeFilter,
    onChangeSearch,
    onChangePage,
    onCreateStandard,
    onDeleteStandard
  } = useStandardsManager();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isNewLearningOutcomeDialogOpen, setIsNewLearningOutcomeDialogOpen] =
    useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [loToDelete, setLoToDelete] = useState(null);

  const handleClickOpen = () => {
    setIsNewLearningOutcomeDialogOpen(true);
  };

  const handleClose = () => {
    setIsNewLearningOutcomeDialogOpen(false);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <PageWithHeader
      left={
        <EDDashboardTabs
          tabs={[
            {
              name: t('standards_tab_title'),
              to: '/standards',
              key: 'standards'
            }
          ]}
          currentTab={'standards'}
        />
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px'
        }}
      >
        <SearchWithFilterButton
          onClick={toggleDrawer}
          value={search}
          onChange={onChangeSearch}
          numberOfFilters={numberOfFilters}
          placeholder={t('search_placeholder')}
        />
      </div>
      <div>
        <div
          style={{
            marginBottom: '19px',
            color: '#8c99ad',
            fontSize: '12px',
            fontWeight: '600'
          }}
        >
          <span
            style={{
              color: '#414b5c'
            }}
          >
            {Intl.NumberFormat().format(total)}
          </span>{' '}
          {t('standards_have_been_found')}
        </div>
      </div>

      <Table
        name='learning-objectives'
        data={data}
        columns={columns}
        isLoading={isLoading || !total}
        // onRowClick={(e) => {
        //   navigate(`/standards/${e.guid}`);
        // }}
        showPagination
        showTopPagination
        paginationSize='large'
        totalPages={totalPages}
        currentPage={parseInt(currentPage) || 1}
        onChangePage={onChangePage}
        isCustomizable
        tableColumnsVisibility={{
          internalEducationYear: false,
          educationYears: false,
          learningStandards: false
        }}
        isFirstColumnSticky
        useApiSorting
        onChangeSort={onChangeFilter}
        isLastColumnSticky
        // options={
        //   !isUserProgramEditor && [
        //     {
        //       icon: <BorderColorOutlinedIcon />,
        //       label: t('edit'),
        //       onClick: (e) => {
        //         navigate(`/standards/${e.guid}`);
        //       }
        //     }
        //   ]
        // }
      />

      <StandardsFilter
        isDrawerOpen={isDrawerOpen}
        currentFilters={filters}
        onSearch={(value) => {
          onChangeFilter(value);
          setIsDrawerOpen(false);
        }}
        onCloseDrawer={() => setIsDrawerOpen(false)}
      />

      <ConfirmDeleteDialog
        isOpen={isConfirmDeleteDialogOpen}
        onClose={() => {
          setLoToDelete(null);
          setIsConfirmDeleteDialogOpen(false);
        }}
        onConfirm={() => {
          setIsConfirmDeleteDialogOpen(false);
          onDeleteStandard(loToDelete);
          setLoToDelete(null);
        }}
        itemToBeDeletedName={loToDelete?.name}
        itemToBeDeletedDescription={''}
      />
    </PageWithHeader>
  );
};

export default StandardsManager;
