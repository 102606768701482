import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../../../../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDButton from '../../../../../../atoms/EDButton/EDButton';
import EDInputWithLabel from '../../../../../../atoms/EDInput/EDInputWithLabel';

const CreateEditGroupDialog = ({
  isOpen,
  onClose,
  schoolYears,
  educationLevels,
  onSubmit,
  isEditing, // New parameter flag for editing
  groupData // New prop for existing group data
}) => {
  const { t } = useTranslation();
  const getInitialValue = (items, guid, key) => {
    const item = items.find((item) => item.guid === guid);
    return item ? item[key] : items[0][key];
  };

  const [selectedEducationLevel, setSelectedEducationLevel] = useState({
    label: isEditing
      ? getInitialValue(
          educationLevels,
          groupData.education_level_guid,
          'level'
        )
      : educationLevels[0].level,
    value: isEditing ? groupData.education_level_guid : educationLevels[0].guid
  });

  const [canProceed, setCanProceed] = useState(false);
  const [newGroupDetails, setNewGroupDetails] = useState({
    name: isEditing ? groupData.name : '',
    school_year_guid: {
      label: isEditing
        ? getInitialValue(schoolYears, groupData.school_year_guid, 'name')
        : schoolYears[0].name,
      value: isEditing ? groupData.school_year_guid : schoolYears[0].guid
    },
    education_year_guid: {
      label: isEditing
        ? getInitialValue(
            educationLevels.find(
              (item) => item.guid === groupData.education_level_guid
            ).educationYears,
            groupData.education_year_guid,
            'year'
          )
        : educationLevels[0].educationYears[0].year,
      value: isEditing
        ? getInitialValue(
            educationLevels.find(
              (item) => item.guid === groupData.education_level_guid
            ).educationYears,
            groupData.education_year_guid,
            'guid'
          )
        : educationLevels[0].educationYears[0].guid
    }
  });

  useEffect(() => {
    setCanProceed(checkCanProceed());
  }, [newGroupDetails]);

  const onCancelDialog = () => {
    setNewGroupDetails({
      name: isEditing ? groupData.name : '',
      school_year_guid: {
        label: isEditing ? groupData.school_year_label : schoolYears[0].name,
        value: isEditing ? groupData.school_year_guid : schoolYears[0].guid
      },
      education_year_guid: {
        label: isEditing
          ? groupData.education_year_label
          : educationLevels[0].educationYears[0].year,
        value: isEditing
          ? groupData.education_year_guid
          : educationLevels[0].educationYears[0].guid
      }
    });
    onClose();
  };

  const checkCanProceed = () => {
    return (
      newGroupDetails.name &&
      newGroupDetails.school_year_guid &&
      newGroupDetails.education_year_guid
    );
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onCancelDialog}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack>
              <Typography variant='h2'>
                {isEditing ? 'Edit Group' : 'Create Group'}
              </Typography>
              {/* Add close icon */}
            </Stack>
          </Grid>

          {isEditing && (
            <Grid item xs={12}>
              <Alert severity='warning' variant='standard'>
                {t('edit_traces_loss_warning')}
              </Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <EDInputWithLabel
              label='Group name'
              value={newGroupDetails.name}
              onChange={({ target: { value } }) => {
                setNewGroupDetails({ ...newGroupDetails, name: value });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDAutocompleteWithLabel
              showLabel
              label='Level'
              options={educationLevels.map((item) => ({
                label: item.level,
                value: item.guid
              }))}
              value={selectedEducationLevel}
              disableClearable
              onChange={(e, value) => {
                setSelectedEducationLevel(value);
                // reset education year
                setNewGroupDetails({
                  ...newGroupDetails,
                  education_year_guid: {
                    label: educationLevels.find(
                      (item) => item.guid === value.value
                    ).educationYears[0].year,
                    value: educationLevels.find(
                      (item) => item.guid === value.value
                    ).educationYears[0].guid
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDAutocompleteWithLabel
              showLabel
              label='Year'
              options={educationLevels
                .filter((item) => item.guid === selectedEducationLevel.value)[0]
                .educationYears.map((item) => ({
                  label: item.year,
                  value: item.guid
                }))}
              value={newGroupDetails.education_year_guid}
              disableClearable
              onChange={(e, value) => {
                setNewGroupDetails({
                  ...newGroupDetails,
                  education_year_guid: value
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EDAutocompleteWithLabel
              showLabel
              label='School year'
              options={schoolYears.map((item) => ({
                label: item.name,
                value: item.guid
              }))}
              value={newGroupDetails.school_year_guid}
              disableClearable
              onChange={(e, value) => {
                setNewGroupDetails({
                  ...newGroupDetails,
                  school_year_guid: value
                });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <EDButton variant='text' onClick={onCancelDialog}>
          {t('cancel')}
        </EDButton>
        <EDButton
          variant='contained'
          disableElevation
          disabled={!canProceed}
          onClick={() => {
            onSubmit({
              ...newGroupDetails,
              education_year_guid: newGroupDetails.education_year_guid.value,
              school_year_guid: newGroupDetails.school_year_guid.value
            });
          }}
        >
          {isEditing ? t('save') : t('Create')}
        </EDButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEditGroupDialog;
