import { Typography } from '@mui/material';
import React from 'react';
import './EDDashboardTabs.scss';
import { Link } from 'react-router-dom';

const EDDashboardTabs = ({ tabs, currentTab, onClick }) => {
  return (
    <div className='ed-dashboard-tabs__container'>
      {tabs.map((tab) => (
        <div
          key={tab.key}
          className={`ed-dashboard-tabs__link ${
            currentTab === tab.key ? 'ed-dashboard-tabs__link--active' : ''
          }`}
          {...(onClick && { onClick: () => onClick(tab.key) })}
        >
          <Typography
            {...(tab.to && { component: Link, to: tab.to })}
            className='ed-dashboard-tabs__link__text'
          >
            {tab.name}
          </Typography>
          {currentTab === tab.key && (
            <div className='ed-dashboard-tabs__link__underline' />
          )}
        </div>
      ))}
    </div>
  );
};

export default EDDashboardTabs;
