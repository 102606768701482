import { createColumnHelper } from '@tanstack/react-table';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CellTag from '../../../components/table/cell/cellTypes/CellTag';
import CellWithOneLineOfText from '../../../components/table/cell/cellTypes/CellWithOneLineOfText';
import { fetchWithToken } from '../../../utils/helpers/fetcher';
import { lastUpdate } from '../../../utils/helpers/lastUpdate';
import useEditorDashboardPermissions from '../../../utils/hooks/useEditorDashboardPermissions';

const useStandardsManager = () => {
  const PAGE_SIZE = 25;
  const columnHelper = createColumnHelper();
  const { t } = useTranslation();
  const { isUserProgramEditor } = useEditorDashboardPermissions();

  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState({});
  const [numberOfFilters, setNumberOfFilters] = useState(0);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(total / PAGE_SIZE);

  const [toastMessage, setToastMessage] = useState(null);

  const columns = [
    columnHelper.accessor('code', {
      header: t('standards_column_code'),
      id: 'name',
      enableSorting: true,
      sortingFn: 'cellWithOneLineOfText',
      enableHiding: false,
      cell: (props) => <CellWithOneLineOfText text={props.row.original.guid} />
    }),

    columnHelper.accessor('description', {
      header: t('standards_column_description'),
      id: 'description',
      enableSorting: false,
      sortingFn: 'alphanumeric',
      enableHiding: true,
      cell: (props) => (
        <CellWithOneLineOfText text={props.row.original.description} />
      )
    }),

    columnHelper.accessor('objectives', {
      header: t('standards_column_objectives'),
      id: 'objectives',
      enableSorting: false,
      sortingFn: 'cellTag',
      enableHiding: true,
      cell: (props) => (
        <CellWithOneLineOfText text={props.row.original.objectives} />
      )
    }),

    columnHelper.accessor('knowledgeComponents', {
      header: t('learning_outcome_column_knowledge_components'),
      id: 'knowledgeComponents',
      enableSorting: false,
      sortingFn: 'cellTag',
      enableHiding: true,
      cell: (props) => <CellTag tags={props.row.original.knowledgeComponents} />
    }),

    columnHelper.accessor('department', {
      header: t('standards_column_department'),
      id: 'department',
      enableSorting: false,
      sortingFn: 'cellTag',
      enableHiding: true,
      cell: (props) => (
        <CellWithOneLineOfText text={props.row.original.departmentName} />
      )
    }),

    columnHelper.accessor('internalEducationYear', {
      header: t('standards_column_internal_education_year'),
      id: 'internalEducationYear',
      enableSorting: false,
      sortingFn: 'alphanumeric',
      enableHiding: true,
      cell: (info) => info.renderValue()
    }),

    columnHelper.accessor('availableLanguages', {
      header: t('department_column_available_languages'),
      id: 'availableLanguages',
      enableSorting: false,
      sortingFn: 'alphanumeric',
      enableHiding: true,
      cell: (props) => <CellTag tags={props.row.original.availableLanguages} />
    }),

    columnHelper.accessor('lastUpdate', {
      header: t('standards_column_updated'),
      id: 'lastUpdate',
      enableSorting: true,
      sortingFn: 'datetime',
      cell: (info) => info.renderValue()
    })
  ];

  const onChangeFilter = (newFilters) => {
    let tempFilters = { ...newFilters };
    // remove page from filters to avoid pagination issues when changing filters
    delete tempFilters.page;
    // filters are added if they do not exist and replaced if they do
    setFilters({ ...tempFilters, search: search });
    // save filters on url
    saveFiltersInURL({ ...tempFilters, search: search });
    // update number of filters
    Object.keys(
      Object.keys(tempFilters).filter(
        (item) => !['search', 'orderBy', 'orderType'].includes(item)
      )
    ).length - 1;

    getData();
  };

  const saveFiltersInURL = (newFilters) => {
    // check if all filters are empty
    const allFiltersEmpty = Object.values(newFilters).every(
      (filter) => filter === '' || filter === null || filter === undefined
    );

    // if all filters are empty, remove filters from url
    if (allFiltersEmpty) {
      const searchParams = new URLSearchParams(window.location.search);

      const URLfilters = Object.fromEntries(searchParams);
      Object.keys(URLfilters).forEach((key) => {
        // delete except search
        if (!['search', 'orderBy', 'orderType'].includes(key)) {
          searchParams.delete(key);
        }
      });

      const newUrl = `${window.location.pathname}${
        searchParams.toString() !== ''
          ? `?${searchParams.toString()}`
          : searchParams.toString()
      }`;

      history.pushState(null, '', newUrl);
      return;
    }

    const searchParams = new URLSearchParams();
    const tempFilters = { ...newFilters };
    delete tempFilters.offset;

    Object.keys(tempFilters).forEach((key) => {
      if (
        tempFilters[key] !== '' &&
        tempFilters[key] !== null &&
        tempFilters[key] !== undefined
      ) {
        searchParams.append(
          key,
          typeof tempFilters[key] === 'object'
            ? JSON.stringify(tempFilters[key])
            : tempFilters[key]
        );
      }
    });
    // searchParams.set('filters', JSON.stringify(newFilters));
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    history.pushState(null, '', newUrl);
  };

  const getFiltersFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    // get all search params
    let URLfilters = {};
    try {
      URLfilters = Object.fromEntries(searchParams);
      Object.keys(URLfilters).forEach((key) => {
        // check if value is a stringified object
        if (URLfilters[key].startsWith('{')) {
          URLfilters[key] = JSON.parse(URLfilters[key]);
        }

        // check if value is a stringified array
        if (URLfilters[key].startsWith('[')) {
          URLfilters[key] = JSON.parse(URLfilters[key]);
        }
      });
    } catch (e) {
      console.log('error parsing filters', e);
    }

    setFilters(URLfilters);
    setSearch(URLfilters.search);
    setOffset(((URLfilters.page || 1) - 1) * PAGE_SIZE);
    setCurrentPage(URLfilters.page || 1);

    // update number of filters
    setNumberOfFilters(
      Object.keys(URLfilters).filter(
        (item) =>
          !['search', 'page', 'orderBy', 'orderType'].includes(item) &&
          URLfilters[item] !== '' &&
          URLfilters[item] !== null &&
          URLfilters[item] !== undefined
      ).length
    );
    console.log('URLfilters.page -- ', URLfilters.page);
    console.log('PAGE_SIZE -- ', PAGE_SIZE);
    console.log(
      '((URLfilters.page || 1) - 1) * PAGE_SIZE -- ',
      ((URLfilters.page || 1) - 1) * PAGE_SIZE
    );

    return { ...URLfilters, offset: ((URLfilters.page || 1) - 1) * PAGE_SIZE };
  };

  const onChangeSearch = (searchValue) => {
    setSearch(searchValue);
    debounceSearch(searchValue);
  };

  const onChangePage = (e, page) => {
    setCurrentPage(page);
    setOffset((page - 1) * PAGE_SIZE);

    saveFiltersInURL({ ...filters, page: page });
  };

  // criteria provided on BBTEC-3677
  // Si es CC sería un código con formato CCSS.MATH.CONTENT.3.MD.A.2 + Si es BNCC el formato sería EF02MA17
  const getStandardType = (standardCode) => {
    if (standardCode.includes('CCSS')) {
      return 'CC';
    }

    if (standardCode.includes('EF')) {
      return 'BNCC';
    }

    return 'unknown';
  };

  const getData = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const filtersFromURL = getFiltersFromURL();

    const queryParameters = [
      `offset=${
        filtersFromURL.offset !== undefined && filtersFromURL.offset !== null
          ? filtersFromURL.offset
          : offset
      }`,
      `pageSize=${PAGE_SIZE}`,
      `orderBy=${filtersFromURL.orderBy || 'updatedAt'}`,
      `orderType=${filtersFromURL.orderType || 'DESC'}`,
      'type[]=outcome', // tipo para LOs
      filtersFromURL.search && `search=${filtersFromURL.search}`,
      filtersFromURL.departments &&
        `departmentGuid[]=${filtersFromURL.departments}`,
      filtersFromURL.educationYearGuid &&
        `internalEducationYearGuid[]=${filtersFromURL.educationYearGuid}`,
      filtersFromURL.parentGuid?.length > 0 &&
        filtersFromURL.parentGuid
          .map((item) => `parentGuid[]=${item}`)
          .join('&')

      // filters.updatedBy && `updatedBy=${filters.updatedBy}`,
      // filters.createdBy && `createdBy=${filters.createdBy}`,
      // filters.language && `langCode=${filters.langCode}`,
      // filters.updatedAt && `updatedAt=${filters.updatedAt}`,
    ];

    const queryParams = queryParameters.filter(Boolean).join('&');
    const url = `/standards?${queryParams}`;

    const response = await fetchWithToken({
      method: 'GET',
      path: url
    });

    console.log('Standards response', response);

    setTotal(response.data.count);

    const data = response.data.standards.map((standard) => {
      const standardType = getStandardType(standard.guid);

      const potentialLanguages = { CC: 'ES', BNCC: 'PT' };

      const selectedLanguage = potentialLanguages[standardType] || 'EN';

      return {
        guid: standard.guid,
        name: standard.name,
        description:
          standard.standardLanguages?.find(
            (item) => item.lang === selectedLanguage
          )?.skills || '',
        objectives:
          standard.standardLanguages?.find(
            (item) => item.lang === selectedLanguage
          )?.objectives || '',
        departmentName: standard.thematicUnit?.name,
        knowledgeComponents: (standard.nodes || [])
          .filter((node) => node.type === 'concept')
          .map((node) => ({
            label: node.name,
            description:
              node.descriptionLanguages.find(
                (item) => item.lang === selectedLanguage
              )?.description || ''
          })),
        internalEducationYear: standard.internalEducationYear
          ? standard.internalEducationYear.year + 'º Primaria'
          : null,
        availableLanguages: standard.standardLanguages.map((language) => ({
          label: language.lang,
          description: language.lang
        })),
        lastUpdate: lastUpdate(
          standard?.updatedAt ||
            standard?.createdAt ||
            new Date().toISOString(),
          (standard.updatedBy || standard.createdBy)?.name || 'user',
          t
        )
      };
    });

    setData(data);

    setIsLoading(false);
  };

  const onCreateStandard = async (newStandard) => {
    const createLOResponse = await fetchWithToken({
      method: 'POST',
      path: '/standards',
      data: newStandard
    });

    if (createLOResponse.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_standards_saved_success')
      });
      // Reload the table with the new data
      getData({});
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_standards_saved_error')
      });
    }
  };

  const onDeleteStandard = async (lo) => {
    const deleteLOResponse = await fetchWithToken({
      method: 'DELETE',
      path: `/nodes/${lo.guid}`
    });

    if (deleteLOResponse.status === 'success') {
      setToastMessage({
        type: 'success',
        text: t('toast_standards_deleted_success')
      });
      getData({});
    } else {
      setToastMessage({
        type: 'error',
        text: t('toast_standards_deleted_error')
      });
    }
  };

  const debounceSearch = useCallback(
    debounce((searchValue) => {
      let filtersFromURL = getFiltersFromURL();
      delete filtersFromURL.page;
      saveFiltersInURL({ ...filtersFromURL, search: searchValue });
      getData();
      return;
    }, 500),
    []
  );

  useEffect(() => {
    getData();
  }, [currentPage]);

  return {
    isUserProgramEditor,
    data,
    total,
    search,
    columns,
    filters,
    isLoading,
    setFilters,
    onChangeSearch,
    onChangeFilter,
    numberOfFilters,
    currentPage,
    totalPages,
    onChangePage,
    toastMessage,
    onCreateStandard,
    onDeleteStandard
  };
};

export default useStandardsManager;
