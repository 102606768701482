import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDAutocompleteWithLabel from '../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import EDInput from '../../atoms/EDInput/EDInput';
import { fetchWithToken } from '../../utils/helpers/fetcher';

const EDDepartmentMainFilter = ({
  defaultValue,
  showLabel = false,
  multiple = false,
  required = false,
  disabled = false,
  language = 'ES',
  onChange
}) => {
  const { t } = useTranslation();
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(-1);
  const [total, setTotal] = useState(0);
  const [inputValue, setInputValue] = useState('');

  const translateDepartmentName = (department) => {
    switch (department) {
      case 'Geometry':
        return t('demo_geometry');
      case 'Magnitudes and measure':
        return t('demo_magnitudes');
      case 'Numbers and operations':
        return t('demo_numbers');
      case 'Statistic and probability':
        return t('demo_statistics');
      default:
        return department;
    }
  };

  const getData = async () => {
    setIsLoading(true);

    let departmentsResponse = await fetchWithToken({
      method: 'GET',
      path: `/departments?pageSize=25&offset=0${
        language ? `&langCode[]=${language.toUpperCase()}` : ''
      }`
    });

    setDepartments(
      departmentsResponse.data.departments?.map((department) => ({
        value: department.guid,
        id: department.guid,
        label:
          department.departmentLanguages.find(
            (departmentLanguage) =>
              departmentLanguage.lang.code === language.toUpperCase()
          )?.name || translateDepartmentName(department.name)
      }))
    );

    setTotal(departmentsResponse.data.count);
    setOffset(departmentsResponse.data.offset);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [language]);

  useEffect(() => {
    if (inputValue && inputValue.length > 0) {
      onChange(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (departments && departments.length > 0) {
      const selectedDepartament = departments.find(
        (level) => level.id === defaultValue || level.id === inputValue
      );
      setInputValue(selectedDepartament || null);
    }
  }, [defaultValue, departments]);

  return (
    departments.length > 0 && (
      <EDAutocompleteWithLabel
        limitTags={2}
        label={t('demo_departments')}
        showLabel={showLabel}
        multiple={multiple}
        required={required}
        loading={isLoading}
        disabled={disabled}
        loadingText={t('loading_text')}
        noOptionsText={t('no_options_text')}
        value={inputValue}
        // defaultValue={defaultValue}
        isLoading={isLoading}
        options={departments}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          );
        }}
        getOptionLabel={(option) => option.label}
        onChange={(event, value) => {
          onChange(value ? value.id : null);
          // onChange(value ? [value] : []);
        }}
        renderInput={(params) => (
          <EDInput {...params} placeholder={t('select_placeholder')} />
        )}
      />
    )
  );
};

export default EDDepartmentMainFilter;
