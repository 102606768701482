import { ThemeProvider, createTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import EditSchool from '../pages/tenantDashboard/schools/Edit/EditSchool';
import Page404 from '../pages/tenantDashboard/error/Page404';

const theme = createTheme({
  palette: {
    common: {
      black: '#303949'
    },
    primary: {
      main: '#2544CA',
      light: '#6363FC',
      dark: '#3535A0'
    },
    success: {
      main: '#00e19a',
      light: '#B7F1E2',
      dark: '#13B481'
    },
    error: {
      main: '#ff3d30',
      light: '#FFE5E2',
      dark: '#DE2A1E'
    },
    warning: {
      main: '#ffae56',
      light: '#FFF0DE',
      dark: '#E8963D'
    },
    info: {
      main: '#45dbf4',
      light: '#DDF9FF',
      dark: '#1FB9D2'
    },
    text: {
      primary: '#303949'
    },
    grey: {
      50: '#FDFDFD',
      100: '#f3f6f9',
      200: '#c6cfdc',
      300: '#dfe5ec',
      400: '#C3C0DC',
      500: '#8c99ad',
      600: '#3E3A68',
      700: '#414b5c',
      800: '#1B1A26'
    },
    action: {
      disabledBackground: '#f0f3f8',
      disabled: '#dfe5ec'
    }
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.75
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.5
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.25
    },
    h4: {
      fontSize: '0.875rem',
      lineHeight: 1.4286
    }
  },
  shape: {
    borderRadius: 4
  }
});

export const schoolAdminDashboardRoutes = [
  {
    element: (
      <ThemeProvider theme={theme}>
        <Outlet />
      </ThemeProvider>
    ),
    children: [
      {
        path: '/',
        element: <EditSchool />
      },
      {
        path: '*',
        element: <Page404 />
      }
    ]
  }
];
