import {
  Chip,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import EDButton from '../../../../../../atoms/EDButton/EDButton';
import EDAutocompleteWithLabel from '../../../../../../atoms/EDAutocomplete/EDAutocompleteWithLabel';
import { createColumnHelper } from '@tanstack/react-table';
import CellWithCheckBox from '../../../../../../components/table/cell/cellTypes/CellWithCheckbox';
import CellWithTitleAndSubtitle from '../../../../../../components/table/cell/cellTypes/CellWithTitleAndSubtitle';
import TableDashboard from '../../../../../../view/tableDashboard/TableDashboard';
import { fetchWithToken } from '../../../../../../utils/helpers/fetcher';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const AddUsersToGroupDialog = ({
  isOpen,
  onClose,
  currentUsers,
  groups,
  schoolGuid,
  getUsersInSchool,
  getSchoolGroups
}) => {
  const pageSize = 5;

  const [nameSearch, setNameSearch] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [logs, setLogs] = useState([]);
  const totalPages = Math.ceil(total / pageSize);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('isSelected', {
      header: 'Select User',
      id: 'isSelected',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellWithCheckBox
          checked={info.row.original.isSelected}
          onChange={() => {
            if (info.row.original.isSelected) {
              setSelectedUsers(
                selectedUsers.filter(
                  (item) => item.guid !== info.row.original.guid
                )
              );
            } else {
              setSelectedUsers([...selectedUsers, info.row.original]);
            }
            // set user as selected or not
            setUsers(
              users.map((item) => {
                if (item.guid === info.row.original.guid) {
                  return {
                    ...item,
                    isSelected: !item.isSelected
                  };
                }
                return item;
              })
            );
          }}
        />
      )
    }),
    columnHelper.accessor('name', {
      header: 'User',
      id: 'name',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellWithTitleAndSubtitle
          title={info.row.original.name}
          subtitle={info.row.original.username}
        />
      )
    }),
    columnHelper.accessor('role_name', {
      header: 'Role',
      id: 'role_name',
      enableSorting: true,
      sortingFn: 'alphanumeric'
    })
  ];

  const onRemoveUser = (user) => {
    const tempUsers = selectedUsers.filter((item) => item.guid !== user.guid);

    setSelectedUsers(tempUsers);
    setUsers(
      users.map((item) => {
        if (item.guid === user.guid) {
          return {
            ...item,
            isSelected: false
          };
        }
        return item;
      })
    );
  };

  const onAddUsersToGroup = async () => {
    setIsAdding(true);
    setCurrentStep(2);

    // loop over the selectedStudents and add them to school and group
    try {
      const promises = selectedUsers.map(async (user) => {
        let responseOfAddingToSchool = {
          status: 'success',
          wasAlreadyAdded: true
        };
        if (!user.schools.find((school) => school.guid === schoolGuid)) {
          responseOfAddingToSchool = await fetchWithToken({
            path: `/users/${user.guid}/schools`,
            method: 'POST',
            data: {
              guid: [schoolGuid]
            }
          });
        }

        if (responseOfAddingToSchool.status === 'success') {
          setLogs((prevLogs) => [
            ...prevLogs,
            {
              guid: `added-user-${user.guid}-to-school-${schoolGuid}`,
              message: `User ${user.name} ${user.lastname} (${
                user.role_name
              }) was ${
                responseOfAddingToSchool.wasAlreadyAdded
                  ? 'already in'
                  : 'successfully added to'
              } school`,
              status: 'success'
            }
          ]);

          const responseOfAddingToGroup = await fetchWithToken({
            path: `/front/school-admin/${schoolGuid}/groups/${selectedGroup.value}/users`,
            method: 'POST',
            data: {
              guid: [user.guid]
            }
          });

          if (responseOfAddingToGroup.status === 'success') {
            setLogs((prevLogs) => [
              ...prevLogs,
              {
                guid: `added-user-${user.guid}-to-group-${selectedGroup.value}`,
                message: `User ${user.name} ${user.lastname} (${user.role_name}) was added to group`,
                status: 'success'
              }
            ]);
          } else {
            setLogs((prevLogs) => [
              ...prevLogs,
              {
                guid: `error-adding-user-${user.guid}-to-group-${selectedGroup.value}`,
                message: `User ${user.name} ${user.lastname} (${user.role_name}) was not added to group`,
                status: 'error'
              }
            ]);
          }
        } else {
          setLogs((prevLogs) => [
            ...prevLogs,
            {
              guid: `error-adding-user-${user.guid}-to-school-${schoolGuid}`,
              message: `User ${user.name} ${user.lastname} (${user.role_name}) was not added to school`,
              status: 'error'
            }
          ]);
        }
      });

      await Promise.all(promises);
    } catch (error) {
      console.error(error);
    }
    await getUsersInSchool();
    await getSchoolGroups();
    setSelectedUsers([]);
    setIsAdding(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getUsers();
  }, [nameSearch, currentPage]);

  const getUsers = async () => {
    // setIsLoading(true);

    const auxNameSearch = nameSearch ? `&name=${nameSearch}` : '';

    let response = await fetchWithToken({
      path: `/users?pageSize=${pageSize}&offset=${offset}&orderBy=created_at+desc${auxNameSearch}`
    });
    setUsers(
      response.data.users.map((item) => ({
        ...item,
        isSelected: selectedUsers.some((user) => user.guid === item.guid),
        name: `${item.name} ${item.lastname}`
      }))
    );
    setTotal(response.data.total);
    setOffset(response.data.offset);
    // setIsLoading(false);
  };

  const onChangePage = (e, page) => {
    setCurrentPage(page);
    setOffset((page - 1) * pageSize);
  };

  let searchDebounceTimer = null;

  const onSearch = (value) => {
    clearTimeout(searchDebounceTimer);
    searchDebounceTimer = setTimeout(() => {
      setNameSearch(value);
    }, 300);
  };

  const handleClose = () => {
    if (isAdding) return;

    onClose();
  };

  return (
    <Dialog fullWidth maxWidth='md' open={isOpen} onClose={handleClose}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack>
              <Typography variant='h2'>Add users to group</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stepper activeStep={currentStep}>
              <Step>
                <StepLabel variant='h3'>Select students</StepLabel>
              </Step>
              <Step>
                <StepLabel variant='h3'>Select group</StepLabel>
              </Step>
              <Step>
                <StepLabel variant='h3'>Confirmation</StepLabel>
              </Step>
            </Stepper>
          </Grid>
          {currentStep === 0 && (
            <Grid item xs={12}>
              <TableDashboard
                tableColumns={columns}
                tableData={users}
                isTableDataLoading={isLoading}
                showPagination={true}
                totalPages={totalPages}
                currentPage={currentPage}
                onChangePage={onChangePage}
                onSearch={onSearch}
                singleEntryLabel='user'
                pluralEntryLabel='users'
                searchInputPlaceholder='Search users'
                totalEntries={total}
              />
            </Grid>
          )}

          {currentStep === 1 && (
            <>
              <Grid item xs={12}>
                <Typography variant='h3'>Selected users to add:</Typography>
              </Grid>
              <Grid item xs={12}>
                {/* Selected student chips with remove option */}
                <Stack
                  direction='row'
                  justifyContent='flex-start'
                  spacing={1}
                  display='inherit'
                >
                  {selectedUsers.map((user) => (
                    <Chip
                      label={`${user.name} ${user.lastname}`}
                      variant='outlined'
                      size='small'
                      onDelete={() => onRemoveUser(user)}
                      key={user.guid}
                      sx={{ m: 1 }}
                    />
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h3'>Select group:</Typography>
              </Grid>
              <Grid item xs={12}>
                <EDAutocompleteWithLabel
                  required={true}
                  options={groups.map((group) => ({
                    value: group.guid,
                    label: group.name
                  }))}
                  onChange={(e, value) => {
                    setSelectedGroup(value);
                  }}
                  disableClearable
                  multiple={false}
                />
              </Grid>
            </>
          )}
          {/* show logs of adding process */}
          {currentStep === 2 && (
            <>
              <Grid item xs={12}>
                <Typography variant='h3'>Logs:</Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack direction='column' spacing={1}>
                  {logs.map((log) => (
                    <Typography key={log.guid}>
                      {log.status === 'success' ? (
                        <CheckIcon color='success' />
                      ) : (
                        <WarningAmberIcon color='error' />
                      )}{' '}
                      {log.message}
                    </Typography>
                  ))}
                </Stack>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Stack direction='row' spacing={2} justifyContent='space-between'>
              <div>
                {currentStep === 1 && (
                  <EDButton
                    variant='outlined'
                    onClick={() => setCurrentStep(0)}
                    disabled={currentStep !== 1}
                  >
                    Go back
                  </EDButton>
                )}
              </div>
              <div>
                {currentStep !== 2 && (
                  <EDButton variant='text' onClick={handleClose}>
                    cancel
                  </EDButton>
                )}
                {currentStep === 0 && (
                  <EDButton
                    disabled={!selectedUsers.length}
                    variant='contained'
                    disableElevation
                    onClick={() => {
                      setCurrentStep(1);
                    }}
                  >
                    Next
                  </EDButton>
                )}
                {currentStep === 1 && (
                  <EDButton
                    disabled={!selectedUsers.length || !selectedGroup}
                    variant='contained'
                    disableElevation
                    onClick={onAddUsersToGroup}
                  >
                    Add users to group
                  </EDButton>
                )}
                {currentStep === 2 && (
                  <EDButton
                    disabled={isAdding}
                    variant='contained'
                    disableElevation
                    onClick={handleClose}
                  >
                    Done
                  </EDButton>
                )}
              </div>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddUsersToGroupDialog;
