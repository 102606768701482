import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import EDButton from '../../../atoms/EDButton/EDButton';
import { useNavigate } from 'react-router-dom';

export default function Page404() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}
    >
      <Container maxWidth='md'>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Typography variant='h1'>404</Typography>
            <Typography variant='h6'>
              The page you’re looking for doesn’t exist.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <EDButton
              disableElevation
              variant='contained'
              onClick={() => navigate('/')}
            >
              Back Home
            </EDButton>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
