import { Button } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError } from 'react-router-dom';
import ErrorCharacter from '../../assets/images/blueberro_not_found.png';
import { AnalysisSegment } from '../../utils/analysis';
import { getPlatform } from '../../utils/helpers/platform';
import './GeneralError.scss';

const GeneralError = ({ code = '', fullscreen = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // collect error that caused this page to be rendered
  let error = useRouteError();
  console.error(error);

  if (Sentry.getClient()) {
    // send error to Sentry
    Sentry.captureException(error);
  }

  const handleExitButtonClick = (event) => {
    event.stopPropagation();
    navigate(`/`);
  };

  useEffect(() => {
    if (getPlatform() === 'teacher') {
      AnalysisSegment.sendSegmentTrackEvent(
        AnalysisSegment.SEGMENT_EVENTS.Error_Page_Viewed,
        { error: code }
      );
    }
  }, []);

  return (
    <div className='error-page'>
      <div
        className={
          fullscreen
            ? 'error-page__container__fullscreen'
            : 'error-page__container'
        }
      >
        <div className='error-page__title'>{`${t('error_title')} ${code}`}</div>
        <div className='error-page__image'>
          <img src={ErrorCharacter} alt='error' />
        </div>
        <div className='error-page__text'>
          {code === 404 ? (
            t('error_message_404')
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: t('error_message', {
                  email: `<a href='mailto:soporte@blueberrymath.ai'>soporte@blueberrymath.ai</a>`
                })
              }}
            ></span>
          )}
        </div>
        <div className='error-page__button'>
          <Button
            variant='contained'
            disableElevation
            onClick={handleExitButtonClick}
          >
            {t('error_button')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GeneralError;
