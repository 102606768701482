import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDrawer from '../../../../atoms/filterDrawer/FilterDrawer';
import FilterItem from '../../../../atoms/filterItem/FilterItem';
import EDDepartmentMainFilter from '../../../../components/filters/EDDepartmentMainFilter';
import EDEducationLevelProgramFilter from '../../../../components/filters/EDEducationLevelProgramFilter';

const StandardsFilter = ({
  isDrawerOpen,
  onCloseDrawer,
  onSearch,
  currentFilters
}) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState(currentFilters);

  useEffect(() => {
    setFilters(currentFilters);
  }, [currentFilters]);

  const onChangeFilter = (filter) => {
    if (filter?.value?.length === 0) {
      let tempFilters = filters;
      delete tempFilters[filter.id];
      setFilters(tempFilters);
    } else {
      setFilters({
        ...filters,
        [filter.id]: filter.value
      });
    }
  };

  return (
    <FilterDrawer
      title={t('Filter')}
      isDrawerOpen={isDrawerOpen}
      onCloseDrawer={onCloseDrawer}
      onReset={() => {
        onSearch({});
        setFilters({});
        onCloseDrawer();
      }}
      onSearch={() => onSearch(filters)}
      isSearchDisabled={Object.keys(filters).length === 0}
    >
      <FilterItem
        title={t('activity_education_level')}
        type='custom'
        defaultSelection={filters.educationYearGuid || []}
        customFilter={
          <EDEducationLevelProgramFilter
            multiple
            defaultValue={filters.educationYearGuid || null}
            onChange={(value) => {
              onChangeFilter({ id: 'educationYearGuid', value });
            }}
          />
        }
      />

      <FilterItem
        title={t('learning_outcome_column_department')}
        type='custom'
        defaultSelection={filters.departments || []}
        customFilter={
          <EDDepartmentMainFilter
            multiple={false}
            defaultValue={filters.departments || null}
            onChange={(value) => {
              onChangeFilter({ id: 'departments', value });
            }}
          />
        }
      />

      <FilterItem
        title={t('standard')}
        type='checkbox'
        multiple
        options={[
          { label: 'CC', value: 'CC' },
          { label: 'BNCC', value: 'BNCC' }
        ]}
        defaultSelection={filters.parentGuid || []}
        onChange={(value) => onChangeFilter({ id: 'parentGuid', value })}
      />
    </FilterDrawer>
  );
};

export default StandardsFilter;
