import { Dialog, DialogContent, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import EDButton from '../../../../../atoms/EDButton/EDButton';
import { useTranslation } from 'react-i18next';

const DeleteDialog = ({ isOpen, onClose, onConfirmDelete }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h6'>{t('confirm_delete')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction='row'
              spacing={3}
              justifyContent='flex-end'
              alignItems='center'
            >
              <EDButton variant='text' onClick={onClose}>
                {t('cancel')}
              </EDButton>
              <EDButton
                variant='contained'
                disableElevation
                onClick={onConfirmDelete}
              >
                {t('delete')}
              </EDButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteDialog;
