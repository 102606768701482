import React from 'react';
import { useTranslation } from 'react-i18next';
import usePlayQuizLobby from './usePlayQuizLobby';
import GameQuizHeader from '../components/gameQuizHeader/GameQuizHeader';
import GameQuizTitle from '../components/gameQuizTitle/GameQuizTitle';
import GameQuizButton from '../components/gameQuizButton/GameQuizButton';
import LobbyPlayerCounter from '../components/lobbyPlayerCounter/LobbyPlayerCounter';
import PlayerCard from '../components/playerCard/PlayerCard';
import LobbyRotatingText from '../components/lobbyRotatingText/LobbyRotatingText';
import './playQuizLobby.scss';

const PlayQuizLobby = ({
  quizName,
  gotoCountdown,
  playerList,
  totalPlayers,
  setPlayerList,
  playSoundEffect,
  currentGameGuid
}) => {
  const {
    connectedPlayers,
    onResendInvitations,
    userIsDemo,
    isLoadingResendInvitation
  } = usePlayQuizLobby({
    playerList,
    setPlayerList,
    playSoundEffect,
    currentGameGuid
  });

  const { t } = useTranslation();

  return (
    <div className='play-quiz-lobby'>
      <div className='play-quiz-lobby__header'>
        <GameQuizHeader
          leftComponent={
            userIsDemo && (
              <GameQuizButton
                text={
                  isLoadingResendInvitation
                    ? t('quiz_resend_invitations_button_loading')
                    : t('quiz_resend_invitations_button')
                }
                onClick={onResendInvitations}
                outline
                disabled={isLoadingResendInvitation}
              />
            )
          }
          centerComponent={<GameQuizTitle title={quizName} />}
          rightComponent={
            <GameQuizButton
              text={t('quiz_start_button')}
              onClick={gotoCountdown}
            />
          }
        />
      </div>
      <div className='play-quiz-lobby__content'>
        <div className='play-quiz-lobby__waiting-text'>
          {connectedPlayers === totalPlayers
            ? t('quiz_ready')
            : t('quiz_waiting_players')}
        </div>
        <div className='play-quiz-lobby__player-counter'>
          <LobbyPlayerCounter
            connectedPlayers={connectedPlayers}
            totalPlayers={totalPlayers}
          />
        </div>

        <div className='play-quiz-lobby__player-list'>
          {playerList?.map((player) => (
            <PlayerCard
              key={player.user_guid}
              name={player.name}
              avatar={player.avatar}
              isConnected={player.is_online}
              size={playerList?.length < 6 ? 'large' : 'small'}
            />
          ))}
        </div>

        <div className='play-quiz-lobby__random-text'>
          <LobbyRotatingText />
        </div>
      </div>
    </div>
  );
};

export default PlayQuizLobby;
