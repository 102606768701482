import { Dialog, DialogContent, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDButton from '../../../../../atoms/EDButton/EDButton';
import EDInputWithLabel from '../../../../../atoms/EDInput/EDInputWithLabel';
import MultilanguageTabs from '../components/MultilanguageTabs';

const CreateAndEditDialog = ({
  isOpen,
  onClose,
  onSubmit,
  guid,
  type,
  languages
}) => {
  const { t } = useTranslation();
  const [canSubmit, setCanSubmit] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('es');
  const [entityLanguages, setEntityLanguages] = useState([
    {
      lang_code: 'es',
      name:
        languages?.find((lang) => lang.lang_code.toLowerCase() === 'es')
          ?.name || ''
    },
    {
      lang_code: 'en',
      name:
        languages?.find((lang) => lang.lang_code.toLowerCase() === 'en')
          ?.name || ''
    },
    {
      lang_code: 'pt',
      name:
        languages?.find((lang) => lang.lang_code.toLowerCase() === 'pt')
          ?.name || ''
    }
  ]);

  const handleEntityNameChange = (langCode) => (e) => {
    setEntityLanguages(
      entityLanguages.map((lang) =>
        lang.lang_code === langCode ? { ...lang, name: e.target.value } : lang
      )
    );
  };

  useEffect(() => {
    setCanSubmit(!entityLanguages.some((language) => language.name === ''));
  }, [entityLanguages]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2'>
              {guid ? t('edit') : t('Create')} {t(type).toLowerCase()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MultilanguageTabs
              currentLanguage={currentLanguage}
              onChangeLanguage={setCurrentLanguage}
              languagesData={entityLanguages}
              languagesField={'name'}
            />
            {currentLanguage === 'es' && (
              <EDInputWithLabel
                label={t('name')}
                value={
                  entityLanguages.find((lang) => lang.lang_code === 'es').name
                }
                onChange={handleEntityNameChange('es')}
                inputRef={(input) => input && input.focus()}
                required
              />
            )}
            {currentLanguage === 'en' && (
              <EDInputWithLabel
                label={t('name')}
                value={
                  entityLanguages.find((lang) => lang.lang_code === 'en').name
                }
                onChange={handleEntityNameChange('en')}
                inputRef={(input) => input && input.focus()}
                required
              />
            )}
            {currentLanguage === 'pt' && (
              <EDInputWithLabel
                label={t('name')}
                value={
                  entityLanguages.find((lang) => lang.lang_code === 'pt').name
                }
                onChange={handleEntityNameChange('pt')}
                inputRef={(input) => input && input.focus()}
                required
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction='row'
              justifyContent='flex-end'
              alignItems='center'
              spacing={2}
            >
              <EDButton variant='text' onClick={onClose}>
                {t('cancel')}
              </EDButton>
              <EDButton
                variant='contained'
                onClick={() =>
                  onSubmit({
                    guid,
                    name: entityLanguages.find(
                      (lang) => lang.lang_code === 'es'
                    ).name,
                    languages: entityLanguages
                  })
                }
                disabled={!canSubmit}
                disableElevation
              >
                {guid ? t('save') : t('Create')}
              </EDButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CreateAndEditDialog;
