import {
  Add,
  ArrowCircleDown,
  ArrowCircleUp,
  ErrorOutline,
  HelpOutline,
  MoreVert,
  SwapVert
} from '@mui/icons-material';
import {
  CardContent,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EDButton from '../../../../../../atoms/EDButton/EDButton';
import EDCard from '../../../../../../atoms/EDCard/EDCard';
import BBTooltip from '../../../../../../atoms/tooltip/BBTooltip';
import './UnitWithLessonsCard.scss';

const UnitWithLessonsCard = ({
  canEditProgram,
  lessons,
  unit,
  totalUnits,
  onCreateLesson,
  onAddLOs,
  onEditUnit,
  onEditLesson,
  onReorderLesson,
  onReorderUnit,
  onDeleteLesson,
  onDeleteUnit,
  programErrors,
  programStandard
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState('unit');
  const [selectedLesson, setSelectedLesson] = useState({});
  const [menuOptions, setMenuOptions] = useState([]);
  const { t } = useTranslation();

  const open = Boolean(anchorEl);
  const handleMenuClick = (event, typeOfMenu) => {
    setSelectedMenu(typeOfMenu);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickOnUnitOptions = (event) => {
    setMenuOptions([
      {
        key: 'add-lesson',
        name: t('program_content_add_lesson'),
        onClick: () => onCreateLesson(unit)
      },
      {
        key: 'edit-unit',
        name: t('program_content_edit_unit'),
        onClick: () => onEditUnit(unit)
      },
      {
        key: 'delete-unit',
        name: t('program_content_delete_unit'),
        onClick: () => onDeleteUnit(unit)
      }
    ]);
    handleMenuClick(event, 'unit');
  };

  const onClickOnLessonOptions = (event, lesson) => {
    setSelectedLesson(lesson);
    setMenuOptions([
      {
        key: 'edit-lesson',
        name: t('program_content_edit_lesson'),
        onClick: () => onEditLesson(lesson)
      },
      {
        key: 'delete-lesson',
        name: t('program_content_delete_lesson'),
        onClick: () => onDeleteLesson(lesson)
      }
    ]);
    handleMenuClick(event, 'lesson');
  };

  const getUniqueStandards = (lessonNodes) => {
    if (!lessonNodes) return;
    let standards = lessonNodes.map((obj) => obj.standards);
    standards = standards.reduce((acc, val) => acc.concat(val), []);
    standards = standards.filter((item) => item.guid !== 'CC');
    let uniqueStandards = Array.from(
      new Set(standards.map((s) => JSON.stringify(s)))
    ).map((s) => JSON.parse(s));
    return uniqueStandards;
  };

  const renderDependencyErrorsData = (guid) => {
    const errorsData = programErrors?.dependencyProblemsData?.filter(
      (item) => item.lo.guid === guid
    );

    const uniqueErrorsData = [
      ...new Map(
        errorsData.map((item) => [item?.lo?.kc?.dependency?.lo?.name, item])
      ).values()
    ];

    return (
      <div className='dependency-error-popup'>
        <div className='dependency-error-popup__title'>
          {t('program_editor_program_errors_dependency_tag')}:
        </div>

        {uniqueErrorsData.map((errorItem, index) => (
          <div className='dependency-error-popup__block' key={index}>
            <p>KC: {errorItem?.lo?.kc?.name}</p>
            <p>LO: {errorItem?.lo?.kc?.dependency?.lo?.name}</p>
            <p>
              #{errorItem?.lo?.kc?.dependency?.lesson?.order} -{' '}
              {errorItem?.lo?.kc?.dependency?.lesson?.name}
            </p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Grid item xs={12} className='unit-with-lessons-card'>
      <EDCard elevation={0}>
        <CardContent>
          <div className='unit-with-lessons-card__content'>
            <details>
              {/* Unit header */}
              <summary>
                <div className='unit-with-lessons-card__unit'>
                  <div className='unit-with-lessons-card__unit-title'>
                    <Typography variant='h2' color='GrayText'>
                      #{unit.order}
                    </Typography>
                    <Typography variant='h2'>
                      {unit.languages[0]?.name || unit.name}
                    </Typography>
                  </div>

                  <div className='unit-with-lessons-card__unit-info'>
                    <div className='unit-with-lessons-card__unit-lessons'>
                      <Typography variant='h2' color='GrayText'>
                        {lessons.length}{' '}
                        {lessons.length === 1
                          ? t('program_content_lesson')
                          : t('program_content_lessons')}
                      </Typography>
                    </div>
                    {canEditProgram && (
                      <div className='unit-with-lessons-card__unit-actions'>
                        {unit.order > 1 && (
                          <IconButton
                            title={t('program_content_move_unit_up')}
                            onClick={() =>
                              onReorderUnit({ newOrder: unit.order - 1 })
                            }
                          >
                            <ArrowCircleUp />
                          </IconButton>
                        )}
                        {unit.order < totalUnits && (
                          <IconButton
                            title={t('program_content_move_unit_down')}
                            onClick={() =>
                              onReorderUnit({ newOrder: unit.order + 1 })
                            }
                          >
                            <ArrowCircleDown />
                          </IconButton>
                        )}
                        <IconButton onClick={onClickOnUnitOptions}>
                          <MoreVert />
                        </IconButton>
                      </div>
                    )}
                  </div>
                </div>
              </summary>
              {/* Lessons */}
              {lessons.map((lesson) => (
                <div
                  className={`unit-with-lessons-card__lesson ${
                    programErrors?.lessonsWithDependencyProblems?.includes(
                      lesson.guid
                    )
                      ? 'unit-with-lessons-card__lesson--error'
                      : ''
                  }`}
                  key={lesson.guid}
                >
                  {/* Lessons header */}
                  <div className='unit-with-lessons-card__lesson-header'>
                    {/* Lessons add LO */}

                    {canEditProgram && (
                      <div className='unit-with-lessons-card__add-lo-button'>
                        <IconButton
                          onClick={() => onAddLOs({ lesson })}
                          sx={{
                            borderRadius: 2,
                            backgroundColor: '#F5F5F5'
                          }}
                          size='small'
                        >
                          <Add color='primary' />
                        </IconButton>
                      </div>
                    )}

                    {/* Lessons Title */}
                    <div className='unit-with-lessons-card__lesson-title'>
                      <Typography variant='h3' color='GrayText'>
                        #{unit.order}.{lesson.order}
                      </Typography>
                      <Typography variant='h3'>
                        {lesson.languages[0]?.name || lesson.name}
                      </Typography>
                    </div>

                    {/* Lessons actions*/}
                    {canEditProgram && (
                      <div className='unit-with-lessons-card__lesson-actions'>
                        {lesson.order > 1 && (
                          <IconButton
                            title={t('program_content_move_lesson_up')}
                            className='unit-with-lessons-card__order-btn'
                            onClick={() => {
                              onReorderLesson({
                                lesson: lesson,
                                newOrder: lesson.order - 1
                              });
                            }}
                          >
                            <ArrowCircleUp />
                          </IconButton>
                        )}
                        {lesson.order < lessons.length && (
                          <IconButton
                            title={t('program_content_move_lesson_down')}
                            className='unit-with-lessons-card__order-btn'
                            onClick={() => {
                              onReorderLesson({
                                lesson: lesson,
                                newOrder: lesson.order + 1
                              });
                            }}
                          >
                            <ArrowCircleDown />
                          </IconButton>
                        )}

                        <IconButton
                          onClick={(event) =>
                            onClickOnLessonOptions(event, lesson)
                          }
                        >
                          <MoreVert />
                        </IconButton>
                      </div>
                    )}
                  </div>

                  {/* Lessons LO and Standards lists */}
                  <div className='unit-with-lessons-card__lesson-content'>
                    <div className='unit-with-lessons-card__lesson-chip-list'>
                      {getUniqueStandards(lesson?.nodes)
                        .filter((standard) => {
                          if (programStandard === 'CC') {
                            return standard?.guid.startsWith('CCSS');
                          } else {
                            return !standard?.guid.startsWith('CCSS');
                          }
                        })
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((standard) => {
                          return (
                            <BBTooltip
                              text={standard.skills}
                              key={standard.guid}
                            >
                              <Chip
                                key={standard.guid}
                                label={standard.name}
                                size='small'
                                className='unit-with-lessons-card__lesson-standard-chip'
                              />
                            </BBTooltip>
                          );
                        })}
                    </div>

                    <div className='unit-with-lessons-card__lesson-chip-list'>
                      {lesson?.nodes
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((node, index) => (
                          <>
                            {programErrors?.nodesWithErrors?.includes(
                              node.guid
                            ) ? (
                              <div className='unit-with-lessons-card__error-icon-container'>
                                {/* Display error - nodes without seeds */}
                                {programErrors?.nodesWithoutSeeds?.includes(
                                  node.guid
                                ) ? (
                                  <BBTooltip
                                    text={t(
                                      'program_editor_program_errors_seeds_tag'
                                    )}
                                    key={index}
                                  >
                                    <HelpOutline className='unit-with-lessons-card__error-icon' />
                                  </BBTooltip>
                                ) : null}
                                {/* Display error - nodes without KC */}
                                {programErrors?.nodesWithoutKC?.includes(
                                  node.guid
                                ) ? (
                                  <BBTooltip
                                    text={t(
                                      'program_editor_program_errors_kc_tag'
                                    )}
                                    key={index}
                                  >
                                    <ErrorOutline className='unit-with-lessons-card__error-icon' />
                                  </BBTooltip>
                                ) : null}
                                {/* Display error - nodes with dependency problems */}
                                {programErrors?.nodesWithDependencyProblems?.includes(
                                  node.guid
                                ) ? (
                                  <BBTooltip
                                    text={renderDependencyErrorsData(node.guid)}
                                    key={index}
                                  >
                                    <SwapVert className='unit-with-lessons-card__error-icon' />
                                  </BBTooltip>
                                ) : null}
                              </div>
                            ) : null}
                            <BBTooltip text={node.description} key={node.guid}>
                              <Chip
                                key={node.guid}
                                label={node.name}
                                size='small'
                                className={`unit-with-lessons-card__lesson-lo-chip ${
                                  programErrors?.nodesWithErrors?.includes(
                                    node.guid
                                  )
                                    ? 'unit-with-lessons-card__lesson-lo-chip--error'
                                    : ''
                                }`}
                              />
                            </BBTooltip>
                          </>
                        ))}
                    </div>
                  </div>

                  {programErrors?.lessonsWithDependencyProblems?.includes(
                    lesson.guid
                  ) ? (
                    <div className='unit-with-lessons-card__error-message'>
                      {t('program_editor_program_errors_dependency')}
                    </div>
                  ) : null}
                </div>
              ))}

              {/* Add lesson */}
              {canEditProgram && (
                <div className='unit-with-lessons-card__add-lesson'>
                  <EDButton
                    variant='text'
                    startIcon={<Add color='primary' />}
                    disableElevation
                    onClick={onCreateLesson}
                    color='secondary'
                  >
                    {t('program_content_create_lesson')}
                  </EDButton>
                </div>
              )}
            </details>
          </div>
        </CardContent>
      </EDCard>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuOptions
          .filter((item) => item !== null)
          .map((item) => (
            <MenuItem
              key={item.key}
              onClick={() => {
                handleClose();
                item.onClick();
              }}
            >
              {item.name}
            </MenuItem>
          ))}
      </Menu>
    </Grid>
  );
};

export default UnitWithLessonsCard;
