import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../utils/contexts/AuthContext';

const PrivateRoute = () => {
  const { isLoggedIn } = useContext(AuthContext);
  if (isLoggedIn) {
    return <Outlet />;
  }

  return <Navigate to={`/login${window.location.search}`} />;
};

export default PrivateRoute;
