import { Dialog, styled } from '@mui/material';

const EDDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '1rem'
  },
  '& .MuiDialogContent-root': {
    paddingBottom: theme.spacing(5)
  },
  '& .MuiDialogActions-root': {
    backgroundColor: 'rgba(247, 249, 252, 0.5)',
    padding: '1rem 1.5rem'
  }
}));

export default EDDialog;
